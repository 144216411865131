import React, { useEffect, useRef, useState } from 'react';
import { CandidatureShort } from 'types/Candidature';
import CandidateKanbanSection from 'pages/agency-app/candidates/candidates/candidateKanban/CandidateKanbanSection';
import { AgencyStep, workflowStepEnum } from 'types/AgencyStep';
import Button from 'theme/Button';
import { Checkbox } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import VisitSlotMissingModal
  from 'pages/common/visitSlots/VisitSlotMissingModal';
import { Property } from 'types/Property';
import { Agent } from 'types/Agent';
import { NotificationService } from 'lib/notification';

type Props = {
  steps: { [key: string]: AgencyStep }
  candidatesProps: CandidatureShort[]
  candidateUpdatedAt: number,
  properties: Property[]
  updateCandidatureSection: (candidatures: CandidatureShort[]) => void
  agents: Agent[],
};

export default function CandidateKanban(
  {
    properties,
    candidatesProps,
    updateCandidatureSection,
    agents,
    steps,
    candidateUpdatedAt,
  }: Props,
) {
  const [
    currentSelectionWorkflowStep,
    setCurrentSelectionWorflowStep,
  ] = useState<string | undefined>();
  const [selectedCandidateIds, setSelectedCandidateIds] = useState<string[]>([]);
  const [candidatesMissingProperty, setCandidatesMissingProperty] = useState<string[]>([]);
  const [candidatesMissingTimeSlot, setCandidatesMissingTimeSlot] = useState<string[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [submitting, setSubmitting] = useState(false);
  const candidateQueries = useCandidatureBackend();
  const { sendVisitInvitation, refuseCandidates, askCandidatesDocuments } = candidateQueries;
  const isDragging = useRef(false);
  const doSroll = (offset: number) => {
    if (containerRef.current && isDragging.current) {
      containerRef.current.scroll({ left: containerRef.current.scrollLeft + offset });
      setTimeout(() => doSroll(offset), 10);
    }
  };

  useEffect(() => {
    const onDrag = (event: DragEvent) => {
      if (containerRef.current) {
        if (event.clientX < containerRef.current?.getBoundingClientRect().y) {
          if (!isDragging.current) {
            isDragging.current = true;
            doSroll(-5);
          }
        } else if (event.clientX
          > containerRef.current?.getBoundingClientRect().y
          + containerRef.current?.getBoundingClientRect().width - 130) {
          if (!isDragging.current) {
            isDragging.current = true;
            doSroll(5);
          }
        }
      } else {
        isDragging.current = false;
      }
    };
    const stopDrag = () => {
      isDragging.current = false;
    };
    window.addEventListener('dragover', stopDrag);
    window.addEventListener('drag', onDrag);
    return () => {
      window.removeEventListener('drag', onDrag);
      window.removeEventListener('dragover', stopDrag);
    };
  }, []);

  const sendInvitation = (candidateIds: string[]) => {
    setSubmitting(true);
    sendVisitInvitation.mutateAsync({ candidateIds }).then((visitInvitationResult) => {
      setCandidatesMissingProperty(visitInvitationResult.candidatesMissingProperty);
      setCandidatesMissingTimeSlot(visitInvitationResult.candidatesMissingTimeSlot);
      if (visitInvitationResult.candidatesMissingProperty.length
        + visitInvitationResult.candidatesMissingTimeSlot.length
        < candidateIds.length) {
        setSelectedCandidateIds([
          ...visitInvitationResult.candidatesMissingProperty,
          ...visitInvitationResult.candidatesMissingTimeSlot,
        ]);
        NotificationService.notifySuccess(Messages.t('visit.invitationSend'));
      }
    }).catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const refuseCandidateList = (candidateIds: string[]) => {
    setSubmitting(true);
    refuseCandidates.mutateAsync({ candidateIds })
      .then(() => {
        setSelectedCandidateIds([]);
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const askDocuments = (candidateIds: string[]) => {
    setSubmitting(true);
    askCandidatesDocuments.mutateAsync({ candidateIds })
      .then(() => {
        setSelectedCandidateIds([]);
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  const createCandidateDict = (candidates) => candidates.reduce((acc, value) => {
    acc[value.workflowStep].push(value);
    return acc;
  }, Object.values(steps).reduce((acc, value) => {
    acc[value.id] = [];
    return acc;
  }, {}));

  const [candidates, setCandidates] = useState(createCandidateDict(candidatesProps));

  // This is used to manage update of the filters
  const ids = candidatesProps.map((candidate) => candidate.id).join('');

  useEffect(() => {
    setCandidates(createCandidateDict(candidatesProps));
  }, [candidateUpdatedAt, ids]);

  return (
    <>
      {
        (candidatesMissingProperty.length > 0 || candidatesMissingTimeSlot.length > 0) && (
          <VisitSlotMissingModal
            agents={agents}
            properties={properties}
            onSubmitted={() => sendInvitation([
              ...candidatesMissingProperty,
              ...candidatesMissingTimeSlot,
            ])}
            onClose={() => {
              setCandidatesMissingTimeSlot([]);
              setCandidatesMissingProperty([]);
            }}
            candidatesMissingProperty={candidatesProps
              .filter((candidate) => candidatesMissingProperty.includes(candidate.id))}
            candidatesMissingTimeSlot={candidatesProps
              .filter((candidate) => candidatesMissingTimeSlot.includes(candidate.id))}
          />
        )
      }
      {
        selectedCandidateIds.length > 0 && (
          <div className="kanban-actions">
            <Checkbox checked onClick={() => setSelectedCandidateIds([])} />
            {Messages.t('candidate.selectedContacts', { number: selectedCandidateIds.length })}
            <Button disabled={submitting} onClick={() => refuseCandidateList(selectedCandidateIds)} color="secondary">
              {Messages.t('candidate.refuse')}
            </Button>
            {
              steps[currentSelectionWorkflowStep || '']?.stepKey !== workflowStepEnum.VIEWING_BOOKED
              && (
                <Button disabled={submitting} onClick={() => sendInvitation(selectedCandidateIds)}>
                  {Messages.t('candidate.proposeViewing')}
                  {
                    steps[currentSelectionWorkflowStep || '']?.stepKey === workflowStepEnum.VIEWING_PROPOSED && Messages.t('candidate.proposeViewing.renewal')
                  }
                </Button>
              )
            }
            <Button disabled={submitting} onClick={() => askDocuments(selectedCandidateIds)}>
              {Messages.t('candidate.askDocuments')}
            </Button>
          </div>
        )
      }
      <div ref={containerRef} className="candidate-kanban-container">
        {
          Object.keys(candidates)
            .sort((a, b) => (steps[a]?.order || 0) - (steps[b]?.order || 0))
            .map((key) => (
              <CandidateKanbanSection
                key={key}
                sendVisitInvitations={sendInvitation}
                refuseCandidates={refuseCandidateList}
                currentSelectionWorkflowStep={currentSelectionWorkflowStep}
                selectedCandidateIds={selectedCandidateIds}
                setCurrentSelectionWorflowStep={
                  (workFlowStep) => setCurrentSelectionWorflowStep(workFlowStep)
                }
                setSelectedCandidateIds={(candidateIds) => setSelectedCandidateIds(candidateIds)}
                selectCandidateId={(candidateId, wasAlreadySelected, workFlowStep) => {
                  if (wasAlreadySelected) {
                    setSelectedCandidateIds(
                      (prevState) => prevState.filter((id) => id !== candidateId),
                    );
                  } else {
                    setCurrentSelectionWorflowStep(workFlowStep);
                    setSelectedCandidateIds(
                      (prevState) => prevState.concat(candidateId),
                    );
                  }
                }}
                onEnd={() => {
                  const list = (Object.values(candidates) as CandidatureShort[][]).flat();
                  setSelectedCandidateIds([]);
                  updateCandidatureSection(list);
                }}
                workflowStep={steps[key]}
                updateCandidatesList={(newCandidates) => {
                  const candidateSection = newCandidates.map(
                    (candidate, index) => ({
                      ...candidate,
                      workflowStep: key,
                      order: index,
                    }),
                  );
                  setCandidates({
                    ...candidates,
                    [key]: candidateSection,
                  });
                }}
                candidates={candidates[key]}
              />
            ))
        }
      </div>
    </>
  );
}
