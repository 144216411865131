import React from 'react';
import Messages from 'services/i18n/Messages';
import { IconButton, Tooltip } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { GliContract } from 'types/ContractTypes';

type Props = {
  gliContracts: GliContract[],
  agencyRatio?: number,
};

export default function GliSummaryTooltip({ gliContracts, agencyRatio }: Props) {
  return (
    <Tooltip
      classes={{ tooltip: 'property-gli-summary' }}
      title={(
        <div className="popover-gli">
          <div className="content">
            <div>{Messages.t('property.gliTooltip.ratio')}</div>
            <div>
              <p>{agencyRatio || Messages.t('gli.tooltip.ratioUndefined')}</p>
            </div>
          </div>
          <div className="content">
            <div>{Messages.t('property.gliTooltip.situation')}</div>
            <div>
              {gliContracts
                ?.filter((contract) => contract.activated)
                ?.map((contract) => (
                  <div key={contract.id} className="gli-contract">
                    <p>{Messages.t(`gli.${contract.name}`)}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    >
      <IconButton>
        <InfoRounded />
      </IconButton>
    </Tooltip>
  );
}
