import { Token } from 'types/security/token';
// eslint-disable-next-line import/no-cycle
import { authentificationApi } from 'network/api/AuthentificationApi';
import sessionManager from 'services/sessionManager';
import { LogInFormType } from 'types/forms/LogInFormType';

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export class SessionService {
  // eslint-disable-next-line no-undef
  private timeout: NodeJS.Timeout | null = null;

  async logIn(loginData: LogInFormType) {
    const response: Token = await authentificationApi.logIn(loginData);
    sessionManager.setCurrentToken(response.token);
    sessionManager.setRefreshToken(response.refreshToken);
    this.scheduleRefreshSession();
  }

  async getSessionFromFirebase(token: string) {
    const response: Token = await authentificationApi.getTokenFromFirebase(token);
    sessionManager.setCurrentToken(response.token);
    sessionManager.setRefreshToken(response.refreshToken);
    this.scheduleRefreshSession();
  }

  scheduleRefreshSession() {
    if (!this.timeout) {
      this.timeout = setTimeout(() => {
        this.timeout = null;
        const refreshToken = sessionManager.getRefreshToken();
        if (refreshToken) {
          this.refreshSession(refreshToken);
        }
      }, FIVE_MINUTES_IN_MS);
    }
  }

  logOut() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    sessionManager.setCurrentToken(undefined);
    sessionManager.setRefreshToken(undefined);
  }

  async refreshSession(token: string | null) {
    if (token) {
      try {
        const response: Token = await authentificationApi.refreshToken(token);
        sessionManager.setCurrentToken(response.token);
        sessionManager.setRefreshToken(response.refreshToken);
        this.scheduleRefreshSession();
      } catch {
        this.logOut();
        // eslint-disable-next-line no-console
        console.log('Error getting session with refresh token');
      }
    }
  }

  async logInToAgent(agentId: string) {
    try {
      const response: Token = await authentificationApi.logInToAgent(agentId);
      sessionManager.setCurrentToken(response.token);
      sessionManager.setRefreshToken(response.refreshToken);
      this.scheduleRefreshSession();
    } catch {
      // eslint-disable-next-line no-console
      console.log('Error getting session with refresh token');
    }
  }
}

const sessionService = new SessionService();
export default sessionService;
