import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { CandidatureDetailled } from 'types/Candidature';
import { CircularProgress } from '@material-ui/core';
import {
  ADD,
  CANDIDATE_DETAIL,
  CANDIDATE_DETAIL_DETAILS, CANDIDATE_DETAIL_DOCUMENTS,
  ID,
} from 'routes/Routes';
import { Routes } from 'routes/RoutesUtils';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import CandidatureRouter from 'pages/agency-app/candidates/candidateDetails/CandidatureRouter';
import { DocumentDataMap } from 'types/DocumentData';
import VisitDialog from 'pages/common/visits/VisitDialog';
import { Agent } from 'types/Agent';

type Props = {
  candidature?: CandidatureDetailled,
  documentDataMap?: DocumentDataMap,
  agents?: Agent[]
  dataUpdatedAt: number,
};

export default function CandidatureTabs(
  {
    candidature,
    documentDataMap,
    agents,
    dataUpdatedAt,
  }: Props,
) {
  const location = useLocation();
  const propertiesQueries = usePropertiesBackend();
  const { getProperties } = propertiesQueries;
  const { data: properties } = getProperties();

  const [showVisitCreationModal, setShowCreationVisitModal] = useState(false);

  const summaryLink = Routes.withPath(CANDIDATE_DETAIL,
    [{ label: ID, value: candidature?.id || ADD }]);
  const detailsLink = Routes.withPath(CANDIDATE_DETAIL_DETAILS,
    [{ label: ID, value: candidature?.id || ADD }]);
  const documentLink = Routes.withPath(CANDIDATE_DETAIL_DOCUMENTS,
    [{ label: ID, value: candidature?.id || '' }]);

  if (!properties) {
    return (
      <CircularProgress />
    );
  }
  return (
    <>
      {
        showVisitCreationModal && properties && candidature && (
          <VisitDialog
            onClose={() => setShowCreationVisitModal(false)}
            properties={properties}
            agents={agents || []}
            candidatures={[candidature]}
            defaultCandidature={candidature}
            open
          />
        )
      }
      <>
        <nav className="candidate-menu page-selector-container">
          <Link
            replace
            className={`page-selector ${location.pathname === summaryLink ? 'selected' : ''}`}
            to={summaryLink}
          >
            {Messages.t('menuCandidate.summary')}
          </Link>
          <Link
            replace
            className={`page-selector ${location.pathname === detailsLink ? 'selected' : ''}`}
            to={detailsLink}
          >
            {Messages.t('menuCandidate.details')}
          </Link>
          {
            candidature && (
              <Link
                replace
                className={`page-selector ${location.pathname === documentLink ? 'selected' : ''}`}
                to={documentLink}
              >
                {Messages.t('candidature.documents')}
              </Link>
            )
          }
        </nav>
        <CandidatureRouter
          dataUpdatedAt={dataUpdatedAt}
          agents={agents}
          documentDataMap={documentDataMap}
          candidature={candidature}
        />
      </>
    </>
  );
}
