import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { AgentForm } from 'types/forms/AgentForm';
import { userApi } from 'network/api/UserApi';
import { NotificationService } from 'lib/notification';
import { passwordRegex } from 'lib/form/FormUtils';

export default function ProfilePasswordForm() {
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AgentForm>({});

  const onSubmit: SubmitHandler<AgentForm> = (formData: AgentForm) => {
    setSubmitting(true);
    userApi.updatePassword(formData)
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <h4>{Messages.t('parameters.modifyPassword')}</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <Controller
            name="password"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: passwordRegex,
                message: Messages.t('form.error.password'),
              },
            }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="password"
                control={controller}
                label={Messages.t('field.passwordChange')}
              />
            )}
          />
        </div>
        <SpinButton
          editing
          spin={submitting}
          title={Messages.t('formButton.modify')}
        />
      </form>
    </div>
  );
}
