import React from 'react';
import { Pinel } from 'types/Pinel';
import Messages from 'services/i18n/Messages';

type Props = {
  pinelBareme: Pinel[],
};

export default function PinelTable({ pinelBareme }: Props) {
  return (
    <>
      <span>{Messages.t('pinel.tableTitle')} :</span>
      <div className="pinel-table">
        <div className="zones">
          <div className="row">
            <div className="td" />
            <div className="td highlighted">
              {Messages.t('pinel.table.zones')}
            </div>
          </div>
          <div className="row all-zones">
            <div className="td with-background" />
            <div className="td with-background">{Messages.t('pinel.zoneABis')}</div>
            <div className="td with-background">{Messages.t('pinel.zoneRestA')}</div>
            <div className="td with-background">{Messages.t('pinel.zoneB1')}</div>
            <div className="td with-background">{Messages.t('pinel.zoneB2C')}</div>
          </div>
        </div>
        <div className="tbody">
          {pinelBareme
            .sort((a, b) => a.zoneABis - b.zoneABis)
            .slice(0, 6).map((composition) => (
              <div key={composition.id} className="row">
                <div className="td with-background">
                  {Messages.t(`pinel.${composition.foyerComposition}`)}
                </div>
                <div className="td">{composition.zoneABis} €</div>
                <div className="td">{composition.zoneRestA} €</div>
                <div className="td">{composition.zoneB1} €</div>
                <div className="td">{composition.zoneB2} €</div>
              </div>
            ))}
          <div className="row">
            <div className="td with-background">
              {Messages.t('pinel.from5thPerson')}
            </div>
            <div className="td">{pinelBareme[8].zoneABis - pinelBareme[7].zoneABis} €</div>
            <div className="td">{pinelBareme[8].zoneRestA - pinelBareme[7].zoneRestA} €</div>
            <div className="td">{pinelBareme[8].zoneB1 - pinelBareme[7].zoneB1} €</div>
            <div className="td">{pinelBareme[8].zoneB2 - pinelBareme[7].zoneB2} €</div>
          </div>
        </div>
      </div>
    </>
  );
}
