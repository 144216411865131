import React, { useState } from 'react';
import Image from 'theme/Image';
import { Property, PropertyDetailled, PropertyPartial } from 'types/Property';
import Messages from 'services/i18n/Messages';
import { ArrowsExpandIcon, LocationMarkerIcon, TemplateIcon } from '@heroicons/react/solid';
import StringUtils from 'services/StringUtils';
import StepStatus from 'pages/agency-app/appartments/appartments/StepStatus';
import { Link } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, PROPERTY_DETAIL } from 'routes/Routes';
import CandidatureUtils from 'services/CandidatureUtils';
import DateUtils from 'services/DateUtils';
import { AgencyStep } from 'types/AgencyStep';
import {
  IconButton,
  Menu,
  MenuItem,
  Popover,
} from '@material-ui/core';
import { MoreVert, WarningAmber } from '@material-ui/icons';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { NotificationService } from 'lib/notification';
import { Controller, useForm } from 'react-hook-form';
import SelectWrapper from 'lib/form/SelectWrapper';
import { Agent } from 'types/Agent';
import AgentUtils from 'services/AgentUtils';
import confirmationService from 'services/ConfirmationService';
import PropertyUtils from 'services/PropertyUtils';

type Props = {
  steps: { [key: string]: AgencyStep }
  agents?: Agent[]
  property: PropertyDetailled
};

export default function PropertyListItem({ property, steps, agents }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [modalAssignAgentOpen, setModalAssignAgentOpen] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssignClick = (event) => {
    setModalAssignAgentOpen(event.currentTarget);
  };

  const handleAssignClose = () => {
    setModalAssignAgentOpen(null);
  };
  const openAssign = Boolean(modalAssignAgentOpen);

  const open = Boolean(anchorEl);
  const propertiesQueries = usePropertiesBackend();
  const { updateProperty } = propertiesQueries;

  const submitProperty = (data: PropertyPartial) => {
    setSubmitting(true);
    updateProperty.mutateAsync(data)
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        handleAssignClose();
        handleClose();
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const archiveProperty = async (propertyId: string) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('confirmation.archivage.title'),
      message: Messages.t('confirmation.archivage.message'),
      actionMessage: Messages.t('confirmation.archivage.action'),
    });
    handleClose();
    if (isConfirmed) {
      submitProperty({ id: propertyId, isArchived: true });
    }
  };

  const {
    control,
  } = useForm<Property>({ defaultValues: property });

  const countByStep = CandidatureUtils.getCountByStep(property.candidatures);
  return (
    <>
      <Link
        to={Routes.withPath(PROPERTY_DETAIL, [{ label: ID, value: property.id }])}
        className="property-list-item"
      >
        <div className="property-image">
          <Image
            src={PropertyUtils.getPropertyMainImage(property)}
            alt="property"
          />
          <div className="image-tag-container">
            <div className={`image-tag ${property.online ? 'success' : 'error'}`}>
              {
                property.online
                  ? Messages.t('status.online')
                  : Messages.t('status.offline')
              }
            </div>
          </div>
        </div>
        <div className="property-info">
          <div>
            <div className="property-price">
              {property.price} &euro;<span className="price-month">{Messages.t('price.byMonth')}</span>
            </div>
            <div>
              <TemplateIcon />
              {property.rooms}
              <ArrowsExpandIcon />
              {property.area} m2
            </div>
            <div className="property-address">
              <LocationMarkerIcon />
              <span>
                {
                  property.address
                    ? property.address
                    : `${StringUtils.getPrettyAddress(property.city)}, ${property.zipCode || ''}`
                }
              </span>
            </div>
            <div className="tag-container">
              {!property.agentID && (
                <div className="contact-tag">
                  <div>
                    <WarningAmber />
                  </div>
                  <small>{Messages.t('status.unassigned')}</small>
                </div>
              )}
            </div>
          </div>
          <div className="property-dates">
            <div>
              {
                property.lastPublishAt
                && Messages.t('property.publishDate', { date: DateUtils.getPrettyDateDeltaFromNow(property.lastPublishAt, true, true) })
              }
            </div>
            <div>
              {
                !property.online
                && property.lastUnpublishAt
                && Messages.t('property.unPublishDate', { date: DateUtils.getPrettyDateDeltaFromNow(property.lastUnpublishAt, true, true) })
              }
            </div>
          </div>
        </div>
        <div className="property-steps-status">
          {
            Object.values(steps)
              .sort((a, b) => (a.order || 0) - (b.order || 0))
              .map((step) => (
                <StepStatus key={step.id} count={countByStep[step.id] || 0} label={step.stepName} />
              ))
          }
        </div>
        <div className="property-identifier">
          {
            property.identifier && (
              `#${property.identifier}`
            )
          }
        </div>
        <div
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className="menu-container"
        >
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
        </div>
      </Link>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disabled={submitting}
          onClick={() => (property?.isArchived
            ? submitProperty({ id: property.id, isArchived: !property.isArchived })
            : archiveProperty(property.id))}
        >
          {
            property.isArchived
              ? Messages.t('property.unArchive')
              : Messages.t('property.archive')
          }
        </MenuItem>
        <MenuItem
          disabled={submitting}
          onClick={handleAssignClick}
        >
          {Messages.t('property.assignAgent')}
        </MenuItem>
      </Menu>
      <Popover
        anchorEl={modalAssignAgentOpen}
        open={openAssign}
        onClose={handleAssignClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          agents && (
            <Controller
              name="agentID"
              control={control}
              render={(controller) => (
                <SelectWrapper
                  error={{}}
                  onChange={(agentId) => submitProperty({ agentID: agentId, id: property.id })}
                  control={controller}
                  label={Messages.t('field.agent')}
                  values={agents.map((agent) => ({
                    key: agent.id,
                    label: AgentUtils.getPrettyName(agent),
                  }))}
                />
              )}
            />
          )
        }
      </Popover>
    </>
  );
}
