import React from 'react';
import { addDays, format, isSameDay } from 'date-fns';
import StringUtils from 'services/StringUtils';
import localService from 'services/i18n/LocalService';
import Messages from 'services/i18n/Messages';

type Props = {
  day: Date
};

export default function AgentDate({ day }: Props) {
  return (
    <div className="agent-date">
      {
        isSameDay(new Date(), day)
          ? Messages.t('dates.today')
          : (
            isSameDay(new Date(), addDays(day, -1))
              ? Messages.t('dates.tomorrow')
              : StringUtils.capitalizeFirstLetter(format(day, 'EEEE dd MMMM', { locale: localService.getDateLocal() }))
          )
      }
    </div>
  );
}
