import React, { ReactNode, useCallback, useEffect } from 'react';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import withCluster from 'lib/googleMap/WithCluster';
import withMap from 'lib/googleMap/Withmap';
/* global google */

type Props = {
  center?: {
    lng: number,
    lat: number,
  },
  coordinates?: google.maps.LatLng,
  map: google.maps.Map,
  children: ReactNode,
  onClose: () => void,
  isOpen: boolean
  cluster?: MarkerClusterer,
};

const CLASSNAME = 'gmap-overlay-container';

function GoogleOverlay(
  {
    center,
    map,
    coordinates,
    children,
    onClose,
    isOpen,
    cluster,
  }: Props,
) {
  const overlayRef = React.useRef<google.maps.InfoWindow | null>(null);

  const setOverlay = (overlay: google.maps.InfoWindow | null) => {
    overlayRef.current = overlay;
  };

  const divRef = React.useRef<HTMLDivElement | null>(null);

  const ref = useCallback((node: HTMLDivElement | null) => {
    divRef.current = node;
    createOverlay();
    // @ts-ignore
  }, []);

  useEffect(() => {
    const close = () => {
      if (overlayRef.current) {
        overlayRef.current.close();
        onClose();
        setOverlay(null);
      }
    };
    map.addListener('click', () => {
      close();
    });
    if (cluster) {
      cluster.addListener('click', () => {
        close();
      });
    }
  }, []);

  useEffect(() => {
    createOverlay();
  }, [isOpen]);

  useEffect(() => {
    if (overlayRef.current && isOpen) {
      overlayRef.current?.setPosition(center);
    }
  }, [center?.lat, center?.lng]);

  const createOverlay = () => {
    if (!overlayRef.current && isOpen && divRef.current && (coordinates || center)) {
      const infowindow = new google.maps.InfoWindow({
        content: divRef.current,
        position: center ? new google.maps.LatLng(center.lat, center.lng) : coordinates,
      });
      infowindow.open(map);
      // trick to set a className to a tooltip
      setTimeout(() => {
        const topParentElt = document.getElementsByClassName(CLASSNAME)[0]
          .parentElement?.parentElement?.parentElement;
        if (topParentElt) {
          topParentElt.className = `${topParentElt.className} custom-tooltips`;
        }
      }, 50);

      setOverlay(infowindow);
    }
  };

  return (
    <div className={CLASSNAME} ref={ref}>
      {children}
    </div>
  );
}

export default withCluster(withMap(GoogleOverlay));
