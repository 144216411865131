import React from 'react';
import {
  AGENDA_BASE,
  APPARTMENT,
  CANDIDATE,
  DASHBOARD,
  PARAMETERES,
} from 'routes/Routes';
import Dashboard from 'pages/agency-app/dashboard/Dashboard';
import Agenda from 'pages/agency-app/agenda/Agenda';
import Parameters from 'pages/agency-app/parameters/Parameters';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppartmentsRouter from 'pages/agency-app/appartments/AppartmentsRouter';
import CandidatesRouter from 'pages/agency-app/candidates/CandidatesRouter';

export default function AgencyRouter() {
  return (
    <Switch>
      <Route exact path={DASHBOARD} component={Dashboard} />
      <Route path={APPARTMENT} component={AppartmentsRouter} />
      <Route path={CANDIDATE} component={CandidatesRouter} />
      <Route path={AGENDA_BASE} component={Agenda} />
      <Route path={PARAMETERES} component={Parameters} />
      <Route render={() => <Redirect to={DASHBOARD} />} />
    </Switch>
  );
}
