import React, { useState } from 'react';
import {
  CANDIDATE,
  CANDIDATE_DETAIL,
  ID,
  PROPERTY_DETAIL,
} from 'routes/Routes';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import {
  Property,
  PropertyDetailled,
  PropertyForm,
  PropertyImage, propertyZoneEnum,
} from 'types/Property';
import SelectWrapper from 'lib/form/SelectWrapper';
import AddressAutocompleteWrapper from 'lib/form/AddressAutocompleteWrapper';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { booleanToString, stringToBoolean, stringToNumber } from 'lib/form/FormUtils';
import ImageUpload from 'lib/ImageUpload';
import { FileUpload } from 'types/FileUpload';
import Image from 'theme/Image';
import CarrouselModal from 'lib/CarrouselModal';
import StepStatus from 'pages/agency-app/appartments/appartments/StepStatus';
import CandidatureUtils from 'services/CandidatureUtils';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { NotificationService } from 'lib/notification';
import StringUtils from 'services/StringUtils';
import confirmationService from 'services/ConfirmationService';
import AgentUtils from 'services/AgentUtils';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { RouterPrompt } from 'pages/common/RouterPrompt';
import Button from 'theme/Button';
import VisitSlotDialog from 'pages/common/visitSlots/VisitSlotDialog';
import { PROPERTY_ID } from 'routes/QueryParams';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import PropertyVisitSlotList from 'pages/common/visitSlots/PropertyVisitSlotList';
import VisitDialog from 'pages/common/visits/VisitDialog';
import PropertyUtils from 'services/PropertyUtils';
import SwitchWrapper from 'lib/form/SwitchWrapper';
import DialogNoGli from 'pages/agency-app/appartments/propertyDetails/DialogNoGli';
import GliSummaryTooltip from 'pages/agency-app/appartments/propertyDetails/GliSummaryTooltip';
import PinelTooltip from 'pages/agency-app/appartments/propertyDetails/PinelTooltip';
import ListWrapper from 'lib/form/ListWrapper';
import { isAfter } from 'date-fns';

type Props = {
  property?: PropertyDetailled,
  propertyCreation?: PropertyForm,
  candidateId?: string,
};

export default function PropertyDetailsForm(
  { property, propertyCreation, candidateId }: Props,
) {
  const [apiErrors] = useState({});
  const location = useLocation();
  const [selectedVisitSlot, setSelectedVisitSlot] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showVisitCreationModal, setShowVisitCreationModal] = useState(false);
  const [openCarrousel, setOpenCarrousel] = useState(false);
  const history = useHistory();
  const propertiesQueries = usePropertiesBackend();
  const {
    createProperty,
    updateProperty,
    uploadPropertyImage,
    updatePropertyImage,
    deletePropertyImage,
    getPropertyVisitSlot,
  } = propertiesQueries;
  const [openTimeSlotModal, setOpenTimeSlotModal] = useState(false);
  const [openNoGliInfoModal, setOpenNoGliInfoModal] = useState(false);
  const agenciesQueries = useAgencyBackend();
  const { getAgencySteps, getCurrentAgency, getGliContracts } = agenciesQueries;
  const { data: steps } = getAgencySteps();
  const { data: agency } = getCurrentAgency();
  const { data: gliContracts } = getGliContracts();
  const agentQueries = useAgentsBackend();
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();

  const candidatureQueries = useCandidatureBackend();
  const { getCandidatures } = candidatureQueries;
  const { data: candidatures } = getCandidatures();

  const propertyToShow = propertyCreation || property;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<PropertyForm>({
    defaultValues: {
      ...propertyToShow,
      description: propertyToShow?.description?.replaceAll('\\n', '\n'),
      furnished: booleanToString(propertyToShow?.furnished),
      address: propertyToShow?.address || ((propertyToShow?.zipCode && propertyToShow?.city) ? `${propertyToShow?.zipCode} ${StringUtils.getPrettyAddress(propertyToShow?.city)}, ${Messages.t('field.france')}` : ''),
    },
  });
  const { data: visitSlots } = getPropertyVisitSlot(property?.id || '', !!property?.id);
  const formfield = watch();

  const onSubmit: SubmitHandler<PropertyForm> = async (formData: PropertyForm) => {
    const data: Property = {
      ...formData,
      floor: stringToNumber(formData.floor),
      bedrooms: stringToNumber(formData.bedrooms),
      bathrooms: stringToNumber(formData.bathrooms),
      furnished: stringToBoolean(formData.furnished),
      price: stringToNumber(formData.price),
      area: stringToNumber(formData.area),
      rooms: stringToNumber(formData.rooms),
    };
    if (property) {
      setSubmitting(true);
      updateProperty.mutateAsync(data)
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
          reset(formData);
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      if (!formData.agentID || formData.agentID === '') {
        const isConfirmed = await confirmationService.confirm({
          title: Messages.t('confirmation.noAgent.title'),
          message: agency?.notificationMail ? Messages.t('confirmation.noAgent.message', { mail: agency?.notificationMail || '' }) : Messages.t('confirmation.noAgent.messageNoMail'),
        });
        if (!isConfirmed) {
          return;
        }
      }
      setSubmitting(true);
      createProperty.mutateAsync(data)
        .then((newProperty) => {
          NotificationService.notifySuccess(Messages.t('notifications.announceCreate'));
          reset(formData);
          if (candidateId) {
            history.push(Routes.withPath(CANDIDATE_DETAIL,
              [{ label: ID, value: candidateId }]));
          } else {
            history.push(Routes.withPath(PROPERTY_DETAIL, [{ label: ID, value: newProperty.id }]));
          }
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const uploadImages = (files: FileUpload[]) => {
    if (property?.id) {
      setSubmitting(true);
      uploadPropertyImage.mutateAsync({ propertyId: property.id, images: files })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      setValue('imagesToUpload', files);
      setShowUploadModal(false);
    }
  };

  const deleteImages = (imageId: string) => {
    if (property?.id) {
      setSubmitting(true);
      deletePropertyImage.mutateAsync({ propertyId: property.id, imageId })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.delete'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const updateImages = (files: PropertyImage[]) => {
    if (property?.id) {
      setSubmitting(true);
      updatePropertyImage.mutateAsync({ propertyId: property.id, images: files })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const countByStep = CandidatureUtils.getCountByStep(property?.candidatures);
  const getCreationImageUrls = (propCreation) => {
    if (formfield.imagesToUpload) {
      return [...formfield.imagesToUpload?.map((image) => image.fileBase64),
        ...propCreation.imageUrls];
    }
    return propCreation.imageUrls;
  };

  return (
    <>
      {
        agents
        && (openTimeSlotModal || selectedVisitSlot) && property && candidatures && (
          <VisitSlotDialog
            properties={[property]}
            agents={agents}
            candidatures={candidatures}
            defaultProperty={property.id}
            visitSlotId={selectedVisitSlot}
            open
            onClose={() => {
              setOpenTimeSlotModal(false);
              setSelectedVisitSlot(undefined);
            }}
          />
        )
      }
      {
        property && agents && candidatures && showVisitCreationModal && (
          <VisitDialog
            candidatures={candidatures}
            properties={[property]}
            agents={agents}
            open
            onClose={() => {
              setShowVisitCreationModal(false);
            }}
          />
        )
      }
      {
        openCarrousel && (
          <CarrouselModal
            imageUrls={
              propertyCreation?.imageUrls.length ? (
                getCreationImageUrls(propertyCreation)
              ) : (
                PropertyUtils.getSortedPropertyImage(property)?.map((image) => image.imageURL)
                || formfield.imagesToUpload?.map((image) => image.fileBase64)
                || []
              )
            }
            onClose={() => setOpenCarrousel(false)}
            isOpen
          />
        )
      }
      <div>
        <div className="property-form-header">
          <div
            className="image-property-container"
            role="presentation"
            onClick={() => setOpenCarrousel(true)}
          >
            <Image
              src={
                PropertyUtils.getPropertyMainImage(property)
                || propertyCreation?.imageUrls?.[0]
                || formfield.imagesToUpload?.[0]?.fileBase64
              }
              alt="property"
            />
            <div className="add-image-icon-button-container" role="presentation" onClick={(e) => e.stopPropagation()}>
              <ImageUpload
                onDelete={deleteImages}
                onUpdate={updateImages}
                images={property?.images}
                submitting={submitting}
                onValidate={uploadImages}
                show={showUploadModal}
                setShow={setShowUploadModal}
              />
            </div>
          </div>
          <div className="property-step-container">
            {
              steps && Object.values(steps)
                .sort((a, b) => (a.order || 0) - (b.order || 0))
                .map((step) => (
                  <StepStatus
                    key={step.id}
                    count={countByStep[step.id] || 0}
                    label={step.stepName}
                  />
                ))
            }
            {
              property && (
                <Link
                  className="link-action"
                  to={
                    Routes.updateUrlWithQuery(
                      CANDIDATE,
                      [{ label: PROPERTY_ID, value: property.id }],
                    )
                  }
                >
                  {Messages.t('property.seeCandidate')}
                </Link>
              )
            }
          </div>
        </div>
        {
          property && (
            <div>
              <div className="property-visit-slot-container">
                <div className="property-visit-slots">
                  {
                    visitSlots && agents && candidatures && (
                      <PropertyVisitSlotList
                        property={property}
                        visitSlots={visitSlots}
                        agents={agents}
                        candidature={candidatures}
                        showShowVisitBtn
                      />
                    )
                  }
                </div>
              </div>
              <div className="visits-actions">
                <Button
                  className="create-slot-button"
                  onClick={() => setOpenTimeSlotModal(true)}
                >
                  {Messages.t('property.createVisitSlot')}
                </Button>
                <Button
                  className="create-slot-button"
                  onClick={() => setShowVisitCreationModal(true)}
                >
                  {Messages.t('property.createVisit')}
                </Button>
              </div>
            </div>
          )
        }
        <form onSubmit={handleSubmit(onSubmit)}>
          <RouterPrompt
            whenPageClose={isDirty}
            when={(newPathName) => isDirty && !newPathName.startsWith(location.pathname.split('/').slice(0, -1).join('/'))}
          />
          <h5>{Messages.t('field.description')}</h5>
          <Controller
            name="description"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="textarea"
                rows={5}
                control={controller}
                label={Messages.t('field.description')}
              />
            )}
          />
          <Controller
            name="identifier"
            control={control}
            rules={{ required: true }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.identifier')}
                prefix={
                  (!formfield.identifier || formfield.identifier === '')
                  && (
                    <button type="button" onClick={() => setValue('identifier', StringUtils.generateId(10))}>
                      {Messages.t('property.generateIdentifier')}
                    </button>
                  )
                }
              />
            )}
          />
          <h5>{Messages.t('field.generalInformations')}</h5>
          <div className="form-row">
            <Controller
              name="price"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="number"
                  control={controller}
                  suffix="€"
                  label={Messages.t('field.price')}
                />
              )}
            />
            <Controller
              name="area"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="number"
                  control={controller}
                  suffix="㎡"
                  label={Messages.t('field.area')}
                />
              )}
            />
          </div>
          <div className="form-row">
            <Controller
              name="rooms"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="number"
                  control={controller}
                  label={Messages.t('field.rooms')}
                />
              )}
            />
            <Controller
              name="bedrooms"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="number"
                  control={controller}
                  label={Messages.t('field.bedrooms')}
                />
              )}
            />
            <Controller
              name="bathrooms"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="number"
                  control={controller}
                  label={Messages.t('field.bathrooms')}
                />
              )}
            />
            <Controller
              name="floor"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="number"
                  control={controller}
                  label={Messages.t('field.floor')}
                />
              )}
            />
            <Controller
              name="furnished"
              control={control}
              render={(controller) => (
                <SelectWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  label={Messages.t('field.furnished')}
                  values={[
                    { key: 'all', label: Messages.t('generics.all') },
                    { key: 'true', label: Messages.t('generics.YES') },
                    { key: 'false', label: Messages.t('generics.NO') },
                  ]}
                />
              )}
            />
          </div>
          <h5>{Messages.t('field.location')}</h5>
          <Controller
            name="address"
            control={control}
            render={(controller) => (
              <AddressAutocompleteWrapper
                apiErrors={apiErrors}
                onPostalCodeChanged={(zipCode) => setValue('zipCode', zipCode)}
                onCityChanged={(city) => setValue('city', city)}
                onCoordinateChanged={(coodinates) => {
                  setValue('latitude', coodinates.latitude);
                  setValue('longitude', coodinates.longitude);
                }}
                error={errors}
                control={controller}
                label={Messages.t('field.address')}
              />
            )}
          />
          {property && (
            <div className="qualification">
              <h5>{Messages.t('field.occupantQualificationLong')}</h5>
              <Controller
                name="enableVisitAutomation"
                control={control}
                rules={{
                  validate: (isEnabled) => {
                    if (isEnabled) {
                      if (!formfield.gliEnabled && !formfield.pinelEnabled) {
                        return Messages.t('form.error.enableVisitQualification');
                      }
                      const now = new Date();
                      if (!visitSlots || visitSlots
                        .filter((slot) => isAfter(new Date(slot.startDate), now)).length === 0) {
                        return Messages.t('form.error.enableVisitQualificationSlot');
                      }
                    }
                    return undefined;
                  },
                }}
                render={(controller) => (
                  <SwitchWrapper
                    control={controller}
                    label={Messages.t('property.enableVisitAutomation')}
                    error={errors}
                  />
                )}
              />
              <div className="gli-property-setting">
                <Controller
                  name="gliEnabled"
                  control={control}
                  render={(controller) => (
                    <SwitchWrapper
                      control={controller}
                      label={Messages.t('property.GLI')}
                      error={errors}
                      changeDisable={!agency?.activateGli}
                      onClick={() => {
                        if (!agency?.activateGli) {
                          setOpenNoGliInfoModal(true);
                        }
                      }}
                    />
                  )}
                />
                {gliContracts && agency?.activateGli && (
                  <GliSummaryTooltip gliContracts={gliContracts} agencyRatio={agency?.gliRatio} />
                )}
              </div>
              {agency && (
                <DialogNoGli
                  open={openNoGliInfoModal}
                  onClose={() => setOpenNoGliInfoModal(false)}
                />
              )}
              <div className="gli-property-setting">
                <Controller
                  name="pinelEnabled"
                  control={control}
                  render={(controller) => (
                    <SwitchWrapper
                      control={controller}
                      label={Messages.t('property.pinel')}
                      error={errors}
                    />
                  )}
                />
                {gliContracts && (
                  <PinelTooltip />
                )}
              </div>
              {
                formfield.pinelEnabled && (
                  <>
                    <p>{Messages.t('property.zoneToDefine')} :</p>
                    <Controller
                      name="zone"
                      control={control}
                      rules={{ required: formfield.pinelEnabled }}
                      render={(controller) => (
                        <ListWrapper
                          control={controller}
                          error={errors}
                          values={(
                            Object.values(propertyZoneEnum).map((key) => (
                              {
                                key,
                                label: `${key !== propertyZoneEnum.zoneRestA ? `${Messages.t('pinel.zone')} ` : ''}${Messages.t(`pinel.${key}`)}`,
                              }
                            ))
                          )}
                        />
                      )}
                    />
                    <a
                      href="https://www.service-public.fr/simulateur/calcul/zonage-abc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {Messages.t('property.findZone')}
                    </a>
                  </>
                )
              }
            </div>
          )}
          {
            agents && !property && (
              <Controller
                name="agentID"
                control={control}
                render={(controller) => (
                  <SelectWrapper
                    error={{}}
                    control={controller}
                    label={Messages.t('field.agent')}
                    values={agents.map((agent) => ({
                      key: agent.id,
                      label: AgentUtils.getPrettyName(agent),
                    }))}
                  />
                )}
              />
            )
          }
          <SpinButton
            editing
            className="submit-button"
            spin={submitting}
            title={Messages.t('formButton.confirm')}
          />
        </form>
      </div>
    </>
  );
}
