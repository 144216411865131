import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import PinelTable from 'pages/agency-app/parameters/occupantQualification/PinelTable';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import Messages from 'services/i18n/Messages';

export default function PinelTooltip() {
  const agencyQueries = useAgencyBackend();
  const { getPinelBareme } = agencyQueries;
  const { data: pinelBareme } = getPinelBareme();

  return (
    <Tooltip
      classes={{ tooltip: 'pinel-summary' }}
      placement="right"
      title={(
        <div>
          {pinelBareme && pinelBareme[0]?.id ? (
            <PinelTable pinelBareme={pinelBareme} />
          ) : (
            <p>{Messages.t('pinel.missingDataDb')}</p>
          )}
        </div>
      )}
    >
      <IconButton>
        <InfoRounded />
      </IconButton>
    </Tooltip>
  );
}
