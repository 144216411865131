import React, { ReactNode } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import confirmationService from 'services/ConfirmationService';
import DialogWrapper from './DialogWrapper';

type Props = {
  title: string,
  message: ReactNode,
  actionColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  actionMessage?: string,
};

export default function ConfirmationModalBase(
  {
    title,
    message,
    actionColor,
    actionMessage,

  }: Props,
) {
  return (
    <DialogWrapper open onClose={() => confirmationService.onClose()}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => confirmationService.onClose()}>{Messages.t('formButton.cancel')}</Button>
        <Button color={actionColor} onClick={() => confirmationService.onConfirm()}>
          {actionMessage || Messages.t('formButton.confirm')}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}
