import React from 'react';
import { Control, Controller } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import { CandidatureForm } from 'types/forms/CandidatureForm';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import PhoneNumberInputWrapper from 'lib/form/PhoneNumberInputWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import { mailRegex } from 'lib/form/FormUtils';
import { situationTypeEnum } from 'types/PersonalSituation';
import { proSituationTypeEnum } from 'types/ProfesionalSituation';
import { contractTypeTypeEnum } from 'types/ContractTypes';
import { useContactBackend } from 'network/queries/ContactQueries';

type Props = {
  control?: Control<CandidatureForm>,
  index: number,
  errors: FieldErrors
  apiErrors: { [key: string]: string[] }
  formField: CandidatureForm,
};

export default function ContactForm(
  {
    index,
    control,
    errors,
    apiErrors,
    formField,
  }: Props,
) {
  const contactQueries = useContactBackend();
  const { getContactModification } = contactQueries;
  const { data: modifications } = getContactModification(formField.contacts[index].id || '', !!formField.contacts[index].id);
  const situationArr = formField.contacts[index].situation?.split(',');
  return (
    <div className="page-content">
      <h5>{Messages.t('field.personalDatas')}</h5>
      <div className="form-row">
        <Controller
          name={`contacts.${index}.firstname` as 'contacts.0.firstname'}
          control={control}
          rules={{ required: { value: !formField.contacts[index]?.lastname, message: Messages.t('form.error.name') } }}
          render={(controller) => (
            <TextFieldWrapper
              modifications={modifications}
              apiErrors={apiErrors}
              error={errors}
              type="text"
              control={controller}
              label={Messages.t('field.firstName')}
            />
          )}
        />
        <Controller
          name={`contacts.${index}.lastname` as 'contacts.0.lastname'}
          control={control}
          rules={{ required: { value: !formField.contacts[index]?.firstname, message: Messages.t('form.error.name') } }}
          render={(controller) => (
            <TextFieldWrapper
              modifications={modifications}
              apiErrors={apiErrors}
              error={errors}
              type="text"
              control={controller}
              label={Messages.t('field.lastName')}
            />
          )}
        />
      </div>
      <div className="form-row">
        <Controller
          name={`contacts.${index}.email` as 'contacts.0.email'}
          control={control}
          rules={{
            required: {
              value: index === 0 && !formField.contacts[index]?.phoneNumber,
              message: Messages.t('form.error.contact'),
            },
            pattern: {
              value: mailRegex,
              message: Messages.t('form.error.email'),
            },
            validate: (email) => {
              if (email && !formField.contacts
                .every((contact, idx) => index === idx || email !== contact.email)
              ) {
                return Messages.t('form.error.emailDuplicate');
              }
              return undefined;
            },
          }}
          render={(controller) => (
            <TextFieldWrapper
              modifications={modifications}
              apiErrors={apiErrors}
              error={errors}
              type="text"
              control={controller}
              label={Messages.t('field.email')}
            />
          )}
        />
        <Controller
          name={`contacts.${index}.phoneNumber` as 'contacts.0.phoneNumber'}
          control={control}
          rules={{
            required: {
              value: index === 0 && !formField.contacts[index]?.email,
              message: Messages.t('form.error.contact'),
            },
            validate: (phoneNumber) => {
              if (phoneNumber && !formField.contacts
                .every((contact, idx) => index === idx || phoneNumber !== contact.phoneNumber)
              ) {
                return Messages.t('form.error.phoneNumberDuplicate');
              }
              return undefined;
            },
          }}
          render={(controller) => (
            <PhoneNumberInputWrapper
              apiErrors={apiErrors}
              modifications={modifications}
              error={errors}
              control={controller}
              label={Messages.t('field.phoneNumber')}
              flag
            />
          )}
        />
      </div>
      <div className="form-row">
        <Controller
          name={`contacts.${index}.type` as 'contacts.0.type'}
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              modifications={modifications}
              control={controller}
              label={Messages.t('field.personalSituation')}
              values={Object.values(situationTypeEnum).map((key) => ({
                key, label: Messages.t(`personalSituations.${key}`),
              }))}
            />
          )}
        />
      </div>
      <h5>{Messages.t('field.professionalDatas')}</h5>
      <div className="form-row">
        <Controller
          name={`contacts.${index}.situation` as 'contacts.0.situation'}
          control={control}
          rules={{ required: true }}
          render={(controller) => (
            <SelectWrapper
              multiple
              apiErrors={apiErrors}
              error={errors}
              modifications={modifications}
              control={controller}
              label={Messages.t('field.professionalSituation')}
              values={Object.values(proSituationTypeEnum).map((key) => ({
                key, label: Messages.t(`professionalSituation.${key}`),
              }))}
            />
          )}
        />
      </div>
      <div className="form-row">
        {
          (situationArr && (situationArr.includes(proSituationTypeEnum.EMPLOYED)
            || situationArr.includes(proSituationTypeEnum.OFFICIAL)
            || situationArr.includes(proSituationTypeEnum.FREELANCER)))
          && (
            <>
              <Controller
                name={`contacts.${index}.profession` as 'contacts.0.profession'}
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    modifications={modifications}
                    error={errors}
                    type="text"
                    control={controller}
                    label={Messages.t('field.profession')}
                  />
                )}
              />
              <Controller
                name={`contacts.${index}.enterprise` as 'contacts.0.enterprise'}
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    modifications={modifications}
                    type="text"
                    control={controller}
                    label={Messages.t('field.enterprise')}
                  />
                )}
              />
            </>
          )
        }
      </div>
      {situationArr && situationArr.includes(proSituationTypeEnum.EMPLOYED)
        && (
          <div className="form-row">
            <Controller
              name={`contacts.${index}.leaseType` as 'contacts.0.leaseType'}
              control={control}
              render={(controller) => (
                <SelectWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  modifications={modifications}
                  label={Messages.t('field.leaseType')}
                  values={Object.values(contractTypeTypeEnum).map((key) => ({
                    key, label: Messages.t(`contractType.${key}`),
                  }))}
                />
              )}
            />
            <Controller
              name={`contacts.${index}.trialPeriod` as 'contacts.0.trialPeriod'}
              control={control}
              render={(controller) => (
                <SelectWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  modifications={modifications}
                  label={Messages.t('field.trialPeriod')}
                  values={[
                    { key: 'all', label: Messages.t('generics.all') },
                    { key: 'true', label: Messages.t('generics.YES') },
                    { key: 'false', label: Messages.t('generics.NO') },
                  ]}
                />
              )}
            />
          </div>
        )}
      {
        formField.contacts[index].situation
        && formField.contacts[index].situation !== proSituationTypeEnum.STUDENT
        && (
          <div className="form-row">
            <Controller
              name={`contacts.${index}.monthlyIncome` as 'contacts.0.monthlyIncome'}
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="text"
                  modifications={modifications}
                  control={controller}
                  label={Messages.t('field.monthlyIncome')}
                />
              )}
            />
          </div>
        )
      }
    </div>
  );
}
