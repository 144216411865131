import React, { useState } from 'react';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import Messages from 'services/i18n/Messages';
import AgendaEventDateDisplay from 'pages/common/calendar/AgendaEventDateDisplay';
import CandidatureUtils from 'services/CandidatureUtils';
import { isAfter, isBefore } from 'date-fns';
import { DialogTitle } from '@material-ui/core';

type Props = {
  propertyId: string
};

export default function PropertyVisits({ propertyId }: Props) {
  const propertiesQueries = usePropertiesBackend();
  const { getPropertyVisits } = propertiesQueries;
  const { data: visits } = getPropertyVisits(propertyId);
  const [showPastVisits, setShowPastVisits] = useState(false);
  const now = new Date();
  return (
    <div className="property-visit-container">
      <DialogTitle>{Messages.t('pageSelector.visit')}</DialogTitle>
      <div className="page-selector-container">
        <button
          type="button"
          className={`page-selector ${!showPastVisits ? 'selected' : ''}`}
          onClick={() => setShowPastVisits(false)}
        >
          {Messages.t('pageSelector.futurVisit')}
        </button>
        <button
          type="button"
          className={`page-selector ${showPastVisits ? 'selected' : ''}`}
          onClick={() => setShowPastVisits(true)}
        >
          {Messages.t('pageSelector.pastVisit')}
        </button>
      </div>
      <h4>{Messages.t('pageSelector.visit')}</h4>
      {(visits && visits.length > 0) ? (
        <>
          <div className="page-selector-container">
            <button
              type="button"
              className={`page-selector ${!showPastVisits ? 'selected' : ''}`}
              onClick={() => setShowPastVisits(false)}
            >
              {Messages.t('pageSelector.futurVisit')}
            </button>
            <button
              type="button"
              className={`page-selector ${showPastVisits ? 'selected' : ''}`}
              onClick={() => setShowPastVisits(true)}
            >
              {Messages.t('pageSelector.pastVisit')}
            </button>
          </div>
          <div className="candidature-visits">
            {
              visits
                .filter((visit) => (
                  showPastVisits
                    ? isBefore(new Date(visit.startDate), now)
                    : isAfter(new Date(visit.startDate), now)))
                .sort((a, b) => (showPastVisits
                  ? b.startDate.localeCompare(a.startDate)
                  : a.startDate.localeCompare(b.startDate)))
                .map((visit) => {
                  const name = CandidatureUtils.getPrettyName(visit.candidature);
                  return (
                    <div
                      key={visit.id}
                      className={`agenda-visit agenda-page-event ${visit.isCancel ? 'canceled' : ''}`}
                    >
                      <AgendaEventDateDisplay
                        event={{
                          start: new Date(visit.startDate),
                          end: new Date(visit.endDate),
                        }}
                        time={{ day: new Date(visit.startDate) }}
                      />
                      <div className="candidate-name">
                        <div className="candidate-initials">
                          {
                            name.split(' ').slice(0, 2).map((word) => (
                              word[0]
                            ))
                          }
                        </div>
                        <div>
                          <div>{name}</div>
                          <div className="candidature-email">
                            {CandidatureUtils.getPrincipalMail(visit.candidature)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
            }
          </div>
        </>
      ) : (
        <p>{Messages.t('visit.noVisit')}</p>
      )}
    </div>
  );
}
