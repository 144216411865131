import React from 'react';
import {
  Autocomplete,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { Property } from 'types/Property';
import Image from 'theme/Image';
import Messages from 'services/i18n/Messages';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import StringUtils from 'services/StringUtils';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { CheckBox, CheckBoxOutlineBlank, OpenInNew } from '@material-ui/icons';
import PropertyUtils from 'services/PropertyUtils';
import { Link } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, PROPERTY_DETAIL } from 'routes/Routes';
import { NONE } from 'services/filters/consts';

type Props = {
  properties: Property[],
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  }, // TODO Better type
  label?: string,
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  onChange?: (value: string[]) => void
  multiple?: boolean,
  requierd?: boolean,
  disableFullwidth?: boolean,
  showOffline?: boolean,
  showEveryButton?: boolean,
  showNone?: boolean,
  shortDisplay?: boolean,
};

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export const SELECT = 'SELECT';
export const PROPERTY_FILTER_KEYS = [SELECT, NONE];

export default function PropertiesFilter(
  {
    control,
    properties,
    disableFullwidth,
    showOffline,
    multiple,
    showNone,
    onChange,
  }: Props,
) {
  const filterOptions = (options, { inputValue }) => PropertyUtils
    .filterProperty(options, inputValue)
    .sort((a, b) => {
      if (typeof a === 'string' && a === SELECT) {
        return -1;
      }
      if (typeof b === 'string' && b === SELECT) {
        return 1;
      }
      return (b?.lastPublishAt || '').localeCompare(a?.lastPublishAt || '');
    });

  const { field } = control;

  const value = (field.value
    && (
      multiple
        ? [
          ...properties.filter((property) => field.value.includes(property.id)),
          ...(field.value.includes(NONE) ? [NONE] : []),
        ]
        : properties.filter((property) => property.id === field.value)[0])
  ) || (multiple ? [] : {});

  let options = showNone ? [SELECT, ...properties, NONE] : properties;

  if (!showOffline) {
    options = options.filter((property) => typeof property === 'string' || property.online);
  }

  const changeValue = (val: (Property | string)[]) => {
    const cleanValue = val.map((option) => {
      if (typeof option === 'string') {
        return option;
      }
      return option.id;
    }).filter((option) => option !== SELECT);
    if (onChange) {
      onChange(cleanValue);
    }
    return field.onChange(cleanValue);
  };

  return (
    <div className={`material-select-wrapper ${disableFullwidth ? 'no-full-widht' : ''}`}>
      <Autocomplete
        {...field}
        value={value}
        onChange={(e, val) => {
          changeValue(val);
        }}
        multiple={multiple}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        options={options}
        disableCloseOnSelect
        limitTags={1}
        freeSolo
        getOptionLabel={(option) => PropertyUtils.getPropertyLabel(option)}
        renderOption={(props, property, { selected }) => (
          typeof property === 'string' && property === SELECT ? (
            multiple && (
              <li {...props} key={SELECT}>
                {
                  field.value && field.value.length > 0 ? (
                    <button
                      className="select-all-button"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        changeValue([]);
                      }}
                    >
                      {Messages.t('formButton.selectNone')}
                    </button>
                  ) : (
                    <button
                      className="select-all-button"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        changeValue(properties);
                      }}
                    >
                      {Messages.t('formButton.selectAll')}
                    </button>
                  )
                }
              </li>
            )
          ) : (
            <li {...props} key={typeof property !== 'string' ? property.id : property}>
              {
                multiple && (
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                )
              }
              {
                typeof property !== 'string' ? (
                  <div
                    className="property-list-item property-selector"
                  >
                    <div className="property-image">
                      <Image
                        src={PropertyUtils.getPropertyMainImage(property)}
                        alt="property"
                      />
                      <div className="image-tag-container">
                        <div className={`image-tag ${property.online ? 'success' : 'error'}`} />
                      </div>
                    </div>
                    <div className="property-info">
                      <div>
                        <div className="property-price">
                          {property.price} &euro;<span className="price-month">{Messages.t('price.byMonth')}</span>
                        </div>
                        <div className="property-address">
                          <LocationMarkerIcon />
                          <span>
                            {
                              property.address
                                ? property.address
                                : `${StringUtils.getPrettyAddress(property.city)}, ${property.zipCode || ''}`
                            }
                          </span>
                        </div>
                        {
                          property.area && (
                            <div><small>{property.area} m2</small></div>
                          )
                        }
                      </div>
                    </div>
                    <div className="property-identifier">
                      {
                        property.identifier && (
                          `#${property.identifier}`
                        )
                      }
                    </div>
                    <div
                      className="property-link"
                      role="presentation"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Link
                        to={Routes.withPath(
                          PROPERTY_DETAIL, [{ label: ID, value: property.id }],
                        )}
                        target="_blank"
                      >
                        <OpenInNew />
                      </Link>
                    </div>
                  </div>
                ) : (
                  <span>{Messages.t('propertiesFilter.noProperty')}</span>
                )
              }
            </li>
          )
        )}
        style={{ width: 450 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={Messages.t('field.property')}
            placeholder={Messages.t('field.search')}
            variant="filled"
            InputProps={{
              disableUnderline: true,
              ...params.InputProps,
            }}
          />
        )}
      />
    </div>
  );
}
