import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { DocumentMail, MailType, mailTypeEnum } from 'types/DocumentData';
import SelectWrapper from 'lib/form/SelectWrapper';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';

type Props = {
  mailTypePossibilies: MailType[]
  sendMail: (data: DocumentMail) => void
  submitting?: boolean
};

export default function DocumentModalForm(
  {
    sendMail,
    mailTypePossibilies,
    submitting,
  }: Props,
) {
  const [apiErrors] = useState({});
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<DocumentMail>({
    defaultValues: {
      mailType: mailTypePossibilies.length === 1 ? mailTypePossibilies[0] : undefined,
    },
  });

  const formFields = watch();

  return (
    <form className="add-agent-form" onSubmit={handleSubmit(sendMail)}>
      {
        mailTypePossibilies.length > 1 && (
          <Controller
            name="mailType"
            rules={{
              required: true,
            }}
            control={control}
            render={(controller) => (
              <SelectWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('field.mailType')}
                values={
                  mailTypePossibilies
                    .map((key) => ({ key, label: Messages.t(`mailType.${key}`) }))
                }
              />
            )}
          />
        )
      }
      {
        formFields.mailType === mailTypeEnum.COMPLETE_CASE && (
          <Controller
            name="comment"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                type="textarea"
                rows={5}
                label={Messages.t('field.missingDocuments')}
              />
            )}
          />
        )
      }
      <SpinButton
        editing
        spin={!!submitting}
        title={Messages.t('formButton.confirm')}
      />
    </form>
  );
}
