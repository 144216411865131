import React, { ReactNode, useRef, useState } from 'react';
import Messages from 'services/i18n/Messages';
import { Controller, useForm } from 'react-hook-form';
import RadioWrapper from 'lib/form/RadioWrapper';
import { booleanToString, stringToBoolean } from 'lib/form/FormUtils';
import {
  AccessTime,
  EditOutlined,
  InfoOutlined, KeyboardArrowDown,
  Pause, PlayArrow, South,
} from '@material-ui/icons';
import SelectWrapper from 'lib/form/SelectWrapper';
import { Agency, AgencyFormType } from 'types/Agency';
import { NotificationService } from 'lib/notification';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import AgencyOpeningHours from 'pages/agency-app/parameters/agencyParameteres/phoneRedirection/AgencyOpeningHours';
import { DialogContent, DialogTitle } from '@material-ui/core';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  agency?: Agency;
};

export default function ForwardCall({ agency }: Props) {
  const [showOpeningHourSettings, setShowOpeningHourSettings] = useState(false);
  const DEFAULT_RING_DURATION = 20;
  const agencyQueries = useAgencyBackend();
  const { updateAgency, getAgencyTimeslots } = agencyQueries;
  const { data: timeslots } = getAgencyTimeslots();

  const {
    control,
    watch,
    trigger,
    formState: { errors },
  } = useForm<AgencyFormType>({
    defaultValues: {
      shouldForwardCall: booleanToString(agency?.shouldForwardCall) || 'false',
      ringDuration: agency?.ringDuration || DEFAULT_RING_DURATION,
      openHoliday: booleanToString(agency?.openHoliday),
    },
  });

  const formField = watch();

  const updateAgencyRedirectionSettings = (data: Partial<Agency>) => {
    if (agency) {
      updateAgency.mutateAsync({ agency: data, agencyId: agency.id })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')));
    }
  };

  return (
    <>
      <div>
        <h5>{Messages.t('parameters.redirectionSubtitle')}</h5>
        <Controller
          name="shouldForwardCall"
          control={control}
          rules={{
            validate: () => {
              if (!agency?.phoneNumber || agency?.phoneNumber === '') {
                return Messages.t('field.forwardCallMandatory');
              }
              return undefined;
            },
          }}
          render={(controller) => (
            <div className="redirect-call-options-wrapper">
              <RadioWrapper
                onChange={async (val) => {
                  const res = await trigger('shouldForwardCall');
                  if (res) {
                    updateAgencyRedirectionSettings({
                      shouldForwardCall: stringToBoolean(val) || false,
                    });
                    if (stringToBoolean(val)) {
                      setShowOpeningHourSettings(true);
                    }
                    return true;
                  }
                  return false;
                }}
                control={controller}
                error={errors}
                row
                values={
                  [
                    { key: 'false', label: Messages.t('field.forwardCall') },
                    { key: 'true', label: Messages.t('field.forwardOnlyIfNotAvailable') },
                  ]
                }
              />
            </div>
          )}
        />
        {
          stringToBoolean(formField.shouldForwardCall) ? (
            <>
              <div className="call-redirection-schema">
                <button
                  type="button"
                  onClick={() => setShowOpeningHourSettings(true)}
                  className="edit-btn"
                >
                  <AccessTime />
                  <EditOutlined />
                </button>
                <h5 className="redirection-schema-title">{Messages.t('parameters.phoneRedirectSchemaTitle')}</h5>
                <div className="line vertical" />
                <div className="branches">
                  <div className="opening">
                    <div className="box">
                      <div className="open-status is-open" />
                      {Messages.t('parameters.open')}
                    </div>
                    <South />
                    <div className="workflow">
                      <div className="workflow-step">
                        {Messages.t('parameters.phoneRedirect', {
                          agencyName: agency?.name,
                          agencyPhone: agency?.phoneNumber,
                        })}
                      </div>
                      <KeyboardArrowDown />
                      <div className="workflow-step ring-duration condition">
                        {agency && (
                          <>
                            <p>{Messages.t('parameters.ringsMoreInfo')}</p>
                            <Controller
                              name="ringDuration"
                              control={control}
                              render={(controller) => (
                                <SelectWrapper
                                  control={controller}
                                  onChange={(val) => updateAgencyRedirectionSettings({
                                    ringDuration: Number(val),
                                  })}
                                  error={errors}
                                  noLabel
                                  values={
                                    ['10', '20', '30', '40', '50', '60'].map((duration) => ({
                                      key: duration,
                                      label: `${duration} ${Messages.t('parameters.seconds')}`,
                                    }))
                                  }
                                />
                              )}
                            />
                          </>
                        )}
                      </div>
                      <KeyboardArrowDown />
                      <div>
                        <div className="workflow-step">
                          <Audio>
                            <p>{Messages.t('parameters.playAudio')}</p>
                          </Audio>
                        </div>
                        <div className="info">
                          <InfoOutlined />
                          <p>{Messages.t('parameters.forwardCallAdvise')}</p>
                        </div>
                      </div>
                      <KeyboardArrowDown />
                      <div className="workflow-step-container">
                        <div className="workflow-step">
                          <p>{Messages.t('parameters.sendSms')}</p>
                          <p className="sms">
                            {Messages.t('parameters.smsContent', { url: agency?.clientFormURL || '' })}
                          </p>
                        </div>
                        <div className="info">
                          <InfoOutlined />
                          <p>{Messages.t('field.sfcOptTwoSms')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="line horizontal" />
                  <div className="opening">
                    <div className="box">
                      <div className="open-status is-closed" />
                      {Messages.t('parameters.close')}
                    </div>
                    <South />
                    <div className="workflow">
                      <div className="workflow-step">
                        <Audio>
                          <p>{Messages.t('parameters.playAudio')}</p>
                        </Audio>
                      </div>
                      <KeyboardArrowDown />
                      <div className="workflow-step-container">
                        <div className="workflow-step">
                          <p>{Messages.t('parameters.sendSms')}</p>
                          <p className="sms">
                            {Messages.t('parameters.smsContent', { url: agency?.clientFormURL || '' })}
                          </p>
                        </div>
                        <div className="info">
                          <InfoOutlined />
                          <p>{Messages.t('field.sfcOptTwoSms')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="call-redirection-schema">
                <h5 className="redirection-schema-title">{Messages.t('parameters.phoneRedirectSchemaTitle')}</h5>
                <div className="branches">
                  <div className="opening">
                    <South />
                    <div className="workflow">
                      <div className="workflow-step">
                        <Audio>
                          <p>{Messages.t('parameters.playAudio')}</p>
                        </Audio>
                      </div>
                      <KeyboardArrowDown />
                      <div className="workflow-step-container">
                        <div className="workflow-step">
                          <p>{Messages.t('parameters.sendSms')}</p>
                          <p className="sms">
                            {Messages.t('parameters.smsContent', { url: agency?.clientFormURL || '' })}
                          </p>
                        </div>
                        <div className="info">
                          <InfoOutlined />
                          <p>{Messages.t('field.sfcOptTwoSms')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>
      {showOpeningHourSettings && (
        <DialogWrapper
          className="opening-hours-settings"
          open
          onClose={() => setShowOpeningHourSettings(false)}
        >
          <DialogTitle>{Messages.t('parameters.openingHours')} :</DialogTitle>
          <DialogContent>
            {timeslots && stringToBoolean(formField.shouldForwardCall) && (
              <div className="forward-call-settings">
                <p>{Messages.t('parameters.notOpen')}</p>
                <AgencyOpeningHours
                  agency={agency}
                  timeslots={timeslots}
                />
                { /* <Controller */}
                { /*   control={control} */}
                { /*   name="openHoliday" */}
                { /*   render={(controller) => ( */}
                { /*     <CheckboxWrapper */}
                { /*       control={controller} */}
                { /*       label={Messages.t('parameters.openHoliday')} */}
                { /*       error={errors} */}
                { /*       onChange={(val) => { */}
                { /*         updateAgencyRedirectionSettings({ */}
                { /*           openHoliday: stringToBoolean(val) || false, */}
                { /*         }); */}
                { /*       }} */}
                { /*     /> */}
                { /*   )} */}
                { /* /> */}
              </div>
            )}
          </DialogContent>
        </DialogWrapper>
      )}
    </>
  );
}

type AudioProps = {
  children: ReactNode,
};

function Audio({ children }: AudioProps) {
  const [playing, setPlaying] = useState(false);
  const audioPlayer = useRef<HTMLAudioElement | null>(null);

  return (
    <div className="custom-audio-player">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        id="player"
        ref={audioPlayer}
        src="https://tool-agency.s3.eu-west-3.amazonaws.com/Agence_-_Logement_-_NEW.mp3"
      />
      {playing ? (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setPlaying(false);
            audioPlayer.current?.pause();
          }}
        >
          <Pause />
        </button>
      ) : (
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setPlaying(true);
            audioPlayer.current?.play();
          }}
        >
          <PlayArrow />
        </button>
      )}
      {children}
    </div>
  );
}
