import { observable, WritableObservable } from 'micro-observables';
import { AppartmentFilterFunctions, AppartmentFilters } from 'types/filters/AppartmentFilters';
import { Property } from 'types/Property';
import PropertyUtils from 'services/PropertyUtils';
import { ALL, NONE } from 'services/filters/consts';
import { stringToBoolean } from 'lib/form/FormUtils';

const filterFunction: AppartmentFilterFunctions = {
  search: (property: Property, filterValue: string) => PropertyUtils
    .propertyMatch(property, filterValue),
  city: (
    property: Property,
    filterValue: string,
  ) => (property.city && property.city.toLowerCase() === filterValue.toLowerCase()) || false,
  agent: (property: Property, filterValue: string) => {
    if (filterValue === NONE) {
      return !property.agentID;
    }
    return property.agentID === filterValue || !property.agentID;
  },
  online: (property: Property, filterValue: string) => property.online
    === stringToBoolean(filterValue),
};

export class PropertiesFilterService {
  private filters: WritableObservable<AppartmentFilters> = observable({ isArchived: 'NO' });

  getFilterObservable() {
    return this.filters.readOnly();
  }

  getFilter() {
    return this.filters.get();
  }

  updateFilters(filter: AppartmentFilters) {
    return this.filters.set(filter);
  }

  applyFilters(properties: Property[]): Property[] {
    const filters = this.getFilter();
    return properties.filter((property) => Object.keys(filters).every((key) => {
      if (!filters[key] || filters[key] === '' || filters[key] === ALL || !filterFunction[key]) {
        return true;
      }
      return filterFunction[key](property, filters[key]);
    }));
  }
}

const propertiesFilterService = new PropertiesFilterService();
export default propertiesFilterService;
