import { differenceInMinutes } from 'date-fns';
import { VisitSlot, VisitSlotFormType } from 'types/VisitSlot';

export default class VisitSlotUtils {
  static getPossibleVisitNumber(visitSlot: VisitSlot | VisitSlotFormType): string {
    const slotDurationInMinute = visitSlot.startDate
      && visitSlot.endDate
      && differenceInMinutes(new Date(visitSlot.endDate), new Date(visitSlot.startDate));

    return (slotDurationInMinute
      && visitSlot.visitDurationMinutes
      && visitSlot.maxVisitors
      && ((slotDurationInMinute / parseInt(visitSlot.visitDurationMinutes.toString(), 10))
        * parseInt(visitSlot.maxVisitors.toString(), 10)).toFixed(0)) || '0';
  }
}
