import { observable, WritableObservable } from 'micro-observables';
import {
  CandidatureFilters, VisitFilterFunctions,
  VisitSlotFilterFunctions,
} from 'types/filters/CandidatureFilters';
import { Property } from 'types/Property';
import { ALL, NONE } from 'services/filters/consts';
import { Visit } from 'types/Visit';
import { VisitSlot } from 'types/VisitSlot';
import { PROPERTY_FILTER_KEYS } from 'pages/common/PropertiesFilter';

const DEFAULT = 'default';
const filterVisitFunction: VisitFilterFunctions = {
  search: () => true,
  properties: (
    visit: Visit,
    filterValue?: string[],
  ) => {
    if (filterValue
      && filterValue.includes(NONE) && !visit.propertyID) {
      return true;
    }
    return !filterValue
      || filterValue.length === 0
      || filterValue.filter((option) => !PROPERTY_FILTER_KEYS.includes(option)
        && option === visit.propertyID).length > 0;
  },
  agent: (
    visit: Visit,
    filterValue: string,
  ) => filterValue.split(',').includes(visit.agentID || DEFAULT),
};

const filterVisitSlotFunction: VisitSlotFilterFunctions = {
  search: () => true,
  properties: (
    visitSlot: VisitSlot,
    filterValue?: (string)[],
  ) => {
    if (filterValue
      && filterValue.includes(NONE) && !visitSlot.propertyID) {
      return true;
    }
    return !filterValue
      || filterValue.length === 0
      || filterValue.filter((option) => !PROPERTY_FILTER_KEYS.includes(option)
        && option === visitSlot.propertyID).length > 0;
  },
  agent: (
    visitSlot: VisitSlot,
    filterValue: string,
  ) => filterValue.split(',').includes(visitSlot.agentID || DEFAULT),
};

export class EventFilterService {
  private filters: WritableObservable<CandidatureFilters> = observable({});

  getFilterObservable() {
    return this.filters.readOnly();
  }

  getFilter(): CandidatureFilters {
    return this.filters.get();
  }

  updateFilters(filter: CandidatureFilters) {
    return this.filters.set(filter);
  }

  public static getOnePropertyId(properties?: Property[]): string {
    return properties?.length === 1 ? properties[0]?.id : '';
  }

  applyFiltersOnVisit(visits: Visit[]): Visit[] {
    const filters = this.getFilter();
    return visits.filter((candidature) => Object.keys(filters).every((key) => {
      if (!filters[key] || filters[key] === '' || filters[key] === ALL) {
        return true;
      }
      return filterVisitFunction[key](candidature, filters[key]);
    }));
  }

  applyFiltersOnVisitSlot(visitSlots: VisitSlot[]): VisitSlot[] {
    const filters = this.getFilter();
    return visitSlots.filter((candidature) => Object.keys(filters).every((key) => {
      if (!filters[key] || filters[key] === '' || filters[key] === ALL) {
        return true;
      }
      return filterVisitSlotFunction[key](candidature, filters[key]);
    }));
  }
}

const eventFilterService = new EventFilterService();
export default eventFilterService;
