import { Property, PropertyImage } from 'types/Property';
import { fuzzysearch } from 'lib/FuzzySearch';
import Messages from 'services/i18n/Messages';
import { NONE } from 'services/filters/consts';
import { removeDuplicateFromArrayOfStrings } from 'services/ArrayUtils';

export default class PropertyUtils {
  public static filterProperty(
    properties: (Property | string)[], filter: string,
  ): (Property | any)[] {
    return properties.filter((property) => {
      if (typeof property === 'string') {
        return true;
      }
      return this.propertyMatch(property, filter);
    });
  }

  public static propertyMatch(property: (Property | string), filter: string): boolean {
    if (typeof property !== 'string') {
      return (
        property.address && fuzzysearch(filter, property.address))
        || (property.price && filter === property.price.toString())
        || (property.identifier && fuzzysearch(filter, property.identifier))
        || (property.city && fuzzysearch(filter, property.city))
        || (property.zipCode && (property.zipCode.includes(filter))
        ) || false;
    }
    return false;
  }

  public static getPropertyLabel(property: Property | string): string {
    if (typeof property !== 'string') {
      let labels: string[] = [];
      if (property.city) {
        labels.push(property.city);
      }
      if (property.rooms) {
        labels.push(`${property.rooms} ${Messages.t('field.rooms')}`);
      }
      if (property.area) {
        labels.push(`${property.area}m2`);
      }
      if (property.price) {
        labels.push(`${property.price}€`);
      }
      labels = [labels.join(' - ')];
      if (property.identifier) {
        labels.push(`Ref:${property.identifier}`);
      }
      return labels.join(' | ');
    }
    return Messages.t('propertiesFilter.noProperty');
  }

  public static propertyListToString = (propertyList: (Property | string)[]) => {
    const list = propertyList.map((property) => ((typeof property !== 'string') ? property.id : NONE));
    return list.join(',');
  };

  public static agentListToString = (propertyList: (Property | string)[]) => (
    removeDuplicateFromArrayOfStrings(propertyList.map((property) => ((typeof property !== 'string') ? (property.agentID || '') : ''))).join(',')
  );

  public static getSortedPropertyImage = (property?: Property): PropertyImage[] | undefined => {
    if (!property?.images) {
      return undefined;
    }
    return property
      .images?.sort((a, b) => ((((a.order !== null && a.order !== undefined) ? a.order : -1))
          - ((b.order !== null && b.order !== undefined) ? b.order : -1))
        || (b.createdAt || '').localeCompare(a.createdAt || ''));
  };

  public static getPropertyMainImage = (property?: Property): string | undefined => {
    if (!property?.images) {
      return undefined;
    }
    return PropertyUtils.getSortedPropertyImage(property)?.[0].imageURL;
  };
}
