import React from 'react';
import { useUserQueries } from 'network/queries/userQueries';
import sessionService from 'services/sessionService';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from 'routes/Routes';
import Messages from 'services/i18n/Messages';
import Image from 'theme/Image';
import { ChevronRight, Search } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { fuzzysearch } from 'lib/FuzzySearch';

type Props = {
  onValidate?: () => void,
};

export default function AgencySelector({ onValidate }: Props) {
  const history = useHistory();
  const userQueies = useUserQueries();
  const { getUserAgents } = userQueies;
  const { data: agents } = getUserAgents();
  const {
    control,
    watch,
  } = useForm<{ agencyFilter: string }>();

  const formField = watch();

  return (
    <div>
      <h3>
        {Messages.t('agent.agencies')}
      </h3>
      {
        agents && agents.length > 10 && (
          <Controller
            name="agencyFilter"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={{}}
                error={{}}
                prefix={<Search />}
                type="text"
                control={controller}
                label={Messages.t('field.search')}
              />
            )}
          />
        )
      }
      {
        agents && agents
          .filter((agent) => !formField.agencyFilter
            || fuzzysearch(formField.agencyFilter, agent.agency.name))
          .sort((a, b) => a.agency.name.localeCompare(b.agency.name))
          .map((agent) => (
            <button
              key={agent.id}
              type="button"
              onClick={
                () => sessionService.logInToAgent(agent.id)
                  .then(() => {
                    history.push(DASHBOARD);
                    if (onValidate) {
                      onValidate();
                    }
                  })
              }
              className="agency-card"
            >
              <div>
                <div className="rounded-image">
                  <Image alt="agent" src={agent.agency?.imageURL} />
                </div>
              </div>
              <div className="agency-name">
                <div>
                  {agent.agency.name}
                </div>
                {
                  agent.createdForAdmin && (
                    <div className="create-for-admin">
                      {Messages.t('agent.createForAdmin')}
                    </div>
                  )
                }
              </div>
              <div>
                <ChevronRight />
              </div>
            </button>
          ))
      }
    </div>
  );
}
