import React from 'react';
import { CandidatureShort } from 'types/Candidature';
import { Routes } from 'routes/RoutesUtils';
import { CANDIDATE_DETAIL, ID } from 'routes/Routes';
import { Link } from 'react-router-dom';
import DateUtils from 'services/DateUtils';
import Messages from 'services/i18n/Messages';
import { candidateNotation } from 'types/CandidatureBase';
import ContactUtils from 'services/ContactUtils';
import {
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import CandidatureUtils from 'services/CandidatureUtils';
import { Folder, MoreVert, WarningAmber } from '@material-ui/icons';
import { AgencyStep, workflowStepEnum } from 'types/AgencyStep';
import { GliContract } from 'types/ContractTypes';
import GliTooltip from 'pages/agency-app/candidates/candidates/candidateKanban/GliTooltip/GliTooltip';
import PinelTooltip from 'pages/agency-app/candidates/candidates/candidateKanban/PinelTooltip/PinelTooltip';

type Props = {
  workflowStep: AgencyStep,
  candidate: CandidatureShort,
  sendVisitInvitation: () => void,
  refuseCandidate: () => void,
  selectedCandidateIds: string[],
  selectCandidateId: (
    candidateId: string,
    wasAlreadySelected: boolean,
    workFlowStep: string,
  ) => void,
  currentSelectionWorkflowStep?: string,
  agencyGliActivated?: boolean,
  gliContracts?: GliContract[],
  agencyGliRatio?: number,
};

export default function CandidateKanbanItem(
  {
    workflowStep,
    refuseCandidate,
    sendVisitInvitation,
    candidate,
    selectedCandidateIds,
    selectCandidateId,
    currentSelectionWorkflowStep,
    agencyGliActivated,
    gliContracts,
    agencyGliRatio,
  }: Props,
) {
  const sortedContact = CandidatureUtils.getSortedContact(candidate);
  const isSelected = selectedCandidateIds.includes(candidate.id);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const showGliStatus = agencyGliActivated && candidate.property?.gliEnabled;
  const propertyPrice = candidate.property?.price;
  const isPinel = candidate.property?.pinelEnabled;
  const zone = candidate.property?.zone;
  const minRevenues = candidate?.gliMinimumRevenues;

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            sendVisitInvitation();
          }}
        >
          {Messages.t('candidate.proposeViewing')}
          {
            workflowStep.stepKey === workflowStepEnum.VIEWING_PROPOSED && Messages.t('candidate.proposeViewing.renewal')
          }
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            refuseCandidate();
          }}
          className="danger-item"
        >
          {Messages.t('candidate.refuse')}
        </MenuItem>
      </Menu>
      <Link
        to={Routes.withPath(CANDIDATE_DETAIL, [{ label: ID, value: candidate.id }])}
        className={`kanban-item ${open ? 'menu-open' : ''} ${candidate.isRefused ? 'disabled' : ''} ${!!currentSelectionWorkflowStep
        && currentSelectionWorkflowStep !== candidate.workflowStep
        && selectedCandidateIds.length > 0 ? 'disable-link' : ''}`}
        key={candidate.id}
      >
        <div className="card-row">
          <Tooltip
            title={(
              <>
                {
                  sortedContact.map((contact) => (
                    <div key={contact.id}>
                      {
                        ContactUtils.getDisplayedName(
                          contact,
                          0,
                        )
                      }
                    </div>
                  ))
                }
              </>
            )}
          >
            <div className="contact-name-container">
              <Checkbox
                disabled={
                  !!currentSelectionWorkflowStep
                  && currentSelectionWorkflowStep !== candidate.workflowStep
                  && selectedCandidateIds.length > 0
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  selectCandidateId(candidate.id, isSelected, candidate.workflowStep);
                }}
                checked={isSelected}
                id={candidate.id}
              />
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
                role="presentation"
                className="contact-name"
              >
                {
                  ContactUtils.getDisplayedName(
                    sortedContact[0],
                    candidate.contacts.length,
                  )
                }
              </span>
            </div>
          </Tooltip>
          <div className="last-modification-date">
            {
              candidate.statusUpdatedAt
              && DateUtils.getPrettyDateDeltaFromNow(
                candidate.statusUpdatedAt,
                false,
                true,
              )
            }
          </div>
          <div className="candidate-menu">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClick(e);
              }}
            >
              <MoreVert />
            </IconButton>
          </div>
        </div>
        <div className="card-row card-body">
          <div className="monthly-income">
            <div>{Messages.t('field.caseIcome')}</div>
            <div>
              {
                CandidatureUtils.caseIncome(candidate.contacts)
              } &euro;
            </div>
          </div>
          <div className="tag-container">
            {
              candidate.notation && (
                <div className="candidate-notation">
                  {candidateNotation[candidate.notation]}
                </div>
              )
            }
            {
              sortedContact
                .filter((contact) => contact.leaseType && contact.leaseType.trim() !== '')
                .map((contact) => (
                  <div key={contact.id} className="contact-tag">
                    {contact.leaseType}
                  </div>
                ))
            }
            {
              candidate.garants
              && candidate.garants.length > 0
              && candidate.garants[0].type
              && (
                <div className="contact-tag">
                  {Messages.t('field.garant')}
                </div>
              )
            }
            {
              candidate.documentsStatus
              && (
                <div className="contact-tag">
                  <Folder />
                  {Messages.t(`documents.status.${candidate.documentsStatus}`)}
                </div>
              )
            }
            {
              candidate.occupantRelationship && (
                <div className="contact-tag">
                  {Messages.t(`relationship.${candidate.occupantRelationship}`)}
                </div>
              )
            }
            {!candidate.property && (
              <div className="contact-tag">
                <div>
                  <WarningAmber fontSize="small" />
                </div>
                <small>{Messages.t('status.noProperty')}</small>
              </div>
            )}
            {
              showGliStatus && !!minRevenues && propertyPrice && (
                <GliTooltip
                  candidate={candidate}
                  gliContracts={gliContracts}
                  showGliStatus={showGliStatus}
                  agencyGliRatio={agencyGliRatio}
                  minRevenues={minRevenues}
                />
              )
            }
            {
              isPinel && zone && (
                <PinelTooltip
                  candidate={candidate}
                  eligibilitePinel={candidate.pinelEligible || false}
                  zone={zone}
                />
              )
            }
          </div>
        </div>
      </Link>
    </>
  );
}
