import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import VisitSlots from 'pages/agency-app/agenda/visitSlot/VisitSlots';
import {
  Link,
  Route,
  Switch, useHistory,
  useLocation,
} from 'react-router-dom';
import {
  AGENDA,
  AGENDA_VISIT_SLOTS,
  AGENDA_VISIT_SLOTS_WITH_OPTIONAL_ID,
  AGENDA_WITH_OPTIONAL_ID,
} from 'routes/Routes';
import Visits from 'pages/agency-app/agenda/visit/Visits';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import {
  Drawer, IconButton,
  Menu, MenuItem,
} from '@material-ui/core';
import AgentCalendar from 'pages/common/calendar/AgentCalendar';
import Button from 'theme/Button';
import Filters from 'pages/agency-app/agenda/Filters';
import VisitSlotDialog from 'pages/common/visitSlots/VisitSlotDialog';
import VisitDialog from 'pages/common/visits/VisitDialog';
import { Close, DateRange } from '@material-ui/icons';
import sessionManager from 'services/sessionManager';
import { DEFAULT_CANDIDATE } from 'routes/QueryParams';

export default function Agenda() {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const defaultCandidateId = queryParams.get(DEFAULT_CANDIDATE);
  const [displayAgentCalendar, setDisplayAgentCalendar] = useState(false);
  const agentQueries = useAgentsBackend();
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();
  const [showVisitSlotModal, setShowVisitSlotModal] = useState(false);
  const [showVisitModal, setShowVisitModal] = useState(!!defaultCandidateId);
  const [anchorEl, setAnchorEl] = useState(null);

  const propertiesQueries = usePropertiesBackend();
  const { getProperties } = propertiesQueries;
  const { data: propertiesData } = getProperties();

  const candidaturesQuery = useCandidatureBackend();
  const { getCandidatures } = candidaturesQuery;
  const { data: candidaturesData } = getCandidatures();
  const defaultCandidature = defaultCandidateId && candidaturesData
    ? candidaturesData
      .filter((candidature) => candidature.id === defaultCandidateId)[0]
    : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <div className="page-header">
        <div className="page-header-row">
          <Filters properties={propertiesData || []} agents={agents || []} />
          <Button onClick={handleClick}>
            {Messages.t('formButton.add')}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setShowVisitModal(true);
              }}
            >
              {Messages.t('visit.form.title')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setShowVisitSlotModal(true);
              }}
            >
              {Messages.t('visitSlot.form.title')}
            </MenuItem>
          </Menu>
          {
            propertiesData && agents && candidaturesData && showVisitSlotModal && (
              <VisitSlotDialog
                candidatures={candidaturesData}
                properties={propertiesData}
                agents={agents}
                open
                onClose={() => {
                  setShowVisitSlotModal(false);
                }}
              />
            )
          }
          {
            propertiesData
            && agents
            && candidaturesData
            && showVisitModal
            && (!defaultCandidateId || defaultCandidature)
            && (
              <VisitDialog
                candidatures={candidaturesData}
                properties={propertiesData}
                agents={agents}
                allowChangeDefaultCandidate
                defaultCandidature={defaultCandidature}
                open
                onClose={() => {
                  setShowVisitModal(false);
                  history.replace(location.pathname);
                }}
              />
            )
          }
        </div>
        <div className="agenda-page-selector">
          <div className="page-selector-container">
            <Link
              className={`page-selector ${location.pathname.startsWith(AGENDA) ? 'selected' : ''}`}
              to={AGENDA}
            >
              {Messages.t('pageSelector.visit')}
            </Link>
            <Link
              className={`page-selector ${location.pathname.startsWith(AGENDA_VISIT_SLOTS) ? 'selected' : ''}`}
              to={AGENDA_VISIT_SLOTS}
            >
              {Messages.t('pageSelector.visitSlot')}
            </Link>
          </div>
          <div className="show-agenda-container">
            <Button color="secondary" startIcon={<DateRange />} onClick={() => setDisplayAgentCalendar(true)}>
              {Messages.t('agenda.showMyAgenda')}
            </Button>
          </div>
        </div>
      </div>
      <div className="page-body">
        <Switch>
          <Route
            exact
            path={AGENDA_WITH_OPTIONAL_ID}
            render={() => (
              <Visits
                properties={propertiesData}
                agents={agents}
                candidatures={candidaturesData}
              />
            )}
          />
          <Route
            exact
            path={AGENDA_VISIT_SLOTS_WITH_OPTIONAL_ID}
            render={() => (
              <VisitSlots
                properties={propertiesData}
                agents={agents}
                candidatures={candidaturesData}
              />
            )}
          />
        </Switch>
        <Drawer
          anchor="right"
          open={displayAgentCalendar}
          onClose={() => setDisplayAgentCalendar(false)}
        >
          <div className="drawer-content">
            <div className="drawer-header">
              <div className="drawer-filler" />
              <h3 className="drawer-title">
                {Messages.t('agenda.title')}
              </h3>
              <div className="button-container">
                <IconButton onClick={() => setDisplayAgentCalendar(false)}>
                  <Close />
                </IconButton>
              </div>
            </div>
            <AgentCalendar agentId={sessionManager.getSession()?.agent_id || ''} />
          </div>
        </Drawer>
      </div>
    </>
  );
}
