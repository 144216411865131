import React from 'react';

type Props = {
  value: number,
  label: string
};

export default function Tile({ value, label }: Props) {
  return (
    <div className="tile">
      <div className="tile-number">
        {value}
      </div>
      {label}
    </div>
  );
}
