import React, { useState } from 'react';
import { CandidatureShort } from 'types/Candidature';
import CandidatureUtils from 'services/CandidatureUtils';
import { IconButton } from '@material-ui/core';
import { Delete, PersonOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { CANDIDATE_DETAIL, ID } from 'routes/Routes';
import { Routes } from 'routes/RoutesUtils';
import { useVisitBackend } from 'network/queries/VisitQueries';
import confirmationService from 'services/ConfirmationService';
import Messages from 'services/i18n/Messages';
import { NotificationService } from 'lib/notification';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { Visit } from 'types/Visit';
import NameWithInitials from 'pages/common/NameWithInitials';

type Props = {
  candidature: CandidatureShort
  visit: Visit,
};

export default function VisitCandidatureTile(
  {
    visit,
    candidature,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const candidateQueries = useCandidatureBackend();
  const { sendVisitInvitation } = candidateQueries;
  const visitQueries = useVisitBackend();
  const { updateVisit } = visitQueries;
  const deleteVisit = async () => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('confirmation.cancelVisit.title'),
      actionColor: 'error',
      message: Messages.t('confirmation.cancelVisit.message'),
    });
    if (isConfirmed) {
      setSubmitting(true);
      updateVisit.mutateAsync({
        visitId: visit.id,
        data: { isCancel: true },
        visitSlotId: visit.visitSlotID,
      }).then(async () => {
        NotificationService.notifySuccess(Messages.t('visit.canceled'));
        const sendInvitation = await confirmationService.confirm({
          title: Messages.t('confirmation.newVisit.title'),
          message: Messages.t('confirmation.newVisit.message'),
        });
        if (sendInvitation) {
          sendVisitInvitation.mutateAsync({ candidateIds: [candidature.id] })
            .then((visitInvitationResult) => {
              if (visitInvitationResult.candidatesMissingTimeSlot.length === 0
                && visitInvitationResult.candidatesMissingProperty.length === 0) {
                NotificationService.notifySuccess(Messages.t('visit.invitationSend'));
              } else {
                NotificationService.notifyWarning(Messages.t('visit.unable'));
              }
            }).catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
            .finally(() => setSubmitting(false));
        }
      }).catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => setSubmitting(false));
    }
  };
  return (
    <div className="candidature-tile">
      <NameWithInitials name={CandidatureUtils.getPrettyName(candidature)} />
      <div className="action-container">
        <Link
          target="_blank"
          to={Routes.withPath(CANDIDATE_DETAIL, [{ label: ID, value: candidature.id }])}
          className="filled-icon-button link-icon-button"
        >
          <PersonOutline />
        </Link>
        <IconButton onClick={deleteVisit} disabled={submitting} className="filled-icon-button">
          <Delete />
        </IconButton>
      </div>
    </div>
  );
}
