import Messages from 'services/i18n/Messages';
import { addMinutes, format, isSameDay } from 'date-fns';
import { rrulestr } from 'rrule';
import { EventDetails, RbcEvent } from 'types/ReactBigCalendar';

export default class DateUtils {
  public static getPrettyDateDeltaFromNow(
    date: string | undefined | number,
    fullDate = false,
    showSince = false,
  ): string {
    if (!date) {
      return Messages.t('dates.publishedDate.unknown');
    }
    const deltaInSeconds = Math.round((Date.now() - (new Date(date)).getTime()) / 1000);
    if (deltaInSeconds < 60) {
      return Messages.t('dates.publishedDate.now');
    }
    const deltaInMinutes = deltaInSeconds / 60;
    if (deltaInMinutes < 60) {
      return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.minutes', { deltaInMinutes: Math.round(deltaInMinutes) })}`;
    }
    const deltaInHours = deltaInMinutes / 60;
    if (deltaInHours < 24) {
      return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.hours', { deltaInHours: Math.round(deltaInHours) })}`;
    }
    if (fullDate) {
      return format(new Date(date), 'dd/MM/yyyy');
    }
    const deltaInDays = deltaInHours / 24;
    if (deltaInDays < 30) {
      return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.days', { deltaInDays: Math.round(deltaInDays) })}`;
    }
    const deltaInMonths = deltaInDays / 30;
    if (deltaInMonths < 12) {
      return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.months', { deltaInMonths: Math.round(deltaInMonths) })}`;
    }
    return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.years', { deltaInYears: Math.round(deltaInMonths / 12) })}`;
  }

  public static dateIsNullOtooOld(date: string): boolean {
    if (!date) {
      return true;
    }
    const deltaInSeconds = Math.round((Date.now() - (new Date(date)).getTime()) / 1000);
    const deltaInYear = deltaInSeconds / (60 * 60 * 24 * 30 * 12);
    return deltaInYear > 10;
  }

  public static getPositiveDaysDelta(firstDate?: Date, lastDate?: Date): number {
    if (!firstDate || !lastDate) {
      return 0;
    }
    const deltaInSeconds = Math.round((lastDate.getTime() - firstDate.getTime()) / 1000);
    const deltaInDays = deltaInSeconds / (60 * 60 * 24);
    if (deltaInDays < 0) {
      return 0;
    }
    return Math.trunc(deltaInDays);
  }

  public static getTimeString(date?: string): string | undefined {
    if (!date) {
      return undefined;
    }
    // Aligne the date with the current timezone https://stackoverflow.com/questions/69230915/the-datetime-minvalue-is-converted-into-0001-01-01-080543-in-javascript
    const dateAtTimeZone = new Date();
    const originalDate = new Date(date);
    dateAtTimeZone.setUTCHours(originalDate.getUTCHours());
    dateAtTimeZone.setUTCMinutes(originalDate.getUTCMinutes());
    return `${dateAtTimeZone.getHours().toString().padStart(2, '0')}:${dateAtTimeZone.getMinutes().toString().padStart(2, '0')}`;
  }

  public static getDaysDelta(firstDate?: Date, lastDate?: Date): number {
    if (!firstDate || !lastDate) {
      return 0;
    }
    const deltaInSeconds = Math.round((lastDate.getTime() - firstDate.getTime()) / 1000);
    const deltaInDays = deltaInSeconds / (60 * 60 * 24);
    return Math.trunc(deltaInDays);
  }

  public static getHoursString(date?: Date): string {
    if (!date) {
      return '';
    }
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

  static computeDayLightOffsetDelta = (date: Date): number => new Date()
    .getTimezoneOffset() - new Date(
    date.getUTCFullYear(),
    date.getMonth(),
    date.getDate(),
  ).getTimezoneOffset();

  public static getRecurrentEvent<T extends {
    recurrenceRule: string,
    id: string,
    title: string,
  }>(
    events: T[],
    from: Date,
    to: Date,
    getEventStartDate: (event: T) => Date,
    getEventEndDate: (event: T) => Date,
    getIsAllDay: (event: T) => boolean,
    filterRecurrentDateEvent: (event: T, recurrentDate: Date) => boolean,
  ): RbcEvent<EventDetails>[] {
    return events
      .filter((event) => event.recurrenceRule)
      .map((event) => {
        let recRule = event.recurrenceRule;
        const hasExdate = recRule.includes('EXDATE');
        recRule = recRule.replaceAll('|', ' ');
        if (hasExdate) {
          recRule = recRule.split(' ').slice(1).join(' ');
        }
        const eventStartDate = getEventStartDate(event);
        if (!recRule.includes('DTSTART') && !recRule.includes('EXDATE')) {
          recRule = `DTSTART:${(eventStartDate.toISOString())
            .replaceAll('-', '')
            .replaceAll(':', '')
            .replaceAll('.', '')
            .slice(0, -4)
          }Z ${recRule}`;
        }
        const rule = rrulestr(recRule);
        const eventEndDate = getEventEndDate(event);
        const dstAlignedStartDate = addMinutes(
          eventStartDate,
          this.computeDayLightOffsetDelta(eventStartDate),
        );
        rule.options.dtstart = dstAlignedStartDate;
        rule.options.byhour = [dstAlignedStartDate.getUTCHours()];
        rule.options.byminute = [dstAlignedStartDate.getUTCMinutes()];
        rule.options.bysecond = [dstAlignedStartDate.getUTCSeconds()];
        const dateDelta = eventEndDate.getTime() - eventStartDate.getTime();

        return rule.between(from, to)
          .filter((startDate) => (!hasExdate || !isSameDay(startDate, dstAlignedStartDate))
            && filterRecurrentDateEvent(event, startDate))
          .map((startDate) => {
            const dstAlignedDate = addMinutes(
              startDate,
              -this.computeDayLightOffsetDelta(startDate),
            );
            const isAllDay = getIsAllDay(event);
            return {
              title: event.title,
              allDay: isAllDay,
              start: dstAlignedDate,
              resource: {
                color: 'blue',
              },
              end: isAllDay
                ? dstAlignedDate
                : new Date(dstAlignedDate.getTime() + dateDelta),
            };
          });
      }).flat();
  }
}
