import React from 'react';
import localizer from 'react-big-calendar/lib/localizers/moment';
import moment from 'moment';
import { Calendar, Formats, Views } from 'react-big-calendar';
import { format, isSameDay } from 'date-fns';
import VisitSlotAgendaEvent from 'pages/agency-app/agenda/visitSlot/VisitSlotAgendaEvent';
import StringUtils from 'services/StringUtils';
import localService from 'services/i18n/LocalService';
import { useVisitSlotBackend } from 'network/queries/VisitSlotQueries';
import { Property } from 'types/Property';
import CalendarToolbar from 'pages/common/calendar/CalendarToolbar';

import { Agent } from 'types/Agent';
import VisitSlotDialog from 'pages/common/visitSlots/VisitSlotDialog';
import { CandidatureShort } from 'types/Candidature';
import { VisitSlot, VisitSlotWithVisitCount } from 'types/VisitSlot';
import { Components, RbcEvent } from 'types/ReactBigCalendar';
import eventFilterService from 'services/filters/EventFilterService';
import { useObservable } from 'micro-observables';
import Messages from 'services/i18n/Messages';
import { useHistory, useParams } from 'react-router-dom';
import { AGENDA_VISIT_SLOTS, AGENDA_VISIT_SLOTS_WITH_ID, ID } from 'routes/Routes';
import { Routes } from 'routes/RoutesUtils';

type Props = {
  properties?: Property[],
  agents?: Agent[],
  candidatures?: CandidatureShort[]
};

type Param = {
  id: string,
};
const AGENDA_DAY_LENGTH = 7;
export default function VisitSlots({ properties, agents, candidatures }: Props) {
  const { id: selectedSlotId } = useParams<Param>();
  const history = useHistory();
  const globalizeLocalizer = localizer(moment);
  const visitSlotQueries = useVisitSlotBackend();
  const { getVisitSlots } = visitSlotQueries;
  const { data: visitSlotsData } = getVisitSlots();

  const formats: Formats = {
    dateFormat: 'dd',
    timeGutterFormat: (date) => format(date, 'HH:mm'),
    agendaHeaderFormat: (range) => `${range.start.getDate().toString().padStart(2, '0')}${range.start.getMonth() !== range.end.getMonth() ? ` ${
      StringUtils.capitalizeFirstLetter(format(range.start, 'MMMM', { locale: localService.getDateLocal() }))
    }` : ''} - ${StringUtils.capitalizeAllLetter(format(range.end, 'dd MMMM, yyyy', { locale: localService.getDateLocal() }))}`,
  };
  useObservable(eventFilterService.getFilterObservable());

  const visitSlots: VisitSlot[] = [];
  if (visitSlotsData) {
    visitSlots.push(...eventFilterService.applyFiltersOnVisitSlot(visitSlotsData));
  }
  return (
    <>
      {
        properties && agents && candidatures && selectedSlotId && (
          <VisitSlotDialog
            candidatures={candidatures}
            visitSlotId={selectedSlotId}
            properties={properties}
            agents={agents}
            open
            onClose={() => history.replace(AGENDA_VISIT_SLOTS)}

          />
        )
      }
      <div className="agenda-page">
        {
          properties && agents && (
            <Calendar
              length={AGENDA_DAY_LENGTH}
              // Temporary fix to the wrong RBC type
              // @ts-ignore
              components={{
                toolbar: (e) => <CalendarToolbar {...e} />,
                agenda: {
                  time: (timeProps) => (
                    <VisitSlotAgendaEvent
                      property={
                        // TODO may be optimize this with memoïsation
                        properties
                          .filter((property) => property.id
                            === timeProps.event.resource?.propertyID)[0]
                      }
                      time={timeProps}
                      event={timeProps.event}
                      onClick={() => history.replace(Routes.withPath(AGENDA_VISIT_SLOTS_WITH_ID, [{
                        label: ID,
                        value: timeProps.event.resource?.id || '',
                      }]))}
                    />
                  ),
                  event: () => null,
                  date: (e) => (
                    <div>
                      {
                        isSameDay(new Date(), e.day)
                          ? Messages.t('dates.today')
                          : StringUtils.capitalizeAllLetter(format(e.day, 'EEEE dd MMMM', { locale: localService.getDateLocal() }))
                      }
                    </div>
                  ),
                },
              } as Components<RbcEvent<VisitSlotWithVisitCount>, {}>}
              events={
                visitSlots.filter((event) => (event.startDate)
                  && (event.endDate))
                  .map((event) => (
                    {
                      resource: event,
                      title: event.propertyID,
                      allDay: false,
                      start: new Date(event.startDate),
                      end: new Date(event.endDate),
                    }
                  ))
              }
              toolbar
              popup
              formats={formats}
              culture="fr"
              selectable
              messages={
                {
                  noEventsInRange: Messages.t('calendar.visitSlot.noEvent'),
                }
              }
              defaultView={Views.AGENDA}
              views={[Views.AGENDA]}
              step={60}
              showMultiDayTimes
              localizer={globalizeLocalizer}
            />
          )
        }
      </div>
    </>
  );
}
