import React from 'react';
import {
  APPARTMENT,
  DASHBOARD,
  PROPERTY_DETAIL,
} from 'routes/Routes';
import Appartments from 'pages/agency-app/appartments/appartments/Appartments';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropertyDetailsContainer from 'pages/agency-app/appartments/propertyDetails/PropertyDetailsContainer';

export default function AppartmentsRouter() {
  return (
    <Switch>
      <Route path={PROPERTY_DETAIL} component={PropertyDetailsContainer} />
      <Route path={APPARTMENT} component={Appartments} />
      <Route render={() => <Redirect to={DASHBOARD} />} />
    </Switch>
  );
}
