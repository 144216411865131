import React, { useState } from 'react';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { Agency } from 'types/Agency';
import StepParameters from 'pages/agency-app/parameters/agencyParameteres/StepParameters';
import { TextField } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import Button from 'theme/Button';
import { NotificationService } from 'lib/notification';

type Props = {
  agency: Agency
};

export default function StepParametersContainer({ agency }: Props) {
  const [newStep, setNewStep] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const agenciesQueries = useAgencyBackend();
  const {
    getAgencySteps,
    createAgencyStep,
  } = agenciesQueries;
  const { data: steps } = getAgencySteps();

  const createStep = () => {
    setSubmitting(true);
    createAgencyStep.mutateAsync({
      agencyId: agency.id,
      step: {
        agencyId: agency.id,
        stepName: newStep,
        stepKey: newStep,
        order: steps ? Object.values(steps).length : 0,
      },
    })
      .then(() => {
        setNewStep('');
        NotificationService.notifySuccess(Messages.t('notifications.stepCreate'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      {
        steps && (
          <StepParameters
            agency={agency}
            stepsProps={Object.values(steps).sort((a, b) => (a.order || 0) - (b.order || 0))}
          />
        )
      }
      <div className="material-textfield-wrapper step-action">
        <TextField
          onChange={(e) => setNewStep(e.target.value)}
          value={newStep}
          fullWidth
          size="medium"
          disabled={submitting}
          variant="filled"
          type="text"
          label={Messages.t('field.step')}
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Button
          disabled={submitting || newStep === ''}
          onClick={createStep}
        >
          {Messages.t('formButton.add')}
        </Button>
      </div>
    </div>
  );
}
