import React, { useState } from 'react';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { Controller, useForm } from 'react-hook-form';
import SelectWrapper from 'lib/form/SelectWrapper';
import { CandidatureDetailled } from 'types/Candidature';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { CandidaturePartial } from 'types/CandidatureBase';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { NotificationService } from 'lib/notification';
import {

  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { CANDIDATE } from 'routes/Routes';
import confirmationService from 'services/ConfirmationService';
import AgentUtils from 'services/AgentUtils';
import CandidateVisitModal from 'pages/agency-app/candidates/candidateDetails/candidateVisit/CandidateVisitModal';

type Props = {
  candidature: CandidatureDetailled,
};

export default function CandidateActions({ candidature }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [showVisitModal, setShowVisitModal] = useState(false);
  const history = useHistory();
  const agentQueries = useAgentsBackend();
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();
  const candidatureQueries = useCandidatureBackend();
  const { updateCandidature, deleteCandidature } = candidatureQueries;
  const agenciesQueries = useAgencyBackend();
  const {
    getAgencySteps,
  } = agenciesQueries;
  const { data: steps } = getAgencySteps();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const {
    control,
  } = useForm<CandidatureDetailled>({ defaultValues: candidature });

  const submitCandidature = (data: CandidaturePartial) => {
    setSubmitting(true);
    updateCandidature.mutateAsync(data)
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const removeCandidature = (candidatureId: string) => {
    setSubmitting(true);
    deleteCandidature.mutateAsync({ candidatureId })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        history.push(CANDIDATE);
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const confirmRemoveCandidature = async (candidatureId: string) => {
    const isConfirmed = await confirmationService.confirm({
      actionColor: 'error',
      title: Messages.t('confirmation.deleteCandidature.title'),
      message: Messages.t('confirmation.deleteCandidature.message'),
      actionMessage: Messages.t('confirmation.deleteCandidature.action'),
    });
    handleClose();
    if (isConfirmed) {
      removeCandidature(candidatureId);
    }
  };

  return (
    <div className="actions-container">
      <Button
        disabled={submitting}
        onClick={() => submitCandidature({ isRefused: !candidature.isRefused, id: candidature.id })}
      >
        {
          !candidature.isRefused
            ? Messages.t('candidate.refuse')
            : Messages.t('candidate.unRefuse')
        }
      </Button>
      <Button
        color="fourth"
        disabled={submitting}
        onClick={() => setShowVisitModal(true)}
      >
        {Messages.t('candidate.inviteVisit')}
      </Button>
      {
        showVisitModal && (
          <CandidateVisitModal
            onClose={() => setShowVisitModal(false)}
            agents={agents || []}
            candidature={candidature}
            steps={steps}
            open
          />
        )
      }
      {
        agents && (
          <Controller
            name="agentID"
            control={control}
            render={(controller) => (
              <SelectWrapper
                error={{}}
                onChange={(agentId) => submitCandidature({ agentID: agentId, id: candidature.id })}
                control={controller}
                label={Messages.t('field.agent')}
                values={agents.map((agent) => ({
                  key: agent.id,
                  label: AgentUtils.getPrettyName(agent),
                }))}
              />
            )}
          />
        )
      }
      {
        steps && (
          <Controller
            name="workflowStep"
            control={control}
            render={(controller) => (
              <SelectWrapper
                error={{}}
                onChange={(stepId) => submitCandidature({
                  workflowStep: stepId,
                  id: candidature.id,
                })}
                control={controller}
                label={Messages.t('field.step')}
                values={
                  Object.values(steps)
                    .sort((a, b) => (a.order || 0) - (b.order || 0))
                    .map((step) => ({ key: step.id, label: step.stepName }))
                }
              />
            )}
          />
        )
      }
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disabled={submitting}
          onClick={() => confirmRemoveCandidature(candidature.id)}
          className="danger-item"
        >
          {Messages.t('candidature.delete')}
        </MenuItem>
      </Menu>
    </div>
  );
}
