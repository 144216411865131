export interface Garant {
  id: string,
  enterprise: string,
  order?: number
  profession?: string
  leaseType?: string
  monthlyIncome?: number
  trialPeriod?: boolean | null
  name?: string
  amount?: number
  situation?: string
  type: GarantType
  firstname?: string
  lastname?: string
  createdAt?: string
  documentGroupID?: string,
  documentGroupCopyID?: string,
}

export interface GarantFrom {
  id: string,
  enterprise: string,
  order?: number
  profession?: string
  leaseType?: string
  monthlyIncome?: string
  trialPeriod?: string
  name?: string
  amount?: string
  type: GarantType
  situation?: string
  firstname?: string
  lastname?: string
  createdAt?: string
}

export type GarantType = 'INSURANCE' | 'BANK' | 'ENTERPRISE' | 'PERSON';

type GarantTypeEnum = {
  [key in GarantType]: key
};

export const garantTypeEnum: GarantTypeEnum = {
  PERSON: 'PERSON',
  INSURANCE: 'INSURANCE',
  BANK: 'BANK',
  ENTERPRISE: 'ENTERPRISE',
};
