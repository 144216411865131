import { Formats } from 'react-big-calendar';
import { format } from 'date-fns';
import StringUtils from 'services/StringUtils';
import localService from 'services/i18n/LocalService';

export default class ReactBigCalendareUtils {
  public static getFormats(): Formats {
    return {
      dateFormat: 'dd',
      timeGutterFormat: (date) => format(date, 'HH:mm'),
      agendaHeaderFormat: (range) => `${range.start.getDate().toString().padStart(2, '0')}${range.start.getMonth() !== range.end.getMonth() ? ` ${
        StringUtils.capitalizeFirstLetter(format(range.start, 'MMMM', { locale: localService.getDateLocal() }))
      }` : ''} - ${StringUtils.capitalizeAllLetter(format(range.end, 'dd MMMM, yyyy', { locale: localService.getDateLocal() }))}`,
    };
  }
}
