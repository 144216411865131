import { Candidature } from 'types/CandidatureBase';
import { Contact, ContactFrom } from 'types/Contact';
import { Garant, GarantFrom, garantTypeEnum } from 'types/Garant';
import { CandidatureDetailled, CandidatureShort } from 'types/Candidature';
import { DocumentGroup } from 'types/DocumentGroup';
import { DocumentDataMap, DocumentTag, optionalTags } from 'types/DocumentData';
import ContactUtils from 'services/ContactUtils';
import { fuzzysearch } from 'lib/FuzzySearch';
import { CandidatureForm } from 'types/forms/CandidatureForm';
import { booleanToString } from 'lib/form/FormUtils';
import { GliType, gliTypeEnum, proSituationTypeEnum } from 'types/ProfesionalSituation';
import { contractTypeTypeEnum, GliContract } from 'types/ContractTypes';

export default class CandidatureUtils {
  public static filterCandidate(candidatures: CandidatureShort[], filterValue: string) {
    return candidatures.filter((candidature) => this.candidateMatch(candidature, filterValue));
  }

  public static candidateMatch(candidature: CandidatureShort, filterValue: string): boolean {
    return (candidature.plateform
        && fuzzysearch(filterValue, candidature.plateform))
      || (candidature.contacts
        && candidature.contacts.filter((contact) => (contact.firstname
            && fuzzysearch(filterValue, contact.firstname))
          || (contact.lastname && fuzzysearch(filterValue, contact.lastname))
          || (contact.email && fuzzysearch(filterValue, contact.email))
          || (contact.phoneNumber && fuzzysearch(filterValue, contact.phoneNumber))).length > 0
      ) || false;
  }

  public static computeCompletion(
    groups: DocumentGroup[] | undefined,
    documentDataMap: DocumentDataMap,
  ): number {
    if (!groups) {
      return 0;
    }
    return Math.floor(groups.reduce((acc, value) => acc
      + this.getCompletionPercentFromTagGroup(
        documentDataMap[value.id] ? Object.keys(documentDataMap[value.id]) : [],
        value,
      ), 0) / groups.length);
  }

  public static getCompletionPercentFromTagGroup(tagList: string[], group: DocumentGroup): number {
    if (!group) {
      return 0;
    }
    const tagListMap = tagList
      .filter((tag) => !optionalTags.includes(tag as DocumentTag))
      .reduce((acc, value) => {
        acc[value.trim().toLowerCase()] = value;
        return acc;
      }, {});
    const tagEnumList = group.possibleTags?.split(',')
      .filter((tag) => !optionalTags.includes(tag as DocumentTag)) || [];
    return Math.floor((
      tagEnumList
        .filter((tag) => tagListMap[tag.trim().toLowerCase()]).length
      / (tagEnumList.length)) * 100);
  }

  public static getCountByStep(candidatures?: Candidature[]): {
    [step: string]: (number | undefined),
  } {
    return (candidatures || []).reduce((acc, value) => {
      if (!acc[value.workflowStep]) {
        acc[value.workflowStep] = 0;
      }
      acc[value.workflowStep] += 1;
      return acc;
    }, {});
  }

  public static caseIncome(occupants: Contact[]): number {
    return occupants
      .reduce((acc, value) => acc + (value.monthlyIncome || 0), 0);
  }

  public static caseIncomePerSituation(
    candidature: CandidatureShort, contract: GliContract,
  ): number {
    if (contract.name === gliTypeEnum.STUDENT) {
      return (CandidatureUtils.garantsIncome(candidature) || 0);
    }
    return CandidatureUtils.caseIncome(
      CandidatureUtils.contactsMatchingSituation(candidature.contacts, contract.name),
    );
  }

  public static getSortedContact(candidate: CandidatureShort): Contact[] {
    return candidate
      .contacts.sort((a, b) => (a.order || -1) - (b.order || -1));
  }

  public static getPrettyName(candidate: CandidatureShort): string {
    if (!candidate) {
      return '';
    }
    return ContactUtils.getDisplayedName(CandidatureUtils.getSortedContact(candidate)[0], 0);
  }

  public static getPrincipalMail(candidate: CandidatureShort): string | undefined {
    if (!candidate) {
      return '';
    }
    return CandidatureUtils.getSortedContact(candidate)[0].email;
  }

  public static garantsIncome(
    candidature: CandidatureDetailled | CandidatureShort,
  ): number | undefined {
    return (candidature && candidature.garants && candidature.garants.length > 0) ? (
      candidature?.garants
        .filter((garant) => garant.type === garantTypeEnum.PERSON)
        .reduce((acc, value) => acc + (value.amount || 0) + (value.monthlyIncome || 0), 0)
    ) : undefined;
  }

  public static getCandidatureFormDefaultValue(
    candidatureProp?: CandidatureDetailled,
  ): CandidatureForm | undefined {
    const toGarantForm = (garant: Garant): GarantFrom => ({
      ...garant,
      monthlyIncome: garant.monthlyIncome?.toString(),
      amount: garant.amount?.toString(),
      trialPeriod: booleanToString(garant.trialPeriod) || undefined,
    });

    const toContactForm = (contact: Contact): ContactFrom => ({
      ...contact,
      monthlyIncome: contact.monthlyIncome?.toString(),
      trialPeriod: booleanToString(contact.trialPeriod) || undefined,
      gliEgible: booleanToString(contact.gliEgible) || undefined,
    });
    return {
      garants: candidatureProp?.garants
        ?.sort((a, b) => (a.order || 0) - (b.order || 0))
        ?.map((garant) => ({
          ...toGarantForm(garant),
          garantId: garant.id,
        })) || [],
      contacts: candidatureProp?.contacts
        ?.sort((a, b) => (a.order || 0) - (b.order || 0))
        ?.map((contact) => ({
          ...toContactForm(contact),
          contactId: contact.id,
        })) || [{}],
      propertyId: candidatureProp?.property?.id,
      moveInWanted: candidatureProp?.moveInWanted,
      source: candidatureProp?.source,
      message: candidatureProp?.message,
      plateform: candidatureProp?.plateform,
      occupantRelationship: candidatureProp?.occupantRelationship,
    };
  }

  public static contactsMatchingSituation(contacts: Contact[], situation: GliType): Contact[] {
    return contacts.filter((contact) => {
      const { leaseType, trialPeriod, situation: contactSituation } = contact;
      const { EMPLOYED } = proSituationTypeEnum;
      const {
        EMPLOYED_CDI_TRIAL,
        EMPLOYED_CDI_NO_TRIAL,
        CDD,
        ALTERNANT,
        INTERIMAIRE,
        INTERMITTENT,
      } = gliTypeEnum;
      const {
        CDI,
        CDD: CDD_CONTRACT,
        Alternant,
        Interimaire,
        Intermittent,
      } = contractTypeTypeEnum;

      if (contact.situation === EMPLOYED) {
        if (situation === EMPLOYED_CDI_TRIAL) {
          return contactSituation === EMPLOYED && leaseType === CDI && !trialPeriod;
        }
        if (situation === EMPLOYED_CDI_NO_TRIAL) {
          return contactSituation === EMPLOYED && leaseType === CDI && trialPeriod;
        }
        if (situation === CDD) {
          return contactSituation === EMPLOYED && leaseType === CDD_CONTRACT;
        }
        if (situation === ALTERNANT) {
          return contactSituation === EMPLOYED && leaseType === Alternant;
        }
        if (situation === INTERIMAIRE) {
          return contactSituation === EMPLOYED && leaseType === Interimaire;
        }
        if (situation === INTERMITTENT) {
          return contactSituation === EMPLOYED && leaseType === Intermittent;
        }
      }
      return contactSituation === situation;
    });
  }

  public static getGliSituation(contact: Contact): (GliType | undefined) {
    if (contact.situation !== proSituationTypeEnum.EMPLOYED) {
      return contact.situation as GliType;
    }
    if (!contact.leaseType) {
      return undefined;
    }
    if (contact.leaseType === contractTypeTypeEnum.CDI) {
      if (!contact.trialPeriod) {
        return gliTypeEnum.EMPLOYED_CDI_TRIAL;
      }
      return gliTypeEnum.EMPLOYED_CDI_NO_TRIAL;
    }
    return gliTypeEnum[contact.leaseType.toUpperCase()] as GliType;
  }
}
