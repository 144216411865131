import React from 'react';
import { Property } from 'types/Property';
import { Agent } from 'types/Agent';

import VisitForm from 'pages/common/visits/VisitForm';
import { CandidatureShort } from 'types/Candidature';
import { useVisitBackend } from 'network/queries/VisitQueries';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  properties: Property[],
  agents: Agent[],
  candidatures: CandidatureShort[],
  visitId?: string,
  open?: boolean,
  onClose: () => void,
  allowChangeDefaultCandidate?: boolean,
  defaultCandidature?: CandidatureShort,
};

export default function VisitDialog(
  {
    properties,
    agents,
    candidatures,
    open,
    onClose,
    visitId,
    defaultCandidature,
    allowChangeDefaultCandidate,
  }: Props,
) {
  const visitQueries = useVisitBackend();
  const { getVisit } = visitQueries;
  const { data: visit } = getVisit(visitId || '', !!visitId);
  return (
    <DialogWrapper open={!!open} onClose={onClose}>
      <div className="visit-slot-modal visit-modal">
        {
          (!visitId || visit) && (
            <VisitForm
              allowChangeDefaultCandidate={allowChangeDefaultCandidate}
              visit={visit}
              properties={properties}
              agents={agents}
              candidatures={candidatures}
              defaultCandidature={defaultCandidature}
            />
          )
        }
      </div>
    </DialogWrapper>
  );
}
