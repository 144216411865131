import React from 'react';
import { Popover } from '@material-ui/core';
import { Property } from 'types/Property';
import CandidateAssignPropertyForm
  from 'pages/common/forms/CandidateAssignPropertyForm';
import { CandidatureShort } from 'types/Candidature';
import VisitSlotDialog from 'pages/common/visitSlots/VisitSlotDialog';
import { Agent } from 'types/Agent';
import Messages from 'services/i18n/Messages';
import missingProperty from 'assets/images/missingProperty.svg';
import ListingCard from 'pages/common/ListingCard';
import NameWithInitials from 'pages/common/NameWithInitials';
import CandidatureUtils from 'services/CandidatureUtils';
import ArrayUtils from 'services/ArrayUtils';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  onSubmitted: () => void
  onClose: () => void
  candidatesMissingProperty: CandidatureShort[]
  candidatesMissingTimeSlot: CandidatureShort[]
  properties: Property[]
  agents: Agent[]
};

export default function VisitSlotMissingModal(
  {
    onSubmitted,
    onClose,
    candidatesMissingProperty,
    candidatesMissingTimeSlot,
    properties,
    agents,
  }: Props,
) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openTimeSlotModal, setOpenTimeSlotModal] = React.useState(false);
  const [
    selectedCandidature,
    setSelectedCandidature,
  ] = React.useState<CandidatureShort | undefined>();

  const [
    selectedProperty,
    setSelectedProperty,
  ] = React.useState<string | undefined>();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedCandidature(undefined);
    setAnchorEl(null);
  };

  return (
    <DialogWrapper open onClose={onClose}>
      <div className="missing-visit-slot-modal">
        <img src={missingProperty} alt="missing" />
        {
          candidatesMissingTimeSlot.length > 0 && (
            <>
              <h3>
                {Messages.t('visitInvitation.missingSlot')}
              </h3>
              <div className="missing-slot-message">
                {Messages.t('visitInvitation.missingSlot.message')}
              </div>
            </>
          )
        }
        {
          ArrayUtils.removeDuplicates(
            candidatesMissingTimeSlot.map((candidate) => candidate.propertyID),
          )
            .map((propertyId) => {
              const property = properties.filter((prop) => prop.id === propertyId)[0];
              return (
                <div className="missing-button-container">
                  <button
                    className="missing-button"
                    type="button"
                    key={propertyId}
                    onClick={() => {
                      setOpenTimeSlotModal(true);
                      setSelectedProperty(propertyId);
                    }}
                  >
                    <ListingCard property={property} />
                  </button>
                </div>
              );
            })
        }
        {
          candidatesMissingProperty.length > 0 && (
            <>
              <h3>
                {Messages.t('visitInvitation.missingProperty')}
              </h3>
              <div className="missing-slot-message">
                {Messages.t('visitInvitation.missingProperty.message')}
              </div>
            </>
          )
        }
        {
          candidatesMissingProperty.map((candidature) => (
            <div className="missing-button-container">
              <button
                className="missing-button"
                type="button"
                key={candidature.id}
                onClick={(e) => {
                  handleClick(e);
                  setSelectedCandidature(candidature);
                }}
              >
                <NameWithInitials name={CandidatureUtils.getPrettyName(candidature)} />
              </button>
            </div>
          ))
        }
        {
          openTimeSlotModal && selectedProperty && (
            <VisitSlotDialog
              properties={properties}
              agents={agents}
              candidatures={[]}
              defaultProperty={selectedProperty}
              onSubmitted={() => {
                setOpenTimeSlotModal(false);
                onSubmitted();
              }}
              open
              onClose={() => setOpenTimeSlotModal(false)}
            />
          )
        }
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && !!selectedCandidature}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {
            selectedCandidature && (
              <div className="property-selector-container">
                <h3>{Messages.t('property.select')}</h3>
                <CandidateAssignPropertyForm
                  onSubmitted={() => {
                    onSubmitted();
                    setSelectedCandidature(undefined);
                  }}
                  candidatureId={selectedCandidature.id}
                  properties={properties}
                />
              </div>
            )
          }
        </Popover>
      </div>
    </DialogWrapper>
  );
}
