import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import Button from 'theme/Button';
import Image from 'theme/Image';

type Props = {
  imageUrls: string[]
  showArrows?: boolean
  intitialSelectedImage?: number,
  selectIndex?: (index: number) => void
  enableKeyBoard?: boolean,
};

export default function ImageCarrousel(
  {
    imageUrls,
    showArrows,
    intitialSelectedImage,
    selectIndex,
    enableKeyBoard,
  }: Props,
) {
  const [seletedIndex, setSelectedIndex] = useState(intitialSelectedImage || 0);
  const nextRef = React.useRef<() => void>(null);
  const prevRef = React.useRef<() => void>(null);

  useEffect(() => {
    const handleKeyBoardEvent = (event: KeyboardEvent) => {
      if (event.keyCode === 37) {
        if (prevRef.current) {
          prevRef.current();
        }
      }
      if (event.keyCode === 39) {
        if (nextRef.current) {
          nextRef.current();
        }
      }
    };
    if (enableKeyBoard) {
      document.addEventListener('keydown', handleKeyBoardEvent);
      return () => {
        document.removeEventListener('keydown', handleKeyBoardEvent);
      };
    }
    return () => {
    };
  }, []);

  return (
    <Carousel
      selectedItem={intitialSelectedImage || 0}
      dynamicHeight={false}
      showThumbs={false}
      showIndicators
      onChange={setSelectedIndex}
      autoPlay={false}
      interval={10000000000}
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={50}
      showStatus={false}
      showArrows={!!showArrows}
      renderArrowNext={(clickHandler, hasNext) => {
        // @ts-ignore
        nextRef.current = clickHandler;
        return (
          hasNext && seletedIndex !== imageUrls.length - 1 && (
            <Button
              onClick={clickHandler}
              preventDefault
              roundedIcon
              color="secondary"
              className="carrousel-arrow right-arrow"
              variant="contained"
            >
              <ChevronRightIcon />
            </Button>
          )
        );
      }}
      renderArrowPrev={(clickHandler, hasPrev) => {
        // @ts-ignore
        prevRef.current = clickHandler;
        return (
          hasPrev && seletedIndex !== 0 && (
            <Button
              onClick={clickHandler}
              preventDefault
              roundedIcon
              color="secondary"
              className="carrousel-arrow left-arrow"
              variant="contained"
            >
              <ChevronLeftIcon />
            </Button>
          )
        );
      }}
      infiniteLoop
      emulateTouch
      className="carousel-custom-root"
    >
      {
        imageUrls.map((image, index) => (
          <div
            role="presentation"
            onClick={() => {
              if (selectIndex) {
                selectIndex(index);
              }
            }}
            key={image}
          >
            <Image
              src={image}
              alt="carrousel"
            />
          </div>
        ))
      }
    </Carousel>
  );
}
