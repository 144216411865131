import { useMutation, useQuery, useQueryClient } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import { CandidatureDetailled, CandidatureShort, CandidatureList } from 'types/Candidature';
import sessionManager from 'services/sessionManager';
import { Candidature, CandidaturePartial } from 'types/CandidatureBase';
import { CandidatureAPI } from 'types/forms/CandidatureForm';
import candidatureFilterService, { CandidatureFilterService } from 'services/filters/CandidatureFilterService';
import { CandidatureNote, NoteCreation } from 'types/Note';
import {
  DocumentCreation,
  DocumentData,
  DocumentDataMap,
  DocumentMail,
} from 'types/DocumentData';
import DocumentUtils from 'services/DocumentUtils';
import { DocumentCopyForm } from 'types/forms/DocumentCopyForm';
import { VisitInvitationResult } from 'types/VisitInvitationResult';
import { Visit } from 'types/Visit';

export function useCandidatureBackend() {
  const queryClient = useQueryClient();

  const UpdateContact = useMutation<CandidatureDetailled, any, {
    contacts: CandidatureAPI,
    candidatureId: string,
  }>(
    async (data) => {
      const response = await backend.put(`/api/candidatures/${data.candidatureId}/contacts`, data.contacts);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.invalidateQueries('getGarantModification');
        queryClient.invalidateQueries('getContactModification');
        queryClient.refetchQueries(['getCandidature', sessionManager.getSession()?.agent_id, param.candidatureId]);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
      },
    },
  );

  const SendVisitInvitation = useMutation<VisitInvitationResult, any, CandidatureList>(
    async (candidatureIds) => {
      const response = await backend.post('/api/candidatures/visit-invitations', candidatureIds);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
        queryClient.invalidateQueries(['getCandidature']);
      },
    },
  );

  const RefuseCandidates = useMutation<void, any, CandidatureList>(
    async (candidatureIds) => {
      const response = await backend.post('/api/candidatures/refuse', candidatureIds);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
        queryClient.invalidateQueries(['getCandidature']);
      },
    },
  );

  const AskCandidatesDocuments = useMutation<void, any, CandidatureList>(
    async (candidatureIds) => {
      const response = await backend.post('/api/candidatures/ask-documents', candidatureIds);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
        queryClient.invalidateQueries(['getCandidature']);
      },
    },
  );

  const UpdateCandidatureList = useMutation<void, any, CandidaturePartial[]>(
    async (candidatures) => {
      const response = await backend.patch('/api/candidatures', candidatures);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
        queryClient.invalidateQueries(['getCandidature']);
      },
    },
  );

  const UpdateCandidature = useMutation<Candidature, any, CandidaturePartial>(
    async (candidature) => {
      const response = await backend.patch(`/api/candidatures/${candidature.id}`, candidature);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getCandidature', sessionManager.getSession()?.agent_id, param.id]);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
      },
    },
  );

  const GetCandidatures = (propertyId = '', enabled = true) => useQuery<CandidatureShort[]>(
    ['getCandidatures', sessionManager.getSession()?.agent_id, propertyId],
    async () => {
      const response: CandidatureShort[] = await backend.fetchJson(
        `/api/candidatures${propertyId ? `?propertyId=${propertyId}` : ''}`,
      );
      return response;
    },
    { enabled },
  );

  const GetCandidatureDocuments = (
    candidatureId: string,
    enabled = true,
  ) => useQuery<DocumentDataMap>(
    ['getCandidatureDocuments', sessionManager.getSession()?.agent_id, candidatureId],
    async () => {
      const response: DocumentData[] = await backend.fetchJson(
        `/api/candidatures/${candidatureId}/documents`,
      );
      return DocumentUtils.getMappedDocuments(response);
    },
    { enabled },
  );

  const GetCandidatureVisit = (
    candidatureId: string,
    enabled = true,
  ) => useQuery<Visit[]>(
    ['getCandidatureVisits', sessionManager.getSession()?.agent_id, candidatureId],
    async () => {
      const response: Visit[] = await backend.fetchJson(
        `/api/candidatures/${candidatureId}/visits`,
      );
      return response;
    },
    { enabled },
  );

  const GetCandidatureDocumentsCopy = (
    candidatureId: string,
    enabled = true,
  ) => useQuery<DocumentDataMap>(
    ['getCandidatureDocumentsCopy', sessionManager.getSession()?.agent_id, candidatureId],
    async () => {
      const response: DocumentData[] = await backend.fetchJson(
        `/api/candidatures/${candidatureId}/documents-copy`,
      );
      return DocumentUtils.getMappedDocuments(response);
    },
    { enabled },
  );

  const GetCandidature = (candidatureId: string, enabled = true) => useQuery<CandidatureDetailled>(
    ['getCandidature', sessionManager.getSession()?.agent_id, candidatureId],
    async () => {
      const response: CandidatureDetailled = await backend.fetchJson(
        `/api/candidatures/${candidatureId}`,
      );
      return response;
    },
    { enabled },
  );

  const GetCandidatureNotes = (
    candidatureId: string,
    enabled = true,
  ) => useQuery<CandidatureNote[]>(
    ['getCandidatureNotes', sessionManager.getSession()?.agent_id, candidatureId],
    async () => {
      const response: CandidatureNote[] = await backend.fetchJson(
        `/api/candidatures/${candidatureId}/notes`,
      );
      return response;
    },
    { enabled, refetchInterval: 5000 },
  );

  const UpdateCandidatureNote = useMutation<CandidatureNote, any, {
    candidatureId: string,
    noteId: string,
    data: Partial<CandidatureNote>
  }>(
    async ({ noteId, candidatureId, data }) => {
      const response = await backend.patch(`/api/candidatures/${candidatureId}/notes/${noteId}`, data);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getCandidatureNotes', sessionManager.getSession()?.agent_id, param.candidatureId]);
      },
    },
  );

  const CreateCandidatureNotes = useMutation<CandidatureNote, any, {
    candidatureId: string,
    data: NoteCreation,
  }>(
    async ({ data, candidatureId }) => {
      const response = await backend.post(`/api/candidatures/${candidatureId}/notes`, data);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getCandidatureNotes', sessionManager.getSession()?.agent_id, param.candidatureId]);
      },
    },
  );

  const DeleteCandidatureNote = useMutation<void, any, {
    candidatureId: string,
    noteId: string,
  }>(
    async ({ noteId, candidatureId }) => {
      const response = await backend.delete(`/api/candidatures/${candidatureId}/notes/${noteId}`);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getCandidatureNotes', sessionManager.getSession()?.agent_id, param.candidatureId]);
      },
    },
  );

  const DeleteCandidature = useMutation<void, any, { candidatureId: string }>(
    async ({ candidatureId }) => {
      const response = await backend.delete(`/api/candidatures/${candidatureId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
      },
    },
  );

  const SaveCandidatureDocument = useMutation<void, any, {
    candidatureId: string,
    documentGroupId: string,
    data: DocumentCreation[],
  }>(
    async ({ candidatureId, documentGroupId, data }) => {
      const response = await backend.post(`/api/candidatures/${candidatureId}/documents/${documentGroupId}`, data);
      return response;
    }, {
      onSuccess: (_, { candidatureId }) => {
        queryClient.refetchQueries(['getCandidatureDocumentsCopy', sessionManager.getSession()?.agent_id, candidatureId]);
      },
    },
  );
  const DeleteCandidatureDocument = useMutation<void, any, {
    candidatureId: string
    documentGroupId: string,
    documentId: string,
  }>(
    async ({ candidatureId, documentGroupId, documentId }) => {
      const response = await backend.delete(`/api/candidatures/${candidatureId}/documents/${documentGroupId}/files/${documentId}`);
      return response;
    }, {
      onSuccess: (_, { candidatureId }) => {
        queryClient.refetchQueries(['getCandidatureDocumentsCopy', sessionManager.getSession()?.agent_id, candidatureId]);
      },
    },
  );

  const CreateDocumentGroupCopy = useMutation<void, any, {
    candidatureId: string,
    data: DocumentCopyForm,
  }>(
    async ({ candidatureId, data }) => {
      const response = await backend.post(`/api/candidatures/${candidatureId}/documents`, data);
      return response;
    }, {
      onSuccess: (_, { candidatureId }) => {
        queryClient.refetchQueries(['getCandidature', sessionManager.getSession()?.agent_id, candidatureId]);
        queryClient.refetchQueries(['getCandidatureDocumentsCopy', sessionManager.getSession()?.agent_id, candidatureId]);
      },
    },
  );
  const SendDocumentMail = useMutation<void, any, {
    candidatureId: string,
    data: DocumentMail,
  }>(
    async ({ candidatureId, data }) => {
      const response = await backend.post(`/api/candidatures/${candidatureId}/documents/mails`, data);
      return response;
    },
  );

  const DeleteGarant = useMutation<CandidatureDetailled, any, {
    candidatureId: string,
    garantId: string,
  }>(
    async ({ candidatureId, garantId }) => {
      const response = await backend.delete(`/api/candidatures/${candidatureId}/garants/${garantId}`);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getCandidature', sessionManager.getSession()?.agent_id, param.candidatureId]);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
      },
    },
  );
  const DeleteContact = useMutation<CandidatureDetailled, any, {
    candidatureId: string,
    contactId: string,
  }>(
    async ({ candidatureId, contactId }) => {
      const response = await backend.delete(`/api/candidatures/${candidatureId}/contacts/${contactId}`);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getCandidature', sessionManager.getSession()?.agent_id, param.candidatureId]);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
      },
    },
  );

  return {
    updateContact: UpdateContact,
    getCandidatures: GetCandidatures,
    getCandidature: GetCandidature,
    updateCandidatureNote: UpdateCandidatureNote,
    createCandidatureNotes: CreateCandidatureNotes,
    getCandidatureNotes: GetCandidatureNotes,
    updateCandidatureList: UpdateCandidatureList,
    updateCandidature: UpdateCandidature,
    deleteCandidatureNote: DeleteCandidatureNote,
    deleteCandidature: DeleteCandidature,
    deleteCaseGarant: DeleteGarant,
    deleteCaseContact: DeleteContact,
    getCandidatureDocuments: GetCandidatureDocuments,
    getCandidatureDocumentsCopy: GetCandidatureDocumentsCopy,
    createDocumentGroupCopy: CreateDocumentGroupCopy,
    deleteCandidatureDocument: DeleteCandidatureDocument,
    saveCandidatureDocument: SaveCandidatureDocument,
    sendDocumentMail: SendDocumentMail,
    sendVisitInvitation: SendVisitInvitation,
    getCandidatureVisit: GetCandidatureVisit,
    refuseCandidates: RefuseCandidates,
    askCandidatesDocuments: AskCandidatesDocuments,
  };
}
