import React from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { IconButton } from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight, ErrorOutline,
} from '@material-ui/icons';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import { PARAMETERES_INTEGRATION } from 'routes/Routes';
import { Link } from 'react-router-dom';

interface Props extends ToolbarProps<Event> {
  showUserWarning?: boolean
  isCurrentAgentCalendar?: boolean
}

export default function CalendarToolbar(
  {
    onNavigate,
    label,
    showUserWarning,
    isCurrentAgentCalendar,
  }: Props,
) {
  return (
    <div className="calendar-toolbar">
      <div className="navigation-actions">
        <div className="navigation-buttons">
          <div className="button-container">
            <IconButton onClick={() => onNavigate('PREV')}>
              <ChevronLeft />
            </IconButton>
          </div>
          <IconButton onClick={() => onNavigate('NEXT')}>
            <ChevronRight />
          </IconButton>
        </div>
        <Button color="third" onClick={() => onNavigate('TODAY')}>
          {Messages.t('dates.today')}
        </Button>
      </div>
      {
        showUserWarning && (
          isCurrentAgentCalendar ? (
            <div className="calendar-warning">
              <ErrorOutline />
              {Messages.t('calendar.warning.current')}
              <Link className="link-as-button" to={PARAMETERES_INTEGRATION}>
                {Messages.t('calendar.warning.sync')}
              </Link>
            </div>
          ) : (
            <div className="calendar-warning">
              <ErrorOutline />
              {Messages.t('calendar.warning')}
            </div>
          )
        )
      }
      <div className="toolbar-dates">
        {label}
      </div>
    </div>
  );
}
