import React, { useState } from 'react';
import { useUserAdminQueries } from 'network/queries/admin/UserQueries';
import { Controller, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Search } from '@material-ui/icons';
import Messages from 'services/i18n/Messages';
import { fuzzysearch } from 'lib/FuzzySearch';
import Button from 'theme/Button';
import { NotificationService } from 'lib/notification';

export default function UserAdmin() {
  const [submitting, setSubmitting] = useState(false);
  const userAdminQueries = useUserAdminQueries();
  const { getAllUser, giveAdminAcces, revokeAdminAcces } = userAdminQueries;
  const { data: users } = getAllUser();
  const {
    control,
    watch,
  } = useForm<{ userFilter: string }>();

  const giveAccess = (userId: string) => {
    setSubmitting(true);
    giveAdminAcces.mutateAsync({ userId })
      .then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const revokeAccess = (userId: string) => {
    setSubmitting(true);
    revokeAdminAcces.mutateAsync({ userId })
      .then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formField = watch();
  return (
    <div className="page-content">
      <Controller
        name="userFilter"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={{}}
            error={{}}
            prefix={<Search />}
            type="text"
            control={controller}
            label={Messages.t('field.search')}
          />
        )}
      />
      {
        users && users
          .filter((user) => !formField.userFilter
            || fuzzysearch(formField.userFilter, user.email))
          .sort((a, b) => a.email.localeCompare(b.email))
          .map((user) => (
            <div className="admin-user-card" key={user.id}>
              <div>
                {user.email}
              </div>
              <div>
                {
                  user.allAgenciesAccess ? (
                    <Button
                      disabled={submitting}
                      color="error"
                      onClick={() => revokeAccess(user.id)}
                    >
                      {Messages.t('admin.revokeAdmin')}
                    </Button>
                  ) : (
                    <Button
                      disabled={submitting}
                      onClick={() => giveAccess(user.id)}
                    >
                      {Messages.t('admin.giveAdmin')}
                    </Button>
                  )
                }
              </div>
            </div>
          ))
      }
    </div>
  );
}
