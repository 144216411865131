export const PROPERTY_ID = 'properties';

export const AGENT_ID = 'agentId';

export const SEARCH_QUERY = 'search';

export const ADD_PROPERTY = 'addProperty';

export const PROPERTY_URL = 'propertyUrl';

export const CODE = 'code';

export const STATE = 'state';

export const REFRESH = 'refresh';

export const TOKEN = 'token';

export const CREATE_VISIT = 'createVisit';

export const EXPIRED = 'expired';

export const DEFAULT_CANDIDATE = 'defaultCandidate';
