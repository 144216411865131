import React, { useState } from 'react';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import Messages from 'services/i18n/Messages';
import Button from 'theme/Button';
import { Check, Circle } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { PARAMETERES_PLAN } from 'routes/Routes';
import sessionManager from 'services/sessionManager';
import { permissionsEnum } from 'types/Permission';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import AgentUtils from 'services/AgentUtils';
import DialogWrapper from 'pages/common/DialogWrapper';

export default function PlanParameters() {
  const location = useLocation();
  const history = useHistory();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const agencyQueries = useAgencyBackend();
  const { getCurrentAgency } = agencyQueries;
  const { data: agency } = getCurrentAgency();
  const isAdmin = sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENT_ADMIN);
  const agentQueries = useAgentsBackend();
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents(!isAdmin);
  if (!agency) {
    return <div>Loading ...</div>;
  }
  return (
    <>
      {
        location.pathname.endsWith('success') && (
          <DialogWrapper open onClose={() => history.replace(PARAMETERES_PLAN)}>
            <div className="success-modal">
              <div className="success-title">
                {Messages.t('parameters.plan.success.title')}
              </div>
              <br />
              <div>
                {Messages.t('parameters.plan.success.subtitle')}
              </div>
              <br />
              <div>
                {Messages.t('parameters.plan.success.message')}
              </div>
            </div>
          </DialogWrapper>
        )
      }
      {
        showPaymentModal && (
          <DialogWrapper open={showPaymentModal} onClose={() => setShowPaymentModal(false)}>
            {
              isAdmin ? (
                <iframe
                  title="payment-page"
                  width="768"
                  height="998"
                  src="https://zohosecurepay.eu/checkout/q0k2rq4t-arp595hk3fed9/Premium-Plan--Settlesweet-Pro"
                  // @ts-ignore
                  // eslint-disable-next-line react/style-prop-object
                  style={{ border: 'none' }}
                />
              ) : (
                <div className="success-modal member-modal">
                  <div>
                    {Messages.t('parameters.plan.noAdmin')}
                  </div>
                  <br />
                  {
                    agents && agents
                      .filter((agent) => agent.roleName.toLowerCase() === 'admin')
                      .map((agent) => (
                        <div className="agent-name">
                          <Circle />
                          {
                            AgentUtils.getPrettyName(agent)
                          }
                        </div>
                      ))
                  }
                </div>
              )
            }
          </DialogWrapper>
        )
      }
      <div className="current-plan-tile">
        <div className="current-plan-tile-header">
          <h3>
            {Messages.t('parameters.plan.current')}
          </h3>
          <div className="current-plan">
            {
              agency.plan.visitSlotPlan
                ? Messages.t('parameters.plan.premium')
                : Messages.t('parameters.plan.free')
            }
          </div>
        </div>
        <div>
          {
            agency.plan.visitSlotPlan ? (
              <>
                <div>
                  {Messages.t('parameters.plan.premium.include')}
                </div>
                <br />
                <div>
                  {Messages.t('parameters.plan.premium.message')}
                </div>
              </>

            ) : (
              Messages.t('parameters.plan.limitedTo', { limit: agency.plan.maxFreeVisitSlot })
            )
          }
        </div>
        {
          !agency.plan.visitSlotPlan && (
            <div className="plan-message">
              {Messages.t('parameters.plan.Message')}
            </div>
          )
        }
      </div>
      {
        !agency.plan.visitSlotPlan && (
          <div className="integration-tile plan-payement-tile">
            <div className="integration-header plan-payement-header">
              <div>
                {Messages.t('parameters.plan.premium')}
              </div>
              <div className="payement-price">
                <div className="price-sign">
                  €
                </div>
                <div className="price-value">
                  {Messages.t('parameters.plan.price')}
                </div>
                <div className="price-range">
                  {Messages.t('price.byMonth')}
                </div>
              </div>
            </div>
            <div className="integration-body">
              <div className="message">
                <Check />
                {Messages.t('parameters.plan.payment.message')}
              </div>
              <div className="action-container">
                <Button onClick={() => setShowPaymentModal(true)}>
                  {Messages.t('parameters.plan.payment.switchPremium')}
                </Button>
                <div>
                  {Messages.t('parameters.plan.payment.noCondition')}
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}
