import { Agent } from 'types/Agent';

export default class AgentUtils {
  public static getPrettyName(agent?: Agent):string {
    if (!agent) {
      return '';
    }
    const name = `${agent.firstname || ''} ${agent.lastname || ''}`;
    return (name.trim() === '' ? agent.email : name.trim()) || '';
  }
}
