import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import ProfileParameteresForm from 'pages/agency-app/parameters/profilParameters/ProfileParameteresForm';
import Image from 'theme/Image';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import ImageUpload from 'lib/ImageUpload';
import { FileUpload } from 'types/FileUpload';
import { NotificationService } from 'lib/notification';
import ProfilePasswordForm from 'pages/agency-app/parameters/profilParameters/ProfilePasswordForm';
import AgentNotificationList from 'pages/agency-app/parameters/profilParameters/AgentNotificationList';

export default function ProfileParameteres() {
  const agentQueries = useAgentsBackend();
  const { getAgent, uploadAgentImage } = agentQueries;
  const { data: agent } = getAgent();

  const [submitting, setSubmitting] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const uploadImages = (files: FileUpload[]) => {
    if (agent && files.length === 1) {
      setSubmitting(true);
      uploadAgentImage.mutateAsync({ agentId: agent.id, image: files[0] })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
          setShowUploadModal(false);
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <div className="parmameters-page">
      <div>
        <div className="rounded-image">
          <Image alt="agent" src={agent?.imageURL} />
          <div className="upload-button-container">
            <ImageUpload
              maxFiles={1}
              submitting={submitting}
              onValidate={uploadImages}
              show={showUploadModal}
              setShow={setShowUploadModal}
            />
          </div>
        </div>
      </div>
      <div className="parameters-form">
        <h3> {Messages.t('parameters.personalDatas')}</h3>
        {
          agent && (
            <>
              <ProfileParameteresForm agent={agent} />
              <ProfilePasswordForm />
            </>
          )
        }
      </div>
      <div className="parameters-list">
        <h3>{Messages.t('parameters.notification')}</h3>
        {
          agent && <AgentNotificationList agent={agent} />
        }
      </div>
    </div>
  );
}
