import Messages from 'services/i18n/Messages';
import React from 'react';
import CandidatureUtils from 'services/CandidatureUtils';
import { CandidatureShort } from 'types/Candidature';
import { CancelRounded, CheckCircle } from '@material-ui/icons';
import { GliContract } from 'types/ContractTypes';
import { gliTypeEnum } from 'types/ProfesionalSituation';

type Props = {
  candidate: CandidatureShort,
  contract: GliContract,
};

export default function GliContractsTableRow({ candidate, contract }: Props) {
  const totalRevenusSituation = CandidatureUtils
    .caseIncomePerSituation(candidate, contract);

  return (
    <div className={`row-situation ${contract.activated ? 'contract-activated' : 'contract-not-activated'}`}>
      <div className="revenues">{totalRevenusSituation} € / mois {contract.name === gliTypeEnum.STUDENT ? (
        <>
          <br />
          <small>({Messages.t('field.garants')})</small>
        </>
      ) : ''}
      </div>
      <div className="situation">{Messages.t(`gli.${contract.name}`)}</div>
      <div className="status">
        {contract.activated ? (
          <>
            <CheckCircle />
            <div>{Messages.t('generics.YES')}</div>
          </>
        ) : (
          <>
            <CancelRounded />
            <div>{Messages.t('generics.NO')}</div>
          </>
        )}
      </div>
    </div>
  );
}
