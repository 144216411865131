import React from 'react';
import { useParams } from 'react-router-dom';
import { ADD } from 'routes/Routes';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import Candidature from 'pages/agency-app/candidates/candidateDetails/Candidature';
import { CircularProgress } from '@material-ui/core';

type Param = {
  id: string,
};

export default function CandidatureContainer() {
  const { id } = useParams<Param>();
  const candidatureQueries = useCandidatureBackend();
  const { getCandidature } = candidatureQueries;
  const { data: candidature, isLoading, dataUpdatedAt } = getCandidature(id, id !== ADD);
  if (isLoading) {
    return (
      <CircularProgress />
    );
  }
  return (
    <Candidature dataUpdatedAt={dataUpdatedAt} candidature={candidature} />
  );
}
