import React, { useCallback, useState } from 'react';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import AddressInputWrapper from 'lib/form/AddressInputWrapper';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import settings from 'services/settings';
import { FormControlLabel, Switch } from '@material-ui/core';
/* global google */

const GOOGLE_MAP_LIBRARIES = ['places'];

const placeTypes = [
  'airport',
  'amusement_park',
  'aquarium',
  'art_gallery',
  'bank',
  'bar',
  'bus_station',
  'gym',
  'hospital',
  'light_rail_station',
  'museum',
  'shopping_mall',
  'restaurant',
  'stadium',
  'train_station',
  'subway_station',
  'university',
];

type Props = {
  placeholder?: string
  onPostalCodeChanged?: (postalCode: string) => void
  onCityChanged?: (city: string) => void
  onCoordinateChanged?: (newCoordinates: {
    address: string,
    latitude?: number,
    longitude?: number,
  }) => void
  label: string
  onChange?: (address: string) => void
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  hideManualMode?: boolean,
  error?: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  autoSaveSubmit?: (value: string) => void,
  contextualActionLabel?: string,
  contextualAction?: (checked: boolean) => void,
  contextualActionChecked?: boolean,
  disabled?: boolean,
};

export default function AddressAutocompleteWrapper(
  {
    placeholder,
    onPostalCodeChanged,
    onCityChanged,
    onCoordinateChanged,
    label,
    control,
    onChange,
    apiErrors,
    error,
    hideManualMode,
    autoSaveSubmit,
    contextualActionLabel,
    contextualAction,
    contextualActionChecked,
    disabled,
  }: Props,
) {
  const { field } = control;

  const [submitting, setSubmitting] = useState(false);
  // this ts-ignore is mandatory. If the type Libraries is included there type a type error
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: settings.googleMapsApiKey || '',
    // @ts-ignore
    libraries: GOOGLE_MAP_LIBRARIES,
  });

  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [manualInput, setManualInput] = useState(false);

  const handlePlaceChanged = useCallback(async () => {
    if (!autocomplete) {
      return;
    }
    const place = autocomplete?.getPlace();
    let adresse = place.formatted_address;
    if (place.types && place.types.includes('point_of_interest')) {
      let placeTypeLabel = '';
      for (const placeType of placeTypes) {
        if (place.types.includes(placeType)) {
          placeTypeLabel = Messages.t(`placeType.${placeType}` as 'placeType.stadium');
          break;
        }
      }
      adresse = `${place.name}, ${place.vicinity}${placeTypeLabel ? ` (${placeTypeLabel})` : ''}`;
    }
    if (onCoordinateChanged && place && place.geometry && place.types && adresse) {
      setSubmitting(true);
      await onCoordinateChanged({
        address: adresse,
        latitude: place.geometry.location?.lat(),
        longitude: place.geometry.location?.lng(),
      });
      field.onChange(adresse);
      setSubmitting(false);
    } else if (adresse) {
      if (autoSaveSubmit) {
        setSubmitting(true);
        await autoSaveSubmit(adresse);
        setSubmitting(false);
      }
      field.onChange(adresse);
    }
    if ((onPostalCodeChanged || onCityChanged) && place.address_components) {
      for (const component of place.address_components) {
        for (const componentType of component.types) {
          if (componentType === 'locality' && component.long_name && onCityChanged) {
            // eslint-disable-next-line no-await-in-loop
            await onCityChanged(component.long_name);
            break;
          }
          if (componentType === 'postal_code' && component.long_name && onPostalCodeChanged) {
            // eslint-disable-next-line no-await-in-loop
            await onPostalCodeChanged(component.long_name);
            break;
          }
        }
      }
    }
  }, [autocomplete, onPostalCodeChanged, field]);
  if (!isLoaded) {
    return null;
  }

  const toggleManualInput = () => {
    setManualInput((prevState) => !prevState);
  };

  return (
    <div className="address-input-wrapper">
      {
        contextualAction && contextualActionLabel && (
          <div className="address-input-contextual-action">
            <FormControlLabel
              labelPlacement="start"
              control={(
                <Switch
                  size="small"
                  disabled={disabled}
                  checked={contextualActionChecked}
                  onChange={(_, checked) => contextualAction(checked)}
                />
              )}
              label={contextualActionLabel}
            />
          </div>
        )
      }
      {
        !hideManualMode && (
          <div className="switch-wrapper">
            <FormControlLabel
              control={<Switch onChange={toggleManualInput} />}
              label={Messages.t('form.action.manuallyEnterAddress')}
            />
          </div>
        )
      }
      {
        manualInput
          ? (
            <AddressInputWrapper
              onPostalCodeChanged={onPostalCodeChanged}
              onCityChanged={onCityChanged}
              control={control}
              error={error}
              apiErrors={apiErrors}
              autoSaveSubmit={autoSaveSubmit}
              hideCountry
            />
          ) : (
            <div className="material-textfield-wrapper">
              <Autocomplete
                onLoad={setAutocomplete}
                onPlaceChanged={handlePlaceChanged}
                restrictions={{ country: 'fr' }}
              >
                <TextFieldWrapper
                  control={control}
                  disabled={disabled}
                  submitting={submitting}
                  error={error}
                  onChange={onChange}
                  apiErrors={apiErrors}
                  placeholder={placeholder}
                  label={label || undefined}
                />
              </Autocomplete>
            </div>
          )
      }
    </div>
  );
}
