import React from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import { Link } from 'react-router-dom';
import { PARAMETERES_QUALIFICATION_OCCUPANTS } from 'routes/Routes';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  open: boolean,
  onClose: () => void,
};

export default function DialogNoGli({ open, onClose }: Props) {
  return (
    <DialogWrapper
      open={open}
      onClose={onClose}
    >
      <div className="no-gli-modal">
        <DialogTitle>
          <div className="title">{Messages.t('property.noGliActivatedTitle')}</div>
        </DialogTitle>
        <DialogContent>
          <div className="content">
            <p>{Messages.t('property.noGliActivatedContent')}</p>
            <div className="link">
              <Link to={PARAMETERES_QUALIFICATION_OCCUPANTS}>
                {Messages.t('property.clickHereGli')}
              </Link>
            </div>
          </div>
        </DialogContent>
      </div>
    </DialogWrapper>
  );
}
