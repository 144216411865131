import Messages from 'services/i18n/Messages';
import CandidatureUtils from 'services/CandidatureUtils';
import {
  CancelRounded, CheckCircle,
  ThumbDown, ThumbUp,
} from '@material-ui/icons';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { GliContract } from 'types/ContractTypes';
import { CandidatureShort } from 'types/Candidature';
import GliContractsTable from 'pages/agency-app/candidates/candidates/candidateKanban/GliTooltip/GliContractsTable';

type Props = {
  candidate: CandidatureShort,
  gliContracts?: GliContract[],
  showGliStatus: boolean,
  agencyGliRatio?: number,
  minRevenues: number,
};

export default function GliTooltip(
  {
    candidate,
    gliContracts,
    showGliStatus,
    agencyGliRatio,
    minRevenues,
  }: Props,
) {
  const contractsActivated = gliContracts?.filter((contract) => contract.activated);

  const checkGli = showGliStatus && (candidate.gliEligible || false);

  const contractsAmongContacts = gliContracts?.filter((contract) => (
    candidate.contacts
      .map((contact) => CandidatureUtils.getGliSituation(contact))
      .filter((contact) => contact === contract.name).length > 0
  ));

  const propertyLease = candidate.property?.price;

  const showTable = contractsActivated
    && propertyLease
    && minRevenues
    && contractsActivated.length > 0
    && contractsAmongContacts && contractsAmongContacts.length > 0;
  return (
    <Tooltip
      classes={{ tooltip: 'gli-pinel-tooltip gli' }}
      title={(
        <div>
          <div>
            {checkGli ? (
              <p className="result ok">
                <CheckCircle />{Messages.t('gli.tooltip.gliSuccess')} !
              </p>
            ) : (
              <p className="result not-ok">
                <CancelRounded />{Messages.t('gli.tooltip.gliFail')} !
              </p>
            )}
          </div>
          {
            showTable ? (
              <GliContractsTable
                contracts={contractsAmongContacts}
                candidate={candidate}
                agencyGliRatio={agencyGliRatio}
                propertyLease={propertyLease}
              />
            ) : (
              <p>{Messages.t('gli.tooltip.noContractActivated')}</p>
            )
          }
        </div>
      )}
    >
      <div className={`contact-tag gli-pinel ${checkGli ? 'ok' : 'not-ok'}`}>
        {Messages.t('property.GLIShort')}
        {checkGli ? <ThumbUp /> : <ThumbDown />}
      </div>
    </Tooltip>
  );
}
