import React, { useState } from 'react';
import { AgentDetailled } from 'types/Agent';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Messages from 'services/i18n/Messages';
import sessionManager from 'services/sessionManager';
import { permissionsEnum } from 'types/Permission';
import { Role } from 'types/Role';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { NotificationService } from 'lib/notification';
import AgentUtils from 'services/AgentUtils';

type Props = {
  agent: AgentDetailled
  roles: Role[],
};

export default function AgentListItem({ agent, roles }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const agentQueries = useAgentsBackend();
  const { updateAgentRole, deleteAgent } = agentQueries;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateAgentsRole = (agentId: string, roleId: string) => {
    setSubmitting(true);
    updateAgentRole.mutateAsync({ agentId, roleId })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        handleClose();
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const deleteAgents = (agentId: string) => {
    setSubmitting(true);
    deleteAgent.mutateAsync(agentId)
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.delete'));
        handleClose();
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const open = Boolean(anchorEl);
  return (
    <div className="agent-item">
      <span>{AgentUtils.getPrettyName(agent)}</span>
      <div className="role-container">
        <div className="role">{agent.roleName}</div>
      </div>
      {
        sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENT_ADMIN)
        && (
          <>
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {
                roles.filter((role) => role.name !== agent.roleName).map((role) => (
                  <MenuItem
                    key={role.id}
                    disabled={submitting}
                    onClick={() => updateAgentsRole(agent.id, role.id)}
                  >
                    {Messages.t('agent.setRole', { role: role.name })}
                  </MenuItem>
                ))
              }
              {
                sessionManager.getSession()?.user_id !== agent.userAccountID && (
                  <MenuItem disabled={submitting} onClick={() => deleteAgents(agent.id)}>
                    {Messages.t('agent.remove')}
                  </MenuItem>
                )
              }
            </Menu>
          </>
        )
      }
    </div>
  );
}
