import { Contact } from 'types/Contact';

export default class ContactUtils {
  public static getDisplayedName(contact: Contact, contactCount: number): string {
    let finalName = '';
    if ((contact.firstname || '') !== '' || (contact.lastname || '') !== '') {
      finalName = `${contact.firstname || ''} ${contact.lastname || ''}`.trim();
    } else if (contact.email && contact.email !== '') {
      finalName = contact.email;
    } else if (contact.phoneNumber && contact.phoneNumber !== '') {
      finalName = contact.phoneNumber;
    }
    if (finalName !== '') {
      return `${finalName}${contactCount > 1 ? ` +${contactCount - 1}` : ''}`;
    }
    return 'No Data';
  }
}
