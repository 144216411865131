import React, { useState } from 'react';
import { PropertyActionsType, PropertyDetailled, PropertyPartial } from 'types/Property';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import Messages from 'services/i18n/Messages';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { Controller, useForm } from 'react-hook-form';
import SelectWrapper from 'lib/form/SelectWrapper';
import { AgentDetailled } from 'types/Agent';
import { useHistory } from 'react-router-dom';
import { APPARTMENT } from 'routes/Routes';
import { NotificationService } from 'lib/notification';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown, MoreVert } from '@material-ui/icons';
import confirmationService from 'services/ConfirmationService';
import { booleanToString } from 'lib/form/FormUtils';

type Props = {
  property: PropertyDetailled
};

export default function PropertyActions({ property }: Props) {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const agentQueries = useAgentsBackend();
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickStatus = (event) => {
    setAnchorElStatus(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseStatus = () => {
    setAnchorElStatus(null);
  };
  const open = Boolean(anchorEl);
  const openStatus = Boolean(anchorElStatus);
  const {
    control,
  } = useForm<PropertyActionsType>({
    defaultValues: {
      ...property,
      online: booleanToString(property.online) || undefined,
      isArchived: booleanToString(property.isArchived) || undefined,
    },
  });
  const propertiesQueries = usePropertiesBackend();
  const { updateProperty, deleteProperty } = propertiesQueries;

  const submitProperty = (data: PropertyPartial) => {
    setSubmitting(true);
    updateProperty.mutateAsync(data)
      .then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
        handleCloseStatus();
      });
  };

  const removeProperty = (propertyId: string) => {
    setSubmitting(true);
    deleteProperty.mutateAsync({ propertyId })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        history.push(APPARTMENT);
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
        handleClose();
      });
  };

  const archiveProperty = async (propertyId: string) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('confirmation.archivage.title'),
      message: Messages.t('confirmation.archivage.message'),
      actionMessage: Messages.t('confirmation.archivage.action'),
    });
    if (isConfirmed) {
      submitProperty({ id: propertyId, isArchived: true });
    } else {
      handleClose();
    }
  };

  const getBtnText = (): Messages => {
    if (property.isArchived) {
      return Messages.t('property.archived');
    }
    return property.online ? Messages.t('status.online') : Messages.t('status.offline');
  };

  const getBtnClass = (): string => {
    if (property.isArchived) {
      return 'archived';
    }
    return property.online ? 'online' : 'offline';
  };

  const confirmeDeleteProperty = async (propertyId: string) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('confirmation.deleteProperty.title'),
      actionColor: 'error',
      message: (
        <div>
          {Messages.t('confirmation.deleteProperty.message')}
          <br />
          <button
            className="archive-link"
            type="button"
            onClick={() => archiveProperty(propertyId)}
          >
            {Messages.t('confirmation.deleteProperty.archive')}
          </button>
        </div>
      ),
      actionMessage: Messages.t('confirmation.deleteProperty.action'),
    });
    handleClose();
    if (isConfirmed) {
      removeProperty(propertyId);
    }
  };

  const getAgentName = (agent: AgentDetailled) => {
    const name = `${agent.firstname || ''} ${agent.lastname || ''}`;
    return name.trim() === '' ? agent.email : name;
  };

  return (
    <div className="actions-container property-actions">
      {
        agents && (
          <Controller
            name="agentID"
            control={control}
            render={(controller) => (
              <SelectWrapper
                error={{}}
                onChange={(agentId) => submitProperty({ agentID: agentId, id: property.id })}
                control={controller}
                label={Messages.t('field.agent')}
                values={agents.map((agent) => ({ key: agent.id, label: getAgentName(agent) }))}
              />
            )}
          />
        )
      }
      <button
        type="button"
        className={`link-action ${getBtnClass()} dropdown ${openStatus ? 'arrow-upside-down' : ''}`}
        onClick={handleClickStatus}
      >
        {getBtnText()} <ArrowDropDown />
      </button>
      <div>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disabled={submitting}
          onClick={() => confirmeDeleteProperty(property.id)}
          className="danger-item"
        >
          {Messages.t('property.delete')}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorElStatus}
        open={openStatus}
        onClose={handleCloseStatus}
      >
        {property.isArchived ? (
          <MenuItem
            onClick={() => submitProperty({
              id: property.id,
              isArchived: false,
            })}
            disabled={submitting}
          >
            {Messages.t('property.unArchive')}
          </MenuItem>
        ) : (
          [
            <MenuItem
              key="off"
              onClick={() => submitProperty({
                online: !property.online,
                id: property.id,
              })}
              disabled={submitting}
            >
              {property.online ? Messages.t('status.turnOffline') : Messages.t('status.turnOnline')}
            </MenuItem>,
            <MenuItem
              key="archive"
              onClick={() => archiveProperty(property.id)}
              disabled={submitting}
            >
              {Messages.t('property.archive')}
            </MenuItem>,
          ]
        )}
      </Menu>
    </div>
  );
}
