import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { PropertyDetailled } from 'types/Property';
import VisitSlotUtils from 'services/VisitSlotUtils';
import AgendaEventDateDisplay from 'pages/common/calendar/AgendaEventDateDisplay';
// The component is recursive
// eslint-disable-next-line import/no-cycle
import VisitSlotDialog from 'pages/common/visitSlots/VisitSlotDialog';
import { VisitSlotWithVisitCount } from 'types/VisitSlot';
import { Agent } from 'types/Agent';
import { CandidatureShort } from 'types/Candidature';
import PropertyVisits from 'pages/agency-app/appartments/propertyDetails/PropertyVisits';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  property: PropertyDetailled
  visitSlots: VisitSlotWithVisitCount[]
  agents: Agent[],
  candidature: CandidatureShort[],
  showShowVisitBtn?: boolean,
};

export default function PropertyVisitSlotList(
  {
    property,
    visitSlots,
    agents,
    candidature,
    showShowVisitBtn,
  }: Props,
) {
  const [selectedVisitSlot, setSelectedVisitSlot] = useState<string | undefined>();
  const [showVisitModal, setShowVisitModal] = useState(false);
  return (
    <>
      {
        selectedVisitSlot && (
          <VisitSlotDialog
            properties={[property]}
            agents={agents}
            candidatures={candidature}
            defaultProperty={property.id}
            visitSlotId={selectedVisitSlot}
            open
            onClose={() => {
              setSelectedVisitSlot(undefined);
            }}
          />
        )
      }
      <div>
        <h4>
          {Messages.t('visitSlot.form.title')}
        </h4>
        <div className="property-visit-slot-container">
          <div className="property-visit-slots">
            {
              visitSlots.sort((a, b) => a.startDate.localeCompare(b.startDate))
                .map((visitSlot) => {
                  const visitNumber = VisitSlotUtils.getPossibleVisitNumber(visitSlot);
                  return (
                    <button
                      type="button"
                      onClick={() => setSelectedVisitSlot(visitSlot.id)}
                      key={visitSlot.id}
                      className="agenda-visit-slot agenda-page-event"
                    >
                      <AgendaEventDateDisplay
                        event={{
                          start: new Date(visitSlot.startDate),
                          end: new Date(visitSlot.endDate),
                        }}
                        time={{ day: new Date(visitSlot.startDate) }}
                      />
                      <div
                        className={`booked-visit-container ${visitSlot.visitCount.toString() === visitNumber ? 'full' : ''}`}
                      >
                        {Messages.t('visitSlot.bookedVisit', {
                          bookedVisit: visitSlot.visitCount,
                          total: visitNumber,
                        })}
                      </div>
                    </button>
                  );
                })
            }
          </div>
          {
            showShowVisitBtn && visitSlots && visitSlots.length > 0 && (
              <button
                type="button"
                className="link-action"
                onClick={() => setShowVisitModal(true)}
              >
                {Messages.t('property.seeVisits')}
              </button>
            )
          }
        </div>
      </div>
      {
        property && showVisitModal && (
          <DialogWrapper open={showVisitModal} onClose={() => setShowVisitModal(false)}>
            <div className="visit-modal-content">
              <PropertyVisits propertyId={property.id} />
            </div>
          </DialogWrapper>
        )
      }
    </>
  );
}
