import React from 'react';
import { Control, Controller } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import { CandidatureForm } from 'types/forms/CandidatureForm';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import SelectWrapper from 'lib/form/SelectWrapper';
import { garantTypeEnum } from 'types/Garant';
import { garantProSituationTypeEnum, proSituationTypeEnum } from 'types/ProfesionalSituation';
import { contractTypeTypeEnum } from 'types/ContractTypes';
import { useGarantBackend } from 'network/queries/GarantQueries';

type Props = {
  control?: Control<CandidatureForm>,
  index: number,
  errors: FieldErrors
  formField: CandidatureForm,
  apiErrors: { [key: string]: string[] },
};

export default function GarantForm(
  {
    index,
    control,
    errors,
    apiErrors,
    formField,
  }: Props,
) {
  const garantQueries = useGarantBackend();
  const { getGarantModification } = garantQueries;
  const { data: modifications } = getGarantModification(formField.garants[index].id || '', !!formField.garants[index].id);
  const type = formField.garants?.[index]?.type;
  const situationArr = formField.garants[index].situation?.split(',');
  return (
    <div className="page-content">
      <h5>{Messages.t('field.garantDatas')}</h5>
      <Controller
        name={`garants.${index}.type` as 'garants.0.type'}
        control={control}
        rules={{ required: true }}
        render={(controller) => (
          <SelectWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            modifications={modifications}
            label={Messages.t('field.type')}
            values={Object.values(garantTypeEnum).map((key) => ({
              key, label: Messages.t(`garantType.${key}`),
            }))}
          />
        )}
      />
      {
        type && ((type === garantTypeEnum.PERSON) ? (
          <>
            <h5>{Messages.t('field.personalDatas')}</h5>
            <div className="form-row">
              <Controller
                name={`garants.${index}.firstname` as 'garants.0.firstname'}
                control={control}
                rules={{
                  required: {
                    value: !formField.garants[index]?.lastname,
                    message: Messages.t('form.error.name'),
                  },
                  validate: () => {
                    const garanName = `${formField.garants[index].firstname} ${formField.garants[index].lastname}`;
                    if (!formField.garants
                      .every((garant, idx) => index === idx || garanName !== `${garant.firstname} ${garant.lastname}`)
                    ) {
                      return Messages.t('form.error.GarantDuplicateName');
                    }
                    return undefined;
                  },
                }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    modifications={modifications}
                    error={errors}
                    type="text"
                    control={controller}
                    label={Messages.t('field.firstName')}
                  />
                )}
              />
              <Controller
                name={`garants.${index}.lastname` as 'garants.0.lastname'}
                control={control}
                rules={{
                  required: {
                    value: !formField.garants[index]?.firstname,
                    message: Messages.t('form.error.name'),
                  },
                  validate: () => {
                    const garanName = `${formField.garants[index].firstname} ${formField.garants[index].lastname}`;
                    if (!formField.garants
                      .every((garant, idx) => index === idx || garanName !== `${garant.firstname} ${garant.lastname}`)
                    ) {
                      return Messages.t('form.error.GarantDuplicateName');
                    }
                    return undefined;
                  },
                }}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    modifications={modifications}
                    error={errors}
                    type="text"
                    control={controller}
                    label={Messages.t('field.lastName')}
                  />
                )}
              />
            </div>
            <h5>{Messages.t('field.professionalDatas')}</h5>
            <div className="form-row">
              <Controller
                name={`garants.${index}.situation` as 'garants.0.situation'}
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <SelectWrapper
                    multiple
                    modifications={modifications}
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('field.professionalSituation')}
                    values={Object.values(garantProSituationTypeEnum).map((key) => ({
                      key, label: Messages.t(`professionalSituation.${key}`),
                    }))}
                  />
                )}
              />
            </div>
            <div className="form-row">
              {
                (situationArr && (situationArr.includes(proSituationTypeEnum.EMPLOYED)
                  || situationArr.includes(proSituationTypeEnum.OFFICIAL)
                  || situationArr.includes(proSituationTypeEnum.FREELANCER)))
                && (
                  <>
                    <Controller
                      name={`garants.${index}.profession` as 'garants.0.profession'}
                      control={control}
                      render={(controller) => (
                        <TextFieldWrapper
                          modifications={modifications}
                          apiErrors={apiErrors}
                          error={errors}
                          type="text"
                          control={controller}
                          label={Messages.t('field.profession')}
                        />
                      )}
                    />
                    <Controller
                      name={`garants.${index}.enterprise` as 'garants.0.enterprise'}
                      control={control}
                      render={(controller) => (
                        <TextFieldWrapper
                          apiErrors={apiErrors}
                          modifications={modifications}
                          error={errors}
                          type="text"
                          control={controller}
                          label={Messages.t('field.enterprise')}
                        />
                      )}
                    />
                  </>
                )
              }
            </div>
            {situationArr && situationArr.includes(proSituationTypeEnum.EMPLOYED)
              && (
                <div className="form-row">
                  <Controller
                    name={`garants.${index}.leaseType` as 'garants.0.leaseType'}
                    control={control}
                    render={(controller) => (
                      <SelectWrapper
                        apiErrors={apiErrors}
                        modifications={modifications}
                        error={errors}
                        control={controller}
                        label={Messages.t('field.leaseType')}
                        values={Object.values(contractTypeTypeEnum).map((key) => ({
                          key, label: Messages.t(`contractType.${key}`),
                        }))}
                      />
                    )}
                  />
                  <Controller
                    name={`garants.${index}.trialPeriod` as 'garants.0.trialPeriod'}
                    control={control}
                    render={(controller) => (
                      <SelectWrapper
                        apiErrors={apiErrors}
                        modifications={modifications}
                        error={errors}
                        control={controller}
                        label={Messages.t('field.trialPeriod')}
                        values={[
                          { key: 'all', label: Messages.t('generics.all') },
                          { key: 'true', label: Messages.t('generics.YES') },
                          { key: 'false', label: Messages.t('generics.NO') },
                        ]}
                      />
                    )}
                  />
                </div>
              )}
            <div className="form-row">
              <Controller
                name={`garants.${index}.monthlyIncome` as 'garants.0.monthlyIncome'}
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    modifications={modifications}
                    error={errors}
                    type="text"
                    control={controller}
                    label={Messages.t('field.monthlyIncome')}
                  />
                )}
              />
            </div>
          </>
        ) : (
          <div className="form-row">
            <Controller
              name={`garants.${index}.name` as 'garants.0.name'}
              control={control}
              rules={{
                required: true,
                validate: (name) => {
                  if (name && !formField.garants
                    .every((garant, idx) => index === idx || name !== garant.name)
                  ) {
                    return Messages.t('form.error.GarantDuplicate');
                  }
                  return undefined;
                },
              }}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  modifications={modifications}
                  error={errors}
                  type="text"
                  control={controller}
                  label={Messages.t('field.name')}
                />
              )}
            />
            {
              type === garantTypeEnum.ENTERPRISE ? (
                <div />
              ) : (
                <Controller
                  name={`garants.${index}.amount` as 'garants.0.amount'}
                  control={control}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      modifications={modifications}
                      error={errors}
                      type="text"
                      control={controller}
                      label={Messages.t('field.amount')}
                    />
                  )}
                />
              )
            }
          </div>
        ))
      }
    </div>
  );
}
