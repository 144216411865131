import { useMutation, useQuery, useQueryClient } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';
import { Agency, AgencyDetailed, AgencyPlan } from 'types/Agency';
import { FileUpload } from 'types/FileUpload';
import { AgencyStep } from 'types/AgencyStep';
import { Role } from 'types/Role';
import candidatureFilterService, { CandidatureFilterService } from 'services/filters/CandidatureFilterService';
import { OpenTimeSlot } from 'types/OpeningDays';
import { GliContract } from 'types/ContractTypes';
import { Pinel } from 'types/Pinel';

export function useAgencyBackend() {
  const queryClient = useQueryClient();

  const GetCurrentAgency = (enabled = true) => useQuery<AgencyDetailed>(
    ['getAgency', sessionManager.getSession()?.agent_id],
    async () => {
      const response: AgencyDetailed = await backend.fetchJson(
        '/api/agencies/current',
      );
      return response;
    },
    { enabled },
  );

  const UploadAgencyImage = useMutation<Agency, any, {
    agencyId: string,
    image: FileUpload
  }>(
    async (data) => {
      const response = await backend.post(`/api/agencies/${data.agencyId}/images`, data.image);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgency', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const UpdateAgency = useMutation<Agency, any, {
    agencyId: string,
    agency: Partial<Agency>
  }>(
    async (data) => {
      const response = await backend.patch(`/api/agencies/${data.agencyId}`, data.agency);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgency', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const UpdateAgencyPlan = useMutation<AgencyPlan, any, {
    agencyId: string,
    agencyPlan: Partial<AgencyPlan>
  }>(
    async (data) => {
      const response = await backend.patch(`/admin/agencies/${data.agencyId}/plan`, data.agencyPlan);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgency', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const UpdateAgencySteps = useMutation<Agency, any, {
    agencyId: string,
    steps: AgencyStep[]
  }>(
    async (data) => {
      const response = await backend.put(`/api/agencies/${data.agencyId}/steps`, data.steps);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgencySteps', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const DeleteAgencyStep = useMutation<Agency, any, {
    agencyId: string,
    stepId: string
  }>(
    async (data) => {
      const response = await backend.delete(`/api/agencies/${data.agencyId}/steps/${data.stepId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgencySteps', sessionManager.getSession()?.agent_id]);
        queryClient.refetchQueries(['getCandidatures', sessionManager.getSession()?.agent_id, CandidatureFilterService.getOnePropertyId(candidatureFilterService.getFilter().properties)]);
      },
    },
  );

  const CreateAgencyStep = useMutation<Agency, any, {
    agencyId: string,
    step: Partial<AgencyStep>
  }>(
    async (data) => {
      const response = await backend.post(`/api/agencies/${data.agencyId}/steps`, data.step);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgencySteps', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const GetAgencySteps = (enabled = true) => useQuery<{ [key: string]: AgencyStep }>(
    ['getAgencySteps', sessionManager.getSession()?.agent_id],
    async () => {
      const response: AgencyStep[] = await backend.fetchJson(
        '/api/agencies/current/steps',
      );
      return response.reduce((acc, value) => {
        acc[value.id] = value;
        return acc;
      }, {});
    },
    { enabled },
  );

  const GetAgencyRoles = (enabled = true) => useQuery<Role[]>(
    ['getAgencyRoles', sessionManager.getSession()?.agent_id],
    async () => {
      const response: Role[] = await backend.fetchJson(
        '/api/agencies/current/roles',
      );
      return response;
    },
    { enabled },
  );

  const GetAgencyTimeslots = (enabled = true) => useQuery<OpenTimeSlot[]>(
    ['getAgencyTimeslots', sessionManager.getSession()?.agent_id],
    async () => {
      const response: OpenTimeSlot[] = await backend.fetchJson(
        '/api/agencies/current/openinghours',
      );
      return response;
    },
    { enabled },
  );

  const UpdateAgencyTimeslots = useMutation<OpenTimeSlot[], any, {
    agencyId: string,
    timeslots?: OpenTimeSlot[],
  }>(
    async (data) => {
      const response = await backend.put(`/api/agencies/${data.agencyId}/openinghours`, data.timeslots);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgencyTimeslots', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const CreateGliContracts = useMutation<GliContract[], any, {
    agencyId: string,
    gliContracts?: GliContract[],
  }>(
    async (data) => {
      const response = await backend.post(`/api/agencies/${data.agencyId}/gli-contracts`, data.gliContracts);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['getGliContracts', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const GetGliContracts = (enabled = true) => useQuery<GliContract[]>(
    ['getGliContracts', sessionManager.getSession()?.agent_id],
    async () => {
      const response: GliContract[] = await backend.fetchJson(
        '/api/agencies/current/gli-contracts',
      );
      return response;
    },
    { enabled },
  );

  const GetPinelBareme = (enabled = true) => useQuery<Pinel[]>(
    ['getPinelBareme', sessionManager.getSession()?.agent_id],
    async () => {
      const response: Pinel[] = await backend.fetchJson(
        '/api/public/agencies/pinel',
      );
      return response;
    },
    { enabled },
  );

  return {
    updateAgencyPlan: UpdateAgencyPlan,
    getAgencyRoles: GetAgencyRoles,
    updateAgency: UpdateAgency,
    uploadAgencyImage: UploadAgencyImage,
    getCurrentAgency: GetCurrentAgency,
    getAgencySteps: GetAgencySteps,
    deleteAgencyStep: DeleteAgencyStep,
    updateAgencySteps: UpdateAgencySteps,
    createAgencyStep: CreateAgencyStep,
    updateAgencyTimeslots: UpdateAgencyTimeslots,
    getAgencyTimeslots: GetAgencyTimeslots,
    createGliContracts: CreateGliContracts,
    getGliContracts: GetGliContracts,
    getPinelBareme: GetPinelBareme,
  };
}
