import React from 'react';
import Image from 'theme/Image';

type Props = {
  name: string
  color?: 'primary' | 'secondary'
  imageUrl?: string
};

export default function NameWithInitials(
  {
    imageUrl,
    name,
    color,
  }: Props,
) {
  return (
    <div className="name-with-initials">
      <div className={`name-initials ${color || ''}`}>
        {
          imageUrl ? (
            <Image alt="logo" src={imageUrl} />
          ) : (
            name.split(' ').slice(0, 2).map((word) => (
              word[0]
            ))
          )
        }
      </div>
      <div>{name}</div>
    </div>
  );
}
