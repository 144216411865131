import React, { useState } from 'react';
import { CandidatureDetailled } from 'types/Candidature';
import Messages from 'services/i18n/Messages';
import {
  DocumentDataMap,
  DocumentSection,
  documentSection,
} from 'types/DocumentData';
import DocumentSectionDetails
  from 'pages/agency-app/candidates/candidateDetails/candidateDocuments/documentSectionDetails/DocumentSectionDetails';
import Button from 'theme/Button';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { NotificationService } from 'lib/notification';
import { DocumentCopyForm } from 'types/forms/DocumentCopyForm';
import FilesUtils from 'services/FilesUtils';
import ContactUtils from 'services/ContactUtils';
import { Download } from '@material-ui/icons';
import SpinButton from 'theme/SpinButton';
import GarantUtils from 'services/GarantUtils';

type Props = {
  candidature: CandidatureDetailled,
  documentDataMap: DocumentDataMap
};

export default function CandidateDocuments({ candidature, documentDataMap }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [selectedContactIndex, setSelectedContact] = useState<number | undefined>(0);
  const [selectedGarantIndex, setSelectedGarant] = useState<number | undefined>();
  const candidatureQueries = useCandidatureBackend();
  const {
    createDocumentGroupCopy,
  } = candidatureQueries;

  const selectedGarant = selectedGarantIndex !== undefined
    ? candidature.garants[selectedGarantIndex]
    : undefined;
  const selectedContact = selectedContactIndex !== undefined
    ? candidature.contacts[selectedContactIndex]
    : undefined;

  const createCase = (data: DocumentCopyForm) => {
    createDocumentGroupCopy.mutateAsync({
      candidatureId: candidature.id,
      data,
    }).then(() => NotificationService.notifySuccess(Messages.t('notifications.case.created')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')));
  };

  const groupId = selectedContact?.documentGroupCopyID;
  const garantGroupId = selectedGarant?.documentGroupCopyID;

  const currentGroupId = groupId || garantGroupId || '';
  const currentGroup = candidature.documentGroups?.filter((group) => group.id
    === currentGroupId)[0];
  const possibleTagsList = documentDataMap[currentGroupId]
    ? Object.keys(documentDataMap[currentGroupId])
    : [];
  const possibleTags: { [tag: string]: string } = {};
  currentGroup?.possibleTags?.split(',')?.forEach((tag) => {
    possibleTags[tag] = tag;
  });
  possibleTagsList.forEach((tag) => {
    possibleTags[tag] = tag;
  });

  const namesMap: { [key: string]: string } = {};
  candidature.contacts.forEach((contact, index) => {
    namesMap[contact.documentGroupCopyID || ContactUtils.getDisplayedName(contact, 0)] = `${Messages.t('field.contact')} ${index + 1}`;
  });

  candidature.garants.forEach((garant, index) => {
    namesMap[garant.documentGroupCopyID || GarantUtils.getDisplayedName(garant)] = `${Messages.t('field.garant')} ${index + 1}`;
  });
  const sortedContact = candidature
    .contacts.sort((a, b) => (a.order || -1) - (b.order || -1));

  const mainContactName = ContactUtils.getDisplayedName(sortedContact[0], 0);

  return (
    <div>
      <div className="page-selector-container">
        {
          candidature.contacts
            .map((item, index) => (
              <button
                type="button"
                onClick={() => {
                  setSelectedContact(index);
                  setSelectedGarant(undefined);
                }}
                key={item.id}
                className={`page-selector ${selectedContactIndex === index ? 'selected' : ''}`}
              >
                {Messages.t('field.contact')} {index + 1}
              </button>
            ))
        }
        {
          candidature.garants
            .map(
              (item, index) => (
                <button
                  type="button"
                  onClick={() => {
                    setSelectedGarant(index);
                    setSelectedContact(undefined);
                  }}
                  key={item.id}
                  className={`page-selector ${selectedGarantIndex === index ? 'selected' : ''}`}
                >
                  {Messages.t('field.garant')} {index + 1}
                </button>
              ),
            )
        }
        {
          documentDataMap && (
            <SpinButton
              spin={submitting}
              type="button"
              className="page-selector"
              disabled={Object.keys(documentDataMap).length === 0}
              startIcon={<Download />}
              onClick={() => {
                setSubmitting(true);
                FilesUtils.generateZipFiles(
                  documentDataMap,
                  namesMap,
                  mainContactName,
                ).finally(() => setSubmitting(false));
              }}
              title={Messages.t('formButton.downloadAll')}
            />
          )
        }
      </div>
      {
        Object.keys(possibleTags).length === 0 && (
          <Button
            onClick={() => createCase({
              contactId: selectedContact?.id,
              garantId: selectedGarant?.id,
            })}
          >
            {Messages.t('case.create')}
          </Button>
        )
      }
      {
        documentDataMap && currentGroupId && Object.keys(possibleTags).length > 0 && (
          possibleTags && Object.keys(documentSection)
            .filter((section) => documentSection[section]
              .some((tag) => possibleTags[tag]))
            .map((section) => (
              <div key={section}>
                <DocumentSectionDetails
                  label={Messages.t(`document.section.${section as DocumentSection}`)}
                  documentsByTags={documentDataMap[currentGroupId]}
                  section={section}
                  possibleTags={possibleTags}
                  documentGroupId={currentGroupId}
                  candidatureId={candidature.id}
                />
              </div>
            ))
        )
      }
    </div>
  );
}
