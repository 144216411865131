import React from 'react';
import { Activity } from 'types/Activity';
import Image from 'theme/Image';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import DateUtils from 'services/DateUtils';
import { Link } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { CANDIDATE_DETAIL, ID } from 'routes/Routes';

type Props = {
  activity: Activity
};

export default function ActivityTile({ activity }: Props) {
  return (
    <Link
      to={Routes.withPath(CANDIDATE_DETAIL, [{ label: ID, value: activity.candidatureID }])}
      className="activity-tile"
    >
      <div className="activity-image">
        <Image src={activity.imageDenorm} alt="activity" />
      </div>
      <div className="activity-content">
        <div className="client-name">
          {activity.clientNameDenorm}
        </div>
        <div className="activty-subtitle">
          <div>#{activity.identifierDenorm}</div>
          <div className="activity-address"><LocationMarkerIcon />{activity.addressDenorm}</div>
        </div>
      </div>
      <div className="activity-workflowStep-container">
        <div className="activity-workflowStep-wrap">
          <div className="activity-workflowStep">
            <span>
              {activity.workflowStep}
            </span>
          </div>
        </div>
      </div>
      <div className="activity-date">
        {DateUtils.getPrettyDateDeltaFromNow(activity.date, false, true)}
      </div>
    </Link>
  );
}
