import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { gliTypeEnum } from 'types/ProfesionalSituation';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { GliContract, GliContracts } from 'types/ContractTypes';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { NotificationService } from 'lib/notification';
import { Agency } from 'types/Agency';

type Props = {
  gliContracts?: GliContract[];
  agency: Agency;
};

export default function OccupantQualificationForm({ gliContracts, agency }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const agencyQueries = useAgencyBackend();
  const { createGliContracts } = agencyQueries;

  const getDefaultValues = (contracts) => (
    {
      contractTypes: Object.values(gliTypeEnum)
        ?.map((key) => {
          const contractDb = contracts?.find((contract) => contract?.name === key);
          return ({
            name: key,
            activated: contractDb?.activated,
            id: contractDb?.id,
          });
        }),
    }
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GliContracts>({
    defaultValues: getDefaultValues(gliContracts),
  });

  const onSubmit = (data: GliContracts) => {
    setSubmitting(true);

    createGliContracts.mutateAsync({
      agencyId: agency.id,
      gliContracts: data.contractTypes,
    })
      .then((res) => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        reset(getDefaultValues(res));
      })
      .catch(() => {
        NotificationService.notifyError(Messages.t('notifications.error'));
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      {agency.activateGli && (
        <>
          <p>
            <b>{Messages.t('parameters.occupantQualif.GLI.selectSituations')}</b>
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              Object.values(gliTypeEnum).map((key, index) => (
                <Controller
                  key={key}
                  control={control}
                  name={`contractTypes.${index}.activated`}
                  render={(controller) => (
                    <div className="situation">
                      <CheckboxWrapper
                        control={controller}
                        label={Messages.t(`gli.${key}`)}
                        error={errors}
                      />
                    </div>
                  )}
                />
              ))
            }
            <SpinButton
              editing
              spin={submitting}
              title={Messages.t('formButton.confirm')}
            />
          </form>
        </>
      )}
    </>

  );
}
