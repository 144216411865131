import { ADD_PROPERTY } from 'routes/QueryParams';

export const DASHBOARD = '/';
export const APPARTMENT = '/appartments';
export const APPARTMENT_MAP = `${APPARTMENT}/map`;

export const APPARTMENT_ADD_PROPERTY = `${APPARTMENT}?${ADD_PROPERTY}=true`;

export const ID = ':id';

export const ADD = 'add';

export const PROPERTY = `${APPARTMENT}/properties`;
export const PROPERTY_DETAIL = `${PROPERTY}/${ID}`;
export const PROPERTY_ADD = `${PROPERTY}/${ADD}`;

export const CANDIDATE = '/candidates';
export const CANDIDATE_TAB = `${CANDIDATE}/tab`;

export const CANDIDATE_DETAIL = `${CANDIDATE}/contacts/${ID}`;
export const CANDIDATE_DETAIL_DETAILS = `${CANDIDATE_DETAIL}/details`;
export const DETAILS = '/details';
export const CANDIDATE_DETAIL_DOCUMENTS = `${CANDIDATE_DETAIL}/documents`;

export const CANDIDATE_ADD_DETAILS = `${CANDIDATE}/contacts/${ADD}/details`;
export const PARAMETERES = '/parameters';
export const ON_BOARDING = '/onboarding';
export const ADMIN = '/admin';

export const PARAMETERES_AGENCY = `${PARAMETERES}/agency`;
export const PARAMETERES_PLAN = `${PARAMETERES}/plan`;
export const PARAMETERES_INTEGRATION = `${PARAMETERES}/integrations`;
export const PARAMETERES_MANAGE = `${PARAMETERES}/manage`;
export const PARAMETERES_REDIRECTION = `${PARAMETERES}/voice-mail`;
export const PARAMETERES_QUALIFICATION_OCCUPANTS = `${PARAMETERES}/qualification-occupants`;

export const LOG_IN = '/login';

export const AGENCY_CREATION = '/create-agency';

export const AGENDA_BASE = '/agenda';
export const AGENDA = `${AGENDA_BASE}/visits`;

export const AGENDA_WITH_ID = `${AGENDA}/${ID}`;
export const AGENDA_WITH_OPTIONAL_ID = `${AGENDA_WITH_ID}?`;

export const AGENDA_VISIT_SLOTS = `${AGENDA_BASE}/visit-slots`;
export const AGENDA_VISIT_SLOTS_WITH_ID = `${AGENDA_VISIT_SLOTS}/${ID}`;
export const AGENDA_VISIT_SLOTS_WITH_OPTIONAL_ID = `${AGENDA_VISIT_SLOTS_WITH_ID}?`;
