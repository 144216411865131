import React from 'react';
import localizer from 'react-big-calendar/lib/localizers/moment';
import moment from 'moment';
import { Calendar, Views } from 'react-big-calendar';
import { format, isSameDay } from 'date-fns';
import StringUtils from 'services/StringUtils';
import localService from 'services/i18n/LocalService';
import { Property } from 'types/Property';
import CalendarToolbar from 'pages/common/calendar/CalendarToolbar';
import { Agent } from 'types/Agent';
import { useVisitBackend } from 'network/queries/VisitQueries';
import ReactBigCalendareUtils from 'services/ReactBigCalendareUtils';
import VisitAgendaEvent from 'pages/agency-app/agenda/visit/VisitAgendaEvent';
import { CandidatureShort } from 'types/Candidature';
import { Components, RbcEvent } from 'types/ReactBigCalendar';
import { Visit } from 'types/Visit';
import eventFilterService from 'services/filters/EventFilterService';
import { useObservable } from 'micro-observables';
import VisitDialog from 'pages/common/visits/VisitDialog';
import Messages from 'services/i18n/Messages';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { AGENDA, AGENDA_WITH_ID, ID } from 'routes/Routes';

type Props = {
  properties?: Property[],
  agents?: Agent[],
  candidatures?: CandidatureShort[]
};

type Param = {
  id: string,
};

const AGENDA_DAY_LENGTH = 7;
export default function Visits({ properties, agents, candidatures }: Props) {
  const { id: selectedVisitId } = useParams<Param>();
  const history = useHistory();
  const globalizeLocalizer = localizer(moment);
  const visitQueries = useVisitBackend();
  const { getVisits } = visitQueries;
  const { data: visitsData } = getVisits();
  const visits: Visit[] = [];
  useObservable(eventFilterService.getFilterObservable());
  if (visitsData) {
    visits.push(...eventFilterService.applyFiltersOnVisit(visitsData));
  }
  return (
    <>
      {
        agents && properties && candidatures && selectedVisitId && (
          <VisitDialog
            onClose={() => history.replace(AGENDA)}
            visitId={selectedVisitId}
            properties={properties}
            agents={agents}
            candidatures={candidatures}
            open
          />
        )
      }
      <div className="agenda-page">
        {
          visits && candidatures && (
            <Calendar
              length={AGENDA_DAY_LENGTH}
              // @ts-ignore
              components={{
                toolbar: (e) => <CalendarToolbar {...e} />,
                agenda: {
                  time: (timeProps) => (
                    <VisitAgendaEvent
                      onClick={() => history.replace(Routes.withPath(AGENDA_WITH_ID, [{
                        label: ID,
                        value: timeProps.event.resource?.id || '',
                      }]))}
                      candidature={
                        candidatures
                          .filter((candidature) => candidature.id
                            === timeProps.event.resource?.candidatureID)[0]
                      }
                      time={timeProps}
                      event={timeProps.event}
                    />
                  ),
                  event: () => null,
                  date: (e) => (
                    <div>
                      {
                        isSameDay(new Date(), e.day)
                          ? Messages.t('dates.today')
                          : StringUtils.capitalizeAllLetter(format(e.day, 'EEEE dd MMMM', { locale: localService.getDateLocal() }))
                      }
                    </div>
                  ),
                },
              } as Components<RbcEvent<Visit>, {}>}
              events={
                visits.filter((event) => (event.startDate)
                  && (event.endDate))
                  .map((event) => (
                    {
                      resource: event,
                      title: event.propertyID,
                      allDay: false,
                      start: new Date(event.startDate),
                      end: new Date(event.endDate),
                    }
                  ))
              }
              toolbar
              popup
              formats={ReactBigCalendareUtils.getFormats()}
              messages={
                {
                  noEventsInRange: Messages.t('calendar.visit.noEvent'),
                }
              }
              culture="fr"
              selectable
              defaultView={Views.AGENDA}
              views={[Views.AGENDA]}
              step={60}
              showMultiDayTimes
              localizer={globalizeLocalizer}
            />
          )
        }
      </div>
    </>
  );
}
