import React from 'react';
import { CandidatureShort } from 'types/Candidature';
import Messages from 'services/i18n/Messages';
import { AgencyStep } from 'types/AgencyStep';
import CandidateTabItem from 'pages/agency-app/candidates/candidates/candidateTab/CandidateTabItem';

type Props = {
  steps: { [key: string]: AgencyStep }
  candidates: CandidatureShort[]
};

export default function CandidateTab({ candidates, steps }: Props) {
  return (
    <div className="candidate-tab-container">
      <div className="candidate-tab">
        <div className="tab-row row-header">
          <div>
            {Messages.t('field.fullname')}
          </div>
          <div>
            {Messages.t('field.status')}
          </div>
          <div>
            {Messages.t('field.notation')}
          </div>
          <div>
            {Messages.t('field.caseIcome')}
          </div>
          <div>
            {Messages.t('field.garants')}
          </div>
          <div>
            {Messages.t('field.leaseType')}
          </div>
          <div>
            {Messages.t('field.lastActivty')}
          </div>
        </div>
        <div className="candidate-row-container">
          {
            candidates
              .sort((a, b) => (b.createdAt || '').localeCompare(a.createdAt || ''))
              .map((candidate) => (
                <CandidateTabItem key={candidate.id} steps={steps} candidate={candidate} />
              ))
          }
        </div>
      </div>
    </div>
  );
}
