import Messages from 'services/i18n/Messages';
import {
  CancelRounded,
  CheckCircle,
  ThumbDown, ThumbUp,
} from '@material-ui/icons';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { CandidatureShort } from 'types/Candidature';
import { PropertyZone, propertyZoneEnum } from 'types/Property';

type Props = {
  eligibilitePinel: boolean,
  candidate: CandidatureShort,
  zone: PropertyZone,
};

export default function PinelTooltip(
  {
    eligibilitePinel,
    candidate,
    zone,
  }: Props,
) {
  const nMinus2 = candidate.createdAt && new Date(candidate.createdAt).getFullYear() - 2;
  const pinelMaxRevenues = candidate?.pinelMaxRevenues || 0;
  return (
    <Tooltip
      classes={{ tooltip: 'gli-pinel-tooltip pinel' }}
      title={(
        (candidate.fiscalIncomesN2
          && candidate.foyerCompositionN2
          && pinelMaxRevenues > 0
        ) ? (
          <div>
            <div>
              {eligibilitePinel ? (
                <p className="result ok"><CheckCircle />{Messages.t('pinel.tooltip.pinelSuccess')} !</p>
              ) : (
                <p className="result not-ok"><CancelRounded />
                  {Messages.t('pinel.tooltip.pinelFail')}.
                </p>
              )}
            </div>
            <div className="main-info">
              <div className="table">
                <div className="header content">
                  <div>{Messages.t('pinel.zoneLong')}</div>
                  <div>{Messages.t('pinel.composition')} ({nMinus2})</div>
                  <div>{Messages.t('pinel.maxRevenues')} ({nMinus2})</div>
                  <div>{Messages.t('pinel.foyerRevenues')} ({nMinus2})</div>
                </div>
                <div className="content">
                  {zone && (
                    <div>
                      {`${zone !== propertyZoneEnum.zoneRestA ? `${Messages.t('pinel.zone')} ` : ''}`}{Messages.t(`pinel.${zone}`)}
                    </div>
                  )}
                  <div>{Messages.t(`pinel.${candidate.foyerCompositionN2}`)}</div>
                  <div>{pinelMaxRevenues} €</div>
                  <div>
                    {candidate.fiscalIncomesN2} € <br />({eligibilitePinel ? ' <= ' : ' > '} {pinelMaxRevenues} €)
                  </div>
                </div>
              </div>
            </div>
          </div>
          ) : (
            <p>{Messages.t('pinel.missingData')}</p>
          )
      )}
    >
      <div className={`contact-tag gli-pinel ${eligibilitePinel ? 'ok' : 'not-ok'}`}>
        {Messages.t('property.pinel')}
        {eligibilitePinel ? <ThumbUp /> : <ThumbDown />}
      </div>
    </Tooltip>
  );
}
