import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { NotificationService } from 'lib/notification';
import { Property } from 'types/Property';
import { Agent } from 'types/Agent';
import PropertiesSelector from 'pages/common/PropertySelector';
import SelectWrapper from 'lib/form/SelectWrapper';
import AgentUtils from 'services/AgentUtils';
import AddressAutocompleteWrapper from 'lib/form/AddressAutocompleteWrapper';
import DateTimeRangePickerWrapper from 'lib/form/DateTimeRangePickerWrapper';
import { addHours, format, isSameDay } from 'date-fns';
import { useVisitBackend } from 'network/queries/VisitQueries';
import { Visit, VisitFormType } from 'types/Visit';
import CandidateSelector from 'pages/common/CandidateSelector';
import { CandidatureShort } from 'types/Candidature';
import { DialogTitle, IconButton, Popover } from '@material-ui/core';
import CandidateAssignPropertyForm from 'pages/common/forms/CandidateAssignPropertyForm';
import Button from 'theme/Button';
import { Link, useHistory } from 'react-router-dom';
import {
  CANDIDATE_ADD_DETAILS,
  CANDIDATE_DETAIL,
  ID,
  PROPERTY_DETAIL,
} from 'routes/Routes';
import { Routes } from 'routes/RoutesUtils';
import { CREATE_VISIT } from 'routes/QueryParams';
import localService from 'services/i18n/LocalService';
import StringUtils from 'services/StringUtils';
import NameWithInitials from 'pages/common/NameWithInitials';
import CandidatureUtils from 'services/CandidatureUtils';
import { Delete, OpenInNew } from '@material-ui/icons';
import confirmationService from 'services/ConfirmationService';
import AgentCalendar from 'pages/common/calendar/AgentCalendar';

type Props = {
  properties: Property[],
  agents: Agent[],
  visit?: Visit,
  candidatures: CandidatureShort[],
  defaultCandidature?: CandidatureShort,
  allowChangeDefaultCandidate?: boolean,
};

export default function VisitForm(
  {
    visit,
    properties,
    agents,
    candidatures,
    allowChangeDefaultCandidate,
    defaultCandidature,
  }: Props,
) {
  const history = useHistory();
  const [apiErrors, setApiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [usePropertyAddress, setUsePropertyAddress] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const visitQueries = useVisitBackend();
  const { createVisit, updateVisit } = visitQueries;
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<VisitFormType>({
    defaultValues: {
      propertyID: properties.length === 1 ? properties[0].id : undefined,
      ...visit,
      candidature: visit
        ? candidatures.filter((candidature) => candidature.id === visit.candidatureID)[0]
        : defaultCandidature,
    },
  });

  useEffect(() => {
    if (defaultCandidature) {
      updatePropertyId(defaultCandidature.propertyID);
    }
  }, []);

  const formField = watch();
  const onSubmit: SubmitHandler<VisitFormType> = (formData: VisitFormType) => {
    if (visit) {
      return;
    }
    setSubmitting(true);
    createVisit.mutateAsync({
      ...formData,
      propertyID: formData.propertyID || '',
      candidatureID: formData.candidature?.id || '',
    })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.visitSlotCreate'));
      })
      .catch((error) => {
        if (!error.json_response) {
          NotificationService.notifyError(Messages.t('notifications.error'));
          return;
        }
        if (error.json_response.errorMessage) {
          // @ts-ignore
          NotificationService.notifyError(Messages.t(`field.error.${error.json_response.errorMessage[0]}`));
        }
        setApiErrors(error.json_response);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const deleteVisit = async () => {
    if (visit) {
      const isConfirmed = await confirmationService.confirm({
        title: Messages.t('confirmation.cancelVisit.title'),
        actionColor: 'error',
        message: Messages.t('confirmation.cancelVisit.message'),
      });
      if (isConfirmed) {
        setSubmitting(true);
        updateVisit.mutateAsync({
          visitId: visit.id,
          data: { isCancel: true },
          visitSlotId: visit.visitSlotID,
        }).then(async () => {
          NotificationService.notifySuccess(Messages.t('visit.canceled'));
        }).catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
          .finally(() => setSubmitting(false));
      }
    }
  };

  const updatePropertyId = (propertyId?: string) => {
    setValue('propertyID', propertyId);
    if (formField.candidature && formField.candidature.propertyID !== propertyId) {
      setValue('candidature', undefined);
    }
    if ((!formField.address || !formField.agentID) && propertyId) {
      const selectedProperty = properties
        .filter((property) => property.id === propertyId)[0];
      if (usePropertyAddress) {
        setValue('address', selectedProperty.address || '');
      }
      if (!formField.agentID) {
        setValue('agentID', selectedProperty.agentID || '');
      }
    }
  };

  const mainContact = formField.candidature
    && CandidatureUtils.getSortedContact(formField.candidature)[0];
  const propertySelected = properties
    .filter((property) => property.id === formField.propertyID)[0];
  return (
    <>
      <div>
        <DialogTitle className="visit-title">
          <div>{Messages.t('visit.form.title')}</div>
          <div>
            {
              !visit?.isCancel && (
                <IconButton onClick={deleteVisit} disabled={submitting} className="filled-icon-button">
                  <Delete />
                </IconButton>
              )
            }
          </div>
        </DialogTitle>
        <form className="visit-slot-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="visit-dates">
            <div>
              {
                visit?.isCancel ? (
                  Messages.t('visit.canceled')
                ) : (
                  formField.startDate && formField.endDate
                  && Messages.t('visit.date.fromTo', {
                    startDate: StringUtils.capitalizeFirstLetter(format(new Date(formField.startDate), Messages.t('visit.dateFormat'), { locale: localService.getDateLocal() })),
                    endDate: isSameDay(new Date(formField.startDate), new Date(formField.endDate))
                      ? format(new Date(formField.endDate), 'HH:mm', { locale: localService.getDateLocal() })
                      : StringUtils.capitalizeFirstLetter(format(new Date(formField.endDate), Messages.t('visit.dateFormat'), { locale: localService.getDateLocal() })),
                  }))

              }
            </div>
          </div>
          <div className="property-select">
            {formField.propertyID && (
              <Link
                to={Routes.withPath(
                  PROPERTY_DETAIL,
                  [{ label: ID, value: formField.propertyID }],
                )}
                target="_blank"
              >
                <div>
                  <p>{Messages.t('property.seeListing')}</p>
                  <OpenInNew />
                </div>
              </Link>
            )}
            <Controller
              name="propertyID"
              control={control}
              rules={{
                required: true,
              }}
              render={(controller) => (
                <PropertiesSelector
                  showOffline
                  disabled={properties.length === 1}
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  onChange={updatePropertyId}
                  label={Messages.t('field.property')}
                  properties={properties}
                />
              )}
            />
          </div>
          <div className="form-row">
            <Controller
              name="startDate"
              rules={{
                required: true,
                validate: (date) => {
                  if (new Date(date) < new Date()) {
                    return Messages.t('field.error.mustBeFuture');
                  }
                  return undefined;
                },
              }}
              control={control}
              render={(controller) => (
                <DateTimeRangePickerWrapper
                  disabled={!!visit}
                  apiErrors={apiErrors}
                  error={errors}
                  defaultDateMonth={formField.endDate ? new Date(formField.endDate) : undefined}
                  disablePast
                  onChange={(newDate) => {
                    if (!formField.endDate) {
                      const endDate = addHours(newDate, 1);
                      setValue('endDate', endDate.toISOString());
                    }
                  }}
                  control={controller}
                  label={Messages.t('field.startDate')}
                />
              )}
            />
            <Controller
              name="endDate"
              rules={{
                required: true,
                validate: (date) => {
                  if (new Date(date) < new Date()) {
                    return Messages.t('field.error.mustBeFuture');
                  }
                  if (new Date(date) < new Date(formField.startDate)) {
                    return Messages.t('field.error.mustBeAfterStartDate');
                  }
                  return undefined;
                },
              }}
              control={control}
              render={(controller) => (
                <DateTimeRangePickerWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  disabled={!!visit}
                  disablePast
                  defaultDateMonth={formField.startDate ? new Date(formField.startDate) : undefined}
                  disableBefore={formField.startDate ? new Date(formField.startDate) : undefined}
                  control={controller}
                  label={Messages.t('field.endDate')}
                />
              )}
            />
          </div>
          <Controller
            name="agentID"
            rules={{
              required: true,
            }}
            control={control}
            render={(controller) => (
              <SelectWrapper
                error={errors}
                disabled={!!visit}
                apiErrors={apiErrors}
                control={controller}
                label={Messages.t('field.agentSelected')}
                values={
                  agents.map((agent) => ({
                    key: agent.id,
                    label: <NameWithInitials
                      imageUrl={agent.imageURL}
                      color="secondary"
                      name={AgentUtils.getPrettyName(agent)}
                    />,
                  }))
                }
              />
            )}
          />
          <Controller
            name="address"
            rules={{
              required: true,
            }}
            control={control}
            render={(controller) => (
              <AddressAutocompleteWrapper
                error={errors}
                disabled={!!visit}
                hideManualMode
                contextualActionChecked={usePropertyAddress}
                contextualActionLabel={propertySelected?.address && Messages.t('field.propertyAddress')}
                contextualAction={(checked) => {
                  setUsePropertyAddress(checked);
                  if (checked && formField.propertyID) {
                    const selectedProperty = properties
                      .filter((property) => property.id === formField.propertyID)[0];
                    setValue('address', selectedProperty.address || '');
                  }
                }}
                onChange={() => {
                  setUsePropertyAddress(false);
                }}
                apiErrors={apiErrors}
                control={controller}
                label={Messages.t('field.visitsAddress')}
              />
            )}
          />
          <Controller
            name="candidature"
            control={control}
            rules={{
              required: true,
            }}
            render={(controller) => (
              <CandidateSelector
                error={errors}
                disabled={(!!defaultCandidature && !allowChangeDefaultCandidate) || !!visit}
                onChange={(candidature) => updatePropertyId(candidature?.propertyID)}
                apiErrors={apiErrors}
                candidatures={
                  formField.propertyID
                    ? candidatures
                      .filter((candidature) => candidature.propertyID === formField.propertyID)
                    : candidatures
                }
                control={controller}
                label={Messages.t('field.candidate')}
              />
            )}
          />
          {
            !formField.candidature && !visit && (
              <div className="create-candidate-button-container">
                <Button
                  color="secondary"
                  onClick={() => history.push(Routes.updateUrlWithQuery(CANDIDATE_ADD_DETAILS, [{
                    label: CREATE_VISIT,
                    value: 'true',
                  }]))}
                >
                  {Messages.t('visit.createCandidate')}
                </Button>
              </div>
            )
          }
          {
            formField.candidature && !formField.propertyID && (
              <div>
                {Messages.t('visit.youMust')}
                <button
                  className="button-as-link"
                  onClick={handleClick}
                  type="button"
                >
                  {Messages.t('visit.assignProperty')}
                </button>
              </div>
            )
          }
          {
            mainContact && formField.candidature && (
              <div className="candidate-information">
                <h4>{Messages.t('visit.candidateInformation')}</h4>
                <div className="information-row">
                  <div className="information-data">
                    <div className="information-header">
                      {Messages.t('field.lastName')}
                    </div>
                    <div>
                      {mainContact.lastname}
                    </div>
                  </div>
                  <div className="information-data">
                    <div className="information-header">
                      {Messages.t('field.firstName')}
                    </div>
                    <div>
                      {mainContact.firstname}
                    </div>
                  </div>
                </div>
                <div className="information-row">
                  <div className="information-data">
                    <div className="information-header">
                      {Messages.t('field.phoneNumber')}
                    </div>
                    <div>
                      {mainContact.phoneNumber}
                    </div>
                  </div>
                  <div className="information-data">
                    <div className="information-header">
                      {Messages.t('field.mail')}
                    </div>
                    <div>
                      {mainContact.email}
                    </div>
                  </div>
                </div>
                <Link
                  className="link-as-button secondary"
                  target="_blank"
                  to={
                    Routes.withPath(
                      CANDIDATE_DETAIL,
                      [{ label: ID, value: formField.candidature.id }],
                    )
                  }
                >
                  {Messages.t('visit.showProfile')}
                </Link>
              </div>
            )
          }
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {
              formField.candidature && (
                <CandidateAssignPropertyForm
                  onSubmitted={(propertyId) => {
                    updatePropertyId(propertyId);
                    handleClose();
                  }}
                  candidatureId={formField.candidature.id}
                  properties={properties}
                />
              )
            }
          </Popover>
          <Controller
            name="notes"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                error={errors}
                disabled={!!visit}
                apiErrors={apiErrors}
                type="textarea"
                control={controller}
                label={Messages.t('field.noteToGuests')}
              />
            )}
          />
          {
            !visit && (
              <SpinButton
                editing
                spin={submitting}
                title={Messages.t('formButton.confirm')}
              />
            )
          }
        </form>
      </div>
      <AgentCalendar agentId={formField.agentID} />
    </>
  );
}
