import React from 'react';
import Tile from 'pages/agency-app/dashboard/Tile';
import ActivityTile from 'pages/common/ActivityTile';
import Chart from 'pages/agency-app/dashboard/Chart';
import { useActivitiesBackend } from 'network/queries/ActivitiesQueries';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { MANDATORY_STEPS } from 'types/AgencyStep';
import { format } from 'date-fns';
import Messages from 'services/i18n/Messages';
import localService from 'services/i18n/LocalService';

export default function Dashboard() {
  const activitiesQueries = useActivitiesBackend();
  const { getActivities } = activitiesQueries;
  const { data: activities } = getActivities();

  const propertyQueries = usePropertiesBackend();
  const { getProperties } = propertyQueries;
  const { data: properties } = getProperties();

  const candidatureQueries = useCandidatureBackend();
  const { getCandidatures } = candidatureQueries;
  const { data: candidatures } = getCandidatures();

  const ageencyQueries = useAgencyBackend();
  const { getAgencySteps } = ageencyQueries;
  const { data: agencyStep } = getAgencySteps();

  const stepByKey = agencyStep && Object.values(agencyStep).reduce((acc, value) => {
    acc[value.stepKey] = value;
    return acc;
  }, {});

  const currentMonth = new Date().getMonth();
  const displayedMonth = [5, 4, 3, 2, 1, 0].map((key) => {
    let month = currentMonth - key;
    if (month < 0) {
      month = 12 + month;
    }
    return month;
  });

  const candidateCountByMonth = candidatures && candidatures
    .reduce((acc, value) => {
      if (value.createdAt) {
        const date = new Date(value.createdAt);
        if (date && displayedMonth.includes(date.getMonth())) {
          if (!acc[date.getMonth()]) {
            acc[date.getMonth()] = 0;
          }
          acc[date.getMonth()] = acc[date.getMonth()] + 1;
        }
      }
      return acc;
    }, {});

  return (

    <div className="page-body">
      <div className="tiles-container">
        {
          properties && (
            <Tile
              value={properties.filter((property) => property.online).length}
              label={Messages.t('dashboard.onlineAnounce')}
            />
          )
        }
        {
          stepByKey && (
            <Tile
              value={
                candidatures?.filter((candidature) => !MANDATORY_STEPS
                  .map((key) => stepByKey[key].id)
                  .includes(candidature.workflowStep)).length || 0
              }
              label={Messages.t('dashboard.viewingRequest')}
            />
          )
        }
        {
          agencyStep && Object.values(agencyStep)
            .sort((a, b) => (a.order || 0) - (b.order || 0))
            .slice(-3)
            .map((step) => (
              <Tile
                key={step.id}
                value={
                  candidatures?.filter((
                    candidature,
                  ) => candidature.workflowStep === step.id).length
                  || 0
                }
                label={step.stepName}
              />
            ))
        }
      </div>
      <div className="dashboard-content">
        <div className="activity-container">
          <h5>{Messages.t('dashboard.activities')}</h5>
          <div className="activity-content">
            {
              activities && activities.map((activity) => (
                <ActivityTile key={activity.id} activity={activity} />
              ))
            }
          </div>
        </div>
        <div className="statistiques-container">
          <h5>{Messages.t('dashboard.statistics')}</h5>
          <Chart
            name="Candidates"
            datas={displayedMonth.map((month) => (candidateCountByMonth?.[month] || 0))}
            labels={displayedMonth.map((month) => format(new Date(2016, month, 1), 'LLL', { locale: localService.getDateLocal() }))}
          />
        </div>
      </div>
    </div>
  );
}
