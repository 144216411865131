import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { AgentForm } from 'types/forms/AgentForm';
import { Agent } from 'types/Agent';
import PhoneNumberInputWrapper from 'lib/form/PhoneNumberInputWrapper';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { NotificationService } from 'lib/notification';
import { mailRegex } from 'lib/form/FormUtils';
import sessionManager from 'services/sessionManager';

type Props = {
  agent: Agent,
};

export default function ProfileParameteresForm({ agent }: Props) {
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const agentQueries = useAgentsBackend();
  const { updateAgent } = agentQueries;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AgentForm>({
    defaultValues: {
      firstname: agent.firstname,
      lastname: agent.lastname,
      email: agent.email,
      phoneNumber: agent.phoneNumber,
    },
  });

  const onSubmit: SubmitHandler<AgentForm> = (formData: AgentForm) => {
    setSubmitting(true);
    updateAgent.mutateAsync({ agentId: agent.id, agent: formData })
      .then((tokens) => {
        sessionManager.setCurrentToken(tokens.token);
        sessionManager.setRefreshToken(tokens.refreshToken);
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <Controller
            name="firstname"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                requierd
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.firstName')}
              />
            )}
          />
          <Controller
            name="lastname"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                requierd
                type="text"
                control={controller}
                label={Messages.t('field.lastName')}
              />
            )}
          />
        </div>
        <div className="form-row">
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: mailRegex,
                message: Messages.t('form.error.email'),
              },
            }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                requierd
                type="text"
                control={controller}
                label={Messages.t('field.email')}
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={(controller) => (
              <PhoneNumberInputWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('field.phoneNumber')}
              />
            )}
          />
        </div>
        <SpinButton
          editing
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
