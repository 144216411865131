import React from 'react';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import AgencyParameteresForm from 'pages/agency-app/parameters/agencyParameteres/AgencyParameteresForm';
import AgentList from 'pages/agency-app/parameters/agencyParameteres/AgentList';
import Messages from 'services/i18n/Messages';
import StepParametersContainer from 'pages/agency-app/parameters/agencyParameteres/StepParametersContainer';
import sessionManager from 'services/sessionManager';
import { permissionsEnum } from 'types/Permission';
import { TextField } from '@material-ui/core';

export default function AgencyParameteres() {
  const agencyQueries = useAgencyBackend();
  const { getCurrentAgency } = agencyQueries;
  const { data: agency, isLoading } = getCurrentAgency();

  const isAdmin = sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENT_ADMIN);

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div className="parmameters-page">
      <div className="parameters-form">
        <h3>{Messages.t('parameters.agencyData')}</h3>
        <AgencyParameteresForm agency={agency} />
        <h3>{Messages.t('parameters.listingData')}</h3>
        <div className="input-row">
          <div className="material-textfield-wrapper">
            <TextField
              variant="filled"
              disabled
              fullWidth
              size="medium"
              label={Messages.t('field.adPhoneNumber')}
              value={agency?.adPhoneNumber || ''}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
          <div className="material-textfield-wrapper">
            <TextField
              variant="filled"
              disabled
              fullWidth
              size="medium"
              type="text"
              label={Messages.t('field.adEmail')}
              value={agency?.adEmail || ''}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </div>
        </div>
        <div className="material-textfield-wrapper">
          <TextField
            variant="filled"
            disabled
            fullWidth
            size="medium"
            label={Messages.t('field.clientFormURL')}
            value={agency?.clientFormURL || ''}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </div>
        {
          agency && isAdmin && (
            <>
              <h3>{Messages.t('parameters.steps')}</h3>
              <StepParametersContainer agency={agency} />
            </>
          )
        }
      </div>
      <div className="parameters-list">
        <h3>{Messages.t('parameters.agents')}</h3>
        <AgentList />
      </div>
    </div>
  );
}
