import React, { useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import {
  Autocomplete, FormControl, FormHelperText, InputBase, InputLabel,
} from '@material-ui/core';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { DatePicker } from '@material-ui/lab';
import Messages from 'services/i18n/Messages';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  label?: string
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  disableBefore?: Date
  disableAfter?: Date
  disablePast?: boolean,
  defaultDateMonth?: Date,
  onChange?: (newDate: Date) => void
  disabled?:boolean,
};

export default function DateTimeRangePickerWrapper(
  {
    control,
    label,
    error,
    apiErrors,
    disablePast,
    disableAfter,
    onChange,
    disableBefore,
    defaultDateMonth,
    disabled,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [open, setOpen] = useState(false);
  const value = field.value || null; // force null instead of undefined
  const dateValue = new Date(value);
  const changeTime = (newTime: string) => {
    setInputValue(undefined);
    const hours = parseInt(newTime?.split(':')[0] || '0', 10);
    const minutes = parseInt(newTime?.split(':')[1] || '0', 10);
    if (hours < 24) {
      dateValue.setHours(hours);
    }
    if (minutes < 60) {
      dateValue.setMinutes(minutes);
    }
    dateValue.setSeconds(0);
    dateValue.setMilliseconds(0);
    field.onChange(dateValue);
    if (onChange) {
      onChange(dateValue);
    }
  };

  const timeValue = `${dateValue.getHours().toString().padStart(2, '0')}:${dateValue.getMinutes().toString().padStart(2, '0')}`;

  const getAllHours = (firstHour: number, lastHour: number, slotByHour: number): (string[]) => {
    const tab: string[] = [];
    const slotDuration = 60 / slotByHour;
    for (let i = firstHour; i <= lastHour; i++) {
      const iString = i.toString().padStart(2, '0');
      for (let j = 0; j < slotByHour; j++) {
        tab.push(`${iString}:${(slotDuration * j).toString().padStart(2, '0')}`);
      }
    }
    return tab;
  };

  const getClosestNumberNMultiple = (n: number, multiple: number): number => {
    const mod = n % multiple;
    if (mod === 0) {
      return n;
    }
    const inf = n - mod;
    const sup = inf + multiple;
    return (n - inf) > (sup - n) ? sup : inf;
  };

  return (
    <div className="material-textfield-wrapper material-date-range-picker-wrapper">
      <DatePicker
        {...field}
        onChange={(e) => {
          const d = new Date(e);
          d.setMinutes(getClosestNumberNMultiple(d.getMinutes(), 5));
          d.setMilliseconds(0);
          d.setSeconds(0);
          dateValue.setMonth(d.getMonth());
          dateValue.setFullYear(d.getUTCFullYear());
          dateValue.setDate(d.getDate());
          field.onChange(d);
          if (onChange) {
            onChange(d);
          }
        }}
        value={value}
        defaultCalendarMonth={defaultDateMonth}
        disablePast={disablePast}
        clearable
        disabled={disabled}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        maxDate={disableAfter}
        minDate={disableBefore}
        label={label || undefined}
        renderInput={(params) => {
          delete params.InputProps;
          return (
            <FormControl variant="filled" focused error={!!errorMessage}>
              <InputLabel>{label}</InputLabel>
              <div className="material-date-range-picker mui-input MuiFilledInput-root">
                { /* @ts-ignore */}
                <InputBase
                  {...params}
                  error={!!errorMessage}
                  disabled={disabled}
                  fullWidth
                  onClick={() => setOpen(true)}
                />
                <span className="time-separtator">{Messages.t('formButton.at')}</span>
                <Autocomplete
                  disablePortal
                  freeSolo
                  disabled={!value || disabled}
                  isOptionEqualToValue={(option, val) => option.startsWith(val.split(':')[0])}
                  options={getAllHours(0, 23, 4)}
                  onChange={(_, val) => {
                    if (val) {
                      changeTime(val);
                    }
                  }}
                  sx={{ width: 300 }}
                  value={value ? timeValue : null}
                  inputValue={inputValue || (value ? timeValue : '')}
                  getOptionDisabled={(option) => {
                    const date = new Date(dateValue);
                    date.setHours(parseInt(option.split(':')[0], 10));
                    date.setMinutes(parseInt(option.split(':')[1], 10));
                    if (disableAfter && date > disableAfter) {
                      return true;
                    }
                    return !!(disableBefore && date < disableBefore);
                  }}
                  renderInput={(param) => (
                    <div className="autocomplete-input" ref={param.InputProps.ref}>
                      <input
                        placeholder="hh:mm"
                        type="text"
                        {...param.inputProps}
                        onBlur={(e) => {
                          changeTime(e.target.value);
                          if (param.inputProps.onBlur) {
                            param.inputProps.onBlur(e);
                          }
                        }}
                        onChange={(e) => {
                          setInputValue(e.target.value);
                        }}
                      />
                    </div>
                  )}
                />
              </div>
              {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            </FormControl>
          );
        }}
      />
    </div>
  );
}
