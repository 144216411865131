import React from 'react';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import Messages from 'services/i18n/Messages';
import ForwardCall from '../agencyParameteres/phoneRedirection/ForwardCall';

export default function CallForwarding() {
  const agencyQueries = useAgencyBackend();
  const { getCurrentAgency } = agencyQueries;
  const { data: agency, isLoading } = getCurrentAgency();

  if (isLoading) {
    return (
      <p>Loading...</p>
    );
  }
  return (
    <div className="parmameters-page">
      <div className="parameters-form">
        <div className="voice-mail-intro">{Messages.t('settings.redirectionIntro')}</div>
        <ForwardCall agency={agency} />
      </div>
    </div>
  );
}
