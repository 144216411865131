import React from 'react';
import { Property } from 'types/Property';
import Image from 'theme/Image';
import Messages from 'services/i18n/Messages';
import AgendaEventDateDisplay from 'pages/common/calendar/AgendaEventDateDisplay';
import { RbcEvent, RbcTime } from 'types/ReactBigCalendar';
import { VisitSlotWithVisitCount } from 'types/VisitSlot';
import VisitSlotUtils from 'services/VisitSlotUtils';
import PropertyUtils from 'services/PropertyUtils';

type Props = {
  property: Property,
  event: RbcEvent<VisitSlotWithVisitCount>
  time: RbcTime<RbcEvent<VisitSlotWithVisitCount>>,
  onClick: () => void
};

export default function VisitSlotAgendaEvent(
  {
    event,
    property,
    time,
    onClick,
  }: Props,
) {
  // Do not use a state in this component, it's not consistent
  if (!event.start) {
    return null;
  }
  if (!event.end) {
    return null;
  }

  const visitNumber = event.resource ? VisitSlotUtils.getPossibleVisitNumber(event.resource) : '0';

  return (
    <button type="button" onClick={onClick} className="agenda-visit-slot agenda-page-event">
      <AgendaEventDateDisplay
        event={event}
        time={time}
      />
      <div
        className="property-list-item agenda-property-item"
      >
        <div className="property-image">
          <Image
            src={PropertyUtils.getPropertyMainImage(property)}
            alt="property"
          />
        </div>
        <div className="property-info">
          <div>
            <div className="property-price">
              {property.price} &euro;<span className="price-month">{Messages.t('price.byMonth')}</span>
            </div>
            <div className="property-address">
              <span>{event.resource?.address}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={`booked-visit-container ${event.resource?.visitCount.toString() === visitNumber ? 'full' : ''}`}>
        {Messages.t('visitSlot.bookedVisit', { bookedVisit: event.resource?.visitCount, total: visitNumber })}
      </div>
    </button>
  );
}
