import React from 'react';
import {
  DocumentCreation,
  DocumentData, DocumentTag,
  documentTypeEnum,
} from 'types/DocumentData';
import { IconButton } from '@material-ui/core';
import { Upload } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import confirmationService from 'services/ConfirmationService';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import DocumentUtils from 'services/DocumentUtils';
import DocumentDataPreview from 'pages/common/DocumentDataPreview';

type Props = {
  documentsByTags?: { [key: string]: DocumentData[] },
  tag: DocumentTag
  onDropped: (documents: DocumentCreation[]) => void,
  documentGroupId: string,
  candidatureId: string,
  contactId?: string,
  garantId?: string,
};

export default function DocumentSectionViewer(
  {
    documentsByTags,
    tag,
    onDropped,
    documentGroupId,
    candidatureId,
  }: Props,
) {
  const candidatureQueries = useCandidatureBackend();
  const { deleteCandidatureDocument } = candidatureQueries;

  const deleteDoc = async (documentId: string) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('confirmation.documentDelete.title'),
      message: Messages.t('confirmation.documentDelete.message'),
      actionColor: 'error',
    });
    if (!isConfirmed) {
      return;
    }
    deleteCandidatureDocument.mutateAsync({
      documentId,
      documentGroupId,
      candidatureId,
    }).then(() => NotificationService.notifySuccess(Messages.t('notifications.document.delete')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*,application/pdf',
    maxFiles: 0,
    onDrop: async (dropedFiles: File[]) => {
      const filesResult = await Promise.all(dropedFiles.map(async (file) => {
        const fileBase64 = await DocumentUtils.getBase64(file);
        return {
          filename: file.name,
          fileBase64,
          fileType: file.type,
        };
      }));
      onDropped(filesResult.map((file) => ({
        tags: [tag],
        file,
        type: documentTypeEnum.DOCUMENT,
      })));
    },
  });

  return (
    <>
      <div>
        <h5>{Messages.t(`document.tag.${tag}`)}</h5>
        {
          documentsByTags?.[tag]?.length === 1
          && documentsByTags?.[tag][0].type === documentTypeEnum.COMMENT && (
            <div className="subtitle">
              {Messages.t('document.missing')}
            </div>
          )
        }
        <div className="document-section-list">
          {
            documentsByTags?.[tag]?.sort((
              a,
              b,
            ) => ((((a.order !== null && a.order !== undefined) ? a.order : -1))
                - ((b.order !== null && b.order !== undefined) ? b.order : -1))
              || (a.createdAt || '').localeCompare(b.createdAt || ''))
              .map((doc) => (
                <DocumentDataPreview
                  document={doc}
                  onDelete={() => deleteDoc(doc.id)}
                  key={doc.id}
                />
              ))
          }
          <div className="file-upload-button-container">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <IconButton>
                <Upload />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
