import React, { ReactNode, useState } from 'react';
import { HighlightOff, Lightbulb } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

type Props = {
  children: ReactNode
};

export function SnackBar({ children }: Props) {
  const [hide, setHide] = useState(false);
  if (hide) {
    return null;
  }
  return (
    <div className="snack-bar-container">
      <div className="snack-bar">
        <Lightbulb />
        <div className="snack-bar-body">
          {children}
        </div>
        <IconButton color="inherit" onClick={() => setHide(true)}>
          <HighlightOff />
        </IconButton>
      </div>
    </div>
  );
}
