// TODO clean this
export const removeDuplicateFromArrayOfStrings = (array: string[]) => {
  const dictionnary = {};
  array.forEach((el) => {
    if (el) {
      dictionnary[el.toLowerCase()] = el.toLowerCase();
    }
  });
  return Object.keys(dictionnary);
};

export default class ArrayUtils {
  static removeDuplicates(array: string[]) {
    return Object.keys(array.reduce((acc, value) => {
      acc[value.toLowerCase()] = value.toLowerCase();
      return acc;
    }, {}));
  }
}
