import React, { useEffect, useState } from 'react';
import AuthentificationRoot from 'pages/authentification/AuthentificationRoot';
import { AuthProvider, useFirebaseApp } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { useObservable } from 'micro-observables';
import sessionManager from 'services/sessionManager';
import sessionService from 'services/sessionService';
import localService from 'services/i18n/LocalService';
import AgencyRoot from 'pages/agency-app/AgencyRoot';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  ADMIN,
  LOG_IN,
  ON_BOARDING,
  PARAMETERES_INTEGRATION,
} from 'routes/Routes';
import OnBoarding from 'pages/authentification/OnBoarding';
import confirmationService from 'services/ConfirmationService';
import ConfirmationModalBase from 'pages/common/ConfirmationModalBase';
import AgencySelectorPage from 'pages/agency-selector/AgencySelectorPage';
import { permissionsEnum } from 'types/Permission';
import UserAdmin from 'pages/admin/UserAdmin';
import {
  CODE, EXPIRED,
  REFRESH,
  STATE,
  TOKEN,
} from 'routes/QueryParams';
import { Routes } from 'routes/RoutesUtils';
import { isBefore } from 'date-fns';

function App() {
  const location = useLocation();
  const history = useHistory();
  const confirmationModal = useObservable(confirmationService.getModal());
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const authToken = useObservable(sessionManager.getCurrentTokenObservable());
  const [isLoading, setIsLoading] = useState(true);
  useObservable(localService.getLocal());
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get(TOKEN);
    const refresh = queryParams.get(REFRESH);
    const code = queryParams.get(CODE);
    const state = queryParams.get(STATE);

    if (token) {
      sessionManager.setCurrentToken(token);
    }
    if (refresh) {
      const session = sessionManager.getRefreshSession(refresh);
      if (session?.exp) {
        const exp = new Date(session?.exp * 1000);
        if (isBefore(exp, new Date())) {
          setIsLoading(false);
          history.replace(Routes.updateUrlWithQuery(
            LOG_IN,
            [{ label: EXPIRED, value: 'true' }],
          ));
          return;
        }
      }
      sessionManager.setRefreshToken(refresh);
    }

    if (token || refresh) {
      history.push(location.pathname);
    }
    if (code) {
      const params = [{ label: CODE, value: code }];
      if (state) {
        params.push({ label: STATE, value: state });
      }
      history.replace(Routes.updateUrlWithQuery(
        PARAMETERES_INTEGRATION,
        params,
      ));
    }
    const refreshToken = sessionManager.getRefreshToken();
    if (refreshToken) {
      sessionService.refreshSession(refreshToken).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  const auth = getAuth(app);
  if (isLoading) {
    return null;
  }

  const isSuperAdmin = sessionManager
    .getSession()?.permissions.includes(permissionsEnum.SUPER_ADMIN);
  return (
    <div className="app">
      {
        confirmationModal && (
          <ConfirmationModalBase
            title={confirmationModal.title}
            message={confirmationModal.message}
            actionMessage={confirmationModal.actionMessage}
            actionColor={confirmationModal.actionColor}
          />
        )
      }
      <AuthProvider sdk={auth}>
        {
          !authToken
            ? <AuthentificationRoot />
            : (
              <Switch>
                <Route exact path={ON_BOARDING} component={OnBoarding} />
                {
                  isSuperAdmin && (
                    <Route exact path={ADMIN} component={UserAdmin} />
                  )
                }
                <Route>
                  {
                    sessionManager.getSession()?.agent_id
                      ? <Route component={AgencyRoot} />
                      : <AgencySelectorPage />
                  }
                </Route>
              </Switch>
            )
        }
      </AuthProvider>
    </div>
  );
}

export default App;
