import { RelationshipType } from 'types/OccupantRelationship';
import { Enum } from 'lib/type/enum';
import { DocumentGroup } from 'types/DocumentGroup';
import { FoyerComposition } from 'types/Pinel';

export interface Candidature {
  id: string,
  workflowStep: string,
  propertyID: string,
  propertyURL: string,
  order?: number,
  isRefused: boolean,
  agencyID: string,
  agentID?: string,
  createdAt?: string,
  updatedAt?: string,
  notation?: number | string,
  moveInWanted?: string,
  source?: string,
  plateform?: string,
  message?: string,
  formMessage?: string,
  occupantRelationship?: RelationshipType,
  documentsStatus?: DocumentStatusType,
  documentsStatusLastUpdatedAt?: string,
  motivation?: string,
  documentGroups?: DocumentGroup[],
  propertyDescription?: string,
  statusUpdatedAt?: string
  foyerCompositionN2?: FoyerComposition,
  fiscalIncomesN2?: number,
}

export interface CandidaturePartial extends Partial<Candidature> {
  id: string,
}

export const candidateNotation = {
  1: '🙁',
  2: '😐',
  3: '🙂',
  4: '🤩',
};

export type DocumentStatusType = 'NOT_ASKED' | 'ASKED' | 'REMINDED' | 'SENT' | 'MISSING' | 'VALIDATED';

export const documentStatusTypeEnum: Enum<DocumentStatusType> = {
  NOT_ASKED: 'NOT_ASKED',
  ASKED: 'ASKED',
  REMINDED: 'REMINDED',
  SENT: 'SENT',
  MISSING: 'MISSING',
  VALIDATED: 'VALIDATED',
};
