import { useMutation, useQuery, useQueryClient } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';
import { Visit } from 'types/Visit';

export function useVisitBackend() {
  const queryClient = useQueryClient();

  const CreateVisit = useMutation<Visit, any, Visit>(
    async (visit) => {
      const response = await backend.post('/api/visits/', visit);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAgentVisits']);
        queryClient.refetchQueries(['getVisits', sessionManager.getSession()?.agent_id]);
        queryClient.invalidateQueries(['getPropertyVisits']);
      },
    },
  );

  const UpdateVisit = useMutation<Visit, any, {
    visitId: string,
    data: Partial<Visit>,
    visitSlotId?: string,
  }>(
    async ({ visitId, data }) => {
      const response = await backend.patch(`/api/visits/${visitId}`, data);
      return response;
    }, {
      onSuccess: (_, { visitId, visitSlotId }) => {
        queryClient.invalidateQueries(['getPropertyVisits']);
        queryClient.invalidateQueries(['getAgentVisits']);
        queryClient.refetchQueries(['getVisits', sessionManager.getSession()?.agent_id]);
        queryClient.refetchQueries(['getVisit', sessionManager.getSession()?.agent_id, visitId]);
        if (visitSlotId) {
          queryClient.invalidateQueries(['getAgentVisitSlots']);
          queryClient.refetchQueries(['getVisitSlot', sessionManager.getSession()?.agent_id, visitSlotId]);
        }
      },
    },
  );

  const GetVisit = (visitId: string, enabled = true) => useQuery<Visit>(
    ['getVisit', sessionManager.getSession()?.agent_id, visitId],
    async () => {
      const response: Visit = await backend.fetchJson(
        `/api/visits/${visitId}`,
      );
      return response;
    },
    { enabled },
  );

  const GetVisits = (enabled = true) => useQuery<Visit[]>(
    ['getVisits', sessionManager.getSession()?.agent_id],
    async () => {
      const response: Visit[] = await backend.fetchJson(
        '/api/visits',
      );
      return response;
    },
    { enabled },
  );

  return {
    getVisit: GetVisit,
    getVisits: GetVisits,
    createVisit: CreateVisit,
    updateVisit: UpdateVisit,
  };
}
