import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import { CandidatureShort } from 'types/Candidature';
import CandidateKanbanItem from 'pages/agency-app/candidates/candidates/candidateKanban/CandidateKanbanItem';
import { AgencyStep } from 'types/AgencyStep';
import { Checkbox } from '@material-ui/core';
import { useAgencyBackend } from 'network/queries/AgencyQueries';

type Props = {
  candidates: CandidatureShort[],
  workflowStep: AgencyStep,
  sendVisitInvitations: (candidateIds: string[]) => void
  refuseCandidates: (candidateIds: string[]) => void
  updateCandidatesList: (candidates: CandidatureShort[]) => void
  onEnd: () => void,
  selectedCandidateIds: string[],
  selectCandidateId: (
    candidateId: string,
    wasAlreadySelected: boolean,
    workFlowStep: string,
  ) => void,
  currentSelectionWorkflowStep?: string,
  setSelectedCandidateIds: (ids: string[]) => void,
  setCurrentSelectionWorflowStep: (step: string) => void,
};

export default function CandidateKanbanSection(
  {
    candidates,
    sendVisitInvitations,
    refuseCandidates,
    workflowStep,
    onEnd,
    updateCandidatesList,
    selectedCandidateIds,
    selectCandidateId,
    currentSelectionWorkflowStep,
    setSelectedCandidateIds,
    setCurrentSelectionWorflowStep,
  }: Props,
) {
  const agencyQueries = useAgencyBackend();
  const { getCurrentAgency, getGliContracts } = agencyQueries;
  const { data: agency } = getCurrentAgency();
  const { data: gliContracts } = getGliContracts();
  const agencyGliActivated = agency?.activateGli;
  const agencyGliRatio = agency?.gliRatio || 0;
  const checked = candidates.length > 0 && (candidates
    .filter((candidate) => selectedCandidateIds
      .filter((id) => candidate.id === id).length > 0).length === candidates.length);

  return (
    <div className="kanban-section">
      <div className="section-header">
        <Checkbox
          disabled={
            !!currentSelectionWorkflowStep
            && currentSelectionWorkflowStep !== workflowStep.id
            && selectedCandidateIds.length > 0
          }
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (checked) {
              setSelectedCandidateIds([]);
            } else {
              setCurrentSelectionWorflowStep(workflowStep.id);
              setSelectedCandidateIds(candidates.map((candidate) => candidate.id));
            }
          }}
          checked={checked}
        />
        <div>{workflowStep.stepName}</div>
        <div className="section-count">{candidates.length}</div>
      </div>
      <ReactSortable
        className="sortable-list"
        group="shared"
        list={candidates}
        animation={200}
        setList={updateCandidatesList}
        onEnd={onEnd}
        scroll={false}
      >
        {
          candidates
            .sort((a, b) => ((((a.order !== null && a.order !== undefined) ? a.order : -1))
                - ((b.order !== null && b.order !== undefined) ? b.order : -1))
              || (b.statusUpdatedAt || '').localeCompare(a.statusUpdatedAt || ''))
            .map((candidate) => (
              <CandidateKanbanItem
                workflowStep={workflowStep}
                key={candidate.id}
                sendVisitInvitation={() => sendVisitInvitations([candidate.id])}
                refuseCandidate={() => refuseCandidates([candidate.id])}
                candidate={candidate}
                selectedCandidateIds={selectedCandidateIds}
                currentSelectionWorkflowStep={currentSelectionWorkflowStep}
                selectCandidateId={selectCandidateId}
                agencyGliActivated={agencyGliActivated}
                gliContracts={gliContracts}
                agencyGliRatio={agencyGliRatio}
              />
            ))
        }
      </ReactSortable>
    </div>
  );
}
