import React from 'react';
import {
  DialogActions,
  DialogContent, DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import { AgencyDetailed } from 'types/Agency';
import Messages from 'services/i18n/Messages';
import { Link } from 'react-router-dom';
import { PARAMETERES_PLAN } from 'routes/Routes';
import Button from 'theme/Button';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  agency: AgencyDetailed,
  open?: boolean,
  onClose: () => void
};

export default function PremuimModal(
  {
    open,
    onClose,
    agency,
  }: Props,
) {
  let progress = (agency && ((agency.visitSlotCount || 0) / agency.plan.maxFreeVisitSlot) * 100)
    || 0;
  if (progress > 100) {
    progress = 100;
  }
  return (
    <DialogWrapper open={!!open} onClose={() => onClose()}>
      <div className="plan-premium-modal">
        <DialogTitle>
          {progress === 100 ? Messages.t('plan.visitCount.reach') : Messages.t('plan.visitCount.almostReach')}
        </DialogTitle>
        <DialogContent>
          <div>{Messages.t('plan.premium.advantages.full')}</div>
          <div className="plan-progress-container">
            <LinearProgress
              variant="determinate"
              value={progress}
            />
            <div className="plan-subtitle">
              {Messages.t('plan.visitSlot', { total: agency.plan.maxFreeVisitSlot, current: agency.visitSlotCount })}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{Messages.t('formButton.cancel')}</Button>
          <Link
            className="link-as-button"
            to={PARAMETERES_PLAN}
          >
            {Messages.t('plan.premium').toUpperCase()}
          </Link>
        </DialogActions>
      </div>
    </DialogWrapper>
  );
}
