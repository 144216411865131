import { useMutation, useQuery, useQueryClient } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';

import {
  AssignVisit,
  VisitSlot,
  VisitSlotDetailed,
  VisitSlotWithVisitCount,
} from 'types/VisitSlot';
import { Visit } from 'types/Visit';

export function useVisitSlotBackend() {
  const queryClient = useQueryClient();

  const CreateVisitSlot = useMutation<VisitSlot, any, VisitSlot>(
    async (visitSlot) => {
      const response = await backend.post('/api/visit-slots/', visitSlot);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgency', sessionManager.getSession()?.agent_id]);
        queryClient.refetchQueries(['getVisitSlots', sessionManager.getSession()?.agent_id]);
        queryClient.invalidateQueries(['getPropertyVisitSlot']);
      },
    },
  );

  const AssignVisitSlot = useMutation<Visit, any, {
    visitSlotId: string,
    assignVisit: AssignVisit,
  }>(
    async (data) => {
      const response = await backend.post(`/api/visit-slots/${data.visitSlotId}/visits`, data.assignVisit);
      return response;
    }, {
      onSuccess: (_, { visitSlotId }) => {
        queryClient.invalidateQueries(['getAgentVisitSlots']);
        queryClient.refetchQueries(['getVisitSlots', sessionManager.getSession()?.agent_id]);
        queryClient.refetchQueries(['getVisitSlot', sessionManager.getSession()?.agent_id, visitSlotId]);
        queryClient.invalidateQueries(['getPropertyVisitSlot']);
        queryClient.invalidateQueries(['getPropertyVisits']);
      },
    },
  );

  const UpdateVisitSlot = useMutation<VisitSlot, any, {
    visitSlotId: string,
    data: Partial<VisitSlot>,
  }>(
    async (data) => {
      const response = await backend.patch(`/api/visit-slots/${data.visitSlotId}`, data.data);
      return response;
    }, {
      onSuccess: (_, { visitSlotId }) => {
        queryClient.invalidateQueries(['getAgentVisitSlots']);
        queryClient.refetchQueries(['getVisitSlots', sessionManager.getSession()?.agent_id]);
        queryClient.refetchQueries(['getVisitSlot', sessionManager.getSession()?.agent_id, visitSlotId]);
        queryClient.invalidateQueries(['getPropertyVisitSlot']);
      },
    },
  );

  const GetVisitSlots = (enabled = true) => useQuery<VisitSlotWithVisitCount[]>(
    ['getVisitSlots', sessionManager.getSession()?.agent_id],
    async () => {
      const response: VisitSlotWithVisitCount[] = await backend.fetchJson(
        '/api/visit-slots',
      );
      return response;
    },
    { enabled },
  );

  const GetVisitSlot = (visitSlotId: string, enabled = true) => useQuery<VisitSlotDetailed>(
    ['getVisitSlot', sessionManager.getSession()?.agent_id, visitSlotId],
    async () => {
      const response: VisitSlotDetailed = await backend.fetchJson(
        `/api/visit-slots/${visitSlotId}`,
      );
      return response;
    },
    { enabled },
  );

  return {
    getVisitSlot: GetVisitSlot,
    getVisitSlots: GetVisitSlots,
    createVisitSlot: CreateVisitSlot,
    assignVisitSlot: AssignVisitSlot,
    updateVisitSlot: UpdateVisitSlot,
  };
}
