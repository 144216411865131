import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SelectWrapper from 'lib/form/SelectWrapper';
import { Search } from '@material-ui/icons';
import { AgentDetailled } from 'types/Agent';
import candidatureFilterService from 'services/filters/CandidatureFilterService';
import { CandidatureFilters } from 'types/filters/CandidatureFilters';
import PropertiesFilter from 'pages/common/PropertiesFilter';
import { useLocation, useHistory } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { Property } from 'types/Property';
import { PROPERTY_ID } from 'routes/QueryParams';
import { NONE } from 'services/filters/consts';
import FiltersUtils from 'services/filters/FiltersUtils';

type Props = {
  defaultProperties?: Property[];
  properties: Property[];
  agents: AgentDetailled[]
};

// TODO refacto
export default function Filters({ defaultProperties, properties, agents }: Props) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const propertyId = queryParams.get(PROPERTY_ID);
  const [apiErrors] = useState({});
  const history = useHistory();

  const getAgentName = (agent: AgentDetailled) => {
    const name = `${agent.firstname || ''} ${agent.lastname || ''}`;
    return name.trim() === '' ? agent.email : name;
  };
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<CandidatureFilters>({
    defaultValues: {
      properties: (defaultProperties && defaultProperties.length > 0)
        ? defaultProperties.map((property) => property.id)
        : propertyId
          ? (
            [...properties.filter((property) => propertyId?.split(',').includes(property.id)).map((property) => property.id), ...(propertyId.includes(NONE) ? [NONE] : [])]
          ) : [],
      ...candidatureFilterService.getFilter(),
      ...FiltersUtils.getObjectFromQueryParam<CandidatureFilters>({
        properties: [],
        search: '',
        agent: '',
      }, queryParams),
    },
  });

  const formField = watch();

  useEffect(() => {
    candidatureFilterService.updateFilters(formField);
    history.replace(Routes.updatePathWithQuery(
      location,
      FiltersUtils.getQueryParamsFromObject(formField),
    ));
  }, [formField.agent, formField.properties, formField.search]);

  return (
    <>
      <form className="filter-header">
        <Controller
          name="search"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              prefix={<Search />}
              control={controller}
              type="text"
              label={Messages.t('field.search')}
            />
          )}
        />
        {
          properties && (
            <Controller
              name="properties"
              control={control}
              render={(controller) => (
                <PropertiesFilter
                  showOffline
                  apiErrors={apiErrors}
                  error={errors}
                  showEveryButton
                  showNone
                  multiple
                  control={controller}
                  shortDisplay
                  label={Messages.t('field.property')}
                  properties={
                    (defaultProperties?.length === 1)
                    && (properties.filter((property) => property.id
                      === defaultProperties[0].id).length === 0)
                      ? [...properties, defaultProperties[0]]
                      : properties
                  }
                />
              )}
            />
          )
        }
        {
          agents && (
            <div className="agent-select">
              <Controller
                name="agent"
                control={control}
                render={(controller) => (
                  <SelectWrapper
                    error={{}}
                    multiple
                    canSelectAll
                    control={controller}
                    label={Messages.t('field.agent')}
                    values={agents.map((agent) => ({ key: agent.id, label: getAgentName(agent) }))}
                  />
                )}
              />
            </div>
          )
        }
      </form>
    </>
  );
}
