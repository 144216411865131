import React, { useState } from 'react';
import localizer from 'react-big-calendar/lib/localizers/moment';
import moment from 'moment';
import {
  Calendar,
  Formats,
  Views,
} from 'react-big-calendar';
import {
  addDays, endOfDay,
  format,
  startOfDay,
} from 'date-fns';
import { UserEvent } from 'types/UserEvent';
import StringUtils from 'services/StringUtils';
import localService from 'services/i18n/LocalService';
import DateUtils from 'services/DateUtils';
import AgentEvent from 'pages/common/calendar/AgentEvent';
import AgentDate from 'pages/common/calendar/AgentDate';
import { Components, EventDetails, RbcEvent } from 'types/ReactBigCalendar';
import { VisitSlot } from 'types/VisitSlot';
import { Visit } from 'types/Visit';
import Messages from 'services/i18n/Messages';
import CalendarToolbar from 'pages/common/calendar/CalendarToolbar';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import sessionManager from 'services/sessionManager';

type Props = {
  agentId?: string,
};

const AGENDA_DAY_LENGTH = 7;
export default function AgentCalendar({ agentId }: Props) {
  const agentQueries = useAgentsBackend();
  const {
    getAgentEvents,
    getAgentVisits,
    getAgentVisitSlots,
    getAgentIntegrations,
  } = agentQueries;
  const { data: userEventData } = getAgentEvents(agentId || '', !!agentId);
  const { data: visitSlots } = getAgentVisitSlots(agentId || '', !!agentId);
  const { data: visits } = getAgentVisits(agentId || '', !!agentId);
  const { data: userIntegrations } = getAgentIntegrations(agentId || '', !!agentId);

  const userEvent = userEventData || [];
  const globalizeLocalizer = localizer(moment);
  const [dateRange, setDateRange] = useState<{ start: Date, end: Date }>({
    start: startOfDay(new Date()),
    end: endOfDay(addDays(new Date(), AGENDA_DAY_LENGTH)),
  });
  const formats: Formats = {
    dateFormat: 'dd',
    timeGutterFormat: (date) => format(date, 'HH:mm'),
    agendaHeaderFormat: (range) => `${range.start.getDate().toString().padStart(2, '0')}${range.start.getMonth() !== range.end.getMonth() ? ` ${
      StringUtils.capitalizeFirstLetter(format(range.start, 'MMMM', { locale: localService.getDateLocal() }))
    }` : ''} - ${StringUtils.capitalizeAllLetter(format(range.end, 'dd MMMM, yyyy', { locale: localService.getDateLocal() }))}`,
  };

  const eventFromRecurentMap: { [key: string]: UserEvent[] } = {};

  userEvent
    .forEach((event) => {
      if (event.recurrenceEventID) {
        if (!eventFromRecurentMap[event.recurrenceEventID]) {
          eventFromRecurentMap[event.recurrenceEventID] = [];
        }
        eventFromRecurentMap[event.recurrenceEventID].push(event);
      }
    });

  const recurrentEvent = DateUtils.getRecurrentEvent<UserEvent>(
    userEvent,
    dateRange.start,
    dateRange.end,
    (event) => new Date(event.startDate || event.startDateTime),
    (event) => new Date(event.endDate || event.endDateTime),
    (event) => !!event.startDate,
    (event, recurrentDate) => {
      if (eventFromRecurentMap[event.sourceID]) {
        return !eventFromRecurentMap[event.sourceID]
          .some((eventFromRecurrent) => (recurrentDate.getDate()
              === new Date(eventFromRecurrent.originalStartDateTime).getDate())
            && (recurrentDate.getMonth()
              === new Date(eventFromRecurrent.originalStartDateTime).getMonth())
            && (recurrentDate.getUTCFullYear()
              === new Date(eventFromRecurrent.originalStartDateTime).getUTCFullYear()));
      }
      return true;
    },
  );

  const visitSlotsMap: { [key: string]: VisitSlot } = {};
  const visitEvents: RbcEvent<EventDetails>[] = [];
  visitSlots?.forEach((slot: VisitSlot) => {
    if (slot.calendarEventID) {
      visitSlotsMap[slot.calendarEventID] = slot;
    }
    visitEvents.push({
      resource: {
        color: 'pink',
      },
      title: Messages.t('visitSlot.form.title'),
      allDay: false,
      start: new Date(slot.startDate),
      end: new Date(slot.endDate),
    });
  });

  const visitsMap: { [key: string]: Visit } = {};
  visits?.forEach((visit: Visit) => {
    if (visit.calendarEventID) {
      visitsMap[visit.calendarEventID] = visit;
    }
    if (!visit.visitSlotID) {
      visitEvents.push({
        resource: {
          color: 'yellow',
        },
        title: Messages.t('visit.form.title'),
        allDay: false,
        start: new Date(visit.startDate),
        end: new Date(visit.endDate),
      });
    }
  });

  return (
    <div className="agent-agenda-container">
      <Calendar
        length={AGENDA_DAY_LENGTH}
        // @ts-ignore
        components={{
          toolbar: (e) => (
            <CalendarToolbar
              isCurrentAgentCalendar={agentId === sessionManager.getSession()?.agent_id}
              showUserWarning={
                userIntegrations
                && !userIntegrations.google
                && !userIntegrations.outlook
              }
              {...e}
            />
          ),
          agenda: {
            time: (timeProps) => <AgentEvent event={timeProps.event} time={timeProps} />,
            date: (e) => <AgentDate day={e.day} />,
            event: () => null,
          },
        } as Components<RbcEvent<EventDetails>, {}>}
        events={[
          ...userEvent
            .filter((event) => (event.startDate || event.startDateTime)
              && (event.endDate || event.endDateTime) && !event.recurrenceRule
              && !visitsMap[event.sourceID]
              && !visitSlotsMap[event.sourceID])
            .map((event) => (
              {
                resource: {
                  color: 'blue',
                },
                title: event.title,
                allDay: !!event.startDate,
                start: new Date(event.startDate || event.startDateTime),
                end: event.endDate
                  ? addDays(new Date(event.endDate), -1)
                  : new Date(event.endDateTime),
              }
            )),
          ...visitEvents,
          ...recurrentEvent,
        ]}
        toolbar
        // @ts-ignore
        onRangeChange={(range) => setDateRange(range)}
        popup
        messages={
          {
            noEventsInRange: agentId ? Messages.t('calendar.select.noEvent') : Messages.t('calendar.select.agent'),
          }
        }
        formats={formats}
        culture="fr"
        selectable
        defaultView={Views.AGENDA}
        views={[Views.AGENDA]}
        step={60}
        showMultiDayTimes
        localizer={globalizeLocalizer}
      />
    </div>
  );
}
