import { observable, WritableObservable } from 'micro-observables';
import { CandidatureFilterFunctions, CandidatureFilters } from 'types/filters/CandidatureFilters';
import { CandidatureShort } from 'types/Candidature';
import CandidatureUtils from 'services/CandidatureUtils';
import { Property } from 'types/Property';
import { ALL, NONE } from 'services/filters/consts';
import { PROPERTY_FILTER_KEYS } from 'pages/common/PropertiesFilter';

const DEFAULT = 'default';
const filterFunction: CandidatureFilterFunctions = {
  search: CandidatureUtils.candidateMatch,
  properties: (
    candidature: CandidatureShort,
    filterValue?: string[],
  ) => {
    if (filterValue
      && filterValue.includes(NONE) && !candidature.propertyID) {
      return true;
    }
    return !filterValue
      || filterValue.length === 0
      || filterValue.filter((option) => !PROPERTY_FILTER_KEYS.includes(option)
        && option === candidature.propertyID).length > 0;
  },
  agent: (
    candidature: CandidatureShort,
    filterValue: string,
  ) => filterValue.split(',').includes(candidature.agentID || DEFAULT)
    || filterValue.split(',').includes(candidature.property?.agentID || DEFAULT),
};

export class CandidatureFilterService {
  private filters: WritableObservable<CandidatureFilters> = observable({});

  getFilterObservable() {
    return this.filters.readOnly();
  }

  getFilter() {
    return this.filters.get();
  }

  updateFilters(filter: CandidatureFilters) {
    return this.filters.set(filter);
  }

  public static getOnePropertyId(properties?: (Property | string)[]): string {
    if (properties?.length === 1) {
      if (typeof properties[0] !== 'string') {
        return properties[0]?.id;
      }
      return properties[0];
    }
    return '';
  }

  applyFilters(candidatures: CandidatureShort[]): CandidatureShort[] {
    const filters = this.getFilter();
    return candidatures.filter((candidature) => Object.keys(filters).every((key) => {
      if (!filters[key] || filters[key] === '' || filters[key] === ALL) {
        return true;
      }
      return filterFunction[key](candidature, filters[key]);
    }));
  }
}

const candidatureFilterService = new CandidatureFilterService();
export default candidatureFilterService;
