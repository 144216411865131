import React from 'react';
import {
  CANDIDATE,
  CANDIDATE_DETAIL,
  DASHBOARD,
} from 'routes/Routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import Candidates from 'pages/agency-app/candidates/candidates/Candidates';
import CandidatureContainer from 'pages/agency-app/candidates/candidateDetails/CandidatureContainer';

export default function CandidatesRouter() {
  return (
    <Switch>
      <Route path={CANDIDATE_DETAIL} component={CandidatureContainer} />
      <Route path={CANDIDATE} component={Candidates} />
      <Route render={() => <Redirect to={DASHBOARD} />} />
    </Switch>
  );
}
