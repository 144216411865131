import React, { useState } from 'react';
import { CandidatureDetailled, CandidatureShort } from 'types/Candidature';
import Messages from 'services/i18n/Messages';
import { Routes } from 'routes/RoutesUtils';
import { CANDIDATE_DETAIL, ID } from 'routes/Routes';
import { Link } from 'react-router-dom';
import { candidateNotation, CandidaturePartial } from 'types/CandidatureBase';
import { Contact } from 'types/Contact';
import DateUtils from 'services/DateUtils';
import { AgencyStep } from 'types/AgencyStep';
import ContactUtils from 'services/ContactUtils';
import { Tooltip } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import SelectWrapper from 'lib/form/SelectWrapper';
import { NotificationService } from 'lib/notification';
import { WarningAmber } from '@material-ui/icons';

type Props = {
  steps: { [key: string]: AgencyStep }
  candidate: CandidatureShort
};

export default function CandidateTabItem({ candidate, steps }: Props) {
  const [submitting, setSubmitting] = useState(false);

  const candidatureQueries = useCandidatureBackend();
  const { updateCandidature } = candidatureQueries;

  const sortedContact = (candidateParam: CandidatureShort): Contact[] => candidateParam
    .contacts.sort((a, b) => (a.order || 0) - (b.order || 0));

  const {
    control,
  } = useForm<CandidatureDetailled>({ defaultValues: candidate });

  const submitProperty = (data: CandidaturePartial) => {
    setSubmitting(true);
    updateCandidature.mutateAsync(data)
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Link
      to={Routes.withPath(CANDIDATE_DETAIL, [{ label: ID, value: candidate.id }])}
      className={`tab-row ${candidate.isRefused ? 'disabled' : ''}`}
      key={candidate.id}
    >
      <div>
        <Tooltip
          title={(
            <>
              {
                sortedContact(candidate).map((contact) => (
                  <div key={contact.id}>
                    {
                      ContactUtils.getDisplayedName(
                        contact,
                        0,
                      )
                    }
                  </div>
                ))
              }
            </>
          )}
        >
          <div>
            {
              ContactUtils.getDisplayedName(
                sortedContact(candidate)[0],
                candidate.contacts.length,
              )
            }
          </div>
        </Tooltip>
        {!candidate.property && (
          <div className="tag-container">
            <div className="contact-tag">
              <div>
                <WarningAmber fontSize="small" />
              </div>
              <small>{Messages.t('status.noProperty')}</small>
            </div>
          </div>
        )}
      </div>
      <div
        role="presentation"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div>
          {
            steps && (
              <Controller
                name="workflowStep"
                control={control}
                render={(controller) => (
                  <SelectWrapper
                    noLabel
                    error={{}}
                    disabled={submitting}
                    onChange={(stepId) => submitProperty({
                      workflowStep: stepId,
                      id: candidate.id,
                    })}
                    control={controller}
                    label={Messages.t('field.step')}
                    values={
                      Object.values(steps)
                        .sort((a, b) => (a.order || 0) - (b.order || 0))
                        .map((step) => ({ key: step.id, label: step.stepName }))
                    }
                  />
                )}
              />
            )
          }
        </div>
      </div>
      <div>
        <div>
          {candidate.notation && candidateNotation[candidate.notation]}
        </div>
      </div>
      <div>
        <div>
          {
            candidate.contacts
              .reduce((acc, value) => acc + (value.monthlyIncome || 0), 0)
          } &euro;
        </div>
      </div>
      <div>
        <div>
          <div className="tag-container">
            {
              candidate.garants
              && candidate.garants.length > 0
              && candidate.garants[0].type
                ? (
                  <div className="contact-tag">
                    {Messages.t('generics.YES')}
                  </div>
                ) : (
                  <div className="contact-tag">
                    {Messages.t('generics.NO')}
                  </div>
                )
            }
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="tag-container">
            {
              sortedContact(candidate)
                .filter((contact) => contact.leaseType && contact.leaseType !== '')
                .map((contact) => (
                  <div key={contact.id} className="contact-tag">
                    {contact.leaseType}
                  </div>
                ))
            }
          </div>
        </div>
      </div>
      <div>
        <div>
          {
            candidate.statusUpdatedAt
            && DateUtils.getPrettyDateDeltaFromNow(candidate.statusUpdatedAt, false, true)
          }
        </div>
      </div>
    </Link>
  );
}
