import React,
{
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useState,
} from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import PhoneInput from 'react-phone-number-input';
import PhoneInputWithoutFlag from 'react-phone-number-input/input';

import { InputAdornment, TextField } from '@material-ui/core';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { Modification } from 'types/Modification';
import FieldHistory from 'lib/form/FieldHistory';

type Props = {
  disabled?: boolean
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  label?: string
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] },
  flag?: boolean
  modifications?: { [field: string]: Modification[] }
};

// @ts-ignore
export default function PhoneNumberInputWrapper(
  {
    disabled,
    control,
    label,
    error,
    apiErrors,
    modifications,
    flag,
  }: Props,
) {
  const { field } = control;
  const fieldName = field.name.split('.').slice(-1)[0];
  const fieldModification = modifications && modifications[fieldName];
  const [ForwardInputComponent] = useState<ForwardRefExoticComponent<RefAttributes<any>>>(
    forwardRef((props: any, ref) => (
      <div
        className={`material-textfield-wrapper ${(
          props.fieldmodification
          && props.fieldmodification.length > 0
          && props.fieldmodification
            .sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0].updatedByAgent)
          ? 'danger-text' : ''}`}
      >
        <TextField
          {...props}
          error={!!props.error}
          helperText={props.error}
          fullWidth
          disabled={disabled}
          label={label || undefined}
          variant="filled"
          inputRef={ref}
          InputProps={{
            disableUnderline: true,
            endAdornment: (props.fieldmodification)
              && (
                <InputAdornment position="end">{
                  props.fieldmodification
                  && <FieldHistory history={props.fieldmodification} />
                }
                </InputAdornment>
              ),
          }}
        />
      </div>
    )),
  );

  const FinalPhoneInput = flag ? PhoneInput : PhoneInputWithoutFlag;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);

  return (
    <FinalPhoneInput
      {...field}
      disabled={disabled}
      error={errorMessage}
      fieldmodification={fieldModification}
      defaultCountry="FR"
      // @ts-ignore
      inputComponent={ForwardInputComponent}
    />
  );
}
