import { useMutation, useQuery, useQueryClient } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';
import { RedirectionUrl } from 'types/RedirectionUrl';
import { AccessToken } from 'types/AccessToken';

export function useIntegrationBackend() {
  const queryClient = useQueryClient();

  const GetGoogleLink = (enabled = true) => useQuery<RedirectionUrl>(
    ['getGoogleUrl', sessionManager.getSession()?.agent_id],
    async () => {
      const response: RedirectionUrl = await backend.fetchJson(
        '/api/integrations/google',
      );
      return response;
    },
    { enabled },
  );

  const GetOutlookLink = (enabled = true) => useQuery<RedirectionUrl>(
    ['getOutlookUrl', sessionManager.getSession()?.agent_id],
    async () => {
      const response: RedirectionUrl = await backend.fetchJson(
        '/api/integrations/outlook',
      );
      return response;
    },
    { enabled },
  );

  const SaveGoogleToken = useMutation<void, any, AccessToken>(
    async (token) => {
      const response = await backend.post('/api/integrations/google', token);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgentIntegrations', sessionManager.getSession()?.agent_id, sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const SaveOutlookToken = useMutation<void, any, AccessToken>(
    async (token) => {
      const response = await backend.post('/api/integrations/outlook', token);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgentIntegrations', sessionManager.getSession()?.agent_id, sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const DisconnectUser = useMutation<void, any, void>(
    async () => {
      const response = await backend.post('/api/integrations/disconnect');
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgentIntegrations', sessionManager.getSession()?.agent_id, sessionManager.getSession()?.agent_id]);
      },
    },
  );

  return {
    getOutlookLink: GetOutlookLink,
    getGoogleLink: GetGoogleLink,
    saveGoogleToken: SaveGoogleToken,
    saveOutlookToken: SaveOutlookToken,
    disconnectUser: DisconnectUser,
  };
}
