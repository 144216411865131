import { useMutation, useQuery, useQueryClient } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';
import { Agent, AgentCreation, AgentDetailled } from 'types/Agent';
import { FileUpload } from 'types/FileUpload';
import { AgentForm } from 'types/forms/AgentForm';
import { AgentNotification } from 'types/AgentNotification';
import { Token } from 'types/security/token';
import { UserEvent } from 'types/UserEvent';
import { VisitSlotWithVisitCount } from 'types/VisitSlot';
import { Visit } from 'types/Visit';
import { UserIntegrations } from 'types/UserIntegrations';

export function useAgentsBackend() {
  const queryClient = useQueryClient();

  const GetAgentIntegrations = (agentId: string, enabled = true) => useQuery<UserIntegrations>(
    ['getAgentIntegrations', sessionManager.getSession()?.agent_id, agentId],
    async () => {
      const response: UserIntegrations = await backend.fetchJson(
        `/api/agents/${agentId}/integrations`,
      );
      return response;
    },
    { enabled },
  );

  const CreateAgent = useMutation<Agent, any, AgentCreation>(
    async (agent) => {
      const response = await backend.post('/api/agents/', agent);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAgents']);
      },
    },
  );

  const GetAgents = (enabled = true) => useQuery<AgentDetailled[]>(
    ['getAgents', sessionManager.getSession()?.agent_id],
    async () => {
      const response: AgentDetailled[] = await backend.fetchJson(
        '/api/agents',
      );
      return response;
    },
    { enabled },
  );

  const GetAgent = (enabled = true) => useQuery<AgentDetailled>(
    ['getAgent', sessionManager.getSession()?.agent_id],
    async () => {
      const response: AgentDetailled = await backend.fetchJson(
        '/api/agents/current',
      );
      return response;
    },
    { enabled },
  );

  const GetAgentNotifications = (agentId: string, enabled = true) => useQuery<{
    [key: string]: AgentNotification,
  }>(
    ['getAgentNotifications', sessionManager.getSession()?.agent_id, agentId],
    async () => {
      const response: AgentNotification[] = await backend.fetchJson(
        `/api/agents/${agentId}/notifications`,
      );
      return response.reduce((acc, value) => {
        acc[value.workflowStep] = value;
        return acc;
      }, {});
    },
    { enabled },
  );

  const UpdateAgentNotifications = useMutation<AgentNotification, any, {
    agentId: string,
    agentNotification: Partial<AgentNotification>
  }>(
    async (data) => {
      const response = await backend.post(`/api/agents/${data.agentId}/notifications`, data.agentNotification);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getAgentNotifications', sessionManager.getSession()?.agent_id, param.agentId]);
      },
    },
  );

  const UploadAgentImage = useMutation<Agent, any, {
    agentId: string,
    image: FileUpload
  }>(
    async (data) => {
      const response = await backend.post(`/api/agents/${data.agentId}/images`, data.image);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgent', sessionManager.getSession()?.agent_id]);
        queryClient.refetchQueries(['getAgents', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const UpdateAgent = useMutation<Token, any, {
    agentId: string,
    agent: AgentForm
  }>(
    async (data) => {
      const response = await backend.patch(`/api/agents/${data.agentId}`, data.agent);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgent', sessionManager.getSession()?.agent_id]);
        queryClient.refetchQueries(['getAgents', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const DeleteAgent = useMutation<void, any, string>(
    async (data) => {
      const response = await backend.delete(`/api/agents/${data}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgents', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const UpdateAgentRole = useMutation<void, any, {
    agentId: string,
    roleId: string,
  }>(
    async (data) => {
      const response = await backend.put(`/api/agents/${data.agentId}/roles/${data.roleId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getAgents', sessionManager.getSession()?.agent_id]);
      },
    },
  );

  const GetAgentEvents = (agentId: string, enabled = true) => useQuery<UserEvent[]>(
    ['getAgentEvents', sessionManager.getSession()?.agent_id, agentId],
    async () => {
      const response: UserEvent[] = await backend.fetchJson(
        `/api/agents/${agentId}/events`,
      );
      return response || [];
    },
    { enabled },
  );

  const GetAgentVisitSlots = (
    agentId: string,
    enabled = true,
  ) => useQuery<VisitSlotWithVisitCount[]>(
    ['getAgentVisitSlots', sessionManager.getSession()?.agent_id, agentId],
    async () => {
      const response: VisitSlotWithVisitCount[] = await backend.fetchJson(
        `/api/agents/${agentId}/visit-slots`,
      );
      return response;
    },
    { enabled },
  );

  const GetAgentVisits = (
    agentId: string,
    enabled = true,
  ) => useQuery<Visit[]>(
    ['getAgentVisits', sessionManager.getSession()?.agent_id, agentId],
    async () => {
      const response: Visit[] = await backend.fetchJson(
        `/api/agents/${agentId}/visits`,
      );
      return response;
    },
    { enabled },
  );

  return {
    getAgentVisitSlots: GetAgentVisitSlots,
    getAgentVisits: GetAgentVisits,
    getAgent: GetAgent,
    getAgentNotifications: GetAgentNotifications,
    updateAgentNotifications: UpdateAgentNotifications,
    getAgents: GetAgents,
    createAgent: CreateAgent,
    deleteAgent: DeleteAgent,
    uploadAgentImage: UploadAgentImage,
    updateAgentRole: UpdateAgentRole,
    updateAgent: UpdateAgent,
    getAgentEvents: GetAgentEvents,
    getAgentIntegrations: GetAgentIntegrations,
  };
}
