import React from 'react';
// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import 'firebase/auth';
import LogInForm from 'pages/authentification/LogInForm';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AGENCY_CREATION, LOG_IN } from 'routes/Routes';
import AgencyCreationFrom from 'pages/agency-creation/AgencyCreation';

export default function AuthentificationRoot() {
  return (
    <Switch>
      <Route exact path={AGENCY_CREATION} component={AgencyCreationFrom} />
      <Route exact path={LOG_IN} component={LogInForm} />
      <Route render={() => <Redirect to={LOG_IN} />} />
    </Switch>
  );
}
