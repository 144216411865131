import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  CANDIDATE_DETAIL,
  CANDIDATE_DETAIL_DETAILS, CANDIDATE_DETAIL_DOCUMENTS,
} from 'routes/Routes';
import CandidatureSummary from 'pages/agency-app/candidates/candidateDetails/candidateSummary/CandidatureSummary';
import CandidatureDetailForm
  from 'pages/agency-app/candidates/candidateDetails/candidateDetailsForm/CandidatureDetailForm';
import { CandidatureDetailled } from 'types/Candidature';
import CandidateDocuments from 'pages/agency-app/candidates/candidateDetails/candidateDocuments/CandidateDocuments';
import { DocumentDataMap } from 'types/DocumentData';
import { useForm } from 'react-hook-form';
import { CandidatureForm } from 'types/forms/CandidatureForm';
import CandidatureUtils from 'services/CandidatureUtils';
import { Agent } from 'types/Agent';

type Props = {
  candidature?: CandidatureDetailled,
  documentDataMap?: DocumentDataMap,
  agents?: Agent[],
  dataUpdatedAt: number,
};

export default function CandidatureRouter(
  {
    candidature,
    documentDataMap,
    agents,
    dataUpdatedAt,
  }: Props,
) {
  const form = useForm<CandidatureForm>({
    defaultValues: CandidatureUtils.getCandidatureFormDefaultValue(candidature),
  });

  return (
    <Switch>
      {
        candidature && (
          <Route
            exact
            path={CANDIDATE_DETAIL}
            render={
              () => (
                <CandidatureSummary
                  dataUpdatedAt={dataUpdatedAt}
                  agents={agents}
                  candidature={candidature}
                />
              )
            }
          />
        )
      }
      <Route
        path={CANDIDATE_DETAIL_DETAILS}
        render={() => (
          <CandidatureDetailForm
            form={form}
            candidature={candidature}
          />
        )}
      />
      {
        candidature && documentDataMap && (
          <Route
            path={CANDIDATE_DETAIL_DOCUMENTS}
            render={() => (
              <CandidateDocuments candidature={candidature} documentDataMap={documentDataMap} />
            )}
          />
        )
      }
    </Switch>
  );
}
