import React, { ReactNode } from 'react';
import { Mail, NotificationsOff } from '@material-ui/icons';

export interface AgentNotification {
  id: string,
  workflowStep: string,
  agentId: string,
  notificationType: NotificationType,
  createdAt: string,
  updatedAt: string,
}

export type NotificationType = 'NONE' | 'MAIL';

type NotificationTypeEnum = {
  [key in NotificationType]: { key: key, icon: ReactNode }
};

export const notificationType: NotificationTypeEnum = {
  NONE: { key: 'NONE', icon: <NotificationsOff /> },
  MAIL: { key: 'MAIL', icon: <Mail /> },
};
