import { Enum } from 'lib/type/enum';

export type Permission =
  'ADMIN'
  | 'SUPER_ADMIN'
  | 'ACTIVITY'
  | 'ACTIVITY_ADMIN'
  | 'AGENCY'
  | 'AGENCY_ADMIN'
  | 'AGENT'
  | 'AGENT_ADMIN'
  | 'AGENCY_MANAGER'
  | 'PROPERTY'
  | 'PROPERTY_ADMIN'
  | 'CANDIDATE'
  | 'CANDIDATE_ADMIN'
  | 'USER'
  | 'ALL_USER';

export const permissionsEnum: Enum<Permission> = {
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  ACTIVITY: 'ACTIVITY',
  ACTIVITY_ADMIN: 'ACTIVITY_ADMIN',
  AGENCY: 'AGENCY',
  AGENCY_ADMIN: 'AGENCY_ADMIN',
  AGENT: 'AGENT',
  AGENT_ADMIN: 'AGENT_ADMIN',
  PROPERTY: 'PROPERTY',
  AGENCY_MANAGER: 'AGENCY_MANAGER',
  PROPERTY_ADMIN: 'PROPERTY_ADMIN',
  CANDIDATE: 'CANDIDATE',
  CANDIDATE_ADMIN: 'CANDIDATE_ADMIN',
  USER: 'USER',
  ALL_USER: 'ALL_USER',
};
