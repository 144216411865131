import { useMutation, useQuery, useQueryClient } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import {
  Property,
  PropertyDetailled, PropertyForm,
  PropertyImage,
  PropertyPartial,
} from 'types/Property';
import sessionManager from 'services/sessionManager';
import { FileUpload } from 'types/FileUpload';
import { PropertyByUrlForm } from 'types/forms/PropertyByUrlForm';
import propertiesFilterService from 'services/filters/PropertiesFilterService';
import { NoteCreation, PropertyNote } from 'types/Note';
import { VisitSlotWithVisitCount } from 'types/VisitSlot';
import { VisitWithCandidate } from 'types/Visit';

export function usePropertiesBackend() {
  const queryClient = useQueryClient();
  const CreateProperty = useMutation<Property, any, Property>(
    async (property) => {
      const response = await backend.post('/api/properties/', property);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getProperties', sessionManager.getSession()?.agent_id, propertiesFilterService.getFilter().isArchived]);
        queryClient.invalidateQueries(['getCandidatures']);
        queryClient.invalidateQueries(['getCandidature']);
      },
    },
  );

  const CreatePropertyByUrl = useMutation<PropertyForm, any, PropertyByUrlForm>(
    async (property) => {
      const response = await backend.post('/api/properties/url', property);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getProperties', sessionManager.getSession()?.agent_id, propertiesFilterService.getFilter().isArchived]);
        queryClient.invalidateQueries(['getCandidatures']);
        queryClient.invalidateQueries(['getCandidature']);
      },
    },
  );

  const UploadPropertyImage = useMutation<Property, any, {
    propertyId: string,
    images: FileUpload[]
  }>(
    async (data) => {
      const response = await backend.post(`/api/properties/${data.propertyId}/images`, data.images);
      return response;
    }, {
      onSuccess: (_, params) => {
        queryClient.refetchQueries(['getProperties', sessionManager.getSession()?.agent_id, propertiesFilterService.getFilter().isArchived]);
        queryClient.refetchQueries(['getProperty', sessionManager.getSession()?.agent_id, params.propertyId]);
      },
    },
  );

  const UpdatePropertyImage = useMutation<Property, any, {
    propertyId: string,
    images: PropertyImage[]
  }>(
    async (data) => {
      const response = await backend.put(`/api/properties/${data.propertyId}/images`, data.images);
      return response;
    }, {
      onSuccess: (_, params) => {
        queryClient.refetchQueries(['getProperties', sessionManager.getSession()?.agent_id, propertiesFilterService.getFilter().isArchived]);
        queryClient.refetchQueries(['getProperty', sessionManager.getSession()?.agent_id, params.propertyId]);
      },
    },
  );

  const DeletePropertyImage = useMutation<Property, any, {
    propertyId: string,
    imageId: string
  }>(
    async (data) => {
      const response = await backend.delete(`/api/properties/${data.propertyId}/images/${data.imageId}`);
      return response;
    }, {
      onSuccess: (_, params) => {
        queryClient.refetchQueries(['getProperties', sessionManager.getSession()?.agent_id, propertiesFilterService.getFilter().isArchived]);
        queryClient.refetchQueries(['getProperty', sessionManager.getSession()?.agent_id, params.propertyId]);
      },
    },
  );

  const UpdateProperty = useMutation<Property, any, PropertyPartial>(
    async (property) => {
      const response = await backend.patch(`/api/properties/${property.id}`, property);
      return response;
    }, {
      onSuccess: (_, params) => {
        queryClient.refetchQueries(['getProperties', sessionManager.getSession()?.agent_id, propertiesFilterService.getFilter().isArchived]);
        queryClient.refetchQueries(['getProperty', sessionManager.getSession()?.agent_id, params.id]);
      },
    },
  );

  const DeleteProperty = useMutation<Property, any, { propertyId: string }>(
    async ({ propertyId }) => {
      const response = await backend.delete(`/api/properties/${propertyId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getProperties', sessionManager.getSession()?.agent_id, propertiesFilterService.getFilter().isArchived]);
      },
    },
  );

  const GetProperties = (isArchived = 'NO', enabled = true) => useQuery<PropertyDetailled[]>(
    ['getProperties', sessionManager.getSession()?.agent_id, isArchived],
    async () => {
      const response: PropertyDetailled[] = await backend.fetchJson(
        `/api/properties?isArchived=${isArchived === 'YES'}`,
      );
      return response;
    },
    { enabled },
  );

  const GetProperty = (
    propertyId: string,
    onSuccess?: ((data: PropertyDetailled) => void) | undefined,
    enabled = true,
  ) => useQuery<PropertyDetailled>(
    ['getProperty', sessionManager.getSession()?.agent_id, propertyId],
    async () => {
      const response: PropertyDetailled = await backend.fetchJson(
        `/api/properties/${propertyId}`,
      );
      return response;
    },
    { enabled, onSuccess },
  );

  const GetPropertyNotes = (
    propertyId: string,
    enabled = true,
  ) => useQuery<PropertyNote[]>(
    ['getPropertyNotes', sessionManager.getSession()?.agent_id, propertyId],
    async () => {
      const response: PropertyNote[] = await backend.fetchJson(
        `/api/properties/${propertyId}/notes`,
      );
      return response;
    },
    { enabled, refetchInterval: 5000 },
  );

  const GetPropertyVisitSlot = (
    propertyId: string,
    enabled = true,
  ) => useQuery<VisitSlotWithVisitCount[]>(
    ['getPropertyVisitSlot', sessionManager.getSession()?.agent_id, propertyId],
    async () => {
      const response: VisitSlotWithVisitCount[] = await backend.fetchJson(
        `/api/properties/${propertyId}/visit-slots`,
      );
      return response;
    },
    { enabled },
  );

  const GetPropertyVisits = (
    propertyId: string,
    enabled = true,
  ) => useQuery<VisitWithCandidate[]>(
    ['getPropertyVisits', sessionManager.getSession()?.agent_id, propertyId],
    async () => {
      const response: VisitWithCandidate[] = await backend.fetchJson(
        `/api/properties/${propertyId}/visits`,
      );
      return response;
    },
    { enabled },
  );

  const CreatePropertyNotes = useMutation<PropertyNote, any, {
    propertyId: string,
    data: NoteCreation,
  }>(
    async ({ data, propertyId }) => {
      const response = await backend.post(`/api/properties/${propertyId}/notes`, data);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getPropertyNotes', sessionManager.getSession()?.agent_id, param.propertyId]);
      },
    },
  );

  const DeletePropertyNote = useMutation<void, any, {
    propertyId: string,
    noteId: string,
  }>(
    async ({ noteId, propertyId }) => {
      const response = await backend.delete(`/api/properties/${propertyId}/notes/${noteId}`);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getPropertyNotes', sessionManager.getSession()?.agent_id, param.propertyId]);
      },
    },
  );

  const UpdatePropertyNote = useMutation<PropertyNote, any, {
    propertyId: string,
    noteId: string,
    data: Partial<PropertyNote>
  }>(
    async ({ noteId, propertyId, data }) => {
      const response = await backend.patch(`/api/properties/${propertyId}/notes/${noteId}`, data);
      return response;
    }, {
      onSuccess: (_, param) => {
        queryClient.refetchQueries(['getPropertyNotes', sessionManager.getSession()?.agent_id, param.propertyId]);
      },
    },
  );

  return {
    getPropertyVisits: GetPropertyVisits,
    createPropertyByUrl: CreatePropertyByUrl,
    createProperty: CreateProperty,
    getProperties: GetProperties,
    getProperty: GetProperty,
    deleteProperty: DeleteProperty,
    createPropertyNotes: CreatePropertyNotes,
    updatePropertyNote: UpdatePropertyNote,
    getPropertyNotes: GetPropertyNotes,
    deletePropertyNote: DeletePropertyNote,
    updateProperty: UpdateProperty,
    uploadPropertyImage: UploadPropertyImage,
    updatePropertyImage: UpdatePropertyImage,
    deletePropertyImage: DeletePropertyImage,
    getPropertyVisitSlot: GetPropertyVisitSlot,
  };
}
