import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { Agency } from 'types/Agency';
import PhoneNumberInputWrapper from 'lib/form/PhoneNumberInputWrapper';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { NotificationService } from 'lib/notification';
import { mailRegex } from 'lib/form/FormUtils';
import {
  Checkbox, DialogActions, DialogContent, DialogTitle,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from '@material-ui/core';
import sessionManager from 'services/sessionManager';
import { permissionsEnum } from 'types/Permission';
import { Settings } from '@material-ui/icons';
import Button from 'theme/Button';
import AgencyNotificationList from 'pages/agency-app/parameters/profilParameters/AgencyNotificationList';
import Image from 'theme/Image';
import ImageUpload from 'lib/ImageUpload';
import { FileUpload } from 'types/FileUpload';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  agency?: Agency;
};

export default function AgencyParameteresForm({ agency }: Props) {
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const agencyQueries = useAgencyBackend();
  const { updateAgency, uploadAgencyImage } = agencyQueries;

  const [showUploadModal, setShowUploadModal] = useState(false);
  const uploadImages = (files: FileUpload[]) => {
    if (agency && files.length === 1) {
      setSubmitting(true);
      uploadAgencyImage.mutateAsync({ agencyId: agency.id, image: files[0] })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
          setShowUploadModal(false);
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<Agency>({
    defaultValues: {
      ...agency,
      shouldForwardCall: undefined,
      ringDuration: undefined,
    },
  });

  const formField = watch();

  const onSubmit: SubmitHandler<Agency> = (formData: Agency) => {
    if (agency) {
      setSubmitting(true);
      updateAgency.mutateAsync({
        agency: {
          ...formData,
          phoneNumber: formData.phoneNumber || '',
        },
        agencyId: agency.id,
      })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const isAdmin = sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENT_ADMIN);
  if (!agency) {
    return (
      <div>Loading...</div>
    );
  }
  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="agency-paramters-form">
            <div>
              <div className="rounded-image">
                <Image alt="agent" src={agency?.imageURL} />
                <div className="upload-button-container">
                  <ImageUpload
                    maxFiles={1}
                    submitting={submitting}
                    onValidate={uploadImages}
                    show={showUploadModal}
                    setShow={setShowUploadModal}
                  />
                </div>
              </div>
            </div>
            <div>
              <Controller
                name="name"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    label={Messages.t('field.agencyName')}
                  />
                )}
              />
              <div className="form-row">
                <Controller
                  name="phoneNumber"
                  rules={{
                    required: agency.shouldForwardCall,
                  }}
                  control={control}
                  render={(controller) => (
                    <PhoneNumberInputWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('field.phoneNumber')}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    pattern: {
                      value: mailRegex,
                      message: Messages.t('form.error.email'),
                    },
                  }}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      type="text"
                      control={controller}
                      label={Messages.t('field.email')}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <h4>{Messages.t('parameters.notification')}</h4>
          {
            formField.email && isAdmin && (
              <div className="material-textfield-wrapper">
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        value={formField.email === formField.notificationMail}
                        checked={formField.email === formField.notificationMail}
                        onClick={() => setValue('notificationMail', formField.email === formField.notificationMail ? '' : formField.email)}
                      />
                    )}
                    label={Messages.t('field.SameNotificationMail', { mail: formField.email })}
                  />
                </FormGroup>
              </div>
            )
          }
          <div className="form-row">
            <Tooltip
              title={isAdmin ? Messages.t('parameters.notificationTooltip') : Messages.t('parameters.notificationTooltipNotAdmin')}
            >
              <div>
                <Controller
                  name="notificationMail"
                  control={control}
                  rules={{
                    pattern: {
                      value: mailRegex,
                      message: Messages.t('form.error.email'),
                    },
                  }}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      disabled={!isAdmin}
                      type="text"
                      control={controller}
                      label={Messages.t('field.notificationMail')}
                    />
                  )}
                />
              </div>
            </Tooltip>
            {
              isAdmin && (
                <button type="button" className="settings-icon-btn">
                  <Settings onClick={() => setShowNotificationModal(true)} fontSize="large" />
                </button>
              )
            }
          </div>
          <SpinButton
            editing
            spin={submitting}
            title={Messages.t('formButton.confirm')}
          />
        </form>
      </div>
      {agency && (
        <DialogWrapper open={showNotificationModal} onClose={() => setShowNotificationModal(false)}>
          <DialogTitle>{Messages.t('parameters.notificationAgencyPreferences')}</DialogTitle>
          <DialogContent>
            <AgencyNotificationList agency={agency} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowNotificationModal(false)}>
              {Messages.t('formButton.close')}
            </Button>
          </DialogActions>
        </DialogWrapper>
      )}
    </>
  );
}
