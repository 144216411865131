import React, { useEffect, useState } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { AgencyStep, MANDATORY_STEPS } from 'types/AgencyStep';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { Agency } from 'types/Agency';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Delete, DragIndicator } from '@material-ui/icons';
import { NotificationService } from 'lib/notification';

type Props = {
  stepsProps: AgencyStep[]
  agency: Agency,
};

export default function StepParameters({ agency, stepsProps }: Props) {
  const [steps, setSteps] = useState(stepsProps);
  const agenciesQueries = useAgencyBackend();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setSteps(stepsProps);
  }, [stepsProps.length]);

  const {
    updateAgencySteps,
    deleteAgencyStep,
  } = agenciesQueries;

  const updateSteps = () => {
    setSubmitting(true);
    updateAgencySteps.mutateAsync({
      agencyId: agency.id,
      steps,
    })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const deleteStep = (stepId: string) => {
    setSubmitting(true);
    deleteAgencyStep.mutateAsync({
      agencyId: agency.id,
      stepId,
    })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.delete'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <ReactSortable
        className="sortable-list"
        group="shared"
        list={steps}
        animation={200}
        setList={(newList) => setSteps(newList.map((step, index) => ({ ...step, order: index })))}
      >
        {
          steps.map((step) => (
            <div key={step.id} className="step-parameter-container">
              <DragIndicator />
              <div className="material-textfield-wrapper">
                <TextField
                  onChange={(e) => {
                    step.stepName = e.target.value;
                  }}
                  defaultValue={step.stepName}
                  fullWidth
                  size="medium"
                  disabled={submitting}
                  variant="filled"
                  type="text"
                  label={Messages.t('field.step')}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: !MANDATORY_STEPS.includes(step.stepKey) && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => deleteStep(step.id)}
                          edge="end"
                        >
                          <Delete />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          ))
        }
      </ReactSortable>
      <Button
        disabled={submitting}
        onClick={updateSteps}
      >
        {Messages.t('formButton.update')}
      </Button>
    </div>
  );
}
