import React from 'react';
import { DocumentData } from 'types/DocumentData';
import FilePreview from 'pages/common/FilePreview';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import DateUtils from 'services/DateUtils';
import Messages from 'services/i18n/Messages';

type Props = {
  document: DocumentData,
  onDelete: () => void,
};

export default function DocumentDataPreview({ document, onDelete }: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      {
        document.link
        && (
          <div className="document-preview-container">
            <FilePreview src={document.link} onDelete={onDelete} />
          </div>
        )
      }
      {
        document.comment
        && (
          <div className="comment-container">
            <div className="comment">
              <div className="comment-header">
                <div>{Messages.t('document.missing.explain')}</div>
                <div
                  className="comment-date"
                >
                  {DateUtils.getPrettyDateDeltaFromNow(document.createdAt, false, true)}
                </div>
              </div>
              <div className="comment-body">
                <div className="comment-message">
                  {document.comment}
                </div>
                <IconButton onClick={handleClick}>
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      onDelete();
                      handleClose();
                    }}
                  >
                    {Messages.t('formButton.delete')}
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}
