import React from 'react';
import {
  FormControl, FormHelperText,
  InputLabel, ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { PropertyDetailled } from 'types/Property';
import Messages from 'services/i18n/Messages';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import ListingCard from 'pages/common/ListingCard';

type Props = {
  properties: PropertyDetailled[],
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  }, // TODO Better type
  label?: string,
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  onChange?: (value: string) => void
  multiple?: boolean,
  requierd?: boolean,
  disableFullwidth?: boolean,
  showOffline?: boolean,
  showEveryButton?: boolean,
  shortDisplay?: boolean,
  disabled?: boolean,
};

export default function PropertiesSelector(
  {
    properties,
    control,
    label,
    error,
    apiErrors,
    requierd,
    onChange,
    multiple,
    disableFullwidth,
    showOffline,
    showEveryButton,
    shortDisplay,
    disabled,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
  return (
    <div className={`material-select-wrapper ${disableFullwidth ? 'no-full-widht' : ''}`}>
      <FormControl
        variant="filled"
        fullWidth={!disableFullwidth}
        error={!!errorMessage}
      >
        <InputLabel>{label}{requierd ? ' *' : ''}</InputLabel>
        <Select
          {...field}
          disabled={disabled}
          value={multiple ? field.value?.split(',').filter((v) => v !== '') || [] : field.value || ''}
          onChange={(e) => {
            let { value } = e.target;
            if (value === 'action_reserved_long_name') {
              return;
            }
            if (multiple) {
              value = value.join(',');
            }
            if (onChange) {
              onChange(value);
            }
            field.onChange(value);
          }}
          label={`${label}${requierd ? ' *' : ''}`}
          renderValue={
            shortDisplay
              ? (propertyId: any) => properties
                .filter((property) => property.id === propertyId)[0].identifier
              : (propertyId: any) => properties.filter((property) => property.id === propertyId)
                .map((property) => (
                  <ListingCard
                    key={property.id}
                    property={property}
                    multiple={multiple}
                    checked={field.value?.includes(property.id)}
                  />
                ))
          }
          disableUnderline
        >
          <MenuItem
            key=""
            value=""
          >
            <ListItemText>
              {
                showEveryButton
                  ? Messages.t('generics.every')
                  : Messages.t('generics.none')
              }
            </ListItemText>
          </MenuItem>
          {
            (showOffline ? properties : properties.filter((property) => property.online))
              .sort((a, b) => (b.lastPublishAt || '').localeCompare(a.lastPublishAt || ''))
              .map((property) => (
                <MenuItem
                  key={property.id}
                  value={property.id}
                >
                  <ListingCard
                    property={property}
                    showButton
                    multiple={multiple}
                    checked={field.value?.includes(property.id)}
                  />
                </MenuItem>
              ))
          }
        </Select>
        {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
}
