import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SelectWrapper from 'lib/form/SelectWrapper';
import { Search } from '@material-ui/icons';
import { AgentDetailled } from 'types/Agent';
import { CandidatureFilters } from 'types/filters/CandidatureFilters';
import PropertiesFilter from 'pages/common/PropertiesFilter';
import { Property } from 'types/Property';
import eventFilterService from 'services/filters/EventFilterService';
import { useHistory, useLocation } from 'react-router-dom';
import AgentUtils from 'services/AgentUtils';
import { Routes } from 'routes/RoutesUtils';
import FiltersUtils from 'services/filters/FiltersUtils';

type Props = {
  properties: Property[];
  agents: AgentDetailled[]
};

export default function Filters({ properties, agents }: Props) {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<CandidatureFilters>({
    defaultValues: {
      ...eventFilterService.getFilter(),
      ...FiltersUtils.getObjectFromQueryParam<CandidatureFilters>({
        properties: [],
        search: '',
        agent: '',
      }, queryParams),
    },
  });

  const formField = watch();

  useEffect(() => {
    eventFilterService.updateFilters(formField);
    history.replace(Routes.updatePathWithQuery(
      location,
      FiltersUtils.getQueryParamsFromObject(formField),
    ));
  }, [formField.agent, formField.properties, formField.search]);

  return (
    <form className="filter-header">
      <Controller
        name="search"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={{}}
            error={errors}
            prefix={<Search />}
            control={controller}
            type="text"
            label={Messages.t('field.search')}
          />
        )}
      />
      {
        properties && (
          <Controller
            name="properties"
            control={control}
            render={(controller) => (
              <PropertiesFilter
                showOffline
                apiErrors={{}}
                error={errors}
                showEveryButton
                showNone
                multiple
                control={controller}
                shortDisplay
                label={Messages.t('field.property')}
                properties={properties}
              />
            )}
          />
        )
      }
      {
        agents && (
          <div className="agent-select">
            <Controller
              name="agent"
              control={control}
              render={(controller) => (
                <SelectWrapper
                  error={{}}
                  multiple
                  canSelectAll
                  control={controller}
                  label={Messages.t('field.agent')}
                  values={agents.map((agent) => ({
                    key: agent.id,
                    label: AgentUtils.getPrettyName(agent),
                  }))}
                />
              )}
            />
          </div>
        )
      }
    </form>
  );
}
