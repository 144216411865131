// eslint-disable-next-line import/no-cycle
import backend from 'network/BackendFetchAdapter';
import { Token } from 'types/security/token';
import { LogInFormType } from 'types/forms/LogInFormType';

export const authentificationApi = {
  logIn: (logInData: LogInFormType): Promise<Token> => backend.post('/api/authentification/login', logInData),
  getTokenFromFirebase: (token: string): Promise<Token> => backend.post('/api/authentification/firebase', { token }),
  refreshToken: (token: string): Promise<Token> => backend.post('/api/authentification/refresh', { token }),
  recoverToken: (logInData: LogInFormType): Promise<void> => backend.post('/api/authentification/recover', logInData),
  logInToAgent: (agentId: string): Promise<Token> => backend.post(`/api/authentification/agents/${agentId}`),
};
