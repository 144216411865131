import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  when: (newLocation: string) => boolean,
  whenPageClose: boolean
};

export function RouterPrompt({ when, whenPageClose }: Props) {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const unblockRef = useRef<() => void>();

  useEffect(() => {
    window.onbeforeunload = confirmExit;

    function confirmExit() {
      return whenPageClose ? 'show warning' : undefined;
    }
  }, [whenPageClose]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    unblockRef.current = history.block((prompt) => {
      if (when(prompt.pathname)) {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      }
    });
    return () => {
      if (unblockRef && unblockRef.current) {
        unblockRef.current();
      }
    };
  }, [history, when]);

  const handleOK = () => {
    if (unblockRef && unblockRef.current) {
      unblockRef.current();
    }
    history.push(currentPath);
    setShowPrompt(false);
  };

  const handleCancel = () => {
    setShowPrompt(false);
  };

  return showPrompt ? (
    <DialogWrapper open onClose={handleCancel}>
      <DialogTitle>{Messages.t('backButton.confirmLeaving.title')}</DialogTitle>
      <DialogContent>
        {Messages.t('backButton.confirmLeaving.message')}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{Messages.t('formButton.cancel')}</Button>
        <Button color="error" onClick={handleOK}>
          {Messages.t('backButton.confirmLeaving.action')}
        </Button>
      </DialogActions>
    </DialogWrapper>
  ) : null;
}
