import { GliType } from 'types/ProfesionalSituation';

export type ContractType = 'CDD' | 'CDI' | 'Alternant' | 'Interimaire' | 'Intermittent';

export type ContractTypeEnum = {
  [key in ContractType]: key
};

export const contractTypeTypeEnum: ContractTypeEnum = {
  CDD: 'CDD',
  CDI: 'CDI',
  Alternant: 'Alternant',
  Interimaire: 'Interimaire',
  Intermittent: 'Intermittent',
};

export interface GliContract {
  name: GliType,
  activated: boolean,
  id: string,
}

export interface GliContracts {
  contractTypes: GliContract[]
}
