import React, { useState } from 'react';
import { CandidatureDetailled } from 'types/Candidature';
import CandidatureUtils from 'services/CandidatureUtils';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import {
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import { CandidaturePartial, DocumentStatusType, documentStatusTypeEnum } from 'types/CandidatureBase';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import DocumentModalForm from 'pages/agency-app/candidates/candidateDetails/DocumentModalForm';
import { DocumentDataMap, DocumentMail, mailTypeEnum } from 'types/DocumentData';
import { NotificationService } from 'lib/notification';
import DateUtils from 'services/DateUtils';
import confirmationService from 'services/ConfirmationService';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  candidature: CandidatureDetailled,
  documentDataMap: DocumentDataMap,
};

export default function DocumentCard({ candidature, documentDataMap }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState<DocumentStatusType | undefined>();

  const candidatureQueries = useCandidatureBackend();
  const { updateCandidature } = candidatureQueries;

  const submitCandidature = (data: CandidaturePartial) => {
    setSubmitting(true);
    updateCandidature.mutateAsync(data)
      .then(() => {
        setSelectedStatus(undefined);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const candidatesQueries = useCandidatureBackend();
  const { sendDocumentMail } = candidatesQueries;

  const sendMail = (
    data: DocumentMail,
    newStatus: DocumentStatusType,
  ) => {
    setSubmitting(true);
    sendDocumentMail.mutateAsync({ data, candidatureId: candidature.id })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.documentMailSend'));
        submitCandidature({
          id: candidature.id,
          documentsStatus: newStatus,
          documentsStatusLastUpdatedAt: (new Date()).toISOString(),
        });
      })
      .catch(() => {
        NotificationService.notifyError(Messages.t('notifications.error'));
        setSubmitting(false);
      });
  };

  const sendReminder = async () => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('candidate.askDocuments'),
      message: Messages.t('documents.sendReminder'),
    });
    if (!isConfirmed) {
      return;
    }
    sendMail({
      mailType: mailTypeEnum.REMINDER,
    }, documentStatusTypeEnum.REMINDED);
  };

  const validateDocuments = async () => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('documents.validate.title'),
      message: Messages.t('documents.validate.text'),
    });
    if (!isConfirmed) {
      return;
    }
    submitCandidature({
      id: candidature.id,
      documentsStatus: documentStatusTypeEnum.VALIDATED,
      documentsStatusLastUpdatedAt: (new Date()).toISOString(),
    });
  };

  const documentsCompletion = CandidatureUtils.computeCompletion(
    candidature.documentGroups,
    documentDataMap,
  );

  return (
    <div className="document-card-container">
      {
        selectedStatus && (
          <DialogWrapper open={!!selectedStatus} onClose={() => setSelectedStatus(undefined)}>
            <DialogTitle>{Messages.t('candidate.askDocuments')}</DialogTitle>
            <DialogContent>
              <DocumentModalForm
                submitting={submitting}
                mailTypePossibilies={
                  selectedStatus === documentStatusTypeEnum.MISSING ? [
                    mailTypeEnum.COMPLETE_CASE,
                  ] : [
                    mailTypeEnum.BEFORE_VISIT,
                    mailTypeEnum.AFTER_VIST,
                  ]
                }
                sendMail={(mailData) => sendMail(
                  mailData,
                  selectedStatus || documentStatusTypeEnum.ASKED,
                )}
              />
            </DialogContent>
          </DialogWrapper>
        )
      }
      <h3>
        {Messages.t('field.document')}
      </h3>
      <div className="document-card">
        <div className="document-card-header">
          <div className="documents-status">
            {Messages.t(`documents.status.${candidature.documentsStatus || documentStatusTypeEnum.NOT_ASKED}`)}
            <span>
              {
                Messages.t(
                  `documents.subtitle.${candidature.documentsStatus || documentStatusTypeEnum.NOT_ASKED}`,
                  {
                    delta: DateUtils.getPrettyDateDeltaFromNow(
                      candidature.documentsStatusLastUpdatedAt,
                    ),
                  },
                )
              }
            </span>
          </div>
          <div>
            {
              `${documentsCompletion} %`
            }
          </div>
        </div>
        <LinearProgress variant="determinate" value={documentsCompletion} />
        <div className="document-card-actions">
          {
            (!candidature.documentsStatus
              || candidature.documentsStatus === documentStatusTypeEnum.NOT_ASKED) && (
              <Button
                onClick={() => setSelectedStatus(documentStatusTypeEnum.ASKED)}
                disabled={submitting}
              >
                {Messages.t('documents.ask')}
              </Button>
            )
          }
          {
            (candidature.documentsStatus === documentStatusTypeEnum.ASKED
              || candidature.documentsStatus === documentStatusTypeEnum.REMINDED) && (
              <Button onClick={sendReminder} disabled={submitting}>
                {Messages.t('documents.reminder')}
              </Button>
            )
          }
          {
            (candidature.documentsStatus === documentStatusTypeEnum.MISSING
              || candidature.documentsStatus === documentStatusTypeEnum.SENT
              || candidature.documentsStatus === documentStatusTypeEnum.VALIDATED) && (
              <Button
                onClick={() => setSelectedStatus(documentStatusTypeEnum.MISSING)}
                disabled={submitting}
              >
                {Messages.t('documents.missing')}
              </Button>
            )
          }
          {
            (candidature.documentsStatus
              && candidature.documentsStatus !== documentStatusTypeEnum.NOT_ASKED
              && candidature.documentsStatus !== documentStatusTypeEnum.VALIDATED) && (
              <Button onClick={validateDocuments} disabled={submitting}>
                {Messages.t('documents.validate')}
              </Button>
            )
          }
        </div>
      </div>
    </div>
  );
}
