import React, { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';

import { NotificationService } from 'lib/notification';
import { VisitSlotDetailed, VisitSlotFormType } from 'types/VisitSlot';
import { useVisitSlotBackend } from 'network/queries/VisitSlotQueries';
import { Property } from 'types/Property';
import { Agent } from 'types/Agent';
import PropertiesSelector from 'pages/common/PropertySelector';
import SelectWrapper from 'lib/form/SelectWrapper';
import AgentUtils from 'services/AgentUtils';
import AddressAutocompleteWrapper from 'lib/form/AddressAutocompleteWrapper';
import DateTimeRangePickerWrapper from 'lib/form/DateTimeRangePickerWrapper';
import { addHours, isAfter } from 'date-fns';
import VisitSlotReservation from 'pages/common/visitSlots/VisitSlotReservation';
import { CandidatureShort } from 'types/Candidature';
import VisitSlotUtils from 'services/VisitSlotUtils';
import NameWithInitials from 'pages/common/NameWithInitials';
import { DialogTitle } from '@material-ui/core';
import AgentCalendar from 'pages/common/calendar/AgentCalendar';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import PremuimModal from 'pages/common/PremuimModal';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
// The component is recursive
// eslint-disable-next-line import/no-cycle
import PropertyVisitSlotList from 'pages/common/visitSlots/PropertyVisitSlotList';
import { Link } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, PROPERTY_DETAIL } from 'routes/Routes';
import { OpenInNew } from '@material-ui/icons';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  properties: Property[],
  agents: Agent[],
  candidatures: CandidatureShort[],
  visitSlot?: VisitSlotDetailed,
  defaultPropertyId?: string,
  onSubmitted: () => void,
};

export default function VisitSlotForm(
  {
    properties,
    agents,
    visitSlot,
    candidatures,
    defaultPropertyId,
    onSubmitted,
  }: Props,
) {
  const [apiErrors, setApiErrors] = useState({});
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [showPropertySlotList, setShowPropertySlotList] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [usePropertyAddress, setUsePropertyAddress] = useState(true);
  const agencyQueries = useAgencyBackend();
  const { getCurrentAgency } = agencyQueries;
  const { data: agency } = getCurrentAgency();

  const visitSlotQueries = useVisitSlotBackend();
  const { createVisitSlot, updateVisitSlot } = visitSlotQueries;
  const defaultProperty: Property | undefined = useMemo(() => properties
    .filter((property) => property.id === defaultPropertyId)[0], [defaultPropertyId]);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {
      errors,
      dirtyFields,
      isDirty,
    },
  } = useForm<VisitSlotFormType>({
    defaultValues: {
      propertyID: defaultProperty?.id,
      address: defaultProperty?.address,
      agentID: defaultProperty?.agentID,
      ...visitSlot,
      visitDurationMinutes: visitSlot?.visitDurationMinutes?.toString() || '15',
      maxVisitors: visitSlot?.maxVisitors?.toString() || '2',
    },
  });
  const now = new Date();

  const formField = watch();

  const propertiesQueries = usePropertiesBackend();
  const { getPropertyVisitSlot } = propertiesQueries;
  const {
    data: selectedPropertyVisitSlot,
  } = getPropertyVisitSlot(formField.propertyID, !!formField.propertyID);

  const onSubmit: SubmitHandler<VisitSlotFormType> = (formData: VisitSlotFormType) => {
    if (!isDirty) {
      return;
    }
    if (agency
      && !agency.plan.visitSlotPlan
      && (agency.visitSlotCount || 0) >= agency.plan.maxFreeVisitSlot) {
      setShowPremiumModal(true);
      return;
    }
    setSubmitting(true);
    if (visitSlot) {
      const data = {
        ...formData,
        visitDurationMinutes: parseInt(formData.visitDurationMinutes, 10),
        maxVisitors: parseInt(formData.maxVisitors, 10),
      };
      Object.keys(data).forEach((key) => {
        if (!dirtyFields[key]) {
          delete data[key];
        }
      });
      updateVisitSlot.mutateAsync({
        visitSlotId: visitSlot.id,
        data,
      }).then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        if (onSubmitted) {
          onSubmitted();
        }
      })
        .catch((error) => {
          const { status } = error;
          if (status === 500) {
            NotificationService.notifyError(Messages.t('notifications.error'));
          }
          if (error.json_response?.errorMessage) {
            // @ts-ignore
            NotificationService.notifyError(Messages.t(`field.error.${error.json_response.errorMessage[0]}`));
          }
          setApiErrors(error.json_response);
        })
        .finally(() => {
          setSubmitting(false);
        });
      return;
    }
    createVisitSlot.mutateAsync({
      ...formData,
      visitDurationMinutes: parseInt(formData.visitDurationMinutes, 10),
      maxVisitors: parseInt(formData.maxVisitors, 10),
    })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.visitSlotCreate'));
        if (onSubmitted) {
          onSubmitted();
        }
      })
      .catch((error) => {
        const { status } = error;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('notifications.error'));
        }
        if (error.json_response.errorMessage) {
          // @ts-ignore
          NotificationService.notifyError(Messages.t(`field.error.${error.json_response.errorMessage[0]}`));
        }
        setApiErrors(error.json_response);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const visitNumber = VisitSlotUtils.getPossibleVisitNumber(formField);

  const filteredVisitSlotFuture = selectedPropertyVisitSlot?.filter(
    (visitSlotData) => visitSlotData.id !== visitSlot?.id
      && isAfter(new Date(visitSlotData.startDate), now),
  );

  const propertySelected = properties
    .filter((property) => property.id === formField.propertyID)[0];

  return (
    <>
      {
        agency && (
          <PremuimModal
            agency={agency}
            open={showPremiumModal}
            onClose={() => setShowPremiumModal(false)}
          />
        )
      }
      {
        showPropertySlotList && (
          <DialogWrapper
            open={showPropertySlotList}
            onClose={() => setShowPropertySlotList(false)}
          >
            <div className="property-visit-slot-list">
              {
                filteredVisitSlotFuture && agents && candidatures && (
                  <PropertyVisitSlotList
                    property={
                      properties
                        .filter((property) => property.id === formField.propertyID)[0]
                    }
                    visitSlots={filteredVisitSlotFuture}
                    agents={agents}
                    candidature={candidatures}
                  />
                )
              }
            </div>
          </DialogWrapper>
        )
      }
      <div>
        <DialogTitle>{Messages.t('visitSlot.form.title')}</DialogTitle>
        {
          !visitSlot && filteredVisitSlotFuture && filteredVisitSlotFuture.length > 0 && (
            <div className="visit-slot-informations-container">
              <div className="information">
                {
                  Messages.t('visitSlot.sameProperty')
                }
                <button
                  type="button"
                  onClick={() => setShowPropertySlotList(true)}
                >
                  {Messages.t('visitSlot.sameProperty.show')}
                </button>
              </div>
            </div>
          )
        }
        <form
          className="visit-slot-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="property-select">
            {formField.propertyID && (
              <Link
                to={Routes.withPath(
                  PROPERTY_DETAIL,
                  [{ label: ID, value: formField.propertyID }],
                )}
                target="_blank"
              >
                <div>
                  <p>{Messages.t('property.seeListing')}</p>
                  <OpenInNew />
                </div>
              </Link>
            )}
            <Controller
              name="propertyID"
              control={control}
              rules={{
                required: true,
              }}
              render={(controller) => (
                <PropertiesSelector
                  showOffline
                  disabled={!!defaultPropertyId || !!visitSlot}
                  apiErrors={apiErrors}
                  error={errors}
                  onChange={(propertyId) => {
                    if (!formField.address || !formField.agentID) {
                      const selectedProperty = properties
                        .filter((property) => property.id === propertyId)[0];
                      if (usePropertyAddress) {
                        setValue('address', selectedProperty.address || '');
                      }
                      if (!formField.agentID) {
                        setValue('agentID', selectedProperty.agentID || '');
                      }
                    }
                  }}
                  control={controller}
                  label={Messages.t('field.property')}
                  properties={properties}
                />
              )}
            />
          </div>
          <div className="form-row">
            <Controller
              name="startDate"
              rules={{
                required: true,
                validate: (date) => {
                  if (new Date(date) < new Date()) {
                    return Messages.t('field.error.mustBeFuture');
                  }
                  return undefined;
                },
              }}
              control={control}
              render={(controller) => (
                <DateTimeRangePickerWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  defaultDateMonth={formField.endDate ? new Date(formField.endDate) : undefined}
                  disablePast
                  onChange={(newDate) => {
                    if (!formField.endDate) {
                      const endDate = addHours(newDate, 1);
                      setValue('endDate', endDate.toISOString());
                    }
                  }}
                  control={controller}
                  label={Messages.t('field.startDate')}
                />
              )}
            />
            <Controller
              name="endDate"
              rules={{
                required: true,
                validate: (date) => {
                  if (new Date(date) < new Date()) {
                    return Messages.t('field.error.mustBeFuture');
                  }
                  if (new Date(date) < new Date(formField.startDate)) {
                    return Messages.t('field.error.mustBeAfterStartDate');
                  }
                  return undefined;
                },
              }}
              control={control}
              render={(controller) => (
                <DateTimeRangePickerWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  disablePast
                  defaultDateMonth={formField.startDate ? new Date(formField.startDate) : undefined}
                  disableBefore={formField.startDate ? new Date(formField.startDate) : undefined}
                  control={controller}
                  label={Messages.t('field.endDate')}
                />
              )}
            />
          </div>
          <div className="form-row">
            <Controller
              name="visitDurationMinutes"
              rules={{
                required: true,
              }}
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="number"
                  control={controller}
                  label={Messages.t('field.visitDurationMinutes')}
                />
              )}
            />
            <Controller
              name="maxVisitors"
              rules={{
                required: true,
              }}
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  type="number"
                  control={controller}
                  label={Messages.t('field.maxVisitors')}
                />
              )}
            />
          </div>
          {
            visitNumber && Number(visitNumber) > 0 && (
              <div className="information">
                {
                  Messages.t('visits.proposed', {
                    number: visitNumber,
                  })
                }
              </div>
            )
          }
          <Controller
            name="agentID"
            rules={{
              required: true,
            }}
            control={control}
            render={(controller) => (
              <SelectWrapper
                error={errors}
                apiErrors={apiErrors}
                control={controller}
                label={Messages.t('field.agentSelected')}
                values={
                  agents.map((agent) => ({
                    key: agent.id,
                    label: <NameWithInitials
                      imageUrl={agent.imageURL}
                      color="secondary"
                      name={AgentUtils.getPrettyName(agent)}
                    />,
                  }))
                }
              />
            )}
          />
          <Controller
            name="address"
            rules={{
              required: true,
            }}
            control={control}
            render={(controller) => (
              <AddressAutocompleteWrapper
                error={errors}
                hideManualMode
                contextualActionChecked={usePropertyAddress}
                contextualActionLabel={propertySelected?.address && Messages.t('field.propertyAddress')}
                contextualAction={(checked) => {
                  setUsePropertyAddress(checked);
                  if (checked && formField.propertyID) {
                    const selectedProperty = properties
                      .filter((property) => property.id === formField.propertyID)[0];
                    setValue('address', selectedProperty.address || '');
                  }
                }}
                onChange={() => {
                  setUsePropertyAddress(false);
                }}
                apiErrors={apiErrors}
                control={controller}
                label={Messages.t('field.visitsAddress')}
              />
            )}
          />
          {
            visitSlot && (
              <VisitSlotReservation
                candidatures={candidatures}
                visitSlot={visitSlot}
              />
            )
          }
          <Controller
            name="notes"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                error={errors}
                apiErrors={apiErrors}
                type="textarea"
                control={controller}
                label={Messages.t('field.noteToGuests')}
              />
            )}
          />
          <SpinButton
            editing
            disabled={!isDirty}
            spin={submitting}
            title={Messages.t('formButton.confirm')}
          />
        </form>
      </div>
      <AgentCalendar agentId={formField.agentID} />
    </>
  );
}
