import React, { MouseEventHandler, ReactNode } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel, ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';

type Props = {
  value?: string,
  label?: string,
  values: { key: string, label: string, icon: ReactNode }[],
  onClick?: MouseEventHandler<HTMLLIElement>
  onChange?: (value: string) => void
  multiple?: boolean,
  requierd?: boolean,
  disableFullwidth?: boolean,
  disabled?: boolean,
  action?: () => void,
  actionLabel?: string
};

export default function Selector(
  {
    value,
    label,
    values,
    onClick,
    requierd,
    onChange,
    multiple,
    disableFullwidth,
    action,
    disabled,
    actionLabel,
  }: Props,
) {
  return (
    <div className={`material-select-wrapper ${disableFullwidth ? 'no-full-widht' : ''}`}>
      <FormControl
        variant="filled"
        fullWidth={!disableFullwidth}
      >
        <InputLabel>{label}{requierd ? ' *' : ''}</InputLabel>
        <Select
          disabled={disabled}
          onChange={(e) => {
            let { value: val } = e.target;
            if (val === 'action_reserved_long_name') {
              return;
            }
            if (multiple) {
              // @ts-ignore
              val = val.join(',');
            }
            if (onChange) {
              // @ts-ignore
              onChange(val);
            }
          }}
          value={multiple ? value?.split(',').filter((v) => v !== '') || [] : value || ''}
          multiple={multiple}
          label={`${label}${requierd ? ' *' : ''}`}
          disableUnderline
        >
          {
            values.map((key) => (
              <MenuItem
                onClick={onClick}
                key={key.label}
                value={key.key}
              >
                {
                  multiple && (
                    <Checkbox checked={value?.includes(key.key)} />
                  )
                }
                <ListItemIcon>{key.icon}</ListItemIcon>
                <ListItemText>{key.label}</ListItemText>
              </MenuItem>
            ))
          }
          {
            action && actionLabel && (
              <MenuItem
                className="select-action"
                onClick={action}
                value="action_reserved_long_name"
                key="action_reserved_long_name"
              >
                <ListItemText>{actionLabel}</ListItemText>
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    </div>
  );
}
