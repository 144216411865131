import Couple from 'assets/images/couple.png';
import Flatsharing from 'assets/images/flatsharing.png';
import Solo from 'assets/images/solo.png';

export type RelationshipType = 'SOLO' | 'COUPLE' | 'FLATSHARING';

type RelationshipTypeEnum = {
  [key in RelationshipType] : key
};

export const relationshipTypeEnum: RelationshipTypeEnum = {
  SOLO: 'SOLO',
  COUPLE: 'COUPLE',
  FLATSHARING: 'FLATSHARING',
};

type PngMappingType = {
  [key in RelationshipType] : string
};

export const pngMapping: PngMappingType = {
  SOLO: Solo,
  COUPLE: Couple,
  FLATSHARING: Flatsharing,
};
