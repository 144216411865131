import React, { useState } from 'react';
import { daysTypeEnum, OpenTimeSlot, Timeslots } from 'types/OpeningDays';
import { Agency } from 'types/Agency';
import { Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import OpenDayRow from 'pages/agency-app/parameters/agencyParameteres/phoneRedirection/OpenDayRow';
import SpinButton from 'theme/SpinButton';
import Messages from 'services/i18n/Messages';
import { NotificationService } from 'lib/notification';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { useForm } from 'react-hook-form';
import DateUtils from 'services/DateUtils';

type Props = {
  agency?: Agency
  timeslots: OpenTimeSlot[]
};

export default function AgencyOpeningHours(
  {
    agency,
    timeslots,
  }: Props,
) {
  const agencyQueries = useAgencyBackend();
  const { updateAgencyTimeslots } = agencyQueries;
  const [editMode, setEditMode] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const getDay = (data, dayEnum) => data?.find((searchedDay) => searchedDay.day === dayEnum);

  const getDefaultValues = (data) => ({
    timeslots: Object.values(daysTypeEnum).map(
      (day) => {
        const timeSlot = getDay(data, day);
        return (
          data?.map((timeslot) => timeslot.day).includes(day) ? (
            ({
              ...timeSlot,
              openingTime: DateUtils.getTimeString(timeSlot.openingTime),
              closingTime: DateUtils.getTimeString(timeSlot.closingTime),
            })
          ) : (
            { day }
          )
        );
      },
    ),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Timeslots>({ defaultValues: getDefaultValues(timeslots) });

  const onSubmit = (formData: Timeslots) => {
    const newFormData = {
      timeslots: formData.timeslots.map((day) => {
        const openingDate = new Date();
        if (day.openingTime) {
          openingDate.setHours(parseInt(day.openingTime.split(':')[0], 10));
          openingDate.setMinutes(parseInt(day.openingTime.split(':')[1], 10));
        }
        const closingDate = new Date();
        if (day.closingTime) {
          closingDate.setHours(parseInt(day.closingTime.split(':')[0], 10));
          closingDate.setMinutes(parseInt(day.closingTime.split(':')[1], 10));
        }
        return (
          {
            ...day,
            openingTime: day.openingTime && openingDate.toISOString(),
            closingTime: day.closingTime && closingDate.toISOString(),
          }
        );
      }),
    };
    if (agency) {
      setSubmitting(true);
      updateAgencyTimeslots.mutateAsync({
        agencyId: agency.id,
        timeslots: newFormData.timeslots,
      })
        .then((updatedTimeslots) => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
          reset(getDefaultValues(updatedTimeslots));
        })
        .catch(() => {
          NotificationService.notifyError(Messages.t('notifications.error'));
        })
        .finally(() => setSubmitting(false));
    }
  };
  const formField = watch();

  return (
    <>
      <div className="agency-opening-hours">
        {!editMode && (
          <div className="edit-button">
            <IconButton onClick={() => setEditMode(true)}>
              <Edit />
            </IconButton>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            agency && formField.timeslots.map((timeSlot, index) => (
              <OpenDayRow
                key={timeSlot.day}
                index={index}
                timeSlot={timeSlot}
                editMode={editMode}
                control={control}
                errors={errors}
              />
            ))
          }
          {editMode && (
            <div className="opening-hours-btns">
              <button
                className="cancel"
                type="button"
                onClick={() => setEditMode(false)}
              >
                {Messages.t('formButton.cancel')}
              </button>
              <SpinButton
                editing
                spin={submitting}
                title={Messages.t('formButton.confirm')}
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
}
