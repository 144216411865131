import React from 'react';
import DateUtils from 'services/DateUtils';
import Messages from 'services/i18n/Messages';
import { differenceInDays, isSameDay, startOfDay } from 'date-fns';
import { EventDetails, RbcEvent, RbcTime } from 'types/ReactBigCalendar';

type Props = {
  event: RbcEvent<EventDetails>
  time: RbcTime<RbcEvent<EventDetails>>,
};

export default function AgentEvent({ event, time }: Props) {
  if (!event.start) {
    return null;
  }
  if (!event.end) {
    return null;
  }
  const start = new Date(event.start);
  const end = new Date(event.end);

  let { title } = event;
  const dayDelta = differenceInDays(startOfDay(end), startOfDay(start));
  const currentDelta = differenceInDays(startOfDay(time.day), startOfDay(start));
  const sameDay = isSameDay(start, end);
  if (!sameDay) {
    title = `${title} (${Messages.t('dates.dayDiff', { current: currentDelta + 1, total: dayDelta + 1 })})`;
  }
  return (
    <div className="agent-event">
      <div className="event-hours">
        {
          !event.allDay && (sameDay || currentDelta === 0 || currentDelta === dayDelta) ? (
            <>
              {!sameDay && currentDelta !== 0 ? '00:00' : DateUtils.getHoursString(start)}
              <span>-</span>
              {!sameDay && currentDelta !== dayDelta ? '00:00' : DateUtils.getHoursString(end)}
            </>
          ) : (
            Messages.t('dates.allDay')
          )
        }
      </div>
      <div className="event-title">
        <div className={`event-pastille ${event.resource?.color}`} />
        <div>
          {title}
        </div>
      </div>
    </div>
  );
}
