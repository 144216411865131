import React, { useState } from 'react';
import {
  ADD,
  AGENDA,
  CANDIDATE_DETAIL_DETAILS,
  ID,
} from 'routes/Routes';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import
{
  Controller, SubmitHandler,
  useFieldArray, UseFormReturn,
} from 'react-hook-form';
import PropertiesSelector from 'pages/common/PropertySelector';
import SpinButton from 'theme/SpinButton';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import { relationshipTypeEnum } from 'types/OccupantRelationship';
import { Delete } from '@material-ui/icons';
import Button from 'theme/Button';
import ContactForm from 'pages/agency-app/candidates/candidateDetails/candidateDetailsForm/ContactForm';
import GarantForm from 'pages/agency-app/candidates/candidateDetails/candidateDetailsForm/GarantForm';
import { NotificationService } from 'lib/notification';
import confirmationService from 'services/ConfirmationService';
import { Garant, GarantFrom } from 'types/Garant';
import { stringToBoolean, stringToNumber } from 'lib/form/FormUtils';
import { ContactCreation, ContactFrom } from 'types/Contact';
import { CandidatureDetailled } from 'types/Candidature';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { CandidatureForm } from 'types/forms/CandidatureForm';
import { Routes } from 'routes/RoutesUtils';
import { CREATE_VISIT, DEFAULT_CANDIDATE } from 'routes/QueryParams';
import { RouterPrompt } from 'pages/common/RouterPrompt';
import { useContactBackend } from 'network/queries/ContactQueries';
import CandidatureUtils from 'services/CandidatureUtils';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  candidature?: CandidatureDetailled;
  form: UseFormReturn<CandidatureForm, object>
};

type Param = {
  id: string,
};

// TODO clean this component functions
export default function CandidatureDetailForm(
  {
    candidature,
    form,
  }: Props,
) {
  const location = useLocation();
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [selectedContact, setSelectedContact] = useState<number | undefined>(0);
  const [selectedGarant, setSelectedGarant] = useState<number | undefined>();
  const [addPropertySelectorModal, setAddPropertySelectorModal] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addRelationshipModal, setAddRelationshipModal] = useState(false);
  const contactQueries = useContactBackend();
  const queryParams = new URLSearchParams(location.search);
  const createVisit = queryParams.get(CREATE_VISIT);
  const { id } = useParams<Param>();

  const candidatureQueries = useCandidatureBackend();
  const { deleteCaseGarant, deleteCaseContact } = candidatureQueries;
  const { updateContact } = candidatureQueries;
  const { createContact } = contactQueries;
  const history = useHistory();
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = form;

  const formField = watch();
  const toGarant = (garant: GarantFrom): Garant => ({
    ...garant,
    amount: stringToNumber(garant.amount) || undefined,
    monthlyIncome: stringToNumber(garant.monthlyIncome) || undefined,
    trialPeriod: stringToBoolean(garant.trialPeriod),
  });

  const toContact = (contact: ContactFrom): ContactCreation => ({
    ...contact,
    monthlyIncome: stringToNumber(contact.monthlyIncome) || undefined,
    trialPeriod: stringToBoolean(contact.trialPeriod),
    gliEgible: stringToBoolean(contact.gliEgible) || undefined,
  });

  const onSubmit: SubmitHandler<CandidatureForm> = (formData: CandidatureForm) => {
    setSubmitting(true);
    const candidatureFormData = {
      ...formData,
      garants: formData.garants?.map((garant, index) => ({
        ...toGarant(garant),
        order: index + 1,
      })),
      contacts: formData.contacts?.map((contact, index) => ({
        ...toContact(contact),
        order: index + 1,
      })),
    };

    if (candidature) {
      updateContact.mutateAsync({
        contacts: candidatureFormData,
        candidatureId: candidature?.id,
      })
        .then((candidatureRes) => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
          reset(CandidatureUtils.getCandidatureFormDefaultValue(candidatureRes));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      createContact.mutateAsync(candidatureFormData)
        .then((candidatureRes) => {
          NotificationService.notifySuccess(Messages.t('notifications.contactCreate'));
          reset(CandidatureUtils.getCandidatureFormDefaultValue(candidatureRes));
          if (createVisit) {
            history.push(Routes.updateUrlWithQuery(AGENDA, [{
              label: DEFAULT_CANDIDATE,
              value: candidatureRes.id,
            }]));
          } else {
            history.push(Routes.withPath(CANDIDATE_DETAIL_DETAILS, [{
              label: ID,
              value: candidatureRes.id,
            }]));
          }
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const { fields: fieldContacts, append: appendContacts, remove: removeContact } = useFieldArray({
    control,
    name: 'contacts',
  });

  const propertiesQueries = usePropertiesBackend();
  const { getProperties } = propertiesQueries;
  const { data: propertyList } = getProperties();

  const { fields: fieldGarant, append: appendGarand, remove: removeGarant } = useFieldArray({
    control,
    name: 'garants',
  });
  const deleteGarant = (index: number, garantId: string, candidatureId: string) => {
    setSubmitting(true);
    deleteCaseGarant.mutateAsync({ candidatureId, garantId })
      .then((candidatureRes) => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        reset(CandidatureUtils.getCandidatureFormDefaultValue(candidatureRes));
        if (formField.garants.length === 1) {
          setSelectedGarant(undefined);
          setSelectedContact(0);
        } else if (index >= 1) {
          setSelectedGarant(index - 1);
        }
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };
  const confirmDeleteGarant = async (
    index: number,
    garantId: string,
    candidatureId: string,
    savedGarant: boolean,
  ) => {
    const isConfirmed = await confirmationService.confirm({
      actionColor: 'error',
      title: Messages.t('confirmation.deleteGarant.title'),
      message: Messages.t('confirmation.deleteGarant.message'),
      actionMessage: Messages.t('confirmation.deleteGarant.action'),
    });
    if (isConfirmed && savedGarant) {
      deleteGarant(index, garantId, candidatureId);
      removeGarant(index);
    } else if (isConfirmed) {
      removeGarant(index);
    }
  };
  const deleteContact = (index: number, contactId: string, candidatureId: string) => {
    setSubmitting(true);
    deleteCaseContact.mutateAsync({ candidatureId, contactId })
      .then((candidatureRes) => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        reset(CandidatureUtils.getCandidatureFormDefaultValue(candidatureRes));
        setSelectedContact(index - 1);
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };
  const confirmDeleteContact = async (
    index: number,
    contactId: string,
    candidatureId: string,
    savedContact: boolean,
  ) => {
    const isConfirmed = await confirmationService.confirm({
      actionColor: 'error',
      title: Messages.t('confirmation.deleteContact.title'),
      message: Messages.t('confirmation.deleteContact.message'),
      actionMessage: Messages.t('confirmation.deleteContact.action'),
    });
    if (isConfirmed && savedContact) {
      deleteContact(index, contactId, candidatureId);
      removeContact(index);
    } else if (isConfirmed) {
      removeContact(index);
    }
  };
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <form className="signed-form" onSubmit={handleSubmit(onSubmit)}>
      <RouterPrompt
        whenPageClose={isDirty}
        when={(newPathName) => isDirty && !newPathName.startsWith(location.pathname.split('/').slice(0, -1).join('/'))}
      />
      {
        id === ADD && addPropertySelectorModal && (
          <DialogWrapper
            open={addPropertySelectorModal}
            onClose={() => setAddPropertySelectorModal(false)}
          >
            <DialogTitle>{Messages.t('property.chooseProperty')}</DialogTitle>
            <DialogContent>
              {propertyList && (
                <div className="property-select">
                  <Controller
                    name="propertyId"
                    control={control}
                    render={(controller) => (
                      <PropertiesSelector
                        showOffline
                        apiErrors={apiErrors}
                        error={errors}
                        control={controller}
                        label={Messages.t('field.property')}
                        properties={propertyList}
                      />
                    )}
                  />
                  <Button
                    onClick={() => setAddPropertySelectorModal(false)}
                  >
                    {Messages.t('formButton.confirm')}
                  </Button>
                </div>
              )}
            </DialogContent>
          </DialogWrapper>
        )
      }
      <div className="form-row">
        <Controller
          name="moveInWanted"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="text"
              control={controller}
              label={Messages.t('field.moveInWanted')}
            />
          )}
        />
        {
          candidature && candidature.contacts.length > 1 && (
            <Controller
              name="occupantRelationship"
              control={control}
              render={(controller) => (
                <SelectWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  disabled={fieldContacts.length > 2 && true}
                  label={Messages.t('field.occupantsRelationship')}
                  values={Object.values(relationshipTypeEnum)
                    .filter((key) => (
                      key !== relationshipTypeEnum.SOLO
                    ))
                    .map(
                      (key) => ({
                        key, label: Messages.t(`relationship.${key}`),
                      }),
                    )}
                />
              )}
            />
          )
        }
      </div>
      <div className="page-selector-container">
        {
          fieldContacts
            .map((item, index) => (
              <div
                role="presentation"
                onClick={() => {
                  setSelectedContact(index);
                  setSelectedGarant(undefined);
                }}
                key={item.id || formField.contacts?.[index]?.id}
                className={`page-selector ${selectedContact === index ? 'selected' : ''}`}
              >
                <>
                  {Messages.t('field.contact')} {index + 1}
                  {candidature
                    && selectedContact === index
                    && selectedContact > 0
                    && (
                      <IconButton
                        size="small"
                        onClick={candidature.contacts?.[index] ? (
                          () => confirmDeleteContact(
                            index,
                            candidature.contacts[index].id,
                            candidature.id,
                            true,
                          )
                        ) : (
                          () => confirmDeleteContact(index, '', '', false)
                        )}
                      >
                        <Delete fontSize="inherit" />
                      </IconButton>
                    )}
                </>
              </div>
            ))
        }
        {
          fieldGarant
            .map(
              (item, index) => (
                <div
                  role="presentation"
                  onClick={() => {
                    setSelectedGarant(index);
                    setSelectedContact(undefined);
                  }}
                  key={item.id || formField.garants?.[index]?.id}
                  className={`page-selector ${selectedGarant === index ? 'selected' : ''}`}
                >
                  <>
                    {Messages.t('field.garant')} {index + 1}
                    {candidature
                      && selectedGarant === index
                      && (
                        <IconButton
                          size="small"
                          onClick={candidature.garants?.[index] ? (
                            () => confirmDeleteGarant(
                              index,
                              candidature.garants[index].id,
                              candidature.id,
                              true,
                            )
                          ) : (
                            () => confirmDeleteGarant(index, '', '', false)
                          )}
                        >
                          <Delete fontSize="inherit" />
                        </IconButton>
                      )}
                  </>
                </div>
              ),
            )
        }
        <Button
          onClick={handleClick}
          className="page-selector"
        >
          {Messages.t('formButton.add')}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => {
            handleClose();
            appendContacts({});
            setSelectedContact(fieldContacts.length);
            setSelectedGarant(undefined);
            setAddRelationshipModal(true);
          }}
          >
            {Messages.t('field.contact')}
          </MenuItem>
          <MenuItem onClick={() => {
            handleClose();
            appendGarand({});
            setSelectedGarant(fieldGarant.length);
            setSelectedContact(undefined);
          }}
          >
            {Messages.t('field.garant')}
          </MenuItem>
        </Menu>
        {
          addRelationshipModal && formField.contacts.length === 2 && (
            <DialogWrapper
              open={addRelationshipModal}
              onClose={() => setAddRelationshipModal(false)}
            >
              <DialogTitle>{Messages.t('relationship.question')}</DialogTitle>
              <DialogContent>
                <Controller
                  name="occupantRelationship"
                  control={control}
                  render={(controller) => (
                    <SelectWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      label={Messages.t('field.occupantsRelationship')}
                      values={Object.values(relationshipTypeEnum)
                        .filter((key) => (
                          key !== relationshipTypeEnum.SOLO
                        ))
                        .map(
                          (key) => ({
                            key, label: Messages.t(`relationship.${key}`),
                          }),
                        )}
                    />
                  )}
                />
              </DialogContent>
              <DialogActions className="action-buttons">
                <Button
                  onClick={() => {
                    setValue('occupantRelationship', '');
                    setAddRelationshipModal(false);
                  }}
                >
                  {Messages.t('formButton.cancel')}
                </Button>
                <Button onClick={() => setAddRelationshipModal(false)}>
                  {Messages.t('formButton.save')}
                </Button>
              </DialogActions>
            </DialogWrapper>
          )
        }
      </div>
      {
        fieldContacts
          .map((item, index) => (
            <div key={item.id || formField.contacts?.[index]?.id}>
              {
                selectedContact === index && (
                  <ContactForm
                    apiErrors={apiErrors}
                    errors={errors}
                    index={index}
                    control={control}
                    formField={formField}
                  />
                )
              }
            </div>
          ))
      }
      {
        fieldGarant
          .map((item, index) => (
            <div key={item.id || formField.garants?.[index]?.id}>
              {
                selectedGarant === index && (
                  <GarantForm
                    formField={formField}
                    apiErrors={apiErrors}
                    errors={errors}
                    index={index}
                    control={control}
                  />
                )
              }
            </div>
          ))
      }
      <SpinButton
        editing
        className="submit-button justified-left"
        spin={submitting}
        title={Messages.t('formButton.confirm')}
      />
    </form>
  );
}
