import { DocumentData, DocumentDataMap, documentTagEnum } from 'types/DocumentData';

export default class DocumentUtils {
  public static getCompletionPercentFromTagList(tagList: string): number {
    const tagListMap = tagList.split(',').reduce((acc, value) => {
      acc[value.trim().toLowerCase()] = value;
      return acc;
    }, {});
    return Math.floor((
      Object.values(documentTagEnum)
        .filter((tag) => tagListMap[tag.trim().toLowerCase()]).length
      / Object.values(documentTagEnum).length) * 100);
  }

  public static getBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  public static getMappedDocuments(document: DocumentData[]): DocumentDataMap {
    return document.reduce((acc, value) => {
      if (!acc[value.documentGroupID]) {
        acc[value.documentGroupID] = {};
      }
      const tags = value.tags.split(',');
      for (let i = 0; i < tags.length; i++) {
        if (!acc[value.documentGroupID][tags[i]]) {
          acc[value.documentGroupID][tags[i]] = [];
        }
        acc[value.documentGroupID][tags[i]].push(value);
      }
      return acc;
    }, {});
  }
}
