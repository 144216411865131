export type SituationType = 'MARRIED' | 'SINGLE' | 'COUPLE' | 'WIDOWER' | 'SPLITED' | 'OTHER';

export type SituationTypeEnum = {
  [key in SituationType]: key
};

export const situationTypeEnum: SituationTypeEnum = {
  MARRIED: 'MARRIED',
  SINGLE: 'SINGLE',
  COUPLE: 'COUPLE',
  WIDOWER: 'WIDOWER',
  OTHER: 'OTHER',
  SPLITED: 'SPLITED',
};
