import React from 'react';
import Messages from 'services/i18n/Messages';
import {
  getAllHours,
  OpenTimeSlot,
  Timeslots,
} from 'types/OpeningDays';
import { Control, Controller } from 'react-hook-form';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

type Props = {
  editMode: boolean
  control: Control<Timeslots>
  timeSlot: OpenTimeSlot,
  errors: FieldErrors,
  index: number,
};

export default function OpenDayRow(
  {
    editMode,
    control,
    errors,
    timeSlot,
    index,
  }: Props,
) {
  const OPENING_HOUR = 0;
  const CLOSING_HOUR = 23;
  const allHours = getAllHours(OPENING_HOUR, CLOSING_HOUR);
  return (
    <div className="opening-hour-row">
      <div>
        {Messages.t(`generics.${timeSlot.day}`)}
      </div>
      {!editMode ? (
        <div>
          {(timeSlot?.isOpen && timeSlot?.openingTime && timeSlot?.closingTime) ? `${timeSlot.openingTime} - ${timeSlot.closingTime}` : Messages.t('parameters.close')}
        </div>
      ) : (
        <>
          <Controller
            control={control}
            name={`timeslots.${index}.isOpen`}
            render={(controller) => (
              <CheckboxWrapper
                control={controller}
                label={Messages.t('parameters.open')}
                error={errors}
              />
            )}
          />
          {timeSlot.isOpen && (
            <div className="opening-hours-selects">
              <Controller
                name={`timeslots.${index}.openingTime`}
                control={control}
                rules={{ required: true }}
                render={(controller) => (
                  <SelectWrapper
                    control={controller}
                    label={Messages.t('parameters.phoneLineOpening')}
                    values={allHours.map((hour) => (
                      { key: hour, label: hour }
                    ))}
                    error={errors}
                  />
                )}
              />
              <Controller
                name={`timeslots.${index}.closingTime`}
                control={control}
                rules={{
                  required: true,
                  validate: (closingTime) => {
                    if (closingTime
                      && timeSlot.openingTime
                      && timeSlot.openingTime >= closingTime) {
                      return Messages.t('parameters.errorClosingHour');
                    }
                    return undefined;
                  },
                }}
                render={(controller) => (
                  <SelectWrapper
                    control={controller}
                    label={Messages.t('parameters.phoneLineClosing')}
                    values={allHours.map((hour) => (
                      { key: hour, label: hour }
                    ))}
                    error={errors}
                  />
                )}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
