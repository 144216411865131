import React, { MouseEvent, ReactNode } from 'react';
import { Button as MUIButton, IconButton } from '@material-ui/core';

type Props = {
  variant?: 'text' | 'outlined' | 'contained' | 'icon'
  roundedIcon?: boolean,
  children: ReactNode
  color?: 'inherit' | 'primary' | 'secondary' | 'third' | 'fourth' | 'success' | 'error' | 'info' | 'warning'
  className?: string
  preventDefault?: boolean
  startIcon?: ReactNode,
  endIcon?: ReactNode,
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean,
};

export default function Button(
  {
    color,
    variant,
    children,
    roundedIcon,
    onClick,
    startIcon,
    endIcon,
    className,
    preventDefault,
    disabled,
  }: Props,
) {
  if (variant === 'icon') {
    return (
      <IconButton
        className={`mui-button ${className}`}
        onClick={(e) => {
          if (preventDefault) {
            e.stopPropagation();
            e.preventDefault();
          }
          e.stopPropagation();
          e.preventDefault();
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {children}
      </IconButton>
    );
  }
  return (
    <MUIButton
      disabled={disabled}
      className={`mui-button ${className} ${color} ${roundedIcon ? 'rounded-button' : ''}`}
      color={(color === 'third' || color === 'fourth') ? 'secondary' : color || 'primary'}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant || 'contained'}
      onClick={(e) => {
        if (preventDefault) {
          e.preventDefault();
        }
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {children}
    </MUIButton>
  );
}
