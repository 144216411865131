import React, { useEffect, useState } from 'react';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import Filters from 'pages/agency-app/candidates/candidates/Filters';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  CANDIDATE,
  CANDIDATE_ADD_DETAILS,
  CANDIDATE_TAB,
} from 'routes/Routes';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import CandidateKanban from 'pages/agency-app/candidates/candidates/candidateKanban/CandidateKanban';
import { CandidatureShort } from 'types/Candidature';
import CandidateTab from 'pages/agency-app/candidates/candidates/candidateTab/CandidateTab';
import { useObservable } from 'micro-observables';
import candidatureFilterService, { CandidatureFilterService } from 'services/filters/CandidatureFilterService';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { PROPERTY_ID } from 'routes/QueryParams';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { NONE } from 'services/filters/consts';

export default function Candidates() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const propertyId = queryParams.get(PROPERTY_ID);
  const propertiesQueries = usePropertiesBackend();
  const { getProperties, getProperty } = propertiesQueries;
  const { data: properties, isLoading } = getProperties();
  const agentQueries = useAgentsBackend();
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();

  const [once, setOnce] = useState(false);

  const {
    data: defaultProperty,
    isLoading: loadingDefaultProperty,
  } = getProperty(
    propertyId || '',
    () => setOnce(true),
    !once && !!propertyId && propertyId !== NONE && !propertyId.includes(','),
  );
  useEffect(() => {
    setOnce(true);
  }, []);

  const defaultProperties = defaultProperty ? [defaultProperty] : [];

  const history = useHistory();
  const candidaturesQuery = useCandidatureBackend();
  const agenciesQueries = useAgencyBackend();
  const { getCandidatures, updateCandidatureList } = candidaturesQuery;
  const candidaturesFilter = useObservable(candidatureFilterService.getFilterObservable());
  // TODO IMPORTANT There is some major optimisation that can be
  //  done with dataUpdatedAt and useMemo all around the project
  const { data: candidaturesData, dataUpdatedAt: candidateUpdatedAt } = getCandidatures(
    CandidatureFilterService.getOnePropertyId(candidaturesFilter.properties),
  );
  const { getAgencySteps } = agenciesQueries;
  const { data: steps } = getAgencySteps();
  const candidatures: CandidatureShort[] = [];
  if (candidaturesData) {
    candidatures.push(...candidatureFilterService.applyFilters(candidaturesData));
  }
  const updateCandidatureSection = (candidatureList: CandidatureShort[]) => {
    updateCandidatureList.mutateAsync(candidatureList.map((candidature) => (
      {
        id: candidature.id,
        workflowStep: candidature.workflowStep,
        order: candidature.order,
      })));
  };

  if (!steps) {
    return <div>loading</div>;
  }
  return (
    <div className="page-content">
      <div className="page-header">
        <div className="page-header-row">
          {
            !isLoading && !loadingDefaultProperty && properties && agents
            && (
              <Filters
                agents={agents}
                defaultProperties={defaultProperties}
                properties={properties}
              />
            )
          }
          <Button onClick={() => history.push(CANDIDATE_ADD_DETAILS)}>
            {Messages.t('formButton.add')}
          </Button>
        </div>
        <div className="page-selector-container">
          <Link
            className={`page-selector ${location.pathname === CANDIDATE ? 'selected' : ''}`}
            to={CANDIDATE}
          >
            {Messages.t('pageSelector.board')}
          </Link>
          <Link
            className={`page-selector ${location.pathname === CANDIDATE_TAB ? 'selected' : ''}`}
            to={CANDIDATE_TAB}
          >
            {Messages.t('pageSelector.tab')}
          </Link>
        </div>
      </div>
      <div className="page-body">
        {
          candidatures && agents && candidateUpdatedAt && (
            <Switch>
              <Route
                exact
                path={CANDIDATE}
                render={() => (
                  <CandidateKanban
                    agents={agents}
                    candidateUpdatedAt={candidateUpdatedAt}
                    properties={properties || []}
                    steps={steps}
                    updateCandidatureSection={updateCandidatureSection}
                    candidatesProps={candidatures}
                  />
                )}
              />
              <Route
                exact
                path={CANDIDATE_TAB}
                render={() => (
                  <CandidateTab
                    steps={steps}
                    candidates={candidatures}
                  />
                )}
              />
            </Switch>
          )
        }
      </div>
    </div>
  );
}
