import React from 'react';
import { Checkbox } from '@material-ui/core';
import Image from 'theme/Image';
import Messages from 'services/i18n/Messages';
import { LocationMarkerIcon } from '@heroicons/react/solid';
import StringUtils from 'services/StringUtils';
import { Link } from 'react-router-dom';
import { Routes } from 'routes/RoutesUtils';
import { ID, PROPERTY_DETAIL } from 'routes/Routes';
import { OpenInNew } from '@material-ui/icons';
import { Property } from 'types/Property';
import PropertyUtils from 'services/PropertyUtils';

type Props = {
  property: Property
  showButton?: boolean
  multiple?: boolean
  checked?: boolean
};

export default function ListingCard(
  {
    property,
    showButton,
    multiple,
    checked,
  }: Props,
) {
  return (
    <>
      {
        multiple && (
          <Checkbox checked={checked} />
        )
      }
      <div
        className="property-list-item property-selector"
      >
        <div className="property-image">
          <Image
            src={PropertyUtils.getPropertyMainImage(property)}
            alt="property"
          />
          <div className="image-tag-container">
            <div className={`image-tag ${property.online ? 'success' : 'error'}`} />
          </div>
        </div>
        <div className="property-info">
          <div>
            <div className="property-price">
              {property.price} &euro;<span className="price-month">{Messages.t('price.byMonth')}</span>
            </div>
            <div className="property-address">
              <LocationMarkerIcon />
              <span>
                {
                  property.address
                    ? property.address
                    : `${StringUtils.getPrettyAddress(property.city)}, ${property.zipCode || ''}`
                }
              </span>
            </div>
            {
              property.area && (
                <div><small>{property.area} m2</small></div>
              )
            }
          </div>
        </div>
        <div className="property-identifier">
          <p>{property.identifier}</p>
        </div>
        {showButton && (
          <div
            className="property-link"
            role="presentation"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Link
              to={Routes.withPath(PROPERTY_DETAIL, [{ label: ID, value: property.id }])}
              target="_blank"
            >
              <OpenInNew />
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
