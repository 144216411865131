import React from 'react';
import PropertyListItem from 'pages/agency-app/appartments/appartments/PropertyListItem';
import { Property } from 'types/Property';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { useAgentsBackend } from 'network/queries/AgentQueries';

type Props = {
  properties: Property[],
};

export default function PropertyList({ properties }: Props) {
  const agenciesQueries = useAgencyBackend();
  const { getAgencySteps } = agenciesQueries;
  const { data: steps } = getAgencySteps();

  const agentQueries = useAgentsBackend();
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();

  return (
    <>
      {
        steps && properties
          .sort((a, b) => (b.lastPublishAt || '').localeCompare(a.lastPublishAt || ''))
          .map((property) => (
            <PropertyListItem
              steps={steps}
              agents={agents}
              key={property.id}
              property={property}
            />
          ))
      }
    </>
  );
}
