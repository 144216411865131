export default class StringUtils {
  static capitalizeOnlyFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static isUpperCase(str) {
    return str === str.toUpperCase();
  }

  static generateId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static prettyCamelCase(name: string) {
    if (StringUtils.isUpperCase(name)) {
      return StringUtils.capitalizeOnlyFirstLetter(name.toLowerCase());
    }
    const words = name.match(/[A-Za-z][a-z]*/g) || [];

    return words.map(StringUtils.capitalizeOnlyFirstLetter).join(' ');
  }

  static capitalizeFirstLetter(string?: string | number | null): string {
    if (string === null || string === undefined) {
      return '';
    }
    if (typeof string !== 'string') {
      return string.toString();
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  static capitalizeAllLetter(string?: string | null): string {
    if (string === null || string === undefined) {
      return '';
    }
    return string.split(' ').map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(' ');
  }

  static prettyDisplayMail(email: string) {
    return StringUtils.capitalizeFirstLetter(email.split('@')[0]);
  }

  static getPrettyAddress(address?: string) {
    if (!address) {
      return '';
    }
    return address.toLowerCase().split('-')
      .map((part) => StringUtils.capitalizeOnlyFirstLetter(part))
      .join('-')
      .split(' ')
      .map((part) => StringUtils.capitalizeOnlyFirstLetter(part))
      .join(' ');
  }
}
