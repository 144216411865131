import React, { useState } from 'react';
import NotificationList from 'pages/agency-app/parameters/profilParameters/NotificationList';
import { Agent } from 'types/Agent';
import { NotificationType } from 'types/AgentNotification';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { useAgencyBackend } from 'network/queries/AgencyQueries';

type Props = {
  agent: Agent;
};

export default function AgentNotificationList({ agent }: Props) {
  const [submitting, setSubmitting] = useState(false);

  const agentQueries = useAgentsBackend();
  const { updateAgentNotifications, getAgentNotifications } = agentQueries;
  const { data: notifications, isLoading } = getAgentNotifications(agent.id);
  const agenciesQueries = useAgencyBackend();
  const { getAgencySteps } = agenciesQueries;
  const { data: steps, isLoading: loadingSteps } = getAgencySteps();

  const updateNotification = (type: NotificationType, stepChosen: string) => {
    setSubmitting(true);
    updateAgentNotifications.mutateAsync({
      agentId: agent.id,
      agentNotification: {
        workflowStep: stepChosen,
        agentId: agent.id,
        notificationType: type,
      },
    })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading || loadingSteps) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <NotificationList
      updateNotification={updateNotification}
      submitting={submitting}
      agencySteps={steps}
      notificationList={notifications}
    />
  );
}
