import React from 'react';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import Messages from 'services/i18n/Messages';
import PlanParametersForm from 'pages/agency-app/parameters/managerParameters/PlanParametersForm';

export default function ManagerParameters() {
  const agencyQueries = useAgencyBackend();
  const { getCurrentAgency } = agencyQueries;
  const { data: agency } = getCurrentAgency();

  return (
    <>
      <h3>{Messages.t('parameters.agencyInfo')}</h3>
      <div>
        {agency && agency.id}
      </div>
      <h3>{Messages.t('parameters.plan')}</h3>
      {
        agency && <PlanParametersForm agency={agency} />
      }
    </>
  );
}
