import React from 'react';
import {
  Autocomplete,
  TextField,
} from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Candidature } from 'types/CandidatureBase';
import { CandidatureShort } from 'types/Candidature';
import CandidatureUtils from 'services/CandidatureUtils';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import NameWithInitials from 'pages/common/NameWithInitials';

type Props = {
  candidatures: CandidatureShort[],
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  }, // TODO Better type
  label?: string,
  error: FieldErrors,
  apiErrors: { [key: string]: string[] }
  onChange?: (value: Candidature | undefined) => void
  disableFullwidth?: boolean,
  disabled?: boolean,
};

export default function CandidateSelector(
  {
    control,
    candidatures,
    disableFullwidth,
    onChange,
    error,
    apiErrors,
    disabled,
    label,
  }: Props,
) {
  const filterOptions = (options, { inputValue }) => CandidatureUtils
    .filterCandidate(options, inputValue)
    .sort((a, b) => (b?.createdAt || '').localeCompare(a?.createdAt || ''));

  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);

  const value = field.value || null;

  const options = candidatures;

  const changeValue = (val: Candidature) => {
    if (onChange) {
      onChange(val);
    }
    return field.onChange(val);
  };

  return (
    <div className={`material-select-wrapper ${disableFullwidth ? 'no-full-widht' : ''}`}>
      <Autocomplete
        {...field}
        value={value}
        onChange={(e, val) => {
          changeValue(val);
        }}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => CandidatureUtils.getPrettyName(option)}
        renderOption={(props, candidature) => (
          <li {...props} key={candidature.id}>
            <NameWithInitials color="secondary" name={CandidatureUtils.getPrettyName(candidature)} />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errorMessage}
            helperText={errorMessage}
            label={label}
            placeholder={Messages.t('field.search')}
            variant="filled"
            InputProps={{
              disableUnderline: true,
              ...params.InputProps,
              startAdornment: field.value && (
                <div className="name-initials secondary">
                  {
                    CandidatureUtils.getPrettyName(field.value).split(' ').slice(0, 2).map((word) => (
                      word[0]
                    ))
                  }
                </div>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
