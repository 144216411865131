import React from 'react';
import PageBackButton from 'pages/common/PageBackButton';
import { PropertyDetailled, PropertyForm } from 'types/Property';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import Notes from 'pages/common/Notes';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import PropertyDetailsForm from 'pages/agency-app/appartments/propertyDetails/PropertyDetailsForm';
import Messages from 'services/i18n/Messages';
import PropertyActions from 'pages/agency-app/appartments/propertyDetails/PropertyActions';
import { APPARTMENT } from 'routes/Routes';

type Props = {
  property?: PropertyDetailled
  propertyCreation?: PropertyForm
  candidateId?: string,
};

export default function PropertyDetails({ property, propertyCreation, candidateId }: Props) {
  const propertiesQueries = usePropertiesBackend();

  const agentQueries = useAgentsBackend();
  const {
    getPropertyNotes,
    createPropertyNotes,
    updatePropertyNote,
    deletePropertyNote,
  } = propertiesQueries;

  const submitPropertyNote = (data: string) => createPropertyNotes.mutateAsync({
    propertyId: property?.id || '',
    data: {
      message: data,
    },
  });

  const editNote = (message: string, noteId: string) => updatePropertyNote.mutateAsync({
    propertyId: property?.id || '',
    noteId,
    data: {
      message,
    },
  });

  const deleteNote = (noteId: string) => deletePropertyNote.mutateAsync({
    propertyId: property?.id || '',
    noteId,
  });
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();
  const { data: notes } = getPropertyNotes(property?.id || '', !!property?.id);

  return (
    <>
      <div className="page-header page-actions">
        <PageBackButton to={APPARTMENT} />
        {
          property && (
            <PropertyActions property={property} />
          )
        }
      </div>
      <div className="page-body ">
        <div className="property-detail-page">
          <div className="property-detail-main-content">
            <PropertyDetailsForm
              property={property}
              propertyCreation={propertyCreation}
              candidateId={candidateId}
            />
          </div>
          {
            agents && notes && (
              <div className="notes">
                <h5>{Messages.t('field.notes')}</h5>
                <Notes
                  notes={notes}
                  editNote={editNote}
                  agents={agents}
                  deleteNote={deleteNote}
                  addNote={submitPropertyNote}
                />
              </div>
            )
          }
        </div>
      </div>
    </>
  );
}
