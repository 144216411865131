import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { AgentCreation } from 'types/Agent';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import { NotificationService } from 'lib/notification';
import { mailRegex } from 'lib/form/FormUtils';

type Props = {
  onValidate?: () => void
};

export default function AddAgentForm({ onValidate }: Props) {
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const agentQueries = useAgentsBackend();
  const { createAgent } = agentQueries;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AgentCreation>();

  const onSubmit: SubmitHandler<AgentCreation> = (formData: AgentCreation) => {
    setSubmitting(true);
    createAgent.mutateAsync(formData).then(() => {
      if (onValidate) {
        onValidate();
      }
    })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.agentCreate'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <form className="add-agent-form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        rules={{
          required: true,
          pattern: {
            value: mailRegex,
            message: Messages.t('form.error.email'),
          },
        }}
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            label={Messages.t('field.email')}
          />
        )}
      />
      <SpinButton
        editing
        spin={submitting}
        title={Messages.t('formButton.confirm')}
      />
    </form>
  );
}
