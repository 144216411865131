import React from 'react';
import { GliContract } from 'types/ContractTypes';
import { CandidatureShort } from 'types/Candidature';
import Messages from 'services/i18n/Messages';
import GliContractsTableRow
  from 'pages/agency-app/candidates/candidates/candidateKanban/GliTooltip/GliContractsTableRow';

type Props = {
  contracts: GliContract[]
  candidate: CandidatureShort,
  agencyGliRatio?: number,
  propertyLease?: number,
};

export default function GliContractsTable(
  {
    contracts,
    candidate,
    agencyGliRatio,
    propertyLease,
  }: Props,
) {
  const sumEligibleRevenues = candidate.gliEligibleRevenues || 0;
  const revenuesThreshold = candidate.gliMinimumRevenues || 0;
  const gliOk = candidate.gliEligible || false;
  return (
    <div className="main-info">
      <div className="info">
        <div className="rent-price">
          <div className="name">{Messages.t('gli.tooltip.monthlyPrice')}</div>
          <div className="price">{Messages.t('gli.tooltip.monthlyRent', { propertyLease })}</div>
        </div>
        <div className="minimum-revenue">
          <div className="name">{Messages.t('gli.tooltip.incomes')}</div>
          <div className="price">{Messages.t('gli.tooltip.ratioSentance', {
            threshold: revenuesThreshold.toFixed(0),
            ratio: agencyGliRatio,
          })}
          </div>
        </div>
      </div>
      <div className="details">
        <div className="name">{Messages.t('gli.tooltip.totalCandidatureRevenues')}</div>
        <div className="price">{sumEligibleRevenues.toFixed(0)} €</div>
        <div className="details-table">
          {
            contracts.map((contract) => (
              <GliContractsTableRow
                contract={contract}
                candidate={candidate}
                key={contract.id}
              />
            ))
          }
        </div>
      </div>
      <div className="name">
        {Messages.t('gli.tooltip.incomesVsRentPrice', {
          comparaison: Messages.t(`generics.${gliOk ? 'superior' : 'inferior'}`),
          ratio: agencyGliRatio,
        })}
      </div>
      {propertyLease && sumEligibleRevenues > 0 && (
        <div className="name">
          {Messages.t('gli.ratio.effortRate', { effortRate: (100 * (propertyLease / sumEligibleRevenues)).toFixed(1) })}
        </div>
      )}
    </div>
  );
}
