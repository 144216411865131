import React from 'react';
import {
  TemplateIcon,
  LocationMarkerIcon,
} from '@heroicons/react/solid';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import { Property } from 'types/Property';
import StringUtils from 'services/StringUtils';
import Messages from 'services/i18n/Messages';
import Image from 'theme/Image';
import PropertyUtils from 'services/PropertyUtils';

type Props = {
  property: Property,
  onClick?: () => void
};

export default function PropertyCard({ property, onClick }: Props) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="property-list-item property-card"
    >
      <div className="property-image">
        <Image
          src={PropertyUtils.getPropertyMainImage(property)}
          alt="property"
        />
        <div className="image-tag-container">
          <div className={`image-tag ${property.online ? 'success' : 'error'}`}>
            {
              property.online
                ? Messages.t('status.online')
                : Messages.t('status.offline')
            }
          </div>
        </div>
      </div>
      <div className="property-info">
        <div>
          <div className="property-price">
            {property.price} &euro;<span className="price-month">{Messages.t('price.byMonth')}</span>
          </div>
          <div>
            <TemplateIcon />
            {property.rooms}
            <ArrowsExpandIcon />
            {property.area} m2
          </div>
          <div className="property-address">
            <LocationMarkerIcon />
            <span>
              {
                property.address
                  ? property.address
                  : `${StringUtils.getPrettyAddress(property.city)}, ${property.zipCode || ''}`
              }
            </span>
          </div>
        </div>
      </div>
      <div className="property-identifier">
        {
          property.identifier && (
            `#${property.identifier}`
          )
        }
      </div>
    </button>
  );
}
