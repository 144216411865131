import React, { useState } from 'react';
import { PossibleVisitSlot, VisitSlotDetailed } from 'types/VisitSlot';
import Messages from 'services/i18n/Messages';
import { format } from 'date-fns';
import Button from 'theme/Button';
import { Popover } from '@material-ui/core';
import { CandidatureShort } from 'types/Candidature';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import CandidateSelector from 'pages/common/CandidateSelector';
import SpinButton from 'theme/SpinButton';
import { NotificationService } from 'lib/notification';
import { useVisitSlotBackend } from 'network/queries/VisitSlotQueries';
import VisitCandidatureTile from 'pages/common/visitSlots/VisitCandidatureTile';

type Props = {
  possibleVisitSlot: PossibleVisitSlot,
  visitSlot: VisitSlotDetailed,
  candidatures: CandidatureShort[]
};

export default function VisitSlotHour(
  {
    possibleVisitSlot,
    visitSlot,
    candidatures,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const visitSlotQueries = useVisitSlotBackend();
  const { assignVisitSlot } = visitSlotQueries;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ candidature: CandidatureShort }>();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    reset();
    setAnchorEl(null);
  };

  const onSubmit: SubmitHandler<{ candidature: CandidatureShort }> = (
    formData: { candidature: CandidatureShort },
  ) => {
    setSubmitting(true);
    assignVisitSlot.mutateAsync({
      visitSlotId: visitSlot.id,
      assignVisit: {
        startDate: possibleVisitSlot.startDate,
        endDate: possibleVisitSlot.endDate,
        dateHash: possibleVisitSlot.dateHash,
        candidatureID: formData.candidature.id,
      },
    })
      .then(() => {
        handleClose();
        NotificationService.notifySuccess(Messages.t('notifications.visitSlotCreate'));
      })
      .catch((error) => {
        if (!error.json_response) {
          NotificationService.notifyError(Messages.t('notifications.error'));
          return;
        }
        if (error.json_response.errorMessage) {
          // @ts-ignore
          NotificationService.notifyError(Messages.t(`field.error.${error.json_response.errorMessage[0]}`));
        } else {
          NotificationService.notifyError(Messages.t('notifications.error'));
        }
        setApiErrors(error.json_response);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <div className="possible-visit-slot-header">
        <div>
          {Messages.t('visit.slotCreationHours', {
            day: format(new Date(possibleVisitSlot.startDate), 'dd/MM'),
            hourStart: format(new Date(possibleVisitSlot.startDate), 'HH:mm'),
            hourEnd: format(new Date(possibleVisitSlot.endDate), 'HH:mm'),
          })}
        </div>
        <div>
          {Messages.t('visit.available', {
            available: possibleVisitSlot.remainingVisits,
            total: visitSlot.maxVisitors,
          })}
        </div>
      </div>
      {
        // TODO some opti here
        visitSlot.visits?.filter((visit) => visit.startDate === possibleVisitSlot.startDate)
          .map((visit) => {
            const candidature = candidatures
              .filter((candidatureData) => candidatureData.id === visit.candidatureID)[0];
            if (!candidature) {
              return null;
            }
            return (
              <VisitCandidatureTile key={visit.id} visit={visit} candidature={candidature} />
            );
          })
      }
      <Button className="add-visit-button" onClick={handleClick} color="secondary">
        {Messages.t('visit.add')}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="candidate-select-popover">
          <h4>{Messages.t('candidate.select')}</h4>
          <Controller
            name="candidature"
            control={control}
            rules={{
              required: true,
            }}
            render={(controller) => (
              <CandidateSelector
                error={errors}
                apiErrors={apiErrors}
                candidatures={
                  candidatures
                    .filter((candidature) => candidature.propertyID === visitSlot.propertyID)
                }
                control={controller}
                label={Messages.t('field.candidate')}
              />
            )}
          />
          <SpinButton
            type="button"
            editing
            onClick={handleSubmit(onSubmit)}
            spin={submitting}
            title={Messages.t('formButton.confirm')}
          />
        </div>
      </Popover>
    </div>
  );
}
