import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AgencyDetailed, AgencyPlan } from 'types/Agency';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import SpinButton from 'theme/SpinButton';

type Props = {
  agency: AgencyDetailed
};

export default function PlanParametersForm({ agency }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors] = useState({});
  const agencyQueries = useAgencyBackend();
  const { updateAgencyPlan } = agencyQueries;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AgencyPlan>({
    defaultValues: agency.plan,
  });

  const onSubmit: SubmitHandler<AgencyPlan> = (formData: AgencyPlan) => {
    if (agency) {
      setSubmitting(true);
      updateAgencyPlan.mutateAsync({
        agencyPlan: {
          ...formData,
          maxFreeVisitSlot: Number(formData.maxFreeVisitSlot.toString()),
        },
        agencyId: agency.id,
      })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="maxFreeVisitSlot"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('parameters.maxFreeVisitSlot')}
            />
          )}
        />
        <Controller
          control={control}
          name="visitSlotPlan"
          render={(controller) => (
            <CheckboxWrapper
              apiErrors={apiErrors}
              control={controller}
              label={Messages.t('parameters.visitSlotPlan')}
              error={errors}
            />
          )}
        />
        <SpinButton
          editing
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </>
  );
}
