import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

type Props = {
  name: string,
  datas: number[],
  labels: string[],
};

export default function Chart({ name, datas, labels }: Props) {
  const data = {
    series: [{
      name,
      data: datas,
    }],
  };

  const option: ApexOptions = {
    chart: {
      height: 200,
      type: 'area',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.00,
        stops: [20, 100, 100, 100],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    colors: ['black'],
    xaxis: {
      type: 'category',
      categories: labels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  };

  return (
    <div className="chart-container">
      <ReactApexChart options={option} series={data.series} type="area" height={200} />
    </div>
  );
}
