import React from 'react';
import { VisitSlotDetailed } from 'types/VisitSlot';
import VisitSlotHour from 'pages/common/visitSlots/VisitSlotHour';
import { CandidatureShort } from 'types/Candidature';

type Props = {
  visitSlot: VisitSlotDetailed,
  candidatures: CandidatureShort[]

};

// TODO this component might not be usefull ...
export default function VisitSlotReservation(
  {
    visitSlot,
    candidatures,
  }: Props,
) {
  return (
    <div className="visit-slot-reservation-container">
      {
        visitSlot.possibleVisitSlots?.map((possibleVisitSlot) => (
          <VisitSlotHour
            key={possibleVisitSlot.dateHash}
            candidatures={candidatures}
            possibleVisitSlot={possibleVisitSlot}
            visitSlot={visitSlot}
          />
        ))
      }
    </div>
  );
}
