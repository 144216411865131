import React from 'react';
import Messages from 'services/i18n/Messages';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import OccupantQualificationForm from 'pages/agency-app/parameters/occupantQualification/OccupantQualificationForm';
import PinelTable from 'pages/agency-app/parameters/occupantQualification/PinelTable';
import AgencyGliForm from 'pages/agency-app/parameters/occupantQualification/AgencyGliForm';

export default function OccupantQualification() {
  const agencyQueries = useAgencyBackend();
  const { getGliContracts, getCurrentAgency, getPinelBareme } = agencyQueries;
  const { data: gliContracts } = getGliContracts();
  const { data: agency } = getCurrentAgency();
  const { data: pinelBareme } = getPinelBareme();

  return (
    <div className="parmameters-page">
      <div className="qualification">
        <h2>{Messages.t('parameters.occupantQualif.GLI')}</h2>
        {
          agency && (
            <AgencyGliForm agency={agency} />
          )
        }
        {
          agency && gliContracts && (
            <OccupantQualificationForm gliContracts={gliContracts} agency={agency} />
          )
        }
        <h2>{Messages.t('parameters.occupantQualif.Pinel')}</h2>
        {
          pinelBareme && pinelBareme[0]?.id ? (
            <PinelTable pinelBareme={pinelBareme} />
          ) : <p>{Messages.t('pinel.missingDataDb')}</p>
        }
      </div>
    </div>
  );
}
