import React from 'react';
import {
  TemplateIcon,
  LocationMarkerIcon,
} from '@heroicons/react/solid';
import { ArrowsExpandIcon } from '@heroicons/react/outline';
import { Property } from 'types/Property';
import StringUtils from 'services/StringUtils';
import Messages from 'services/i18n/Messages';
import Image from 'theme/Image';
import PropertyUtils from 'services/PropertyUtils';

type Props = {
  property: Property,
  onClick?: () => void
};

export default function ClusterCard({ property, onClick }: Props) {
  return (
    <button
      type="button"
      className="map-desktop-card"
      onClick={onClick}
    >
      <div className="image-container">
        <Image
          src={PropertyUtils.getPropertyMainImage(property)}
          alt="property"
        />
      </div>
      <div className="candidate-card-content">
        <div className="candidate-card-data">
          <div className="property-price">
            {property.price} &euro;<span className="price-month">{Messages.t('price.byMonth')}</span>
          </div>
          <div className="candidate-card-dimensions">
            <TemplateIcon />
            {property.rooms}
            <ArrowsExpandIcon />
            {property.area} m2
          </div>

          <div className="candidate-card-data-row">
            <div className="candidate-card-address">
              <LocationMarkerIcon />
              <span>
                {
                  property.address
                    ? property.address
                    : `${StringUtils.getPrettyAddress(property.city)}, ${property.zipCode || ''}`
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}
