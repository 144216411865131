import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { AgentForm } from 'types/forms/AgentForm';
import { userApi } from 'network/api/UserApi';
import { Agent } from 'types/Agent';
import { NotificationService } from 'lib/notification';
import { useHistory } from 'react-router-dom';
import { DASHBOARD } from 'routes/Routes';
import { passwordRegex } from 'lib/form/FormUtils';

type Props = {
  agent: Agent,
};

export default function OnBoardingForm({ agent }: Props) {
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AgentForm>({
    defaultValues: {
      firstname: agent.firstname,
      lastname: agent.lastname,
      email: agent.email,
      phoneNumber: agent.phoneNumber,
    },
  });

  const formField = watch();
  const onSubmit: SubmitHandler<AgentForm> = (formData: AgentForm) => {
    userApi.updatePassword(formData).then(() => {
      NotificationService.notifySuccess(Messages.t('notifications.update'));
      history.push(DASHBOARD);
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              disabled
              type="text"
              control={controller}
              label={Messages.t('field.email')}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{
            required: true,
            pattern: {
              value: passwordRegex,
              message: Messages.t('form.error.password'),
            },
          }}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="password"
              control={controller}
              label={Messages.t('field.password')}
            />
          )}
        />
        <Controller
          name="passwordConfirm"
          control={control}
          rules={{ required: true, validate: (v) => v === formField.password || Messages.t('field.notMatchingPasswor') }}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="password"
              control={controller}
              label={Messages.t('field.passwordConfirm')}
            />
          )}
        />
        <SpinButton
          editing
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
