import { NotificationType } from 'types/AgentNotification';
import { Enum } from 'lib/type/enum';

export interface AgencyStep {
  id: string,
  agencyId: string,
  stepName: string,
  stepKey: string,
  order?: number,
  createdAt?: string,
  updatedAt?: string,
  notificationType: NotificationType,
}

export type WorkflowStep = 'INTERRESTED' | 'VIEWING_REQUEST' | 'VIEWING_BOOKED' | 'VIEWING_PROPOSED';

export const workflowStepEnum: Enum<WorkflowStep> = {
  INTERRESTED: 'INTERRESTED',
  VIEWING_REQUEST: 'VIEWING_REQUEST',
  VIEWING_BOOKED: 'VIEWING_BOOKED',
  VIEWING_PROPOSED: 'VIEWING_PROPOSED',
};

export const MANDATORY_STEPS: string[] = [
  workflowStepEnum.INTERRESTED,
  workflowStepEnum.VIEWING_REQUEST,
  workflowStepEnum.VIEWING_BOOKED,
  workflowStepEnum.VIEWING_PROPOSED,
];
