import React, { ReactChild, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Carousel } from 'react-responsive-carousel';
import Button from 'theme/Button';

type Props = {
  listLength: number,
  children: ReactChild[],
  enableTouch?: boolean
};

export default function CardCarrousel({ listLength, children, enableTouch }: Props) {
  const [seletedIndex, setSelectedIndex] = useState(0);

  return (
    <Carousel
      selectedItem={seletedIndex < listLength ? seletedIndex : 0}
      dynamicHeight={false}
      showThumbs={false}
      showIndicators={false}
      onChange={setSelectedIndex}
      autoPlay={false}
      showStatus={false}
      centerMode={listLength > 1}
      interval={10000000000}
      preventMovementUntilSwipeScrollTolerance
      swipeScrollTolerance={50}
      centerSlidePercentage={90}
      showArrows
      renderArrowNext={(clickHandler, hasNext) => hasNext
        && seletedIndex !== listLength - 1
        && (
          <Button
            onClick={clickHandler}
            preventDefault
            roundedIcon
            color="secondary"
            className="carrousel-arrow right-arrow"
            variant="contained"
          >
            <ChevronRightIcon />
          </Button>
        )}
      renderArrowPrev={(clickHandler, hasPrev) => hasPrev
        && seletedIndex !== 0
        && (
          <Button
            onClick={clickHandler}
            roundedIcon
            color="secondary"
            preventDefault
            className="carrousel-arrow left-arrow"
            variant="contained"
          >
            <ChevronLeftIcon />
          </Button>
        )}
      infiniteLoop
      emulateTouch={enableTouch}
      className="carousel-custom-root"
    >
      {children}
    </Carousel>
  );
}
