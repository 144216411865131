import React, { useState } from 'react';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import AgentListItem from 'pages/agency-app/parameters/agencyParameteres/AgentListItem';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import AddAgentForm from 'pages/agency-app/parameters/agencyParameteres/AddAgentForm';
import { DialogContent, DialogTitle } from '@material-ui/core';
import sessionManager from 'services/sessionManager';
import { permissionsEnum } from 'types/Permission';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import DialogWrapper from 'pages/common/DialogWrapper';

export default function AgentList() {
  const agentQueries = useAgentsBackend();
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();
  const agencyQueries = useAgencyBackend();
  const { getAgencyRoles } = agencyQueries;
  const { data: roles } = getAgencyRoles();
  const [addAgentModal, setAddAgentModal] = useState(false);
  return (
    <>
      <div className="agent-list">
        {
          agents && roles && agents.map((agent) => (
            <React.Fragment key={agent.email}>
              <AgentListItem roles={roles} agent={agent} />
              <hr />
            </React.Fragment>
          ))
        }
      </div>
      {
        addAgentModal && (
          <DialogWrapper open={addAgentModal} onClose={() => setAddAgentModal(false)}>
            <DialogTitle>{Messages.t('agent.addAgent')}</DialogTitle>
            <DialogContent>
              <AddAgentForm onValidate={() => setAddAgentModal(false)} />
            </DialogContent>
          </DialogWrapper>
        )
      }
      {
        sessionManager.getSession()?.permissions.includes(permissionsEnum.AGENT_ADMIN)
        && (
          <Button onClick={() => setAddAgentModal(true)}>{Messages.t('formButton.add')}</Button>
        )
      }
    </>
  );
}
