import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from '@material-ui/icons';

type Props = {
  label: string,
  icon: React.ReactNode
  path: string
  exact?: boolean
  matchingPath?: string
};

function NavItem(
  {
    label,
    icon,
    path,
    exact,
    matchingPath,
  }: Props,
) {
  const location = useLocation();
  const matchPath = exact
    ? (matchingPath || path) === location.pathname
    : location.pathname.startsWith(matchingPath || path);
  return (
    <Link to={path} className={`nav-item ${matchPath ? 'active' : ''}`}>
      {icon}
      <span>{label}</span>
      {
        matchPath && <ChevronRight />
      }
    </Link>
  );
}

export default NavItem;
