import { Enum } from 'lib/type/enum';

export type DaysType = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

export interface OpenTimeSlot {
  id?: string,
  agencyId: string,
  day: DaysType,
  isOpen?: boolean,
  openingTime: string | null,
  closingTime: string | null,
  createdAt?: string,
  updatedAt?: string,
}

export type Timeslots = {
  timeslots: OpenTimeSlot[],
};

export const daysTypeEnum: Enum<DaysType> = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
};

export function getAllHours(firstHour: number, lastHour: number): (string[]) {
  const tab: string[] = [];
  for (let i = firstHour; i <= lastHour; i++) {
    const iString = i.toString().padStart(2, '0');
    tab.push(`${iString}:00`);
    tab.push(`${iString}:30`);
  }
  return tab;
}
