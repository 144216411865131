import React from 'react';
import { Property } from 'types/Property';
import { Controller, useForm } from 'react-hook-form';
import Messages from 'services/i18n/Messages';
import PropertiesSelector from 'pages/common/PropertySelector';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { CandidaturePartial } from 'types/CandidatureBase';
import { NotificationService } from 'lib/notification';
import { CandidatureForm } from 'types/forms/CandidatureForm';

type Props = {
  onSubmitted: (propertyId: string) => void
  candidatureId: string
  properties: Property[]
};

export default function CandidateAssignPropertyForm(
  {
    onSubmitted,
    properties,
    candidatureId,
  }: Props,
) {
  const {
    control,
    formState: { errors },
  } = useForm<CandidatureForm>();
  const candidatureQueries = useCandidatureBackend();
  const { updateCandidature } = candidatureQueries;
  const submitCandidature = (data: CandidaturePartial) => {
    updateCandidature.mutateAsync(data)
      .then((candidature) => {
        onSubmitted(candidature.propertyID);
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')));
  };

  return (
    <>
      <Controller
        name="propertyId"
        control={control}
        render={(controller) => (
          <PropertiesSelector
            showOffline
            apiErrors={{}}
            error={errors}
            control={controller}
            label={Messages.t('field.property')}
            properties={properties}
            onChange={(propertyID) => submitCandidature({ propertyID, id: candidatureId })}
          />
        )}
      />
    </>
  );
}
