import React, { useEffect, useState } from 'react';
import { useIntegrationBackend } from 'network/queries/IntegrationQueries';
import { useHistory, useLocation } from 'react-router-dom';
import { CODE, STATE } from 'routes/QueryParams';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import GoogleCalendarImage from 'assets/images/googleCalendarIcon.svg';
import OutlookCalendarImage from 'assets/images/outlook.png';
import googleButton from 'assets/images/googleButton.png';
import googleButtonDisable from 'assets/images/googleButtonDisable.png';
import msButton from 'assets/images/msButton.png';
import { CheckCircle } from '@material-ui/icons';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import sessionManager from 'services/sessionManager';
import confirmationService from 'services/ConfirmationService';

const MS_STATE = 'msToken';

export default function IntegrationsParameters() {
  const location = useLocation();
  const history = useHistory();

  const [submittingGoogle, setSubmittingGoogle] = useState(false);
  const [submittingOutlook, setSubmittingOutlook] = useState(false);

  const integrationsQueries = useIntegrationBackend();
  const {
    disconnectUser,
    getGoogleLink,
    saveGoogleToken,
    saveOutlookToken,
    getOutlookLink,
  } = integrationsQueries;
  const { data: googleRedirection } = getGoogleLink();
  const { data: outlookRedirection } = getOutlookLink();

  const agentQueries = useAgentsBackend();

  const { getAgentIntegrations } = agentQueries;

  const { data: userIntegrations } = getAgentIntegrations(sessionManager.getSession()?.agent_id || '');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get(CODE);
    const state = queryParams.get(STATE);
    if (code) {
      if (state && state.startsWith(MS_STATE)) {
        setSubmittingOutlook(true);
        saveOutlookToken.mutateAsync({ token: code })
          .then(() => {
            NotificationService.notifySuccess(Messages.t('notifications.update'));
            history.replace(location.pathname);
          })
          .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
          .finally(() => {
            setSubmittingOutlook(false);
          });
      } else {
        setSubmittingGoogle(true);
        saveGoogleToken.mutateAsync({ token: code })
          .then(() => {
            NotificationService.notifySuccess(Messages.t('notifications.update'));
            history.replace(location.pathname);
          })
          .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
          .finally(() => {
            setSubmittingGoogle(false);
          });
      }
    }
  }, []);

  const disconnect = async () => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('button.logout'),
      actionColor: 'error',
      message: Messages.t('integration.disconnect'),
    });
    if (!isConfirmed) {
      return;
    }
    setSubmittingGoogle(true);
    setSubmittingOutlook(true);
    disconnectUser.mutateAsync().then(() => {
      NotificationService.notifySuccess(Messages.t('notifications.update'));
      history.replace(location.pathname);
    })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => {
        setSubmittingGoogle(false);
        setSubmittingOutlook(false);
      });
  };

  return (
    <div className="integration-tile-container">
      {
        googleRedirection && (
          <div className="integration-tile">
            <div className="integration-header">
              <div className="image-container">
                <img src={GoogleCalendarImage} alt="calendar" />
              </div>
            </div>
            <div className="integration-body">
              <h3>
                {Messages.t('integration.google.title')}
              </h3>
              {
                userIntegrations?.google ? (
                  <>
                    <div className="integration-done-icon">
                      <CheckCircle />
                    </div>
                    <div className="integration-done-text">
                      {Messages.t('integration.done')}
                    </div>
                    <button className="log-out-button" type="button" onClick={disconnect}>
                      {Messages.t('button.logout')}
                    </button>
                  </>
                ) : (
                  <>
                    <div className="integration-description">
                      {Messages.t('integration.google.description')}
                    </div>
                    <a
                      href={googleRedirection.url}
                      className={`${((submittingGoogle || submittingOutlook) || userIntegrations?.outlook) ? 'disabled' : ''}`}
                    >
                      {
                        ((submittingGoogle || submittingOutlook) || userIntegrations?.outlook)
                          ? <img alt="google-button" src={googleButtonDisable} />
                          : <img alt="google-button" src={googleButton} />
                      }
                    </a>
                  </>
                )
              }
            </div>
          </div>
        )
      }
      {
        outlookRedirection && (
          <div className="integration-tile">
            <div className="integration-header">
              <div className="image-container">
                <img src={OutlookCalendarImage} alt="calendar" />
              </div>
            </div>
            <div className="integration-body">
              <h3>
                {Messages.t('integration.outlook.title')}
              </h3>
              {
                userIntegrations?.outlook ? (
                  <>
                    <div className="integration-done-icon">
                      <CheckCircle />
                    </div>
                    <div className="integration-done-text">
                      {Messages.t('integration.done')}
                    </div>
                    <button className="log-out-button" type="button" onClick={disconnect}>
                      {Messages.t('button.logout')}
                    </button>
                  </>
                ) : (
                  <>
                    <div className="integration-description">
                      {Messages.t('integration.outlook.description')}
                    </div>
                    <a
                      href={outlookRedirection.url}
                      className={`${(submittingGoogle || submittingOutlook) || userIntegrations?.google ? 'disabled' : ''}`}
                    >
                      <img alt="google-button" src={msButton} />
                    </a>
                  </>
                )
              }
            </div>
          </div>
        )
      }
    </div>
  );
}
