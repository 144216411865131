import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { CandidatureDetailled } from 'types/Candidature';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import AgendaEventDateDisplay from 'pages/common/calendar/AgendaEventDateDisplay';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import VisitDialog from 'pages/common/visits/VisitDialog';
import { Agent } from 'types/Agent';
import { isAfter } from 'date-fns';

type Props = {
  candidature?: CandidatureDetailled;
  agents?: Agent[];
  hidePastVisit?: boolean
  label?: string
};

export default function CandidateVisitList(
  {
    candidature,
    agents,
    hidePastVisit,
    label,
  }: Props,
) {
  const [selectedVisitId, setSelectedVisitId] = useState<string | undefined>();
  const candidatureQueries = useCandidatureBackend();
  const { getCandidatureVisit } = candidatureQueries;

  const propertiesQueries = usePropertiesBackend();
  const { getProperties } = propertiesQueries;
  const { data: propertyList } = getProperties();
  const { data: visitsData } = getCandidatureVisit(candidature?.id || '', !!candidature?.id);
  const now = new Date();
  const visits = hidePastVisit
    ? (visitsData && visitsData.filter((visit) => isAfter(new Date(visit.startDate), now)))
    : visitsData;
  return (
    <>
      {
        agents && propertyList && candidature && selectedVisitId && (
          <VisitDialog
            onClose={() => setSelectedVisitId(undefined)}
            visitId={selectedVisitId}
            properties={propertyList}
            agents={agents}
            candidatures={[candidature]}
            open
          />
        )
      }
      {
        candidature && visits && visits.length > 0 && (
          <div className="candidature-visits">
            <h4>{label || Messages.t('pageSelector.visit')}</h4>
            {
              visits.sort((a, b) => a.startDate.localeCompare(b.startDate)).map((visit) => (
                <button
                  type="button"
                  onClick={() => setSelectedVisitId(visit.id)}
                  key={visit.id}
                  className={`agenda-visit agenda-page-event ${visit.isCancel ? 'canceled' : ''}`}
                >
                  <AgendaEventDateDisplay
                    event={{
                      start: new Date(visit.startDate),
                      end: new Date(visit.endDate),
                    }}
                    time={{ day: new Date(visit.startDate) }}
                  />
                  {
                    visit.isCancel ? (
                      <div className="property-address">
                        {Messages.t('visit.canceled')}
                      </div>
                    ) : (
                      <div className="property-address">
                        <LocationMarkerIcon />
                        <div>{visit.address}</div>
                      </div>
                    )
                  }
                </button>
              ))
            }
          </div>
        )
      }
    </>
  );
}
