import React, { ReactNode } from 'react';
import { Modification } from 'types/Modification';
import { InfoOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import DateUtils from 'services/DateUtils';
import Messages from 'services/i18n/Messages';

type Props = {
  history?: Modification[],
  mapValue?: (value: string) => string | ReactNode,
};

export default function FieldHistory({ history, mapValue }: Props) {
  if (!history || history.length === 0) {
    return null;
  }
  return (
    <>
      <Tooltip
        classes={{
          tooltip: 'history-tooltip',
        }}
        title={(
          <div>
            <div className="modification-row header-row">
              <div className="modified-value">
                {Messages.t('history.modification')}
              </div>
              <div className="modified-value">
                {Messages.t('history.date')}
              </div>
              <div className="modified-value">
                {Messages.t('history.author')}
              </div>
            </div>
            {
              history
                .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                .map((modification) => (
                  <div key={modification.id} className="modification-row">
                    <div className="modified-value">
                      {
                        mapValue
                          ? mapValue(modification.newValue)
                          : modification.newValue
                      }
                    </div>
                    <div className="modified-at modified-value">
                      {
                        DateUtils.getPrettyDateDeltaFromNow(
                          modification.createdAt,
                          false,
                          true,
                        )
                      }
                    </div>
                    <div className="modified-value">
                      {
                        modification.updatedByAgent ? Messages.t('field.agent') : Messages.t('field.contact')
                      }
                    </div>
                  </div>
                ))
            }
          </div>
        )}
        arrow
      >
        <InfoOutlined />
      </Tooltip>
    </>
  );
}
