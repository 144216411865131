import React from 'react';
import { Event } from 'react-big-calendar';
import {
  differenceInDays,
  format,
  isSameDay,
  startOfDay,
} from 'date-fns';
import StringUtils from 'services/StringUtils';
import localService from 'services/i18n/LocalService';
import DateUtils from 'services/DateUtils';
import Messages from 'services/i18n/Messages';
import { ArrowForward } from '@material-ui/icons';

type Props = {
  event: Event
  time: { day: Date },
};

export default function AgendaEventDateDisplay(
  {
    event,
    time,
  }: Props,
) {
  if (!event.start) {
    return null;
  }
  if (!event.end) {
    return null;
  }
  const start = new Date(event.start);
  const end = new Date(event.end);

  const dayDelta = differenceInDays(startOfDay(end), startOfDay(start));
  const currentDelta = differenceInDays(startOfDay(time.day), startOfDay(start));
  const sameDay = isSameDay(start, end);

  return (
    <div className="agenda-event-dates">
      <div className="date-icon">
        <div>
          {StringUtils.capitalizeFirstLetter(format(time.day, 'MMM', { locale: localService.getDateLocal() }))}
        </div>
        <div className="day-number">
          {time.day.getDate()}
        </div>
      </div>
      <div>
        <div>
          {StringUtils.capitalizeFirstLetter(format(time.day, 'EEEE', { locale: localService.getDateLocal() }))}
        </div>
        <div className="event-hours">
          {
            !event.allDay && (sameDay || currentDelta === 0 || currentDelta === dayDelta) ? (
              <>
                {!sameDay && currentDelta !== 0 ? '00:00' : DateUtils.getHoursString(start)}
                <ArrowForward />
                {!sameDay && currentDelta !== dayDelta ? '00:00' : DateUtils.getHoursString(end)}
              </>
            ) : (
              Messages.t('dates.allDay')
            )
          }
        </div>
      </div>
    </div>
  );
}
