import React, { useState } from 'react';
import NotificationList from 'pages/agency-app/parameters/profilParameters/NotificationList';
import { Agency } from 'types/Agency';
import { NotificationType } from 'types/AgentNotification';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import { useAgencyBackend } from 'network/queries/AgencyQueries';

type Props = {
  agency: Agency,
};

export default function AgencyNotificationList({ agency }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const agenciesQueries = useAgencyBackend();
  const { getAgencySteps, updateAgencySteps } = agenciesQueries;
  const { data: steps, isLoading } = getAgencySteps();

  const updateNotification = (type: NotificationType, stepChosen: string) => {
    if (steps) {
      setSubmitting(true);
      updateAgencySteps.mutateAsync({
        agencyId: agency.id,
        steps: Object.values(steps).map((step) => {
          if (step.id === stepChosen) {
            return { ...step, notificationType: type };
          }
          return step;
        }),
      })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.update'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  if (isLoading) {
    return (
      <div>Loading...</div>
    );
  }
  return (
    <NotificationList
      updateNotification={updateNotification}
      submitting={submitting}
      notificationList={steps}
      agencySteps={steps}
    />
  );
}
