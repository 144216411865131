import React, { useRef, useState } from 'react';
import {
  DocumentCreation,
  DocumentData,
  documentSection,
} from 'types/DocumentData';
import DocumentSectionViewer
  from 'pages/agency-app/candidates/candidateDetails/candidateDocuments/documentSectionDetails/DocumentSectionViewer';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { ChevronRight } from '@material-ui/icons';

type Props = {
  documentsByTags?: { [tag: string]: DocumentData[] }
  section: string
  documentGroupId: string,
  candidatureId: string,
  contactId?: string,
  garantId?: string,
  label: string
  possibleTags: { [tag: string]: string }
};

export default function DocumentSectionDetails(
  {
    documentsByTags,
    section,
    documentGroupId,
    candidatureId,
    garantId,
    contactId,
    label,
    possibleTags,
  }: Props,
) {
  const candidatureQueries = useCandidatureBackend();
  const {
    saveCandidatureDocument,
  } = candidatureQueries;

  const submitAll = (toUploadDocs: DocumentCreation[]) => {
    saveCandidatureDocument.mutateAsync({
      data: toUploadDocs,
      candidatureId,
      documentGroupId,
    }).then(() => NotificationService.notifySuccess(Messages.t('notifications.update')))
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')));
  };

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const containerHeight = (ref.current && isOpen) ? ref.current.offsetHeight : 0;

  const extendStep = () => {
    setIsOpen(((prevState) => !prevState));
  };
  return (
    <div className="collapsable-container">
      <button type="button" onClick={extendStep} className="collapsable-title">
        <h3>{label}</h3>
        <div>
          <ChevronRight className={`extend-icon ${isOpen ? 'extended' : ''}`} />
        </div>
      </button>
      <div style={{ height: containerHeight }} className="collapsable-content">
        <div ref={ref} className="collapsable-body">
          {
            documentSection[section]?.filter((tag) => possibleTags[tag])
              .map((tag) => (
                <DocumentSectionViewer
                  garantId={garantId}
                  contactId={contactId}
                  documentGroupId={documentGroupId}
                  documentsByTags={documentsByTags}
                  tag={tag}
                  candidatureId={candidatureId}
                  key={tag}
                  onDropped={(newFiles) => {
                    submitAll(newFiles);
                  }}
                />
              ))
          }
        </div>
      </div>
    </div>
  );
}
