import React from 'react';
import PageBackButton from 'pages/common/PageBackButton';
import CandidateActions from 'pages/agency-app/candidates/candidateDetails/CandidateActions';
import { CandidatureDetailled } from 'types/Candidature';
import CandidatureTabs from 'pages/agency-app/candidates/candidateDetails/CandidatureTabs';
import { Controller, useForm } from 'react-hook-form';
import RadioWrapper from 'lib/form/RadioWrapper';
import { candidateNotation, CandidaturePartial } from 'types/CandidatureBase';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { stringToNumber } from 'lib/form/FormUtils';
import { NotificationService } from 'lib/notification';
import Notes from 'pages/common/Notes';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import Messages from 'services/i18n/Messages';
import { pngMapping } from 'types/OccupantRelationship';
import ContactUtils from 'services/ContactUtils';
import { Contact } from 'types/Contact';
import DocumentCard from 'pages/agency-app/candidates/candidateDetails/DocumentCard';
import { CANDIDATE } from 'routes/Routes';

type Props = {
  candidature?: CandidatureDetailled,
  dataUpdatedAt: number,
};

export default function Candidature({ candidature, dataUpdatedAt }: Props) {
  const candidatureQueries = useCandidatureBackend();
  const agentQueries = useAgentsBackend();
  const {
    updateCandidature,
    getCandidatureNotes,
    createCandidatureNotes,
    deleteCandidatureNote,
    updateCandidatureNote,
    getCandidatureDocumentsCopy,
  } = candidatureQueries;

  const { data: documentDataMap } = getCandidatureDocumentsCopy(candidature?.id || '', !!candidature);
  const {
    control,
  } = useForm<CandidatureDetailled>({
    defaultValues: {
      notation: candidature?.notation?.toString() || undefined,
    },
  });
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();
  const { data: notes } = getCandidatureNotes(candidature?.id || '', !!candidature?.id);

  const submitCandidate = (data: CandidaturePartial) => {
    updateCandidature.mutateAsync(data)
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')));
  };

  const submitCandidateNote = (data: string) => createCandidatureNotes.mutateAsync({
    candidatureId: candidature?.id || '',
    data: {
      message: data,
    },
  });

  const editNote = (message: string, noteId: string) => updateCandidatureNote.mutateAsync({
    candidatureId: candidature?.id || '',
    noteId,
    data: {
      message,
    },
  });

  const deleteNote = (noteId: string) => deleteCandidatureNote.mutateAsync({
    candidatureId: candidature?.id || '',
    noteId,
  });
  const getSortedContact = (candidate: CandidatureDetailled): Contact[] => candidate
    .contacts.sort((a, b) => (a.order || -1) - (b.order || -1));
  return (
    <>
      <div className="page-header page-actions">
        <PageBackButton to={CANDIDATE} prefGoBack />
        {
          candidature && (
            <CandidateActions candidature={candidature} />
          )
        }
      </div>
      <div className="page-body details">
        <div className="candidature-header">
          <div className="candidature-main-occupant-name">
            {candidature?.occupantRelationship && (
              <div className="relationship-image">
                <img
                  src={pngMapping[candidature.occupantRelationship]}
                  alt={candidature?.occupantRelationship}
                />
              </div>
            )}
            <h2>
              {
                candidature && ContactUtils.getDisplayedName(
                  getSortedContact(candidature)[0],
                  candidature.contacts.length,
                )
              }
            </h2>
          </div>
          {
            candidature && documentDataMap && (
              <DocumentCard
                documentDataMap={documentDataMap}
                candidature={candidature}
              />
            )
          }
        </div>
        <div className="candidate-details-page">
          <div>
            <CandidatureTabs
              dataUpdatedAt={dataUpdatedAt}
              agents={agents}
              documentDataMap={documentDataMap}
              candidature={candidature}
            />
          </div>
          <div>
            {
              candidature && (
                <>
                  <div>
                    <h5>{Messages.t('field.notation')}</h5>
                    <div className="candidature-radio-container">
                      <form>
                        <Controller
                          name="notation"
                          control={control}
                          render={(controller) => (
                            <RadioWrapper
                              hideButton
                              onChange={(value) => {
                                submitCandidate({
                                  notation: stringToNumber(value) || undefined,
                                  id: candidature.id,
                                });
                                return undefined;
                              }}
                              control={controller}
                              error={{}}
                              row
                              values={
                                Object.keys(candidateNotation).map((key) => (
                                  { key, label: candidateNotation[key] }
                                ))
                              }
                            />
                          )}
                        />
                      </form>
                    </div>
                  </div>
                  <div>
                    <h5>{Messages.t('field.notes')}</h5>
                    {
                      agents && notes && (
                        <Notes
                          notes={notes}
                          agents={agents}
                          editNote={editNote}
                          deleteNote={deleteNote}
                          addNote={submitCandidateNote}
                        />
                      )
                    }
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}
