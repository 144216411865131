import React, { useState } from 'react';
import { CandidatureDetailled } from 'types/Candidature';
import { CircularProgress, DialogContent } from '@material-ui/core';
import Messages from 'services/i18n/Messages';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import VisitSlotMissingModal
  from 'pages/common/visitSlots/VisitSlotMissingModal';
import { Agent } from 'types/Agent';
import VisitDialog from 'pages/common/visits/VisitDialog';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import calendarIcon from 'assets/images/calendar.svg';
import calendarShare from 'assets/images/calendarShare.svg';
import calendarAdd from 'assets/images/calendarAdd.svg';
import { NotificationService } from 'lib/notification';
import { AgencyStep, workflowStepEnum } from 'types/AgencyStep';
import CandidateVisitList from 'pages/agency-app/candidates/candidateDetails/candidateVisit/CandidateVisitList';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  candidature: CandidatureDetailled,
  open?: boolean,
  onClose: () => void,
  agents: Agent[],
  steps?: { [key: string]: AgencyStep }
};

export default function CandidateVisitModal(
  {
    candidature,
    onClose,
    open,
    agents,
    steps,
  }: Props,
) {
  const candidateQueries = useCandidatureBackend();
  const { sendVisitInvitation } = candidateQueries;
  const [createVisitModal, setCreateVisitModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const propertiesQueries = usePropertiesBackend();
  const { getProperties } = propertiesQueries;
  const { data: properties } = getProperties();
  const [candidatesMissingProperty, setCandidatesMissingProperty] = useState<string[]>([]);
  const [candidatesMissingTimeSlot, setCandidatesMissingTimeSlot] = useState<string[]>([]);

  const sendInvitation = (candidateIds: string[]) => {
    setSubmitting(true);
    sendVisitInvitation.mutateAsync({ candidateIds }).then((visitInvitationResult) => {
      setCandidatesMissingProperty(visitInvitationResult.candidatesMissingProperty);
      setCandidatesMissingTimeSlot(visitInvitationResult.candidatesMissingTimeSlot);
      if (visitInvitationResult.candidatesMissingProperty.length === 0
        && visitInvitationResult.candidatesMissingTimeSlot.length === 0) {
        NotificationService.notifySuccess(Messages.t('visit.invitationSend'));
        onClose();
      }
    }).catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      {
        (candidatesMissingProperty.length > 0 || candidatesMissingTimeSlot.length > 0)
        && properties && (
          <VisitSlotMissingModal
            agents={agents}
            properties={properties}
            onSubmitted={() => sendInvitation([
              ...candidatesMissingProperty,
              ...candidatesMissingTimeSlot,
            ])}
            onClose={() => {
              setCandidatesMissingTimeSlot([]);
              setCandidatesMissingProperty([]);
            }}
            candidatesMissingProperty={candidatesMissingProperty.length > 0 ? [candidature] : []}
            candidatesMissingTimeSlot={candidatesMissingTimeSlot.length > 0 ? [candidature] : []}
          />
        )
      }
      {
        createVisitModal && properties && (
          <VisitDialog
            onClose={() => setCreateVisitModal(false)}
            properties={properties}
            agents={agents}
            candidatures={[candidature]}
            defaultCandidature={candidature}
            open
          />
        )
      }
      <DialogWrapper open={!!open} onClose={onClose}>
        <DialogContent>
          <div className="candidate-visit-modal">
            <img src={calendarIcon} alt="calendar" />
            <h2>{Messages.t('candidate.inviteVisit')}</h2>
            {
              steps && steps[candidature.workflowStep || '']?.stepKey === workflowStepEnum.VIEWING_BOOKED && (
                <CandidateVisitList
                  hidePastVisit
                  label={Messages.t('visit.alreadyExist')}
                  candidature={candidature}
                  agents={agents}
                />
              )
            }
            <button
              type="button"
              className="candidate-visit-modal-button"
              onClick={() => setCreateVisitModal(true)}
            >
              <div className="icon-container">
                <img src={calendarAdd} alt="calendar" />
              </div>
              <div>
                <div className="button-title">
                  {Messages.t('visit.create')}
                </div>
                <div>
                  {Messages.t('visit.create.message')}
                </div>
              </div>
            </button>
            {
              steps && steps[candidature.workflowStep || '']?.stepKey !== workflowStepEnum.VIEWING_BOOKED && (
                <button
                  type="button"
                  className="candidate-visit-modal-button"
                  disabled={submitting}
                  onClick={() => sendInvitation([candidature.id])}
                >
                  <div className="icon-container">
                    {
                      submitting
                        ? <CircularProgress size={25} />
                        : <img src={calendarShare} alt="calendar" />
                    }

                  </div>
                  <div>
                    <div className="button-title">
                      {Messages.t('candidate.proposeViewing')}
                      {
                        steps && steps[candidature.workflowStep || '']?.stepKey === workflowStepEnum.VIEWING_PROPOSED && Messages.t('candidate.proposeViewing.renewal')
                      }
                    </div>
                    <div>
                      {Messages.t('candidate.proposeViewing.message')}
                    </div>
                  </div>
                </button>
              )
            }
          </div>
        </DialogContent>
      </DialogWrapper>
    </>
  );
}
