export type ProfessionnalSituationType = 'EMPLOYED' | 'STUDENT' | 'RETIRED' | 'FREELANCER' | 'OFFICIAL' | 'JOB_SEEKER';
export type GliType = 'EMPLOYED_CDI_TRIAL' | 'EMPLOYED_CDI_NO_TRIAL' | 'ALTERNANT' | 'INTERIMAIRE' | 'INTERMITTENT' | 'CDD' | 'STUDENT' | 'RETIRED' | 'FREELANCER' | 'OFFICIAL' | 'JOB_SEEKER';

export type ProfessionnalSituationTypeEnum = {
  [key in ProfessionnalSituationType]: key
};

export type GliTypeEnum = {
  [key in GliType]: key
};

export const proSituationTypeEnum: ProfessionnalSituationTypeEnum = {
  STUDENT: 'STUDENT',
  EMPLOYED: 'EMPLOYED',
  RETIRED: 'RETIRED',
  FREELANCER: 'FREELANCER',
  OFFICIAL: 'OFFICIAL',
  JOB_SEEKER: 'JOB_SEEKER',
};

export const garantProSituationTypeEnum: Partial<ProfessionnalSituationTypeEnum> = {
  EMPLOYED: 'EMPLOYED',
  RETIRED: 'RETIRED',
  FREELANCER: 'FREELANCER',
  OFFICIAL: 'OFFICIAL',
};

export const gliTypeEnum: GliTypeEnum = {
  EMPLOYED_CDI_TRIAL: 'EMPLOYED_CDI_TRIAL',
  EMPLOYED_CDI_NO_TRIAL: 'EMPLOYED_CDI_NO_TRIAL',
  ALTERNANT: 'ALTERNANT',
  INTERIMAIRE: 'INTERIMAIRE',
  INTERMITTENT: 'INTERMITTENT',
  CDD: 'CDD',
  STUDENT: 'STUDENT',
  RETIRED: 'RETIRED',
  FREELANCER: 'FREELANCER',
  OFFICIAL: 'OFFICIAL',
  JOB_SEEKER: 'JOB_SEEKER',
};
