import React from 'react';
import AgencySelector from 'pages/common/AgencySelector';
import Messages from 'services/i18n/Messages';
import sessionManager from 'services/sessionManager';
import sessionService from 'services/sessionService';

export default function AgencySelectorPage() {
  return (
    <div className="login-page">
      <h1>{Messages.t('agency.select')}</h1>
      <div className="login-card agency-select">
        <div>{Messages.t('user.connectWith', { mail: sessionManager.getSession()?.user_mail })}</div>
        {Messages.t('user.notYou')}
        <button
          className="log-out-button"
          onClick={() => sessionService.logOut()}
          type="button"
        >
          {Messages.t('button.logout')}
        </button>
        <AgencySelector />
      </div>
    </div>
  );
}
