import { useQuery } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';
import { Modification } from 'types/Modification';

export function useGarantBackend() {
  const GetGarantModification = (
    contactId: string,
    enabled = true,
  ) => useQuery<{ [field: string]: Modification[] }>(
    ['getGarantModification', sessionManager.getSession()?.agent_id, contactId],
    async () => {
      const response: Modification[] = await backend.fetchJson(
        `/api/garants/${contactId}/modifications`,
      );

      return response.reduce((acc, value) => {
        if (!acc[value.field]) {
          acc[value.field] = [];
        }
        acc[value.field].push(value);
        return acc;
      }, {}) as { [field: string]: Modification[] };
    },
    { enabled },
  );

  return {
    getGarantModification: GetGarantModification,
  };
}
