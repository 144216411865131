import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import RadioWrapper from 'lib/form/RadioWrapper';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { AgencyGlyForm, AgencyPartial } from 'types/Agency';
import { useAgencyBackend } from 'network/queries/AgencyQueries';
import { NotificationService } from 'lib/notification';
import SpinButton from 'theme/SpinButton';
import { booleanToString, stringToBoolean } from 'lib/form/FormUtils';

type Props = {
  agency: AgencyPartial;
};

export default function AgencyGliForm({ agency }: Props) {
  const agencyQueries = useAgencyBackend();
  const { updateAgency } = agencyQueries;
  const [submitting, setSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AgencyGlyForm>({
    defaultValues: {
      id: agency.id,
      activateGli: booleanToString(agency.activateGli) || 'false',
      gliRatio: agency?.gliRatio ? agency.gliRatio : 3,
    },
  });
  const formField = watch();
  const onSubmit = (data: AgencyGlyForm) => {
    setSubmitting(true);
    const newFormData = {
      ...data,
      activateGli: stringToBoolean(data.activateGli) || false,
      gliRatio: data?.gliRatio ? +(data?.gliRatio?.toString()?.replace(',', '.') || 0) : 3,
    };

    updateAgency.mutateAsync({ agency: newFormData, agencyId: agency.id })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
      .finally(() => setSubmitting(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="activateGli"
        render={(controller) => (
          <RadioWrapper
            label={Messages.t('parameters.occupantQualif.GLI.activateQuestion')}
            control={controller}
            values={[{ key: 'true', label: Messages.t('generics.YES') }, {
              key: 'false',
              label: Messages.t('generics.NO'),
            }]}
            error={errors}
          />
        )}
      />
      {
        stringToBoolean(formField.activateGli) && (
          <Controller
            control={control}
            name="gliRatio"
            rules={{ validate: (v) => (+(v?.toString().replace(',', '.') || 0)) > 1 || Messages.t('gli.ratio.minimumError') }}
            render={(controller) => (
              <TextFieldWrapper
                control={controller}
                error={errors}
                label={Messages.t('gli.tooltip.ratioLong')}
              />
            )}
          />
        )
      }
      <SpinButton
        editing
        spin={submitting}
        title={Messages.t('formButton.confirm')}
      />
    </form>
  );
}
