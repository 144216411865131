import React from 'react';

type Props = {
  count: number,
  label: string,
};

export default function StepStatus({ count, label }: Props) {
  return (
    <div className="step-status">
      <span>{count}</span>
      <span>{label}</span>
    </div>
  );
}
