import { Property } from 'types/Property';

export type AppartmentFiltersKeys = 'search' | 'city' | 'agent' | 'isArchived' | 'online';

export type AppartmentFilters = {
  [key in AppartmentFiltersKeys]?: string;
};

export const appartmentFilterDefaultValues: AppartmentFilters = {
  search: '',
  city: '',
  agent: '',
  isArchived: 'NO',
  online: '',
};

export type AppartmentFilterFunctions = Omit<{
  [key in AppartmentFiltersKeys]: (property: Property, filter: string) => boolean;
}, 'isArchived'>;
