import React from 'react';
import { Property } from 'types/Property';
import { Agent } from 'types/Agent';
import { useVisitSlotBackend } from 'network/queries/VisitSlotQueries';
// The component is recursive
// eslint-disable-next-line import/no-cycle
import VisitSlotForm from 'pages/common/visitSlots/VisitSlotForm';
import { CandidatureShort } from 'types/Candidature';
import DialogWrapper from 'pages/common/DialogWrapper';

type Props = {
  properties: Property[],
  agents: Agent[],
  candidatures: CandidatureShort[]
  defaultProperty?: string,
  visitSlotId?: string,
  open: boolean,
  onClose: () => void,
  onSubmitted?: () => void,
};

export default function VisitSlotDialog(
  {
    properties,
    agents,
    open,
    onClose,
    visitSlotId,
    candidatures,
    defaultProperty,
    onSubmitted,
  }: Props,
) {
  const visitSlotQueries = useVisitSlotBackend();
  const { getVisitSlot } = visitSlotQueries;
  const { data: visitSlot } = getVisitSlot(visitSlotId || '', !!visitSlotId);
  return (
    <DialogWrapper open={open} onClose={() => onClose()}>
      <div className="visit-slot-modal">
        {
          (!visitSlotId || visitSlot) && (
            <VisitSlotForm
              onSubmitted={() => {
                if (onSubmitted) {
                  onSubmitted();
                }
                onClose();
              }}
              defaultPropertyId={defaultProperty}
              candidatures={candidatures}
              visitSlot={visitSlot}
              properties={properties}
              agents={agents}
            />
          )
        }
      </div>
    </DialogWrapper>
  );
}
