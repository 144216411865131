import { useQuery } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';
import { AgentWithAgency } from 'types/Agent';

export function useUserQueries() {
  const GetUserAgents = (enabled = true) => useQuery<AgentWithAgency[]>(
    ['getUserAgents', sessionManager.getSession()?.user_id],
    async () => {
      const response: AgentWithAgency[] = await backend.fetchJson(
        '/api/users/agents',
      );
      return response;
    },
    { enabled },
  );

  return {
    getUserAgents: GetUserAgents,
  };
}
