import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SelectWrapper from 'lib/form/SelectWrapper';
import { appartmentFilterDefaultValues, AppartmentFilters } from 'types/filters/AppartmentFilters';
import { Search } from '@material-ui/icons';
import { useAgentsBackend } from 'network/queries/AgentQueries';
import propertiesFilterService from 'services/filters/PropertiesFilterService';
import { Property } from 'types/Property';
import { ALL, NONE } from 'services/filters/consts';
import { removeDuplicateFromArrayOfStrings } from 'services/ArrayUtils';
import StringUtils from 'services/StringUtils';
import { IconButton, Popover } from '@material-ui/core';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import { useHistory, useLocation } from 'react-router-dom';
import AgentUtils from 'services/AgentUtils';
import FiltersUtils from 'services/filters/FiltersUtils';
import { Routes } from 'routes/RoutesUtils';

type Props = {
  properties?: Property[]
};

export default function Filters({ properties }: Props) {
  const location = useLocation();
  const history = useHistory();

  const [apiErrors] = useState({});
  const agentQueries = useAgentsBackend();
  const { getAgents } = agentQueries;
  const { data: agents } = getAgents();

  const [anchorEl, setAnchorEl] = useState(null);
  const queryParams = new URLSearchParams(location.search);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<AppartmentFilters>({
    defaultValues: {
      ...appartmentFilterDefaultValues,
      ...propertiesFilterService.getFilter(),
      ...FiltersUtils.getObjectFromQueryParam<AppartmentFilters>({
        search: '',
        city: '',
        agent: '',
        isArchived: '',
        online: '',
      }, queryParams),
    },
  });
  const formField = watch();

  useEffect(() => {
    propertiesFilterService.updateFilters(formField);
    history.replace(Routes.updatePathWithQuery(
      location,
      FiltersUtils.getQueryParamsFromObject(formField),
    ));
  }, [formField.agent, formField.city, formField.search, formField.online, formField.isArchived]);

  const cities = useMemo(() => properties?.reduce((acc, val) => {
    if (val.city && val.city !== '') {
      acc[val.city] = val.city;
    }
    return acc;
  }, {}) || {}, [properties?.length]) as { [key: string]: string };

  const filteredCitiesArr = removeDuplicateFromArrayOfStrings(Object.keys(cities));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <form className="filter-header">
      <Controller
        name="isArchived"
        control={control}
        render={(controller) => (
          <SelectWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            label={Messages.t('field.isArchived')}
            values={[
              {
                key: 'YES',
                label: Messages.t('filter.isArchived'),
              },
              {
                key: 'NO',
                label: Messages.t('filter.isNotArchived'),
              },
            ]}
          />
        )}
      />
      <Controller
        name="search"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            prefix={<Search />}
            type="text"
            control={controller}
            label={Messages.t('field.search')}
          />
        )}
      />
      <Controller
        name="city"
        control={control}
        render={(controller) => (
          <SelectWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            label={Messages.t('field.city')}
            values={[
              {
                key: ALL,
                label: Messages.t('filter.All'),
              },
              ...filteredCitiesArr.map((city) => (
                { key: city, label: StringUtils.getPrettyAddress(city) }
              )),
            ]}
          />
        )}
      />
      {
        agents && (
          <Controller
            name="agent"
            control={control}
            render={(controller) => (
              <SelectWrapper
                error={{}}
                control={controller}
                label={Messages.t('field.agent')}
                values={[
                  {
                    key: ALL,
                    label: Messages.t('filter.All'),
                  },
                  {
                    key: NONE,
                    label: Messages.t('filter.None'),
                  },
                  ...agents.map((agent) => ({
                    key: agent.id,
                    label: AgentUtils.getPrettyName(agent),
                  })),
                ]}
              />
            )}
          />
        )
      }
      <IconButton className="adjustment" onClick={handleClick}>
        <AdjustmentsIcon />
      </IconButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Controller
          name="online"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('field.status')}
              values={[
                {
                  key: 'ALL',
                  label: Messages.t('filter.All'),
                },
                {
                  key: 'true',
                  label: Messages.t('status.online'),
                },
                {
                  key: 'false',
                  label: Messages.t('status.offline'),
                },
              ]}
            />
          )}
        />
      </Popover>
    </form>
  );
}
