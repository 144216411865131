import React from 'react';
import back from 'assets/images/backArrow.svg';
import Messages from 'services/i18n/Messages';
import { useHistory } from 'react-router-dom';

type Props = {
  to?: string,
  prefGoBack?: boolean
};
export default function PageBackButton({ to, prefGoBack }: Props) {
  const history = useHistory();

  const leavePage = async () => {
    if (prefGoBack) {
      if (history.length > 1) {
        history.goBack();
        return;
      }
    }
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  };
  return (
    <button className="back-button link" onClick={leavePage} type="button">
      <img src={back} alt="back" />
      <span>{Messages.t('formButton.back')}</span>
    </button>
  );
}
