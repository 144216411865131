import { useQuery } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessionManager';
import { Activity } from 'types/Activity';

export function useActivitiesBackend() {
  const GetActivities = (enabled = true) => useQuery<Activity[]>(
    ['getActivities', sessionManager.getSession()?.agent_id],
    async () => {
      const response: Activity[] = await backend.fetchJson(
        '/api/activities',
      );
      return response;
    },
    { enabled },
  );

  return {
    getActivities: GetActivities,
  };
}
