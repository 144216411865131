import React, { Dispatch, SetStateAction } from 'react';
import { Note, NoteCreation } from 'types/Note';
import { MoreVert } from '@material-ui/icons';
import Messages from 'services/i18n/Messages';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import AgentUtils from 'services/AgentUtils';
import DateUtils from 'services/DateUtils';
import sessionManager from 'services/sessionManager';
import { Agent } from 'types/Agent';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SpinButton from 'theme/SpinButton';
import Button from 'theme/Button';

type Props = {
  note: Note
  deleteNote: () => void
  agentMap: { [key: string]: Agent },
  submitting?: boolean,
  setSubmitting: Dispatch<SetStateAction<boolean>>
  editNote: (message: string) => Promise<Note>
};

export default function NoteItem(
  {
    note,
    deleteNote,
    agentMap,
    submitting,
    setSubmitting,
    editNote,
  }: Props,
) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editing, setEditNote] = React.useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<NoteCreation>({
    defaultValues: { message: note.message },
  });

  const formField = watch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSubmit: SubmitHandler<NoteCreation> = (formData: NoteCreation) => {
    setSubmitting(true);
    editNote(formData.message)
      .then(() => {
        setEditNote(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div
      className={`note-container ${(note.authorID && agentMap[note.authorID]?.userAccountID === sessionManager.getSession()?.user_id) ? 'is_author' : ''}`}
    >
      <div className="note">
        <div className="note-header">
          <div
            className="agent-name"
          >
            {note.authorID && AgentUtils.getPrettyName(agentMap[note.authorID])}
          </div>
          <div className="note-date">
            {DateUtils.getPrettyDateDeltaFromNow(note.createdAt, false, true)}
          </div>
        </div>
        <div className="note-body">
          <div className="note-message">
            {
              editing ? (
                <>
                  <form className="add-note-form" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      name="message"
                      control={control}
                      render={(controller) => (
                        <TextFieldWrapper
                          apiErrors={{}}
                          error={errors}
                          type="textarea"
                          control={controller}
                          label={Messages.t('field.note')}
                        />
                      )}
                    />
                    <div>
                      <Button
                        disabled={submitting}
                        color="secondary"
                        onClick={() => setEditNote(false)}
                      >
                        {Messages.t('formButton.cancel')}
                      </Button>
                      <SpinButton
                        editing
                        disabled={!formField.message}
                        spin={!!submitting}
                        title={Messages.t('formButton.edit')}
                      />
                    </div>
                  </form>
                </>
              ) : (
                note.message
              )
            }
          </div>
          {
            (note.authorID
              && agentMap[note.authorID]?.userAccountID
              === sessionManager.getSession()?.user_id) && (
              <>
                <IconButton onClick={(e) => handleClick(e)}>
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    disabled={submitting}
                    onClick={() => {
                      setEditNote(true);
                      handleClose();
                    }}
                  >
                    {Messages.t('formButton.edit')}
                  </MenuItem>
                  <MenuItem
                    disabled={submitting}
                    className="danger-item"
                    onClick={() => {
                      deleteNote();
                      handleClose();
                    }}
                  >
                    {Messages.t('formButton.delete')}
                  </MenuItem>
                </Menu>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}
