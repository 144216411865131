import React from 'react';
import Messages from 'services/i18n/Messages';
import { AgentNotification, NotificationType, notificationType } from 'types/AgentNotification';
import Selector from 'lib/form/Selector';
import { AgencyStep } from 'types/AgencyStep';

type Props = {
  updateNotification: (type: NotificationType, stepChosen: string) => void;
  submitting: boolean;
  agencySteps?: { [p: string]: AgencyStep };
  notificationList?: { [p: string]: AgencyStep } | { [p: string]: AgentNotification };
};

export default function NotificationList(
  {
    updateNotification,
    submitting,
    agencySteps,
    notificationList,
  }: Props,
) {
  return (
    <div className="agent-list">
      {
        agencySteps && Object.values(agencySteps)
          .sort((a, b) => (a.order || 0) - (b.order || 0))
          .map((step) => (
            <div key={step.id} className="notification-editor">
              <div className="notification-name">{step.stepName}</div>
              <Selector
                disabled={submitting}
                value={(notificationList?.[step.id]?.notificationType || notificationType.NONE.key)}
                label={Messages.t('notification.type')}
                onChange={(value) => updateNotification(value as NotificationType, step.id)}
                values={Object.values(notificationType).map((key) => ({
                  key: key.key, label: Messages.t(`notification.${key.key}`), icon: key.icon,
                }))}
              />
            </div>
          ))
      }
    </div>
  );
}
