import React from 'react';
import { CandidatureShort } from 'types/Candidature';
import AgendaEventDateDisplay from 'pages/common/calendar/AgendaEventDateDisplay';
import CandidatureUtils from 'services/CandidatureUtils';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { RbcEvent, RbcTime } from 'types/ReactBigCalendar';
import { Visit } from 'types/Visit';
import Messages from 'services/i18n/Messages';

type Props = {
  candidature: CandidatureShort,
  event: RbcEvent<Visit>
  time: RbcTime<RbcEvent<Visit>>,
  onClick: () => void
};

export default function VisitAgendaEvent(
  {
    event,
    time,
    candidature,
    onClick,
  }: Props,
) {
  if (!event.start) {
    return null;
  }
  if (!event.end) {
    return null;
  }
  const name = CandidatureUtils.getPrettyName(candidature);

  return (
    <button
      type="button"
      onClick={onClick}
      className={`agenda-visit agenda-page-event ${event.resource?.isCancel ? 'canceled' : ''}`}
    >
      <AgendaEventDateDisplay
        event={event}
        time={time}
      />
      <div className="candidate-name">
        <div className="candidate-initials">
          {
            name.split(' ').slice(0, 2).map((word) => (
              word[0]
            ))
          }
        </div>
        <div>
          <div>{name}</div>
          <div className="candidature-email">{CandidatureUtils.getPrincipalMail(candidature)}</div>
        </div>
      </div>
      {
        event.resource?.isCancel ? (
          <div className="property-address">
            {Messages.t('visit.canceled')}
          </div>
        ) : (
          <div className="property-address">
            <LocationMarkerIcon />
            <div>{event.resource?.address}</div>
          </div>
        )
      }
    </button>
  );
}
