import React, { useEffect } from 'react';
import Messages from 'services/i18n/Messages';
import { CandidatureDetailled } from 'types/Candidature';
import { Controller, useForm } from 'react-hook-form';
import PropertiesSelector from 'pages/common/PropertySelector';
import { usePropertiesBackend } from 'network/queries/PropertiesQueries';
import { CandidaturePartial } from 'types/CandidatureBase';
import { NotificationService } from 'lib/notification';
import { useCandidatureBackend } from 'network/queries/CandidatureQueries';
import { Routes } from 'routes/RoutesUtils';
import {
  APPARTMENT,
  APPARTMENT_ADD_PROPERTY,
  ID,
  PROPERTY_DETAIL,
} from 'routes/Routes';
import { OpenInNew, Warning } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { removeDuplicateFromArrayOfStrings } from 'services/ArrayUtils';
import CandidatureUtils from 'services/CandidatureUtils';
import StringUtils from 'services/StringUtils';
import { Agent } from 'types/Agent';
import CandidateVisitList from 'pages/agency-app/candidates/candidateDetails/candidateVisit/CandidateVisitList';
import { ADD_PROPERTY } from 'routes/QueryParams';
import { ProfessionnalSituationType } from 'types/ProfesionalSituation';

type Props = {
  candidature: CandidatureDetailled;
  agents?: Agent[];
  dataUpdatedAt: number,
};

export default function CandidatureSummary(
  {
    candidature,
    agents,
    dataUpdatedAt,
  }: Props,
) {
  const {
    control,
    formState: { errors },
    reset,
  } = useForm<{ propertyId: string }>({
    defaultValues: { propertyId: candidature.propertyID },
  });
  const candidatureQueries = useCandidatureBackend();
  const { updateCandidature } = candidatureQueries;
  const submitCandidature = (data: CandidaturePartial) => {
    updateCandidature.mutateAsync(data)
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
      })
      .catch(() => NotificationService.notifyError(Messages.t('notifications.error')));
  };
  // const agencyQueries = useAgencyBackend();
  // const { getCurrentAgency } = agencyQueries;
  // const { data: agency } = getCurrentAgency();
  const caseIncome = CandidatureUtils.caseIncome(candidature.contacts);
  const garantsIncome = CandidatureUtils.garantsIncome(candidature);
  const propertiesQueries = usePropertiesBackend();
  const { getProperties } = propertiesQueries;
  const { data: propertyList } = getProperties();

  const contactsArray = candidature.contacts;

  useEffect(() => {
    reset({ propertyId: candidature.propertyID });
  }, [dataUpdatedAt]);

  // const isPinel = candidature.property?.pinelEnabled;
  // const eligibilitePinel = candidature.pinelEligible;
  // const showGliStatus = agency?.activateGli && candidature?.property?.gliEnabled;
  // const isGliOk = candidature.gliEligible;

  return (
    <div className="summary">
      <div className="candidacy-summary">
        {
          !!caseIncome && caseIncome > 0 && (
            <div>
              <h5>{Messages.t('summary.caseIncome')}</h5>
              <p className="candidature-summary-text">
                {`${caseIncome} €`}
              </p>
            </div>
          )
        }
        {
          candidature.garants.length > 0 && (
            <div>
              <h5>{Messages.t('summary.garants')}</h5>
              {
                !!garantsIncome && garantsIncome >= 0 && (
                  <p className="candidature-summary-text">
                    {Messages.t('summary.garantPersonRevenus')}: {garantsIncome} €
                  </p>
                )
              }
              <p className="candidature-summary-text">
                {Messages.t('summary.type')} : {
                candidature.garants
                && removeDuplicateFromArrayOfStrings(candidature?.garants.map(
                  (garant) => {
                    if (garant.type) {
                      return Messages.t(`garantType.${garant.type}`);
                    }
                    return Messages.t('summary.unknown');
                  },
                )).join(', ')
              }
              </p>
            </div>
          )
        }
        {
          candidature.plateform && (
            <div>
              <h5>{Messages.t('field.source')}</h5>
              <p className="candidature-summary-text">
                {StringUtils.capitalizeOnlyFirstLetter(candidature.plateform)}
              </p>
            </div>
          )
        }
        {candidature.source && (
          <div>
            <h5>{Messages.t('field.canal')}</h5>
            <p className="candidature-summary-text">
              {StringUtils.capitalizeOnlyFirstLetter(candidature.source)}
            </p>
          </div>
        )}
        <div>
          <h5>{Messages.t('summary.professionalSituation')}</h5>
          {
            contactsArray?.length === 1 ? (
              contactsArray[0].situation
                ? (
                  <p className="candidature-summary-text">
                    {contactsArray[0].situation.split(',').map((job) => (
                      Messages.t(`professionalSituation.${job as ProfessionnalSituationType}`)
                    )).join(', ')}
                  </p>
                ) : (
                  <p className="candidature-summary-text">
                    {Messages.t('summary.unknownProfessionalSituation')}
                  </p>
                )
            ) : (
              <ul>
                {
                  contactsArray?.map((contact, index) => (
                    <li key={contact.id} className="candidature-summary-text">
                      {Messages.t('field.contact')} {index + 1}: {
                      contact.situation ? contact.situation.split(',').map((job) => (
                        Messages.t(`professionalSituation.${job as ProfessionnalSituationType}`)
                      )).join(', ') : (
                        Messages.t('summary.unknownProfessionalSituation'))
                    }
                    </li>
                  ))
                }
              </ul>
            )
          }
        </div>
        {
          candidature.moveInWanted && (
            <div>
              <h5>{Messages.t('field.moveInWanted')}</h5>
              <p className="candidature-summary-text">{candidature.moveInWanted}</p>
            </div>
          )
        }
        {
          candidature.message && (
            <div className="summary-message">
              <h5>{Messages.t('field.message')}</h5>
              <p className="candidature-summary-text">{candidature.message}</p>
            </div>
          )
        }
        {
          candidature.formMessage && (
            <div className="summary-message">
              <h5>{Messages.t('field.messageForm')}</h5>
              <p className="candidature-summary-text">{candidature.formMessage}</p>
            </div>
          )
        }
        {
          candidature.motivation && (
            <div className="summary-message">
              <h5>{Messages.t('field.motivation')}</h5>
              <p className="candidature-summary-text">{candidature.motivation}</p>
            </div>
          )
        }
        {
          // (showGliStatus || isPinel) && (
          //   <div className="summary-message">
          //     <h5>{Messages.t('field.occupantQualification')}</h5>
          //     {showGliStatus && (
          //       <p className="candidature-summary-text">
          //         {`${Messages.t('summary.gliEgible')}: ${isGliOk
          //         ? Messages.t('generics.YES') : Messages.t('generics.NO')}`}
          //       </p>
          //     )}
          //     {isPinel && (
          //       <p className="candidature-summary-text">
          // eslint-disable-next-line max-len
          //         {`${Messages.t('summary.pinelEligible')}: ${eligibilitePinel ? Messages.t('generics.YES') : Messages.t('generics.NO')}`}
          //       </p>
          //     )}
          //   </div>
          // )
        }
      </div>
      {
        !candidature.propertyID
        && candidature.propertyDescription
        && (
          <div className="warning-message">
            <div className="icon-warning">
              <Warning />
            </div>
            <div>
              {Messages.t('candidate.noAnnounce', { description: candidature?.propertyDescription })}
              <Link to={APPARTMENT_ADD_PROPERTY}>
                {Messages.t('formButton.clickHere')}
              </Link>
            </div>
          </div>
        )
      }
      {
        !candidature.propertyID
        && candidature.propertyURL
        && (
          <div className="warning-message">
            <div className="icon-warning">
              <Warning />
            </div>
            <div>
              <div>
                <div>
                  {Messages.t('candidate.noAnnounceLink')}
                </div>
                <a href={candidature?.propertyURL} target="_blank" rel="noreferrer">
                  {candidature?.propertyURL}
                </a>
              </div>
              {Messages.t('candidate.noAnnounceLinkAdd')}
              <Link
                to={{
                  pathname: APPARTMENT,
                  search: `?${ADD_PROPERTY}=true`,
                  state: { propertyUrl: candidature?.propertyURL, candidateId: candidature.id },
                }}
              >
                {Messages.t('formButton.clickHere')}
              </Link>
            </div>
          </div>
        )
      }
      {propertyList && (
        <div className="property-select">
          {candidature.propertyID && (
            <Link
              to={Routes.withPath(
                PROPERTY_DETAIL,
                [{ label: ID, value: candidature.propertyID }],
              )}
              target="_blank"
            >
              <div>
                <p>{Messages.t('property.seeListing')}</p>
                <OpenInNew />
              </div>
            </Link>
          )}
          <Controller
            name="propertyId"
            control={control}
            render={(controller) => (
              <PropertiesSelector
                showOffline
                apiErrors={{}}
                error={errors}
                control={controller}
                label={Messages.t('field.property')}
                properties={propertyList}
                onChange={(propertyID) => submitCandidature({ propertyID, id: candidature.id })}
              />
            )}
          />
        </div>
      )}
      <CandidateVisitList candidature={candidature} agents={agents} />
    </div>
  );
}
